const MAX_WEBSOCKET_FAILS = 7;
const MIN_WEBSOCKET_RETRY_TIME = 3000; // 3 sec
const MAX_WEBSOCKET_RETRY_TIME = 300000; // 5 mins
const JITTER_RANGE = 2000; // 2 sec
const WEBSOCKET_HELLO = "hello";

class WebSocketClient {
  constructor() {
    // this.eventCallback = null;
    // this.firstConnectCallback = null;
    // this.reconnectCallback = null;
    // this.missedEventCallback = null;
    // this.errorCallback = null;
    // this.closeCallback = null;
    this.messageListeners = new Set();
    this.firstConnectListeners = new Set();
    this.reconnectListeners = new Set();
    this.missedMessageListeners = new Set();
    this.errorListeners = new Set();
    this.closeListeners = new Set();
    this.conn = null;
    this.connectionUrl = null;
    this.responseSequence = 1;
    this.serverSequence = 0;
    this.connectFailCount = 0;
    this.responseCallbacks = {};
    this.connectionId = "";
  }

  initialize(connectionUrl = this.connectionUrl, token) {
    if (this.conn) {
      return;
    }
    if (connectionUrl == null) {
      console.log("websocket must have a connection URL");
      return;
    }
    if (this.connectFailCount === 0) {
      console.log("websocket connecting to " + connectionUrl);
    }

    this.conn = new WebSocket(
      `${connectionUrl}?connection_id=${this.connectionId}&sequence_number=${this.serverSequence}`
    );
    this.connectionUrl = connectionUrl;

    this.conn.onopen = () => {
      if (token) {
        this.sendMessage("authentication_challenge", { token });
      }
      if (this.connectFailCount > 0) {
        console.log("websocket re-established connection");
        this.reconnectListeners.forEach(listener => listener());
      } else if (this.firstConnectListeners.size > 0) {
        this.firstConnectListeners.forEach(listener => listener());
      }
      this.connectFailCount = 0;
    };

    this.conn.onclose = () => {
      this.conn = null;
      this.responseSequence = 1;
      if (this.connectFailCount === 0) {
        console.log("websocket closed");
      }
      this.connectFailCount++;
      this.closeListeners.forEach(listener => listener(this.connectFailCount));
      let retryTime = MIN_WEBSOCKET_RETRY_TIME;
      if (this.connectFailCount > MAX_WEBSOCKET_FAILS) {
        retryTime =
          MIN_WEBSOCKET_RETRY_TIME *
          this.connectFailCount *
          this.connectFailCount;
        if (retryTime > MAX_WEBSOCKET_RETRY_TIME) {
          retryTime = MAX_WEBSOCKET_RETRY_TIME;
        }
      }
      retryTime += Math.random() * JITTER_RANGE;
      setTimeout(() => {
        this.initialize(connectionUrl, token);
      }, retryTime);
    };

    this.conn.onerror = evt => {
      if (this.connectFailCount <= 1) {
        console.log("websocket error");
        console.log(evt);
      }
      this.errorListeners.forEach(listener => listener(evt));
    };

    this.conn.onmessage = evt => {
      const msg = JSON.parse(evt.data);

      console.log("Inside onMessage==============>", msg);
      if (msg.seq_reply) {
        if (msg.error) {
          console.log(msg);
        }
        if (this.responseCallbacks[msg.seq_reply]) {
          this.responseCallbacks[msg.seq_reply](msg);
          delete this.responseCallbacks[msg.seq_reply];
        }
      } else if (this.messageListeners.size > 0) {
        if (
          msg.event === WEBSOCKET_HELLO &&
          this.missedMessageListeners.size > 0
        ) {
          console.log("got connection id ", msg.data.connection_id);
          if (
            this.connectionId !== "" &&
            this.connectionId !== msg.data.connection_id
          ) {
            console.log(
              "long timeout, or server restart, or sequence number is not found."
            );
            this.missedMessageListeners.forEach(listener => listener());
            this.serverSequence = 0;
          }
          this.connectionId = msg.data.connection_id;
        }
        if (msg.seq !== this.serverSequence) {
          console.log(
            "missed websocket event, act_seq=" +
              msg.seq +
              " exp_seq=" +
              this.serverSequence
          );
          this.connectFailCount = 0;
          this.responseSequence = 1;
          this.conn.close();
          return;
        }
        this.serverSequence = msg.seq + 1;
        // this.eventCallback(msg);
        this.messageListeners.forEach(listener => listener(msg));
      }
    };
  }

  addMessageListener(listener) {
    this.messageListeners.add(listener);
  }

  removeMessageListener(listener) {
    this.messageListeners.delete(listener);
  }

  addFirstConnectListener(listener) {
    this.firstConnectListeners.add(listener);
  }

  removeFirstConnectListener(listener) {
    this.firstConnectListeners.delete(listener);
  }

  addReconnectListener(listener) {
    this.reconnectListeners.add(listener);
  }

  removeReconnectListener(listener) {
    this.reconnectListeners.delete(listener);
  }

  addMissedMessageListener(listener) {
    this.missedMessageListeners.add(listener);
  }

  removeMissedMessageListener(listener) {
    this.missedMessageListeners.delete(listener);
  }

  addErrorListener(listener) {
    this.errorListeners.add(listener);
  }

  removeErrorListener(listener) {
    this.errorListeners.delete(listener);
  }

  close() {
    this.connectFailCount = 0;
    this.responseSequence = 1;
    if (this.conn && this.conn.readyState === WebSocket.OPEN) {
      this.conn.onclose = () => {};
      this.conn.close();
      this.conn = null;
      console.log("websocket closed");
    }
  }

  sendMessage(action, data, responseCallback) {
    const msg = {
      action,
      seq: this.responseSequence++,
      data
    };
    if (responseCallback) {
      this.responseCallbacks[msg.seq] = responseCallback;
    }
    if (this.conn && this.conn.readyState === WebSocket.OPEN) {
      this.conn.send(JSON.stringify(msg));
    } else if (!this.conn || this.conn.readyState === WebSocket.CLOSED) {
      this.conn = null;
      this.initialize();
    }
  }

  userTyping(channelId, parentId, callback) {
    const data = {
      channel_id: channelId,
      parent_id: parentId
    };
    this.sendMessage("user_typing", data, callback);
  }

  userUpdateActiveStatus(userIsActive, manual, callback) {
    const data = {
      user_is_active: userIsActive,
      manual
    };
    this.sendMessage("user_update_active_status", data, callback);
  }

  getStatuses(callback) {
    this.sendMessage("get_statuses", null, callback);
  }

  getStatusesByIds(userIds, callback) {
    const data = {
      user_ids: userIds
    };
    this.sendMessage("get_statuses_by_ids", data, callback);
  }
}

export default WebSocketClient;
