import axios from "axios";
import config from "../config.js";

export default {
  registerUser: function(data) {
    return new Promise((resolve, reject) => {
      let url = config.DOMAIN + config.API_VERSION + "users/register";
      axios
        .post(url, data)
        .then(response => {
          resolve(response);
        })
        .catch(e => {
          reject(e);
        });
    });
  },
  saveApplication: function(data) {
    return new Promise((resolve, reject) => {
      let url = config.DOMAIN + config.API_VERSION + "company_info";
      let method = "post";
      if (data?.id) {
        url += "/" + data.id;
        method = "put";
      }
      axios({
        method: method,
        url: url,
        data: data
      })
        .then(response => {
          resolve(response);
        })
        .catch(e => {
          reject(e);
        }); //axios
    });
  },
  fetchUserPreferenceByUserIdAndRoleId(data) {
    // http://localhost/api/v1/sales_person_details/:id
    return new Promise(function(resolve, reject) {
      var url =
        config.DOMAIN +
        config.API_VERSION +
        "userpreferences?user_id=" +
        data.user_id +
        "&role_id=" +
        data.role_id;
      if (!!data.company_posting_id)
        url = url + "&company_posting_id=" + data.company_posting_id;
      axios
        .get(url)
        .then(response => {
          resolve(response);
        })
        .catch(e => {
          reject(e.response.data.message);
        });
    });
  },
  createPostApplication: function(data) {
    return new Promise((resolve, reject) => {
      let url = config.DOMAIN + config.API_VERSION + "company_posting";
      axios
        .post(url, data)
        .then(response => {
          resolve(response);
        })
        .catch(e => {
          reject(e);
        }); //axios
    });
  },
  deletePostApplication: function(id) {
    return new Promise((resolve, reject) => {
      let url = config.DOMAIN + config.API_VERSION + "company_posting/" + id;

      axios
        .delete(url)
        .then(response => {
          console.log("Posting deleted...");
          resolve(response);
        })
        .catch(e => {
          reject(e);
        });
    });
  },
  savePostApplication: function(data) {
    return new Promise((resolve, reject) => {
      let url = config.DOMAIN + config.API_VERSION + "company_posting";
      let method = "post";
      if (data?.id) {
        url += "/" + data.id;
        method = "put";
      }
      axios({
        method: method,
        url: url,
        data: data
      })
        .then(response => {
          resolve(response);
        })
        .catch(e => {
          reject(e);
        }); //axios
    });
  },
  getPostDetailsById: function(id) {
    return new Promise((resolve, reject) => {
      let url = config.DOMAIN + config.API_VERSION + "company_posting/" + id;
      axios
        .get(url)
        .then(response => {
          resolve(response);
        })
        .catch(e => {
          reject(e);
        }); //axios
    });
  },
  fetchUserPreferences(data) {
    // http://localhost/api/v1/preferences?sales_application_type_id=1&preferences_type_id=2
    return new Promise(function(resolve, reject) {
      var url =
        config.DOMAIN +
        config.API_VERSION +
        "userpreferences/getuserpreferences";

      url =
        url +
        "?sales_application_type_id=" +
        data.sales_application_type_id +
        "&preferences_type_id=" +
        data.preferences_type_id +
        "&role_id=" +
        data.role_id +
        "&user_id=" +
        data.user_id +
        "&company_posting_id=" +
        data.company_posting_id;

      axios
        .get(url)
        .then(response => {
          resolve(response);
        })
        .catch(e => {
          reject(e.response.data.message);
        });
    });
  },
  prospectingSearch: function(payloadData, timestamp) {
    // http://localhost/api/v1/company_posting/companypostingsforappliedfilters
    return new Promise((resolve, reject) => {
      let url =
        config.DOMAIN +
        config.API_VERSION +
        "company_posting/companypostingsforappliedfilters";

      axios
        .post(url, payloadData)
        .then(response => {
          response.data["timeStamp"] = timestamp;
          resolve(response);
        })
        .catch(e => {
          reject(e);
        });
    });
  },
  getPostings: function(data) {
    return new Promise((resolve, reject) => {
      let url =
        config.DOMAIN +
        config.API_VERSION +
        "company_posting?user_id=" +
        data.user_id +
        "&role_id=" +
        data.role_id +
        "&limit=6" +
        "&status=" +
        data.status;
      if (!!data.company_info_id)
        url = url + "&company_info_id=" + data.company_info_id;

      axios
        .get(url)
        .then(response => {
          resolve(response);
        })
        .catch(e => {
          reject(e);
        }); //axios
    });
  },
  getOneExistingPosting: function(data) {
    return new Promise((resolve, reject) => {
      let url =
        config.DOMAIN +
        config.API_VERSION +
        "company_posting?user_id=" +
        data.user_id +
        "&role_id=" +
        data.role_id +
        "&limit=1";
      if (!!data.company_info_id)
        url = url + "&company_info_id=" + data.company_info_id;

      axios
        .get(url)
        .then(response => {
          resolve(response);
        })
        .catch(e => {
          reject(e);
        }); //axios
    });
  },
  getCompanyPostingDetails: function(data) {
    let url =
      config.DOMAIN +
      config.API_VERSION +
      "company_posting?organization_id=" +
      data.organization_id +
      "&status=" +
      data.status +
      "&company_info_id=" +
      data.company_info_id;
    if (data?.limit) {
      url += "&limit=" + data.limit;
    }
    if (data?.search) {
      url += "&search=" + data.search;
    }
    return new Promise((resolve, reject) => {
      axios
        .get(url)
        .then(response => {
          resolve(response);
        })
        .catch(e => {
          reject(e);
        }); //axios
    });
  },
  getPostedByNames(data) {
    return new Promise(function(resolve, reject) {
      var url =
        config.DOMAIN +
        config.API_VERSION +
        "company_posting/fetch-company-posted-by-users?";

      if (!!data.search) {
        url = url + "search=" + data.search;
      }
      if (!!data.organization_id) {
        url = url + "&organization_id=" + data.organization_id;
      }
      axios
        .get(url)
        .then(response => {
          if (!!data.timestamp) response.data["timeStamp"] = data.timestamp;
          resolve(response);
        })
        .catch(e => {
          reject(e.response.data.message);
        });
    });
  },
  getCompanyDetails(data) {
    return new Promise(function(resolve, reject) {
      var url = config.DOMAIN + config.API_VERSION + "company_info?";

      if (!!data.search) {
        url = url + "&search=" + data.search;
      }
      if (!!data.sales_person_details_id) {
        url = url + "&sales_person_details_id=" + data.sales_person_details_id;
      }
      if (!!data.is_visible) {
        url = url + "&is_visible=" + data.is_visible;
      }
      if (!!data.organization_id) {
        url = url + "&organization_id=" + data.organization_id;
      }
      axios
        .get(url)
        .then(response => {
          if (!!data.timestamp) response.data["timeStamp"] = data.timestamp;
          resolve(response);
        })
        .catch(e => {
          reject(e.response.data.message);
        });
    });
  },
  getPreferencesForSearch(data) {
    return new Promise(function(resolve, reject) {
      var url =
        config.DOMAIN + config.API_VERSION + "preferences?uniqueNames=true";
      if (!!data.preferences_type_id) {
        url = url + "&preferences_type_id=" + data.preferences_type_id;
      }
      if (!!data.search) {
        url = url + "&search=" + data.search;
      }
      axios
        .get(url)
        .then(response => {
          if (!!data.timestamp) response.data["timeStamp"] = data.timestamp;
          resolve(response);
        })
        .catch(e => {
          reject(e.response.data.message);
        });
    });
  },
  addCandidateToPosting: function(data) {
    return new Promise((resolve, reject) => {
      let url = config.DOMAIN + config.API_VERSION + "company_candidate";
      axios
        .post(url, data)
        .then(response => {
          resolve(response);
        })
        .catch(e => {
          reject(e);
        });
    });
  },
  updateCandidateStatus: function(data) {
    return new Promise((resolve, reject) => {
      let url =
        config.DOMAIN + config.API_VERSION + "company_candidate/" + data.id;
      axios
        .put(url, data)
        .then(response => {
          resolve(response);
        })
        .catch(e => {
          reject(e);
        });
    });
  },
  removeCandidateFromPosting: function(data) {
    return new Promise((resolve, reject) => {
      let url =
        config.DOMAIN + config.API_VERSION + "company_candidate/" + data.id;

      axios
        .delete(url)
        .then(response => {
          resolve(response);
        })
        .catch(e => {
          reject(e);
        });
    });
  },
  getSingleCandidateDetails(data) {
    return new Promise(function(resolve, reject) {
      var url =
        config.DOMAIN +
        config.API_VERSION +
        "company_candidate?company_posting_id=" +
        data.postingId +
        "&sales_person_details_id=" +
        data.salespersonId;
      axios
        .get(url)
        .then(response => {
          resolve(response);
        })
        .catch(e => {
          reject(e.response.data.message);
        });
    });
  },
  getAllCompaniesForSupportTeams(data) {
    //localhost/api/v1/company_info/user/1
    http: return new Promise(function(resolve, reject) {
      var url =
        config.DOMAIN +
        config.API_VERSION +
        "company_info/user/" +
        data.user_id;

      if (data?.limit) {
        url += "?limit=" + data.limit;
      }

      axios
        .get(url)
        .then(response => {
          resolve(response);
        })
        .catch(e => {
          reject(e.response.data.message);
        });
    });
  },
  getCompanyInfoBySlug(data) {
    return new Promise(function(resolve, reject) {
      var url =
        config.DOMAIN + config.API_VERSION + "company_info/slug/" + data.slug;
      if (data?.id) url += "?id=" + data.id;
      axios
        .get(url)
        .then(response => {
          resolve(response);
        })
        .catch(e => {
          reject(e.response.data.message);
        });
    });
  },
  TermsAgreementCompleted: function(data) {
    return new Promise((resolve, reject) => {
      let url = config.DOMAIN + config.API_VERSION + "company_info/" + data.id;
      axios
        .put(url, data)
        .then(response => {
          resolve(response);
        })
        .catch(e => {
          reject(e);
        });
    });
  },
  ChangeRoleToOwner: function(data) {
    return new Promise((resolve, reject) => {
      let url = config.DOMAIN + config.API_VERSION + "company_user";
      axios
        .post(url, data)
        .then(response => {
          resolve(response);
        })
        .catch(e => {
          reject(e);
        });
    });
  },
  generateLinkToJoin: function(data) {
    return new Promise((resolve, reject) => {
      let url =
        config.DOMAIN +
        config.API_VERSION +
        "company_info/" +
        data.company_info_id +
        "/create_join_link";
      axios
        .post(url, data)
        .then(response => {
          resolve(response);
        })
        .catch(e => {
          reject(e);
        });
    });
  },
  createStripeCheckoutSession: function(data) {
    return new Promise((resolve, reject) => {
      let url =
        config.DOMAIN +
        config.API_VERSION +
        "stripe_payment/create_stripe_checkout_session";
      let method = "post";
      axios({
        method: method,
        url: url,
        data: data
      })
        .then(response => {
          resolve(response);
        })
        .catch(e => {
          reject(e);
        }); //axios
    });
  },
  getPostingByTitle: function(data) {
    return new Promise(function(resolve, reject) {
      var url =
        config.DOMAIN +
        config.API_VERSION +
        "company_posting/get_posting_by_title?post_title=" +
        data.post_title;
      if (data?.id) {
        url += "&id=" + data.id;
      }
      if (data?.company_info_id) {
        url += "&company_info_id=" + data.company_info_id;
      }
      axios
        .get(url)
        .then(response => {
          resolve(response);
        })
        .catch(e => {
          reject(e.response.data.message);
        });
    });
  },
  switchCompany: function(data) {
    return new Promise((resolve, reject) => {
      let url =
        config.DOMAIN + config.API_VERSION + "company_info/switch_company";
      axios
        .post(url, data)
        .then(response => {
          resolve(response);
        })
        .catch(e => {
          reject(e);
        }); //axios
    });
  }
};
