import Vue from "vue";
import config from "./config";
import UploadProgress from "./components/ContentDrip/ContentAdd/UploadProgress";
import store from "./store";
import router from "./router";
import axios from "axios";
import $ from "jquery";
import { mapState, mapGetters, mapActions } from "vuex";
import organizationsServices from "./services/organization";
import analyticsServices from "./services/analytics";
import "./assets/css/quill.font.scss";
import WebSocketClient from "./lib/mattermostClient";
import mattermostChat from "./services/mattermostChat";
import { mmChats } from "./mixins/mmChats";

//CSS for Vuetify tags
import UUID from "vue-uuid";
Vue.use(UUID);

import EventBus from "./mixins/eventbus";

window.Vue = require("vue");
import Swal from "sweetalert2";
import VueAnalytics from "vue-analytics";

//Importing Vue Drag and Drop

import VueDragDrop from "vue-drag-drop";
Vue.use(VueDragDrop);
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

import VueDragscroll from "vue-dragscroll";
Vue.use(VueDragscroll);

//Old socket connection code
// import VueSocketIOExt from "vue-socket.io-extended";
// var socket = io(config.DOMAIN, {
//   path: config.API_VERSION + "flow/socket.io/"
// });
// Vue.use(VueSocketIO, socket);

import VueSocketIO from "vue-socket.io";
import io from "socket.io-client";

// import { MultiSelectPlugin } from "@syncfusion/ej2-vue-dropdowns";
// import { MultiSelect, CheckBoxSelection } from "@syncfusion/ej2-dropdowns";
// MultiSelect.Inject(CheckBoxSelection);
// Vue.use(MultiSelectPlugin);

// import "@syncfusion/ej2-base/styles/material.css";
// import "@syncfusion/ej2-inputs/styles/material.css";
// import "@syncfusion/ej2-vue-dropdowns/styles/material.css";
// import "@syncfusion/ej2-buttons/styles/material.css";

const options = { path: config.API_VERSION + "flow/socket.io/" };
Vue.use(
  new VueSocketIO({
    debug: true,
    connection: io(config.DOMAIN, options)
  })
);

//Custom Vue directive for registering outside click
Vue.directive("click-outside", {
  bind: function(el, binding, vnode) {
    el.eventSetDrag = function() {
      el.setAttribute("data-dragging", "yes");
    };
    el.eventClearDrag = function() {
      el.removeAttribute("data-dragging");
    };
    el.eventOnClick = function(event) {
      var dragging = el.getAttribute("data-dragging");
      // Check that the click was outside the el and its children, and wasn't a drag
      if (!(el == event.target || el.contains(event.target)) && !dragging) {
        // call method provided in attribute value
        vnode.context[binding.expression](event);
      }
    };
    document.addEventListener("touchstart", el.eventClearDrag);
    document.addEventListener("touchmove", el.eventSetDrag);
    document.addEventListener("click", el.eventOnClick);
    document.addEventListener("touchend", el.eventOnClick);
  },
  unbind: function(el) {
    document.removeEventListener("touchstart", el.eventClearDrag);
    document.removeEventListener("touchmove", el.eventSetDrag);
    document.removeEventListener("click", el.eventOnClick);
    document.removeEventListener("touchend", el.eventOnClick);
    el.removeAttribute("data-dragging");
  }
});

Vue.use(VueAnalytics, {
  id: config.GOOGLE_ANALYTICS_ID,
  router
});

// new ui

require("./bootstrap");
import { BootstrapVue } from "bootstrap-vue";
import vClickOutside from "v-click-outside";
import VueMask from "v-mask";
import Vuelidate from "vuelidate";
import toastr from "toastr";

Vue.use(BootstrapVue);
Vue.use(vClickOutside);
Vue.use(VueMask);
Vue.use(Vuelidate);

window.app = new Vue({
  el: "#app",
  store,
  router,
  axios,
  data() {
    return {
      isToggeled: false,
      newUI: false,
      layoutType: "vertical",
      editable: false,
      domainOrgId: null,
      domainOrgSlug: null,
      organization: null,

      selectedEmailsForModal: null,

      loading: false,

      gettingStarted: {
        steps: 0,
        completed: 0,
        progress: 0
      },

      selectedTaskForModel: null,

      subtypes: [],

      selectedOrgUser: {},
      webSocketClient: null,
      notificationSound: new Audio("/sound/notify.mp3")
    };
  },
  mixins: [mmChats],
  components: {
    UploadProgress
  },
  created() {
    //this.whoAmI();
    console.log("IN app.js");

    (this.domainOrgId = !!document.getElementById("domainOrgId")
      ? document.getElementById("domainOrgId").value
      : null),
      (this.domainOrgSlug = !!document.getElementById("domainOrgSlug")
        ? document.getElementById("domainOrgSlug").value
        : null);
  },
  watch: {
    loading: function(newVal, oldVal) {
      if (newVal == true) {
        Swal.fire({
          title: "Processing...",
          allowEscapeKey: false,
          // html: '',// add html attribute if you want or remove
          allowOutsideClick: false,
          onBeforeOpen: () => {
            Swal.showLoading();
          }
        });
      } else {
        Swal.hideLoading();
        Swal.close();
      }
    },
    chatInfo: {
      handler(newVal, oldVal) {
        if (newVal && newVal.mattermost_auth_token) {
          this.webSocketClient = new WebSocketClient();
          this.webSocketClient.initialize(
            config.MM_WEBSOCKET_URL,
            newVal.mattermost_auth_token || ""
          );
          this.webSocketClient.addMessageListener(this.handleEvents);
        }
      },
      deep: true
    }
  },
  computed: {
    ...mapState({
      whoami: state => state.auth.whoami,
      isAuth: state => state.auth.isAuth,
      organizations: state => state.auth.organizations,
      campaign: state => state.campaigns.campaign,
      chatInfo: state => state.chats.chatInfo
    }),

    shortcode: function() {
      // short code of active organization
      if (this.whoami.active_organization) {
        return this.whoami.active_organization.shortcode;
      } else if (this.$route.params.organization_slug) {
        console.log(
          "ressss======================================",
          this.$route.params.organization_slug
        );
        return this.$route.params.organization_slug;
      }
    },

    org: function() {
      // return "/" + this.shortcode + "/admin/";
      if (this.domainOrgId) return "/admin/";
      else {
        if (this.shortcode) {
          console.log("inside else in root org");
          console.log("shortcode---", this.shortcode);
          return "/" + this.shortcode + "/admin/";
        }
      }
    },

    domainPrefix: function() {
      if (this.domainOrgId) return "/admin/";
      else {
        return `/${this.shortcode}/`;
      }
    },

    domain_api: function() {
      return config.DOMAIN + config.API_VERSION;
    },

    orgSlug: function() {
      return !!this.$route.params.organization_slug
        ? this.$route.params.organization_slug
        : this.domainOrgSlug;
    },

    domain: function() {
      return config.DOMAIN;
    },

    orgForContent: function() {
      // return "/" + this.shortcode + "/admin/";
      if (this.domainOrgId) return "/admin/";
      else {
        return "/" + this.shortcode;
      }
    }
  },

  async mounted() {
    console.log("In Mounted appp");
    this.$nextTick(() => {
      try {
        $(function() {
          $(".dropdown-trigger").dropdown({
            hover: false,
            coverTrigger: false
          });
        });
      } catch (e) {}
    });

    this.newUI = true;
    const res = await mattermostChat.chatAuth();
    this.setChatInfo(!!res?.data ? res.data : {});
  },

  methods: {
    ...mapActions("auth", {
      whoAmI: "whoAmI",
      changeIsDropdownOpen: "changeIsDropdownOpen",
      setDomainOrgId: "setDomainOrgId"
    }),
    ...mapActions("chats", {
      setChatInfo: "setChatInfo",
      setTypingUserInfo: "setTypingUserInfo"
    }),

    toggleSidebar() {
      this.isToggeled = !this.isToggeled;
    },

    getOrganizationByShortcode(shortcode) {
      let self = this;
      return new Promise(async function(resolve, reject) {
        console.log("130 --", self.whoami, shortcode);
        if (!!self.whoami.organizations) {
          let org = self.whoami.organizations.find(
            e => e.shortcode == shortcode
          );
          resolve(org);
        } else {
          let data = {};
          data.shortcode = shortcode;
          await organizationsServices
            .getOrganizationByShortcode(data)
            .then(res => {
              console.log("188-> ", res);
              // set active organization
              self.organization = res.data;
              store
                .dispatch("auth/setActiveOrganization", res.data, {
                  root: true
                })
                .then(r => {
                  resolve(res.data);
                });
            });
        }
      });
    },

    toast(title, body = "", variant = "success") {
      toastr.clear();
      toastr.success(title, body, {
        timeOut: config.TOASTR_TIMEOUT
      });
    },

    /** track activity
     * @param action = activity action
     * @param path = for what path?
     */
    // saveActivity(action, path, title = null) {
    saveActivity(data) {
      data.url = config.DOMAIN + data.path;
      data.title = !!data.title ? data.title : null;
      data.organization_slug = this.orgSlug;

      console.log("165: data: ", data);

      this.$socket.emit("activity", { ...data, ...this.$route.params });

      if (data.action == "call_to_action") {
        let data1 = {};
        this.getOrganizationByShortcode(data.organization_slug).then(r => {
          data1.organization_id = r.id;
          data1.campaign_contact_id = this.$route.params.campaign_contact_id;
          data1.token = this.$route.params.token;
          data1.action = "clickedOnActionButton";
          console.log("255 data1: ", data1);
          analyticsServices.activity(data1);
        });
      }
    },

    /** track activity and redirect to next url
     * @param action = activity action
     * @param path = for what path?
     * @param next = where to redirect?
     */
    // saveActivityAndRedirect(action, path, title, next) {
    saveActivityAndRedirect(data) {
      console.log("177: title: ", data);
      this.saveActivity(data);
      window.open(data.next);
    },

    removeContentFromEmail(data) {
      if (!!this.campaign.is_multiple_email_campaign)
        EventBus.$emit("remove-content-from-emails", data);
      else EventBus.$emit("remove-content-from-email", data);
    },
    copyCode(data, showToast = false) {
      const el = document.createElement("textarea");
      el.value = data;
      document.body.appendChild(el);
      el.focus();
      el.select();
      el.focus();
      document.execCommand("copy");
      el.focus();
      document.body.removeChild(el);

      if (showToast) {
        this.toast("Link has been copied.", "");
      }
    },
    async handleEvents(message) {
      const eventData = message.data;
      console.log("event data-------------------", message);

      switch (message.event) {
        case "posted":
          let post = {};
          let userDetails = {};
          let notificationTitle = "";
          let notificationBody = "";
          if (Notification.permission !== "granted")
            Notification.requestPermission();
          if (message.data?.post) {
            post = JSON.parse(message.data.post);
            if (this.chatInfo.mattermost_user_id !== post.user_id) {
              if (this.selectedChannel?.id !== post.channel_id) {
                userDetails = await this.fetchUserDetails(post.user_id);
                if (
                  message.data?.channel_type === "P" ||
                  message.data?.channel_type === "O"
                ) {
                  notificationTitle =
                    "New message in #" + message.data.channel_display_name;
                  notificationBody =
                    userDetails.first_name +
                    " " +
                    userDetails.last_name +
                    " : " +
                    post.message;
                }
                if (message.data?.channel_type === "D") {
                  notificationTitle =
                    "New message from " +
                    userDetails.first_name +
                    " " +
                    userDetails.last_name;
                  notificationBody = post.message;
                }
                var notification = new Notification(notificationTitle, {
                  body: notificationBody,
                  icon: "/favicon-32x32.png"
                });
                // notification.onclick = function(event) {
                //   console.log(navigator.userAgent);
                //   window.open("https://www.google.com","_blank")
                // }
              }
              this.notificationSound.play();
            }
          }
          if (!!this.selectedChannel?.id) {
            this.fetchMessages();
          }
          break;
        case "typing":
          const typingUser = {
            id: eventData.user_id,
            name: eventData.parent_id
          };
          this.setTypingUserInfo(typingUser);
          break;
        case "post_deleted":
          this.fetchMessages();
          break;
        default:
          break;
      }
    },
    async fetchUserDetails(mmUserId) {
      try {
        const res = await mattermostChat.getUserDetailsByMmUserId(mmUserId);
        if (res) {
          return res.data;
        }
      } catch (error) {
        console.log(error);
      }
    }
  }
});

$(document).ready(function() {
  try {
    $("select").formSelect();
    $(".tabs").tabs();
  } catch (e) {}
  // $('.datepicker').datepicker();

  window.onbeforeunload = function() {
    if (
      $(
        'div[data-isDraft=true],div[class=progress-message]:contains("Creating Post"),div[data-iseditmodeon=true]'
      ).length > 0
    ) {
      return "Do you want to leave?";
    }
  };

  let chargebeeInstance = Chargebee.init({
    site: config.CHARGEBEE_SITE_URL
  });
});

window.onclick = function(event) {
  if (!event.target.matches(".sl_dropbtn")) {
    var dropdowns = document.getElementsByClassName("sl_dropdown-content");
    var i;
    for (i = 0; i < dropdowns.length; i++) {
      var openDropdown = dropdowns[i];
      if (openDropdown.classList.contains("show")) {
        //
        openDropdown.classList.remove("show");
      }
    }
  }

  if (!event.target.matches("#header_main_dropdown ")) {
    // console.warn("Close dropdown menus");
    if (!!store.state.isDropdownOpen) store.dispatch("changeIsDropdownOpen");
  }
};
