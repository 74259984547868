import axios from "axios";
import config from "../../config.js";
import * as moment from "moment";
import router from "../../router";
import toastr from "toastr";
import tagsServices from "../../services/tags";
import groupsServices from "../../services/groups";

export default {
  namespaced: true,
  state: {
    categories: [],
    tags: [],
    selectedCategories: [],
    selectedTags: [],
    selectedContentTypes: [],
    category: {
      id: "",
      name: "",
      description: "",
      image_id: ""
    },
    tag: {
      id: "",
      name: [],
      description: "",
      image_id: ""
    },
    content_types: [],
    getSelectedTagsOfModel: [],
    content_type: {
      id: "",
      name: "",
      description: "",
      image_id: ""
    },

    tagImage: null
  },

  getters: {},
  mutations: {
    // 3- tag type set
    setAllCategories(state, data) {
      state.categories = data;
    },
    setAllTags(state, data) {
      state.tags = data;
    },
    setAllContentTypes(state, data) {
      state.content_types = data;
    },

    setCategory(state, data) {
      state.category = data;
    },
    setTag(state, data) {
      state.tag = data;
    },
    setContentType(state, data) {
      state.content_type = data;
    },

    setSelectedCategories(state, data) {
      state.selectedCategories = data;
    },
    setSelectedTags(state, data) {
      state.selectedTags = data;
    },
    setSelectedContentTypes(state, data) {
      state.selectedContentTypes = data;
    },

    setTagImage(state, data) {
      console.log("Inside mutation setTagImage", data);
      state.tagImage = data;
    },
    //tags
    getAllTags(state, data) {
      state.tags = data;
    },
    setSelectedTags(state, data) {
      state.selectedTags = data;
    },
    //Content Type
    getAllContentTypes(state, data) {
      state.content_types = data;
    },
    setSelectedContentTypes(state, data) {
      state.selectedContentTypes = data;
    },

    attachSelectedTag(state, data) {
      console.log("state.selectedCategories 2", state.selectedCategories);
      if (data.organization_tag_type_id == 1)
        state.selectedCategories.splice(0, 0, data);
      else if (data.organization_tag_type_id == 2)
        state.selectedTags.splice(0, 0, data);
      else if (data.organization_tag_type_id == 3)
        state.selectedContentTypes.splice(0, 0, data);
    },
    detachSelectedTag(state, data) {
      const index = state.organizations.organizationTags.findIndex(
        element => element.id === data.id
      );
      state.organizations.organizationTags.splice(index, 1);
    },

    updateTags(state, data) {
      state.tags.push(data);
    }
  },

  actions: {
    /* == getAllTags() => this is global function for 3 types =========
        @organization_tag_type = 1 : will getch all categories
        @organization_tag_type = 2 : will getch all tags
        @organization_tag_type = 3 : will getch all content_types
    ================================================================== */
    getAllTags({ state, commit, rootState }, tag_params) {
      return new Promise((resolve, reject) => {
        console.log("getAllTags() Called-> ", tag_params.organization_tag_type);
        let data = {};
        data.organization_tag_type = tag_params.organization_tag_type;

        if (tag_params.search) data.search = tag_params.search;

        if (tag_params.page_id) {
          data.page_id = tag_params.page_id;
        }
        tagsServices.getTags(data).then(response => {
          console.log("All tags GET API With New Logic:: ", response.data);
          if (tag_params.organization_tag_type == 1)
            commit("setAllCategories", response.data.tags);
          else if (tag_params.organization_tag_type == 2)
            commit("setAllTags", response.data.tags);
          else if (tag_params.organization_tag_type == 3)
            commit("setAllContentTypes", response.data.tags);
          resolve(response.data.tags);
        });
      });
    },

    attachSelectedTag({ state, commit, rootState, dispatch }, data) {
      return new Promise((resolve, reject) => {
        if (
          rootState.settings.applySettingsFor == "" ||
          rootState.settings.applySettingsFor == null
        )
          console.error("applySettingsFor is not set!");

        console.log("state.selectedCategories 1", state.selectedCategories);
        if (rootState.settings.applySettingsFor == "curatedArticle")
          dispatch("curatedArticle/attachSelectedTag", data, { root: true });
        else if (rootState.settings.applySettingsFor == "socialPosts")
          dispatch("socialPosts/attachSelectedTag", data, {
            root: true
          });
        else if (rootState.settings.applySettingsFor == "streams")
          dispatch("streams/addOrganizationTagsFilter", data, {
            root: true
          });
        else if (rootState.settings.applySettingsFor == "organizations")
          dispatch("organizations/attachSelectedTag", data, {
            root: true
          });
        else if (rootState.settings.applySettingsFor == "groups") {
          let data1 = {};
          data1.group_id = rootState.auth.whoami.active_organization_group.id;
          data1.tag_id = data.id;
          groupsServices.attachTagToGroup(data1).then(response => {
            resolve(response);
          });

          if (rootState.settings.applySettingsFor != "groups") resolve();
        } // if
      });
    },

    detachSelectedTag({ state, commit, rootState, dispatch }, data) {
      return new Promise((resolve, reject) => {
        if (
          rootState.settings.applySettingsFor == "" ||
          rootState.settings.applySettingsFor == null
        )
          console.error("applySettingsFor is not set!");

        if (rootState.settings.applySettingsFor == "curatedArticle")
          dispatch("curatedArticle/detachSelectedTag", data, { root: true });
        else if (rootState.settings.applySettingsFor == "socialPosts")
          dispatch("socialPosts/detachSelectedTag", data, {
            root: true
          });
        else if (rootState.settings.applySettingsFor == "streams")
          dispatch("streams/removeOrganizationTagFilter", data, {
            root: true
          });
        else if (rootState.settings.applySettingsFor == "organizations")
          dispatch("organizations/detachSelectedTag", data, {
            root: true
          });
        else if (rootState.settings.applySettingsFor == "groups") {
          let data1 = {};
          data1.group_id = rootState.auth.whoami.active_organization_group.id;
          data1.tag_id = data.id;
          groupsServices.detachTagFromGroup(data1).then(response => {
            resolve(response);
          });
        }

        if (rootState.settings.applySettingsFor != "groups") resolve();
      });
    },

    getSelectedTagsOfModel({ state, commit, rootState, dispatch }, type) {
      // This function is usefull to display all selected categories on first screen of settings
      // TODO: add else if here only depending on applySettingsFor

      let selectedTags = null;
      if (rootState.settings.applySettingsFor == "socialPosts") {
        selectedTags = [
          ...rootState.socialPosts.socialPost.organizationTags.filter(
            element => {
              if (element.organization_tag_type_id == type) return element;
            }
          )
        ];
      } else if (rootState.settings.applySettingsFor == "curatedArticle")
        selectedTags =
          rootState.curatedArticle.curatedArticle.selectedCategories;
      else if (rootState.settings.applySettingsFor == "streams") {
        if (type == 1) selectedTags = rootState.streams.filters["category"];
        else if (type == 2) selectedTags = rootState.streams.filters["tags"];
        else if (type == 3)
          selectedTags = rootState.streams.filters["content_type"];
      } else if (rootState.settings.applySettingsFor == "organizations") {
        console.log("inside iff", rootState, rootState.organizations);
        selectedTags = [
          ...rootState.organizations.organizations.organizationTags.filter(
            element => {
              if (element.organization_tag_type_id == type) return element;
            }
          )
        ];
      } else if (rootState.settings.applySettingsFor == "groups") {
        // fetch all tags attached to group and set
        let data = {};
        data.organization_tag_type = type;
        data.group_ids = rootState.auth.whoami.active_organization_group.id;
        tagsServices.getTags(data).then(response => {
          if (type == 1) commit("setSelectedCategories", response.data.tags);
          else if (type == 2) commit("setSelectedTags", response.data.tags);
          else if (type == 3)
            commit("setSelectedContentTypes", response.data.tags);
        });
      }

      if (type == 1) commit("setSelectedCategories", selectedTags);
      else if (type == 2) commit("setSelectedTags", selectedTags);
      else if (type == 3) commit("setSelectedContentTypes", selectedTags);
    },
    getTagsOfGroup({ state, commit, rootState, dispatch }, data) {},
    // detachSelectedTag by Id
    removeSelectedTag({ state, commit, rootState, dispatch }, tagId) {
      // to remove selected tag:
      // 1. add new mutation in store
      // 2. ad if loop here

      if (rootState.settings.applySettingsFor == "curatedArticle")
        commit("curatedArticle/detachSelectedTagById", tagId, { root: true });
      else if (rootState.settings.applySettingsFor == "socialPosts")
        commit("socialPosts/detachSelectedTagById", tagId, { root: true });
      else if (rootState.settings.applySettingsFor == "streams")
        commit("streams/removeOrganizationTagFilterById", tagId, {
          root: true
        });
      else if (rootState.settings.applySettingsFor == "groups") {
        let data1 = {};
        data1.group_id = rootState.auth.whoami.active_organization_group.id;
        data1.tag_id = tagId;
        groupsServices.detachTagFromGroup(data1);
      }
    },

    createCategory({ state, commit, rootState, dispatch }, data) {
      return new Promise(function(resolve, reject) {
        console.log("inside createTag");
        let type = data.type;
        let val = data.data[0];
        if (rootState.settings.applySettingsFor == "groups")
          val.attach_to_group_id =
            rootState.auth.whoami.active_organization_group.id;
        console.log("328 -- ", rootState.settings.applySettingsFor, val);

        axios
          .post(
            config.DOMAIN +
              config.API_VERSION +
              "organizations/" +
              rootState.auth.whoami.active_organization.id +
              "/tags",
            val
          )
          .then(response => {
            resolve(response);
            if (type == "category" || type == "content_type") {
              console.log(type + " saved!!! ", response.data);

              // remove _ and make 1st letter capital
              type = (type.charAt(0).toUpperCase() + type.slice(1)).replace(
                "_",
                " "
              );
              // type = type.replace('_', ' ');

              toastr.error(type + " created successfully.", "", {
                timeOut: config.TOASTR_TIMEOUT
              });
              state.category = {};
              state.content_type = {};
            }
            if (
              (type == "Category" || type == "Content type") &&
              state.tagImage != null
            ) {
              console.log("fileList : ", state.tagImage);
              let data = {
                tagId: response.data.id,
                imageData: state.tagImage
              };
              dispatch("uploadTagImage", data);
            }
          })
          .catch(e => {
            console.log("Create Category Error: ", e);
          }); //axios
      });
    },
    uploadTagImage({ state, commit, dispatch, rootState }, data) {
      let imageData = new FormData();
      let tagId = data.tagId;
      let img = data.imageData;

      console.log("img", img.name);

      // append to formData
      imageData.append("image", img, img.name);

      // /api/v1/organizations/{orgaization_id}/categories/{category_id}/posts/{id}/images - POST
      axios
        .post(
          config.DOMAIN +
            config.API_VERSION +
            "organizations/" +
            rootState.auth.whoami.active_organization.id +
            "/tags/" +
            +tagId +
            "/image",
          imageData
        )
        .then(response => {
          console.log(img.name + " file uploaded!");
          // router.push({ name: "PostCategories" });
        })
        .catch(e => {
          console.log("Unable to upload image - : " + e);
        }); //axios
    },
    setTagImage({ state, commit, rootState, dispatch }, data) {
      commit("setTagImage", data);
    },
    createTags({ state, commit, rootState, dispatch }, data) {
      return new Promise((resolve, reject) => {
        console.log("Data: ", data);
        let type = data.type;
        let tag = data.data[0];
        const promises = [];
        let url =
          config.DOMAIN +
          config.API_VERSION +
          "organizations/" +
          rootState.auth.whoami.active_organization.id +
          "/tags";

        axios
          .post(url, tag)
          .then(response => {
            commit("updateTags", response.data);
            resolve(response.data);
          })
          .catch(e => {
            console.log("Unable to create tag");
          }); //axios
      });
    },

    createFolder({ state, commit, rootState, dispatch }, data) {
      return new Promise((resolve, reject) => {
        const promises = [];
        let url =
          config.DOMAIN +
          config.API_VERSION +
          "organizations/" +
          rootState.auth.whoami.active_organization.id +
          "/foldertags";

        axios
          .post(url, data)
          .then(response => {
            resolve(response);
          })
          .catch(e => {
            console.log("Create Folder Error: ", e);
          });

        // let mockResponse = {
        //   data: {
        //     id: 1,
        //     name: data.name,
        //     page_id: data.organization_page_id,
        //     type: "folder"
        //   }
        // };
        // resolve(mockResponse);
      });
    },

    openAddCategoryPage({ state, commit, rootState, dispatch }, data) {
      if (rootState.settings.applySettingsFor == "curatedArticle") {
        router.push({ name: "CategoryList" });
      } else router.push({ name: "CategoryList" });
    },
    //tags
    // getAllTags({ state, commit, rootState }, curatedObj) {
    //   console.log("getAllTags() Called");
    //   axios
    //     .get(
    //       config.DOMAIN +
    //         config.API_VERSION +
    //         "organizations/" +
    //         rootState.auth.whoami.active_organization.id +
    //         "/tags?organization_tag_type_id=2"
    //     )
    //     .then(response => {
    //       console.log("All tags GET API:: ", response.data);
    //       commit("getAllTags", response.data.tags);
    //     })
    //     .catch(e => {
    //       console.log("Error: ", e);
    //     }); //axios
    // },

    setSelectedTags({ state, commit, rootState, dispatch }, data) {
      console.log(state.tags);
      commit("setSelectedTags", data);
      if (rootState.settings.applySettingsFor == "curatedArticle") {
        dispatch("curatedArticle/setSelectedTags", data, { root: true });
      }
    },
    openAddTagPage({ state, commit, rootState, dispatch }, data) {
      if (rootState.settings.applySettingsFor == "curatedArticle") {
        router.push({ name: "TagList" });
      }
    },
    getAllContentTypes({ state, commit, rootState }, curatedObj) {
      console.log("getAllContentTypes() Called");
      axios
        .get(
          config.DOMAIN +
            config.API_VERSION +
            "organizations/" +
            rootState.auth.whoami.active_organization.id +
            "/tags?organization_tag_type_id=3"
        )
        .then(response => {
          console.log("All content type GET API:: ", response.data);
          commit("getAllContentTypes", response.data.tags);
        })
        .catch(e => {
          console.log("Error: ", e);
        }); //axios
    },
    openAddContentTypePage({ state, commit, rootState, dispatch }, data) {
      console.log("openAddContentTypePage========");
      if (rootState.settings.applySettingsFor == "curatedArticle") {
        console.log("Inside if");
        router.push({ name: "ContentTypeList" });
      }
    },
    setSelectedContentTypes({ state, commit, rootState, dispatch }, data) {
      commit("setSelectedContentTypes", data);
      if (rootState.loadSettings == "curatedArticle") {
        dispatch("curatedArticle/setSelectedContentTypes", data, {
          root: true
        });
      }
    },
    updateCategory({ state, commit, rootState, dispatch }, data) {
      return new Promise(function(resolve, reject) {
        console.log("inside createTag");
        let type = data.type;
        let val = data.data[0];
        if (rootState.settings.applySettingsFor == "groups")
          val.attach_to_group_id =
            rootState.auth.whoami.active_organization_group.id;
        console.log("328 -- ", rootState.settings.applySettingsFor, val);

        axios
          .put(
            config.DOMAIN +
              config.API_VERSION +
              "organizations/" +
              rootState.auth.whoami.active_organization.id +
              "/tags/" +
              val.id,
            val
          )
          .then(response => {
            resolve(response);
            if (type == "category" || type == "content_type") {
              console.log(type + " saved!!! ", response.data);

              // remove _ and make 1st letter capital
              type = (type.charAt(0).toUpperCase() + type.slice(1)).replace(
                "_",
                " "
              );
              // type = type.replace('_', ' ');

              toastr.error(type + " updated successfully.", "", {
                timeOut: config.TOASTR_TIMEOUT
              });
            }
          })
          .catch(e => {
            console.log("Create Category Error: ", e);
          }); //axios
      });
    },
    deleteCategory({ state, commit, rootState, dispatch }, data) {
      return new Promise(function(resolve, reject) {
        console.log("inside createTag");
        let type = data.type;
        let val = data.data[0];
        if (rootState.settings.applySettingsFor == "groups")
          val.attach_to_group_id =
            rootState.auth.whoami.active_organization_group.id;
        console.log("328 -- ", rootState.settings.applySettingsFor, val);

        axios
          .delete(
            config.DOMAIN +
              config.API_VERSION +
              "organizations/" +
              rootState.auth.whoami.active_organization.id +
              "/tags/" +
              val.id
          )
          .then(response => {
            resolve(response);
            if (type == "category" || type == "content_type") {
              console.log(type + " saved!!! ", response.data);

              // remove _ and make 1st letter capital
              type = (type.charAt(0).toUpperCase() + type.slice(1)).replace(
                "_",
                " "
              );
              // type = type.replace('_', ' ');

              toastr.error(type + " deleted successfully.", "", {
                timeOut: config.TOASTR_TIMEOUT
              });
            }
          })
          .catch(e => {
            console.log("Deleted Category Error: ", e);
          }); //axios
      });
    },
    updateFolder({ state, commit, rootState, dispatch }, data) {
      return new Promise(function(resolve, reject) {
        console.log("inside updateFolder");
        let type = data.type;
        let val = data.data[0];

        axios
          .put(
            config.DOMAIN +
              config.API_VERSION +
              "organizations/" +
              rootState.auth.whoami.active_organization.id +
              "/foldertags/" +
              val.id,
            val
          )
          .then(response => {
            resolve(response);
            toastr.error(type + " updated successfully.", "", {
              timeOut: config.TOASTR_TIMEOUT
            });
          })
          .catch(e => {
            console.log("Update Folder Error: ", e);
          });
      });
    },
    deleteFolder({ state, commit, rootState, dispatch }, data) {
      return new Promise(function(resolve, reject) {
        console.log("inside deleteFolder");
        let val = data;

        axios
          .delete(
            config.DOMAIN +
              config.API_VERSION +
              "organizations/" +
              rootState.auth.whoami.active_organization.id +
              "/foldertags/" +
              val.id
          )
          .then(response => {
            resolve(response);
            toastr.error("Folder deleted successfully.", "", {
              timeOut: config.TOASTR_TIMEOUT
            });
          })
          .catch(e => {
            console.log("Deleted Folder Error: ", e);
          }); //axios
      });
    },
    fetchFolderTags({ state, commit, rootState }, data) {
      return new Promise(function(resolve, reject) {
        console.log("fetchFolderTags() Called");
        axios
          .get(
            config.DOMAIN +
              config.API_VERSION +
              "organizations/" +
              rootState.auth.whoami.active_organization.id +
              "/foldertags?page_id=" +
              data.page_id
          )
          .then(response => {
            resolve(response);
          })
          .catch(e => {
            console.log("Error: ", e);
          }); //axios
      });
    },
    fetchFolderAndCategoryTags({ state, commit, rootState }, data) {
      return new Promise(function(resolve, reject) {
        console.log("fetchFolderTags() Called");
        axios
          .get(
            config.DOMAIN +
              config.API_VERSION +
              "organizations/" +
              rootState.auth.whoami.active_organization.id +
              "/foldertags/page/" +
              data.page_id
          )
          .then(response => {
            resolve(response);
          })
          .catch(e => {
            console.log("Error: ", e);
          }); //axios
      });
    }
  }
};
