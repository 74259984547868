import store from "../store";
import router from "../router";
import Swal from "sweetalert2";
import moment from "moment";

function checkStatus() {
  Swal.fire({
    title: "Upgrade Plan",
    text: "Upgrade your plan to use these features",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Upgrade"
  }).then(result => {
    if (result.isConfirmed) {
      router.push({
        name: "BillingWrapper",
        params: { selectedTab: "Plan" }
      });
    }
  });
}
function checkForMarketPlace(key) {
  if (
    key in store.state.auth.whoami.subscription_allowed_features.menu_options
  ) {
    return store.state.auth.whoami.subscription_allowed_features.menu_options[
      key
    ];
  }
  let endDate = moment.utc(store.state.auth.whoami.subscription_expiry_date);
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  let now = moment.tz(userTimeZone);
  let convertedUTC = now.clone().utc();

  if (convertedUTC > endDate) {
    return false;
  }
  return true;
}

export default [
  {
    path: "/:organization_slug/admin/company/switchcompanies",
    name: "SupportSwitchCompanies",
    meta: { role_id: 3 },
    component: () => import("../components/ClientCompanies/ClientCompanies.vue")
  },
  {
    path: "/:organization_slug/company/get_started",
    name: "CompanyLogin",
    meta: { role_id: 3 },
    component: () =>
      import("../components/Workspace/CompanyApplication/SignUp.vue")
  },
  {
    path: "/:organization_slug/admin/company/add",
    name: "CreateCompany",
    meta: { role_id: 3 },
    component: () => import("../components/ClientCompanies/CreateCompany.vue")
  },
  {
    path: "/:organization_slug/admin/company/step1",
    name: "CompanyRegistrationStep1",
    meta: { role_id: 3 },
    component: () =>
      import(
        "../components/Workspace/CompanyApplication/Registration/Step1.vue"
      )
  },
  // {
  //   path: "/:organization_slug/admin/company/step2",
  //   name: "CompanyRegistrationStep2Experience",
  //   meta: { role_id: 3 },
  //   component: () =>
  //     import(
  //       "../components/Workspace/CompanyApplication/Registration/StepExperience.vue"
  //     )
  // },
  // {
  //   path: "/:organization_slug/admin/company/step2",
  //   name: "CompanyRegistrationStep2Industry",
  //   meta: { role_id: 3 },
  //   component: () =>
  //     import(
  //       "../components/Workspace/CompanyApplication/Registration/StepIndustry.vue"
  //     )
  // },
  // {
  //   path: "/:organization_slug/admin/company/step2",
  //   name: "CompanyRegistrationStep2Role",
  //   meta: { role_id: 3 },
  //   component: () =>
  //     import(
  //       "../components/Workspace/CompanyApplication/Registration/StepRole.vue"
  //     )
  // },
  {
    path: "/:organization_slug/admin/company/step2",
    name: "CompanyRegistrationStep3",
    meta: { role_id: 3 },
    component: () =>
      import(
        "../components/Workspace/CompanyApplication/Registration/Step3.vue"
      )
  },
  {
    path: "/:organization_slug/admin/company/step3",
    name: "CompanyRegistrationStep4",
    meta: { role_id: 3 },
    component: () =>
      import(
        "../components/Workspace/CompanyApplication/Registration/Step4.vue"
      )
  },
  {
    path: "/company/review",
    name: "CompanyApplicationReview",
    meta: { role_id: 3 },
    component: () =>
      import("../components/Workspace/CompanyApplication/ApplicationReview.vue")
  },
  {
    path: "/:organization_slug/admin/company/update-password",
    name: "CompanyUpdatePassword",
    meta: { role_id: 3 },
    component: () =>
      import(
        "../components/Workspace/CompanyApplication/UserSettings/UpdateOldPassword.vue"
      )
  },
  {
    path:
      "/:organization_slug/admin/company/:slug/posting/profile/:company_posting_id",
    name: "CompanyPostingProfile",
    meta: { role_id: 3 },
    component: () =>
      import("../components/Workspace/Posting/ClientView/PostingProfile.vue")
  },
  {
    path:
      "/:organization_slug/admin/company/:slug/posting/profile/edit/:company_posting_id",
    name: "CompanyPostingProfile",
    meta: { role_id: 3 },
    component: () =>
      import("../components/Workspace/Posting/ClientView/PostingProfile.vue")
  },
  {
    path: "/:organization_slug/admin/posting/profile/:company_posting_id",
    name: "AdminPostingProfile",
    component: () =>
      import("../components/Workspace/Posting/AdminView/PostingProfile.vue")
  },
  {
    path: "/:organization_slug/admin/posting/search",
    name: "PostingSearch",
    meta: { hideSideBar: true },
    component: () =>
      import("../components/Workspace/PostingProspecting/Search/main.vue"),
    props: true,
    beforeEnter: (to, from, next) => {
      if (checkForMarketPlace("postings_search")) {
        next();
      } else {
        checkStatus();
      }
    }
  },
  {
    path: "/:organization_slug/admin/company/:slug/dashboard",
    name: "CompanyPostingDashboard",
    meta: { role_id: 3 },
    component: () =>
      import("../components/Workspace/Posting/ClientView/PostingDashboard.vue")
  },
  {
    path: "/:organization_slug/admin/company/:slug/postings",
    name: "Postings",
    meta: { role_id: 3 },
    component: () =>
      import("../components/Workspace/CompanyApplication/Postings/Main.vue")
  },
  {
    path: "/:organization_slug/admin/company/:slug/overview",
    name: "CompanyPostingOverview",
    meta: { role_id: 3 },
    component: () =>
      import(
        "../components/Workspace/CompanyApplication/Overview/OverviewMain.vue"
      )
  },
  {
    path: "/:organization_slug/admin/company/:slug/freelancers",
    name: "CompanyFreelancersSearch",
    meta: { role_id: 3 },
    component: () =>
      import(
        "../components/Workspace/CompanyApplication/Freelancers/FreelancersMain.vue"
      )
  },

  {
    path:
      "/:organization_slug/admin/company/:slug/posting/summary/:company_posting_id",
    name: "PostingSummary",
    meta: { role_id: 3 },
    component: () =>
      import("../components/Workspace/Posting/ClientView/PostingSummary.vue")
  },
  // {
  //   path: "/:organization_slug/admin/posting/search",
  //   name: "PostingSearch",
  //   meta: { hideSideBar: true, role_id: 3 },
  //   component: () =>
  //     import("../components/Workspace/PostingProspecting/Search/main.vue"),
  //   props: true
  // },
  // {
  //   path: "/:organization_slug/admin/posting/dashboard",
  //   name: "AdminPostingDashboard",
  //   meta: { role_id: 3 },
  //   component: () =>
  //     import("../components/Workspace/Posting/AdminView/PostingDashboard.vue")
  // },
  {
    path: "/:organization_slug/admin/contract/dashboard",
    name: "AdminContractDashboard",
    component: () =>
      import("../components/Workspace/Posting/AdminView/ContractDashboard.vue"),
    beforeEnter: (to, from, next) => {
      if (checkForMarketPlace("contracts")) {
        next();
      } else {
        checkStatus();
      }
    }
  },
  // {
  //   path: "/:organization_slug/admin/posting/:company_posting_id",
  //   name: "CompanyPostingProfile",
  //   component: () =>
  //     import(
  //       "../components/Workspace/CompanyApplication/Profile/PostingProfileAdminView.vue"
  //     )
  // },

  {
    path: "/:organization_slug/admin/companies/summary",
    name: "CompanySummaryAdmin",
    component: () =>
      import("../components/Workspace/Posting/AdminView/CompanySummary.vue"),
    beforeEnter: (to, from, next) => {
      if (checkForMarketPlace("companies")) {
        next();
      } else {
        checkStatus();
      }
    }
  },
  {
    path: "/:organization_slug/admin/contract/:contract_id",
    name: "ViewContractForAdmin",
    component: () =>
      import(
        "../components/Workspace/Contract/AdminView/ViewContractReadOnly.vue"
      ),
    beforeEnter: (to, from, next) => {
      if (
        store.state.auth.whoami.subscription_allowed_features.menu_options
          .prospecting_search == true
      ) {
        next();
      } else {
        checkStatus();
      }
    }
  },
  {
    path: "/:organization_slug/admin/contract/edit/:contract_id",
    name: "ViewContractForAdmin",
    component: () =>
      import(
        "../components/Workspace/Contract/AdminView/ViewContractReadOnly.vue"
      ),
    meta: { showEdit: true, role_id: 3 },
    beforeEnter: (to, from, next) => {
      if (
        store.state.auth.whoami.subscription_allowed_features.menu_options
          .prospecting_search == true
      ) {
        next();
      } else {
        checkStatus();
      }
    }
  },
  {
    path: "/:organization_slug/admin/posting/summary/:company_posting_id",
    name: "PostingSummaryAdmin",
    component: () =>
      import("../components/Workspace/Posting/AdminView/PostingSummary.vue")
  },

  // {
  //   path: "/company/freelancer/view",
  //   name: "CompanyFreelancerView",
  //   component: () =>
  //     import(
  //       "../components/Workspace/CompanyApplication/FreelancerHireTable/Freelancer.vue"
  //     )
  // },
  {
    path: "/:organization_slug/admin/posting/dashboard",
    name: "AdminPostingDashboard",
    component: () =>
      import("../components/Workspace/Posting/AdminView/PostingDashboard.vue"),
    beforeEnter: (to, from, next) => {
      if (checkForMarketPlace("postings_dashboard")) {
        next();
      } else {
        checkStatus();
      }
    }
  },
  {
    path:
      "/:organization_slug/admin/company/:slug/posting/summary/freelancer/:salesperson_id",
    name: "ClientSalespersonProfile",
    meta: { role_id: 3 },
    component: () =>
      import(
        "../components/Workspace/Posting/ClientView/SalespersonProfile.vue"
      )
  },
  {
    path: "/:organization_slug/admin/company/:slug/members",
    name: "ClientSalespersonProfile",
    meta: { role_id: 3 },
    component: () =>
      import(
        "../components/Workspace/CompanyApplication/TeamMember/TeamMemberMain.vue"
      )
  },
  {
    path: "/:organization_slug/admin/company/:slug/chats",
    name: "ClientSalespersonChats",
    meta: { role_id: 3 },
    component: () =>
      import(
        "../components/Workspace/CompanyApplication/Chats/CompanyChats.vue"
      )
  },
  //meta data is not set as this route is for organization freelancer profile
  {
    path:
      "/:organization_slug/admin/posting/summary/freelancer/:salesperson_id",
    name: "AdminSalespersonProfile",
    component: () =>
      import("../components/Workspace/Posting/AdminView/SalespersonProfile.vue")
  },
  {
    path: "/:organization_slug/admin/add-new-company",
    name: "AddCompanyProfile",
    meta: { role_id: 3 },
    component: () =>
      import("../components/Workspace/Posting/AdminView/AddCompanyProfile.vue")
  },
  {
    path: "/:organization_slug/admin/company/:slug/details",
    name: "CompanyProfile",
    meta: { role_id: 3 },
    component: () =>
      import(
        "../components/Workspace/CompanyApplication/OrganizationProfile/OrganizationEdit.vue"
      )
  },
  {
    path: "/company/:slug/offer/edit",
    name: "OfferEditMain",
    meta: { role_id: 3 },
    component: () =>
      import("../components/Workspace/Contract/ClientView/OfferEditMain.vue")
  },
  {
    path: "/:organization_slug/admin/company/:slug/contract/:id",
    name: "ViewContractReadOnly",
    meta: { role_id: 3 },
    component: () =>
      import(
        "../components/Workspace/Contract/ClientView/ViewContractReadOnly.vue"
      )
  },
  {
    path: "/:organization_slug/admin/company/:slug/contract/edit/:id",
    name: "ViewContractEditOnly",
    meta: { role_id: 3 },
    component: () =>
      import(
        "../components/Workspace/Contract/ClientView/ViewContractReadOnly.vue"
      )
  },
  {
    path: "/:organization_slug/admin/company/:slug/contracts",
    name: "Contracts",
    meta: { role_id: 3 },
    component: () =>
      import(
        "../components/Workspace/CompanyApplication/Contracts/ContractsMain.vue"
      )
  },
  {
    path: "/:organization_slug/admin/company/:slug/timer/report/weekly",
    name: "WeeklyReportMain",
    meta: { role_id: 3 },
    component: () =>
      import(
        "../components/Workspace/CompanyApplication/TimerReport/WeeklyReportMain.vue"
      )
  },
  {
    path: "/:organization_slug/admin/company/:slug/timer/report/contract",
    name: "ContractReportMain",
    meta: { role_id: 3 },
    component: () =>
      import(
        "../components/Workspace/CompanyApplication/TimerReport/ContractReportMain.vue"
      )
  },
  {
    path: "/:organization_slug/admin/company/:slug/timer/report/freelancer",
    name: "FreelancerReportMain",
    meta: { role_id: 3 },
    component: () =>
      import(
        "../components/Workspace/CompanyApplication/TimerReport/FreelancerReportMain.vue"
      )
  },
  {
    path: "/:organization_slug/admin/company/:slug/invoice/list",
    name: "InvoiceList",
    meta: { role_id: 3 },
    component: () =>
      import(
        "../components/Workspace/CompanyApplication/Invoice/InvoiceList.vue"
      )
  },
  {
    path: "/:organization_slug/admin/company/:slug/invoice/contact",
    name: "InvoiceContact",
    meta: { role_id: 3 },
    component: () =>
      import(
        "../components/Workspace/CompanyApplication/Invoice/InvoiceContact.vue"
      )
  },
  {
    path: "/:organization_slug/admin/company/:slug/invoice/view/:id",
    name: "ViewInvoice",
    meta: { role_id: 3 },
    component: () =>
      import(
        "../components/Workspace/CompanyApplication/Invoice/ViewInvoice.vue"
      )
  },
  {
    path: "/:organization_slug/admin/company/:slug/invoice/payment_success",
    name: "CompanyInvoicePaymentSuccess",
    meta: { role_id: 3 },
    component: () =>
      import(
        "../components/Workspace/CompanyApplication/Invoice/CompanyInvoicePaymentSuccess.vue"
      )
  },
  {
    path: "/:organization_slug/admin/company/:slug/invoice/payment_failed",
    name: "CompanyInvoicePaymentFailed",
    meta: { role_id: 3 },
    component: () =>
      import(
        "../components/Workspace/CompanyApplication/Invoice/CompanyInvoicePaymentFailed.vue"
      )
  },
  {
    path: "/:organization_slug/company/login",
    name: "CompanyLoginNew",
    meta: { role_id: 3 },
    component: () => import("../components/Auth/LoginWrapper.vue")
  },
  {
    path: "/:organization_slug/company/forgot_password",
    name: "CompanyForgotPassword",
    meta: { role_id: 3 },
    component: () => import("../components/Auth/ForgotPassword.vue")
  }
];
