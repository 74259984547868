const NotFound = () => import("../views/NotFound");

export default [
  // {
  //   path: "/social_post_card",
  //   name: "SocialPostCard",
  //   component: () => import("../components/SocialPosts/CreatePostCard.vue")
  // },

  {
    path: "/not_found",
    name: "NotFound",
    component: NotFound
  },
  {
    path: "*",
    name: "NotFoundwc",
    component: NotFound
  }
];
