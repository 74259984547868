import axios from "axios";
import router from "../../router";
import toastr from "toastr";
import config from "../../config.js";
import swal from "sweetalert2";

export default {
  namespaced: true,
  state: {
    isAuth: false,
    isFirstCheck: true,
    whoami: {},
    whoami_webuser: {},
    organizations: [],
    activeOrganization: {},
    activeOrganizationGroup: {},
    profile_image: null,
    applyOrganizationSettingsFor: "organizations",
    createFor: "organization",

    afterSignUp_JoinOrganizationData: null,

    uploadOrganizationLogo: false,
    uploadOrganizationImage: false,
    uploadOrganizationLogoPercentage: 0,
    uploadOrganizationImagePercentage: 0,

    uploadUserAvatar: false,
    uploadUserAvatarProgress: {},
    domainOrgId: null,
    active_org_subscription_status: false,

    uploadedSalespersonResume: false,
    isProcessing: false
  },

  getters: {
    // computed properties
    isProcessing(state) {
      return state.isProcessing;
    }
  },

  mutations: {
    setActiveOrganizationSubscriptionStatus(state, data) {
      state.active_org_subscription_status = data;
    },
    setUploadUserAvatar(state, data) {
      state.uploadUserAvatar = data;
    },
    setUploadUserAvatarProgress(state, data) {
      state.uploadUserAvatarProgress = data;
    },
    setUploadedOrganizationLogoPercentage(state, data) {
      state.uploadOrganizationLogoPercentage = data;
    },
    setUploadedOrganizationImagePercentage(state, data) {
      state.uploadOrganizationImagePercentage = data;
    },
    setUploadedOrganizationImage(state, data) {
      state.uploadOrganizationImage = data;
    },
    setUploadedOrganizationLogo(state, data) {
      state.uploadOrganizationLogo = data;
    },
    setIsFirstCheck(state, data) {
      console.log("Calling mutation: setIsFirstCheck: " + data);
      state.isFirstCheck = data;
    },
    setWhoAmI(state, data) {
      console.log("Calling mutation: setWhoAmI userid: ", data);
      // console.log("62 Root", state.whoami.active_organization.shortcode);
      state.whoami = data;
      state.activeOrganization = data.active_organization;
      state.activeOrganizationGroup = data.active_organization_group;
      localStorage.setItem("whoami", JSON.stringify(state.whoami));
    },

    setWhoAmIWebUser(state, data) {
      state.whoami_webuser = data;
    },

    setActiveOrganization(state, data) {
      state.whoami.active_organization = data;
      state.activeOrganization = data;
    },
    setActiveOrganizationGroup(state, data) {
      state.whoami.active_organization_group = data;
      state.activeOrganizationGroup = data;
      console.log("Set : ", state.activeOrganizationGroup);
    },
    setApplyOrganizationSettingsFor(state, data) {
      state.applyOrganizationSettingsFor = data;
      console.log("Group selected: ", state.applyOrganizationSettingsFor);
    },
    loginUser(state, data) {
      console.log("Calling mutation: loginUser");
      state.organizations = data;
      state.isAuth = true;
    },

    registerUser(state, data) {
      console.log("Calling mutation: registerUser");
      state.organizations = data;
      state.isAuth = true;
    },

    logoutUser(state) {
      console.log("Calling mutation: logoutUser");
      state.organizations = [];
      state.whoami = {};
      state.activeOrganization = {};
      state.isAuth = false;
      localStorage.removeItem("selectedCompanyPostId");
      localStorage.setItem("whoami", JSON.stringify(state.whoami));
      localStorage.setItem(
        "activeOrganization",
        JSON.stringify(state.activeOrganization)
      );
    },

    setMessagePageState(state, message_page) {
      console.log("Calling mutation: setMessagePageState");
      state.message_page = message_page;
    },

    changeActiveCategory(state, data) {
      state.whoami.active_organization_category_id = data;
    },

    setOrganizations(state, data) {
      state.organizations = data;
    },

    updateOrganizationImage(state, data) {
      state.activeOrganization.imageData = data;
    },
    updateOrganizationBanner(state, data) {
      state.activeOrganization.bannerData = data;
    },
    setCreateFor(state, data) {
      state.createFor = data;
    },

    SetAfterSignUp_JoinOrganizationData(state, data) {
      state.afterSignUp_JoinOrganizationData = data;
    },
    setDomainOrgId(state, data) {
      state.domainOrgId = data;
    },
    setUploadedSalespersonResume(state, data) {
      state.uploadedSalespersonResume = data;
    }
  },

  actions: {
    setWhoAmI({ state, commit }, data) {
      commit("setWhoAmI", data);
    },
    setWhoAmIWebUser({ state, commit }, data) {
      commit("setWhoAmIWebUser", data);
    },

    whoAmI({ state, commit, rootState, dispatch }) {
      let self = this;
      console.log("Inside whoamI==========");
      return new Promise(function(resolve, reject) {
        let role_id = self.state.userRole.role_id;
        // get whoami
        console.log("whoAmI() Called");
        axios
          .get(
            config.DOMAIN +
              config.API_VERSION +
              "users/whoami?role_id=" +
              role_id
          )
          .then(response => {
            console.log("Inside whoami---------------------", response);
            if (response.data.userid != null) {
              var isPageDomianFound = false;
              console.log("Inside if-------------");
              let whoami = response.data;
              commit("setWhoAmI", whoami);

              //Check for pageDomain
              if (!!whoami.page_domains) {
                console.log("inside isPageDomianFound");
                let res = whoami.page_domains.find(
                  pd => pd.domain_name == window.location.hostname
                );
                if (res) {
                  isPageDomianFound = true;
                  console.log("Page redirected-------------------");
                }
              }
              // check domain is correct, o/w redirect rto new domain

              console.log(
                "before ifffffff =>",
                whoami.domain,
                isPageDomianFound,
                window.location.hostname
              );
              if (!!whoami.domain && !isPageDomianFound) {
                console.log(
                  "inside not isPageDomianFound",
                  whoami.domain.domain_name,
                  whoami.shortLinkDomain
                );
                if (
                  !whoami.shortLinkDomain &&
                  whoami.domain.domain_name != window.location.hostname
                ) {
                  console.log("Line 190 :");
                  window.location.replace(
                    window.location.protocol + "//" + whoami.domain.domain_name
                  );
                }
              }

              if (response.data.active_organization) {
                dispatch(
                  "getSubscriptionStatus",
                  response.data.active_organization.id
                );
              }
              //notification
              console.log("before swal");
              if (!!whoami.active_organization) {
                let maxLimit = whoami.active_organization.max_messages;
                let currentCount = whoami.active_organization.messages;
                let messagesReachedLimit =
                  parseInt(currentCount) >= parseInt(maxLimit);
                var storageReachedLimit = false;

                if (
                  !!whoami.subscription_addon &&
                  whoami.subscription_addon.isStorageUsageLimitReached
                )
                  storageReachedLimit = true;

                // if (messagesReachedLimit || storageReachedLimit) {
                //   dispatch(
                //     "billingNotifications/showNotification",
                //     response.data,
                //     {
                //       root: true,
                //     }
                //   );
                // }
              }
            } else {
              console.log("Inside else-------------");
              commit("logoutUser");
            }
            resolve(response);
          });
        // .catch(e => {
        //   commit("logoutUser");
        //   console.log("ERRORS: " + e);
        //   resolve();
        // });
      }); // promise
    }, // whoAmI

    getUpdatedRoute({ state, commit, dispatch, rootState }, route) {
      dispatch("whoAmI").then(res => {
        if (!!state.whoami.domain && state.whoami.domain.is_global == 1) {
          window.location.replace(
            config.DOMAIN +
              "/" +
              state.whoami.active_organization.shortcode +
              route
          );
        } else {
          window.location.replace(
            "https://" + state.whoami.domain_name + route
          );
        }
      });
    },

    login({ state, commit, dispatch, rootState }, login_data) {
      // login user
      console.log("Login() function called. 1", rootState);

      swal.fire({
        title: "Processing...",
        allowEscapeKey: false,
        // html: '',// add html attribute if you want or remove
        allowOutsideClick: false,
        onBeforeOpen: () => {
          swal.showLoading();
        }
      });

      return new Promise(function(resolve, reject) {
        let url = config.DOMAIN + config.API_VERSION;
        if (login_data.userRole == 2) {
          url = url + "users/salesperson_dologin";
        } else if (login_data.userRole == 3) {
          url = url + "users/company_dologin";
        } else {
          url = url + "users/dologin";
        }
        axios
          .post(url, login_data)
          .then(
            response => {
              swal.hideLoading();
              swal.close();
              // return;
              console.log("res: ", response.data);
              if (response.data.hasOwnProperty("redirect")) {
                console.log("135 Redirecting to : ", response.data.url);
                window.location.replace(response.data.url);
              } else if (response.data.hasOwnProperty("salesperson")) {
                if (response.data["reset_password"]) {
                  window.location.replace(
                    config.DOMAIN + "/salesperson/update-password"
                  );
                  return;
                }
                dispatch("whoAmI").then(res => {
                  console.log("state.whoami.domain====>", state.whoami.domain);
                  if (
                    !!state.whoami.domain &&
                    state.whoami.domain.is_global == 1
                  ) {
                    window.location.replace(
                      config.DOMAIN +
                        "/" +
                        state.whoami.active_organization.shortcode +
                        "/admin/salesperson/" +
                        response.data["salesperson"].redirect_to_step
                    );
                  } else {
                    window.location.replace(
                      "https://" +
                        state.whoami.domain.domain_name +
                        "/admin/salesperson/" +
                        response.data["salesperson"].redirect_to_step
                    );
                  }
                });
              } else if (response.data.hasOwnProperty("companyApplication")) {
                // if(response.data["companyApplication"].redirect_to_step != "dashboard"){
                //   localStorage.setItem(
                //     "selectedCompanyPostId",
                //     response.data["companyApplication"].id
                //   );
                // }
                if (response.data["reset_password"]) {
                  dispatch("getUpdatedRoute", "/company/update-password");
                  return;
                }

                if (
                  response.data["companyApplication"].redirect_to_step ==
                  "dashboard"
                ) {
                  dispatch("getUpdatedRoute", "/company/overview");
                } else {
                  dispatch(
                    "getUpdatedRoute",
                    "/admin/company/" +
                      response.data["companyApplication"].redirect_to_step
                  );
                }
              } else if (response.data.hasOwnProperty("organizations")) {
                // if got respected response
                if (response.data["reset_password"]) {
                  window.location.replace(
                    config.DOMAIN + "/more/update_password"
                  );
                  return;
                }
                commit("loginUser", response.data);
                // dispatch('whoAmI');
                axios
                  .get(config.DOMAIN + config.API_VERSION + "users/whoami")
                  .then(whoami_response => {
                    console.log("Done!!!!", whoami_response);
                    commit("setWhoAmI", whoami_response.data);
                    if (
                      !!whoami_response &&
                      !!whoami_response.active_organization
                    ) {
                      dispatch(
                        "getSubscriptionStatus",
                        whoami_response.data.active_organization.id
                      );
                    }
                    dispatch("decideWhereToRedirect", whoami_response);
                  })
                  .catch(e => {
                    console.log("ERROR: " + e);
                  }); // axios

                resolve();
              } else {
                toastr.error("Login Failed!. Try again.", "Error: ", {
                  timeOut: config.TOASTR_TIMEOUT
                });
              } // else
            },
            error => {
              console.log("ERRORS 1: " + error.response.data.message);
              // toastr.error(error.response.data.message, "Error ", {
              //   timeOut: config.TOASTR_TIMEOUT
              // });

              swal.hideLoading();
              swal.close();

              reject(error.response.data.message);
            }
          )
          .catch(e => {
            console.log("ERRORS: " + e);
            // toastr.error("Login Failed!. Try again.", "Error: ", {
            //   timeOut: config.TOASTR_TIMEOUT
            // });
            swal.hideLoading();
            swal.close();
            reject(e.response.data.message);
          });
      });
    }, // login

    decideWhereToRedirect(
      { state, commit, dispatch, rootState },
      whoami_response
    ) {
      console.log(
        "inside decideWhereToRedirect------------------",
        rootState,
        rootState.campaignPreviewUrl
      );
      if (!!rootState.campaignPreviewUrl) {
        var previewUrl = rootState.campaignPreviewUrl;
        router.push(previewUrl);
        return false;
      }

      if (whoami_response.data.organizations_count <= 0) {
        router.push("/organizations");
      } else if (true || whoami_response.data.organizations_count == 1) {
        let url = "/admin/getting_started";
        if (
          !!whoami_response.data.domain &&
          whoami_response.data.domain.is_global == 1
        ) {
          url = "/" + whoami_response.data.active_organization.shortcode + url;
        }

        if (
          !!whoami_response.domain &&
          whoami_response.domain.default_login_domain == 1
        ) {
          url =
            "/" +
            state.whoami.active_organization.shortcode +
            "/admin/getting_started";
        }
        console.log("240: url", url);
        window.location.href = url;
      }
    },
    register({ state, commit, dispatch }, form_data) {
      // register user
      console.log("register() function called.");

      commit("setLoading", true, { root: true });
      axios
        .post(config.DOMAIN + config.API_VERSION + "users/register", form_data)
        .then(
          response => {
            console.log("response --- ", response.data);

            commit("setLoading", false, { root: true });

            if (!!response.data && response.data.hasOwnProperty("redirect")) {
              console.log("135 Redirecting to : ", response.data.url);
              window.location.replace(response.data.url);
            } else if (!!response.data) {
              commit("setWhoAmI", response.data);
              // router.push("/appointment");
              router.push("/organizations");

              if (!!response.data && response.data.userid > 0) {
                dispatch("whoAmI").then(res => {
                  // alert("405 appointment");
                  // router.push("/appointment");
                  router.push("/organizations");
                  // dispatch("decideWhereToRedirect", response);
                });
                // call whoami api again and in then decideWhereToRedirect
              } else router.push("/login");

              // userServices.getWhoAmI().then(response => {
              //   commit("setWhoAmI", response.data);
              //   if (!!response.data && response.data.userid > 0)
              //     dispatch("decideWhereToRedirect", response);
              //   else router.push("/login");
              // });
            } else if (
              !!response.data &&
              response.data.email_verified == null
            ) {
              swal({
                title: "",
                text:
                  "A verification email was sent. Please verify your email before proceeding.",
                button: "Close",
                html: true
              });
            } else {
              toastr.error("Registration Failed. Try again", "Error ", {
                timeOut: config.TOASTR_TIMEOUT
              });
            } // else
          },
          error => {
            commit("setLoading", false, { root: true });

            console.log("ERRORS 1: " + error.response.data.message);
            toastr.error(error.response.data.message, "", {
              timeOut: config.TOASTR_TIMEOUT
            });
          }
        )
        .catch((response, e) => {
          swal.hideLoading();
          swal.close();
          console.log("e  -", e, response);
          toastr.error("Registration Failed. Try again!", "", {});
        });
    }, // register

    logoutUser({ state, commit }) {
      swal.fire({
        title: "Processing...",
        allowEscapeKey: false,
        // html: '',// add html attribute if you want or remove
        allowOutsideClick: false,
        onBeforeOpen: () => {
          swal.showLoading();
        }
      });
      axios
        .get(config.DOMAIN + config.API_VERSION + "users/dologout")
        .then(response => {
          // here response is not specified in API docs
          // You can replace true= your response
          const currentRole = state.whoami.role_id;
          commit("logoutUser");
          if (currentRole == 3) {
            console.log("Before router.push");
            router.push("/company/login");
            console.log("After router.push");
          } else if (currentRole == 2) {
            console.log("Before router.push");
            router.push("/salesperson/login");
            console.log("After router.push");
          } else {
            router.push({ name: "Login" });
          }
          swal.hideLoading();
          swal.close();
        })
        .catch(e => {
          toastr.error("Something went wrong.", "Error ", {
            timeOut: config.TOASTR_TIMEOUT
          });
        });
    }, // logout

    forgotPassword({ state, commit }, form_data) {
      swal.fire({
        title: "Processing...",
        allowEscapeKey: false,
        // html: '',// add html attribute if you want or remove
        allowOutsideClick: false,
        onBeforeOpen: () => {
          swal.showLoading();
        }
      });
      axios
        .post(
          config.DOMAIN + config.API_VERSION + "users/forgotpassword",
          form_data
        )
        .then(response => {
          swal.hideLoading();
          swal.close();
          if (response.data.message != null) {
            // if got respected response
            let message_page = {
              title: "Temporary Password Sent",
              message:
                "A Temporary Password has been sent to the email provided if it is in the system. Please use this Temporary Password to update your Password.",
              next_route: ""
            };

            if (form_data.role_id == 3) {
              message_page.next_route = "/company/login";
            } else if (form_data.role_id == 2) {
              message_page.next_route = "/salesperson/login";
            } else {
              message_page.next_route = "/login";
            }

            commit("setMessagePageState", message_page);
            router.push("/temporary_password");
          } else
            toastr.error("Something went wrong.", "Error ", {
              timeOut: config.TOASTR_TIMEOUT
            });
        })
        .catch(e => {
          console.log("ERRORS: " + e);
          toastr.success("", e.response.data.message, {
            timeOut: config.TOASTR_TIMEOUT
          });
        });
    }, // forgotPassword

    updatePassword({ state, commit }, form_data) {
      form_data.username = state.whoami.email;
      state.isProcessing = true;
      console.log(
        "updatePassword() called. FormData: " +
          form_data.username +
          " password: " +
          form_data.password +
          " role_id: " +
          form_data.role_id
      );
      axios
        .put(
          config.DOMAIN +
            config.API_VERSION +
            "users/" +
            state.whoami.userid +
            "/password",
          form_data
        )
        .then(
          response => {
            state.isProcessing = false;
            if (true) {
              // if got respected response
              var message_page = {
                title: "Password Change",
                message:
                  "Your Password has been changed. You may now use your new password for future logins.",
                next_route: "/organizations"
              };
              commit("setMessagePageState", message_page);
              if (state.whoami.role_id == 2) {
                if (
                  !!state.whoami.domain &&
                  state.whoami.domain.is_global == 1
                ) {
                  router.push(
                    config.DOMAIN +
                      "/" +
                      state.whoami.active_organization.shortcode +
                      "/admin/salesperson/" +
                      state.whoami.redirect_to_step
                  );
                } else {
                  router.push(
                    "/admin/salesperson/" + state.whoami.redirect_to_step
                  );
                }
              } else if (state.whoami.role_id == 3) {
                if (state.whoami?.company_info?.slug) {
                  router.push(
                    "/admin/company/" +
                      state.whoami.company_info.slug +
                      "/overview"
                  );
                } else {
                  router.push(
                    "/" +
                      state.whoami.active_organization.shortcode +
                      "/company/" +
                      state.whoami.redirect_to_step
                  );
                }
              } else router.push("/temporary_password");
            } else {
            } // else
          },
          error => {
            state.isProcessing = false;
            toastr.error(error.response.data.message, "", {
              timeOut: config.TOASTR_TIMEOUT
            });
          }
        )
        .catch(e => {
          console.log("ERRORS: " + e);
        });
    }, // updatePassword
    deleteAccount({ dispatch, state, commit }, data) {
      console.log("deleteAccount() called");
      console.log("data -- ", data);
      axios
        .delete(
          config.DOMAIN + config.API_VERSION + "users/" + state.whoami.userid,
          { params: data }
        )
        .then(
          response => {
            if (true) {
              console.log(response);
              dispatch("logoutUser");
              var data = {};
              data.whoami = {};
              data.active_organization = {};
              data.activeOrganizationGroup = {};
              commit("setWhoAmI", data);
              router.push({ name: "SignUp" });
            } else {
            } // else
          },
          error => {
            toastr.error(error.response.data.message, "", {
              timeOut: config.TOASTR_TIMEOUT
            });
          }
        )
        .catch(e => {
          console.log("ERRORS: " + e);
        });
    }, // deleteAccount

    updateAvatar({ state, commit, dispatch }, form_data) {
      //form_data.id = state.whoami.userid;
      new Promise(function(resolve, reject) {
        let totalAttachments = 1;
        let progress = {
          total: totalAttachments,
          percent: 0,
          current: 0
        };
        let fileProgress = [];

        const configData = {
          onUploadProgress: function(progressEvent) {
            // save the individual file's progress percentage in object
            fileProgress[progress.current] =
              (progressEvent.loaded * 100) / progressEvent.total;
            // sum up all file progress percentages to calculate the overall progress
            let totalPercent = 0;
            // divide the total percentage by the number of files
            if (progress.total > 1) {
              totalPercent = fileProgress.filter(ele => ele == 100).length;
              progress.percent = parseInt(
                Math.round((totalPercent / progress.total) * 100)
              );
            } else {
              totalPercent = fileProgress
                ? Object.values(fileProgress).reduce((sum, num) => sum + num, 0)
                : 0;
              progress.percent = parseInt(Math.round(totalPercent));
            }
            commit("setUploadUserAvatarProgress", progress);
            if (progressEvent.loaded === progressEvent.total) {
              progress.current++;
            }
          }
        };
        axios
          .post(
            config.DOMAIN +
              config.API_VERSION +
              "users/" +
              state.whoami.userid +
              "/image",
            form_data,
            configData
          )
          .then(
            response => {
              if (true) {
                console.log(response);
                commit("setUploadUserAvatar", true);
                commit("setUploadUserAvatarProgress", 0);
                /*router.push("/organizations/profile");*/
                // if got respected response
                // var message_page = {'title': 'Password Change', 'message': 'Your Password has been changed. You may now use your new password for future logins.', 'next_route': '/'};
                // commit('setMessagePageState', message_page);
                // router.push({name: 'Message'});
              } else {
              } // else
            },
            error => {
              toastr.error(error.response.data.message, "", {
                timeOut: config.TOASTR_TIMEOUT
              });
            }
          )
          .catch(e => {
            console.log("ERRORS: " + e);
          });
      });
    }, // UpdateAvatar
    uploadResume({ state, commit, dispatch }, form_data) {
      //form_data.id = state.whoami.userid;
      new Promise(function(resolve, reject) {
        // let totalAttachments = 1;
        // let progress = {
        //   total: totalAttachments,
        //   percent: 0,
        //   current: 0
        // };
        // let fileProgress = [];

        // const configData = {
        //   onUploadProgress: function(progressEvent) {
        //     // save the individual file's progress percentage in object
        //     fileProgress[progress.current] =
        //       (progressEvent.loaded * 100) / progressEvent.total;
        //     // sum up all file progress percentages to calculate the overall progress
        //     let totalPercent = 0;
        //     // divide the total percentage by the number of files
        //     if (progress.total > 1) {
        //       totalPercent = fileProgress.filter(ele => ele == 100).length;
        //       progress.percent = parseInt(
        //         Math.round((totalPercent / progress.total) * 100)
        //       );
        //     } else {
        //       totalPercent = fileProgress
        //         ? Object.values(fileProgress).reduce((sum, num) => sum + num, 0)
        //         : 0;
        //       progress.percent = parseInt(Math.round(totalPercent));
        //     }
        //     commit("setUploadUserAvatarProgress", progress);
        //     if (progressEvent.loaded === progressEvent.total) {
        //       progress.current++;
        //     }
        //   }
        // };
        axios
          .post(
            config.DOMAIN +
              config.API_VERSION +
              "sales_person_details/upload-resume",
            form_data
          )
          .then(
            response => {
              if (true) {
                commit("setUploadedSalespersonResume", true);
              } else {
                commit("setUploadedSalespersonResume", false);
              }
            },
            error => {
              toastr.error(error.response.data.message, "", {
                timeOut: config.TOASTR_TIMEOUT
              });
            }
          )
          .catch(e => {
            console.log("ERRORS: " + e);
          });
      });
    }, // UpdateAvatar
    addCompanyAttachment({ state, commit, dispatch }, form_data) {
      //form_data.id = state.whoami.userid;
      new Promise(function(resolve, reject) {
        axios
          .post(
            config.DOMAIN +
              config.API_VERSION +
              "company_contract/contract-attachment",
            form_data
          )
          .then(
            response => {
              if (true) {
                console.log("uploaded Resume----------", response);
              }
            },
            error => {
              toastr.error(error.response.data.message, "", {
                timeOut: config.TOASTR_TIMEOUT
              });
            }
          )
          .catch(e => {
            console.log("ERRORS: " + e);
          });
      });
    },
    verifyEmail({ state, commit, dispatch, rootState }, data) {
      new Promise(function(resolve, reject) {
        console.log("verify email Called");
        axios
          .get(
            config.DOMAIN +
              config.API_VERSION +
              "users/verify?email=" +
              data.email +
              "&verifycode=" +
              data.verifycode
          )
          .then(response => {
            console.log("verify response============>", response);
            debugger;
            if (response.data.hasOwnProperty("salesperson")) {
              window.location.replace(
                config.DOMAIN +
                  "/admin/salesperson/" +
                  response.data["salesperson"].redirect_to_step
              );
            } else if (response.data.hasOwnProperty("organizations")) {
              toastr.error("Email verified successfully! ", " ", {
                timeOut: config.TOASTR_TIMEOUT
              });
              // router.push({ name: "Welcome" });
              // if got respected response
              commit("loginUser", response.data);
              // dispatch('whoAmI');

              axios
                .get(config.DOMAIN + config.API_VERSION + "users/whoami")
                .then(whoami_response => {
                  console.log("Done!!!!");
                  commit("setWhoAmI", whoami_response.data);
                  router.push("/organizations");
                })
                .catch(e => {
                  console.log("ERROR: " + e);
                });
            } else {
              toastr.error("Email Verification Failed! ", "Error:  ", {
                timeOut: config.TOASTR_TIMEOUT
              });
            }
            resolve();
          })
          .catch(e => {
            console.log("Error: " + e);
            toastr.error("Email Verification Failed! ", "Error:  ", {
              timeOut: config.TOASTR_TIMEOUT
            });
            resolve();
          }); //axios
      }); // promise
    },

    updateSettings({ state, commit, rootState }, data) {
      axios
        .put(
          config.DOMAIN +
            config.API_VERSION +
            "users/" +
            data.userId +
            "/emailnotificationsettings",
          data.formData
        )
        .then(
          response => {
            console.log(data.hasOwnProperty("updateSettingsListType"));

            if (response.data.hasOwnProperty("id")) {
              toastr.clear();
              toastr.error("Your email notification settings updated!", "", {
                timeOut: config.TOASTR_TIMEOUT
              });
              if (data.updateSettingsListType == "singleSelect") {
                if (data.settingName == "newsletters") {
                  console.log("Inside newsletters");
                  commit("setWhoAmI", {
                    ...data.whoami,
                    newsletters: data.settingValue
                  });
                }
                if (data.settingName == "postUpdateSuccess") {
                  console.log("Inside postUpdateSuccess");
                  commit("setWhoAmI", {
                    ...data.whoami,
                    post_update_success: data.settingValue
                  });
                }
                if (data.settingName == "postUpdateFailure") {
                  console.log("Inside postUpdateFailure");
                  commit("setWhoAmI", {
                    ...data.whoami,
                    post_update_failure: data.settingValue
                  });
                }
                if (data.settingName == "promotionNotifications") {
                  console.log("Inside promotionNotifications");
                  commit("setWhoAmI", {
                    ...data.whoami,
                    promotion_notifications: data.settingValue
                  });
                }
              }
            } else {
              toastr.clear();
              toastr.error(
                "Unable to update email notification settings. Try again",
                "Error ",
                {
                  timeOut: config.TOASTR_TIMEOUT
                }
              );
            }
          },
          error => {
            toastr.error(error.response.data.message, "", {
              timeOut: config.TOASTR_TIMEOUT
            });
          }
        )
        .catch(e => {
          console.log("ERRORS: " + e);
        });
    },
    getSubscriptionStatus(
      { state, commit, rootState, dispatch },
      organizations_id
    ) {
      return new Promise(function(resolve, reject) {
        console.log(
          "Inside getSubscriptionStatus organizations_id",
          organizations_id
        );
        axios
          .get(
            config.DOMAIN +
              config.API_VERSION +
              "organizations/" +
              organizations_id
          )
          .then(response => {
            console.log("response => ", response, response.data);
            if (!!response.data) {
              let subscriptionStatus = response.data.active_subscription;
              console.log(
                "subscriptionStatus  =>",
                subscriptionStatus,
                response.data
              );
              let status =
                !!subscriptionStatus &&
                (subscriptionStatus == "1" || subscriptionStatus == 1)
                  ? true
                  : false;
              let orgRes = response.data;
              state.whoami.active_organization.active_subscription =
                orgRes.active_subscription;
              state.whoami.active_organization.default_domain_id =
                orgRes.default_domain_id;
              state.whoami.active_organization.organization_role_id =
                orgRes.organization_role_id;
              state.whoami.active_organization.license = orgRes.license;

              state.whoami.active_organization.license = orgRes.license;
              state.whoami.active_organization.trial_expiry_date =
                orgRes.trial_expiry_date;
              state.whoami.active_organization.subscriptionCreated =
                orgRes.subscriptionCreated;

              state.whoami.active_organization.plan_name = orgRes.plan_name;
              state.whoami.active_organization.subscription_status =
                orgRes.subscription_status;

              if (state.whoami.active_organization?.subscription?.expiry_date) {
                state.whoami.active_organization.subscription.expiry_date =
                  orgRes.expiry_date;
              }
              //commit("setActiveOrganization", response.data);
              commit("setActiveOrganizationSubscriptionStatus", status);
              resolve(response.data);
            }
          })
          .catch(e => {
            console.log("Error in getSubscriptionStatus::", e);
          });
      });
    },

    getAllOrganizations({ state, commit }) {
      return new Promise(function(resolve, reject) {
        // get whoami
        console.log("getAllOrganizations() Called");
        axios
          .get(config.DOMAIN + config.API_VERSION + "organizations")
          .then(response => {
            resolve(response);
            console.log("response -- 574 ", response, response.data);
            commit("setOrganizations", response.data);
          })
          .catch(e => {
            console.log("Failed!", e);
            resolve();
          });
      }); // promise
    }, // getAllOrganizations
    setActiveOrganizationGroup({ state, commit }, data) {
      console.log("864", data);

      return new Promise((resolve, reject) => {
        if (!!data == false || !!data.id == false) {
          reject("Group is not valid");
          return fasle;
        }
        axios
          .get(
            config.DOMAIN +
              config.API_VERSION +
              "organizations/" +
              data.organization_id +
              "/organizationgroups/set_default/" +
              data.id
          )
          .then(response => {
            commit("setActiveOrganizationGroup", data);
            resolve(response);
          })
          .catch(e => {
            console.error("Failed!", e.response.data.message);
            toastr.error(e.response.data.message, "", {
              timeOut: config.TOASTR_TIMEOUT
            });
            reject(e);
          });
      });
    },

    updateOrganization({ state, commit }, data) {
      console.log("updateOrganization called() ", state.activeOrganization);
      return new Promise((resolve, reject) => {
        axios
          .put(
            config.DOMAIN + config.API_VERSION + "organizations/" + data.id,
            data
          )
          .then(response => {
            resolve(response);
            toastr.success("", "Organization updated.", {
              timeOut: 5000
            });
          })
          .catch(e => {
            let err_msg = e.response.data.message;
            if (err_msg.includes("Duplicate entry")) {
              err_msg =
                "Url code already exists. Please enter unique url code.";
            }
            reject(err_msg);
            console.log("Failed!", e);
          });
      });
    }, //updateOrganizations
    updateOrganizationPages({ state, commit }, data) {
      console.log(
        "updateOrganizationPages called() ",
        state.activeOrganization
      );
      return new Promise((resolve, reject) => {
        axios
          .post(
            config.DOMAIN +
              config.API_VERSION +
              "organizations/" +
              state.whoami.active_organization.id +
              "/organization_onboarding_details",
            data
          )
          .then(response => {
            resolve(response);
            toastr.success("", "Organization updated.", {
              timeOut: 5000
            });
          })
          .catch(e => {
            let err_msg = e.response.data.message;
            if (err_msg.includes("Duplicate entry")) {
              err_msg =
                "Url code already exists. Please enter unique url code.";
            }
            reject(err_msg);
            console.log("Failed!", e);
          });
      });
    }, //updateOrganizations

    updateProfile({ state, commit, root }, data) {
      console.log("updateProfile: ", data);
      return new Promise((resolve, reject) => {
        axios
          .put(
            config.DOMAIN +
              config.API_VERSION +
              "users/" +
              state.whoami.userid +
              "/profile",
            data
          )
          .then(response => {
            var fullname =
              response.data.first_name + " " + response.data.last_name;
            commit("setWhoAmI", {
              ...state.whoami,
              first_name: response.data.first_name
            });
            commit("setWhoAmI", {
              ...state.whoami,
              last_name: response.data.last_name
            });
            commit("setWhoAmI", {
              ...state.whoami,
              about_me: response.data.about_me
            });
            commit("setWhoAmI", {
              ...state.whoami,
              email: response.data.email
            });
            commit("setWhoAmI", {
              ...state.whoami,
              phone: response.data.phone
            });
            commit("setWhoAmI", {
              ...state.whoami,
              name: fullname
            });
            console.log("response - ", response);
            resolve(response.data);
            toastr.success("", "User Profile updated.", {
              timeOut: 500000
            });
          })
          .catch(e => {
            reject(e.response.data.message);
            console.log("Failed!", e);
          });
      });
    }, // updateProfile
    updateOrganizationImage({ state, commit, rootState }, data) {
      console.log("Update Organization Logo: ", data);

      // save image of ad
      let imageData = new FormData();
      imageData.append("image", data.image, data.image.name);
      return new Promise((resolve, reject) => {
        let totalAttachments = 1;
        let progress = {
          total: totalAttachments,
          percent: 0,
          current: 0
        };
        let fileProgress = [];

        const configData = {
          onUploadProgress: function(progressEvent) {
            // save the individual file's progress percentage in object
            fileProgress[progress.current] =
              (progressEvent.loaded * 100) / progressEvent.total;
            // sum up all file progress percentages to calculate the overall progress
            let totalPercent = 0;
            // divide the total percentage by the number of files
            if (progress.total > 1) {
              totalPercent = fileProgress.filter(ele => ele == 100).length;
              progress.percent = parseInt(
                Math.round((totalPercent / progress.total) * 100)
              );
            } else {
              totalPercent = fileProgress
                ? Object.values(fileProgress).reduce((sum, num) => sum + num, 0)
                : 0;
              progress.percent = parseInt(Math.round(totalPercent));
            }
            commit("setUploadedOrganizationImagePercentage", progress);
            if (progressEvent.loaded === progressEvent.total) {
              progress.current++;
            }
          }
        };

        axios
          .post(
            config.DOMAIN +
              config.API_VERSION +
              "organizations/" +
              data.id +
              "/image",
            imageData,
            configData
          )
          .then(image_response => {
            commit("setUploadedOrganizationImage", true);
            commit("setUploadedOrganizationImagePercentage", 0);
            console.log("Organization Image Updated", image_response.data);
            commit("updateOrganizationImage", data.image);
            resolve(image_response);
          })
          .catch(e => {
            console.log("Image Post Error: ", e);
            toastr.clear();
            toastr.error(e.response.data.message, "", {
              timeOut: config.TOASTR_TIMEOUT
            });
          }); //axios
      });
    },
    updateOrganizationBanner({ state, commit, dispatch }, data) {
      axios
        .post(
          config.DOMAIN +
            config.API_VERSION +
            "organizations/" +
            data.id +
            "/banner",
          data
        )
        .then(image_response => {
          commit("updateOrganizationBanner", data.image);
          //resolve(image_response);
        })
        .catch(e => {
          console.log("Image Post Error: ", e);
        }); //axios
    },
    updateOrganizationLogo({ state, commit, dispatch }, data) {
      console.log("Update Organization Logo: ", data);

      // save image of ad
      let imageData = new FormData();
      imageData.append("image", data.logo, data.logo.name);
      return new Promise((resolve, reject) => {
        let totalAttachments = 1;
        let progress = {
          total: totalAttachments,
          percent: 0,
          current: 0
        };
        let fileProgress = [];

        const configData = {
          onUploadProgress: function(progressEvent) {
            // save the individual file's progress percentage in object
            fileProgress[progress.current] =
              (progressEvent.loaded * 100) / progressEvent.total;
            // sum up all file progress percentages to calculate the overall progress
            let totalPercent = 0;
            // divide the total percentage by the number of files
            if (progress.total > 1) {
              totalPercent = fileProgress.filter(ele => ele == 100).length;
              progress.percent = parseInt(
                Math.round((totalPercent / progress.total) * 100)
              );
            } else {
              totalPercent = fileProgress
                ? Object.values(fileProgress).reduce((sum, num) => sum + num, 0)
                : 0;
              progress.percent = parseInt(Math.round(totalPercent));
            }
            commit("setUploadedOrganizationLogoPercentage", progress);
            if (progressEvent.loaded === progressEvent.total) {
              progress.current++;
            }
          }
        };

        axios
          .post(
            config.DOMAIN +
              config.API_VERSION +
              "organizations/" +
              data.id +
              "/logo",
            imageData,
            configData
          )
          .then(image_response => {
            commit("setUploadedOrganizationLogo", true);
            commit("setUploadedOrganizationLogoPercentage", 0);
            console.log("Organization Logo Updated", image_response.data);
            //commit("updateOrganizationLogo", data.logo);
            dispatch("getAllOrganizations");
            dispatch("whoAmI");
            resolve(image_response);
          })
          .catch(e => {
            console.log("Image Post Error: ", e);
            toastr.clear();
            toastr.error(e.response.data.message, "", {
              timeOut: config.TOASTR_TIMEOUT
            });
          }); //axios
      });
    },
    changeActiveOrganizationAndCategory(
      { state, commit, data },
      changeOrganizationData
    ) {
      return new Promise(function(resolve, reject) {
        var organization_id = changeOrganizationData.organization_id;
        // 1. change active_organization.id in session: api call
        // 2. change active_organization.id in whoami
        // 3. load all categories of selected organization
        // 4. set default category in session
        // 5. set default category in whoami
        // 6. redirect to category_drip

        axios
          .get(
            config.DOMAIN +
              config.API_VERSION +
              "organizations/set_default/" +
              organization_id
          )
          .then(response => {
            console.log(response.data);

            if (response.data.userid != null) {
              commit("setWhoAmI", response.data);
              let defaultOrganization = response.data.active_organization;
              /*{
                ...state.organizations.filter(
                  o => o.id == response.data.active_organization.id
                )[0]
              };*/
              commit("setActiveOrganization", defaultOrganization);
              resolve(response);
            } else commit("logoutUser");
          })
          .catch(e => {
            commit("logoutUser");
            console.log("ERRORS: " + e);
            reject(e);
          });
      });
    },

    setApplyOrganizationSettingsFor({ state, commit }, data) {
      commit("setApplyOrganizationSettingsFor", data);
    },
    setCreateFor({ state, commit }, data) {
      commit("setCreateFor", data);
    },

    signUpAndJoinOrganization({ state, commit }, data) {
      commit("SetAfterSignUp_JoinOrganizationData", data);
      router.push("sign_up");
    },
    setActiveOrganization({ state, commit }, data) {
      commit("setActiveOrganization", data);
    },
    setUploadOrganizationImageFlag(state, data) {
      commit("setUploadedOrganizationImage", data);
    },
    setUploadOrganizationLogoFlag(state, data) {
      commit("setUploadedOrganizationLogo", data);
    },
    updateOrgUserInfo({ state, commit, root }, data) {
      console.log("updateOrgUserInfo: ", data);
      return new Promise((resolve, reject) => {
        axios
          .post(
            config.DOMAIN + config.API_VERSION + "organizations/updateorguser",
            data
          )
          .then(response => {
            resolve(response.data);
          })
          .catch(e => {
            reject(e.response.data.message);
            console.log("Failed!", e);
          });
      });
    },
    setDomainOrgId({ state, commit }, data) {
      console.log("setDomainOrgId -- ", data);
      commit("setDomainOrgId", data);
    },
    setUploadedOrganizationLogo({ state, commit }, data) {
      console.log("setUploadedOrganizationLogo -- ", data);
      commit("setUploadedOrganizationLogo", data);
    },
    setUploadedSalespersonResume({ state, commit }, data) {
      commit("setUploadedSalespersonResume", data);
    },
    logoutUserNoRedirect({ state, commit }) {
      axios
        .get(config.DOMAIN + config.API_VERSION + "users/dologout")
        .then(response => {
          // here response is not specified in API docs
          // You can replace true= your response
          if (true) {
            // if got respected response
            commit("logoutUser");
            toastr.error("Logged out successfully.", "", {
              timeOut: config.TOASTR_TIMEOUT
            });
          } else {
          }
        })
        .catch(e => {
          toastr.error("Something went wrong.", "Error ", {
            timeOut: config.TOASTR_TIMEOUT
          });
        });
    }
  }
};
