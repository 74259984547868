import axios from "axios";
import toastr from "toastr";
import config from "../../config.js";
import * as moment from "moment";

export default {
  namespaced: true,
  state: {
    forms: [],
    form: {},
    detailLead: {},
    detailLeadForms: []
  },

  getters: {},

  mutations: {
    setForms(state, data) {
      state.forms = data;
    },
    updateForms(state, data) {
      const index = state.forms.findIndex(element => element.id === data.id);
      state.forms.splice(index, 1);
    },
    clearForm(state, data) {
      state.form = data;
    },
    setDetailLead(state, data) {
      state.detailLead = data;
    },
    setDetailLeadForms(state, data) {
      state.detailLeadForms = data;
    }
  },

  actions: {
    setForms({ state, commit }, data) {
      commit("setForms", data);
    },
    updateForms({ state, commit }, data) {
      commit("updateForms", data);
    },
    clearForm({ state, commit }, data) {
      commit("clearForm", {
        organization_connection_type_id: null,
        name: "",
        url: ""
      });
    },
    setDetailLeadData({ state, commit }, data) {
      commit("setDetailLead", data.lead);
      commit("setDetailLeadForms", data.leadForms);
    }
  }
};
