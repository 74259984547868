const Organizations = () => import("../components/Organizations/Organizations");
const AddTeamMembersToOrganization = () =>
  import("../components/Organizations/AddTeamMembers/AddTeamMember");
const OrganizationsAdd = () =>
  import("../components/Organizations/OrganizationsAdd");
const StreamPage = () => import("../components/Streams/Single/StreamPage");

// const DiscoverGroups = () =>
//   import("../components/Organizations/DiscoverGroups");
// const OrganizationDiscover = () =>
//   import("../components/Organizations/OrganizationDiscover");
// const OrganizationHome = () =>
//   import("../components/Organizations/OrganizationHome");
// const GroupStreams = () => import("../components/Organizations/GroupStreams");
// const YourGroups = () => import("../components/Organizations/YourGroups");
// const ExternalGroups = () =>
//   import("../components/Organizations/ExternalGroups");
// /* const OrganizationSubscriptions = () =>
//   import("../components/Organizations/OrganizationSubscriptions"); */
const OrganizationDetails = () =>
  import("../components/Organizations/OrganizationDetails");
// const Create = () => import("../components/Organizations/Create/Create");
// const TeamMembersList = () =>
//   import("../components/Organizations/AddTeamMembers/ListMembers");

export default [
  {
    path: "/organizations/details",
    name: OrganizationDetails,
    component: OrganizationDetails,
    meta: { for: "organizations" }
  },
  // {
  //   path: "/groups/details",
  //   name: OrganizationDetails,
  //   component: OrganizationDetails,
  //   meta: { for: "groups" }
  // },
  // {
  //   path: "/organizations/:organization_id/groups/details/:group_id",
  //   name: OrganizationDetails,
  //   component: OrganizationDetails,
  //   meta: { for: "groups" }
  // },
  // {
  //   path: "/organizations/details/:organizationId/sub-organization/:groupId",
  //   name: OrganizationDetails,
  //   component: OrganizationDetails
  // },

  // {
  //   path: "/organizations/discover",
  //   name: "OrganizationDiscover",
  //   component: OrganizationDiscover,
  //   meta: { for: "organizations" }
  // },

  // {
  //   path: "/groups/streams",
  //   name: "GroupStreams",
  //   component: GroupStreams
  // },
  // {
  //   path: "/groups/:group_id/:group_name/streams",
  //   name: "GroupStreams2",
  //   component: GroupStreams
  // },
  // {
  //   path: "/organizations/:organization_id/group/:group_id",
  //   name: "DiscoverGroups",
  //   component: DiscoverGroups,
  //   meta: { for: "organizations", showGrayFooter: true }
  // },

  {
    path: "/organizations",
    name: "Organizations",
    component: Organizations
  },

  // {
  //   path: "/organizations/create",
  //   name: "OrganizationsAdd",
  //   component: OrganizationsAdd,
  //   meta: { for: "organizations" }
  // },
  // {
  //   path: "/organizations/group/create",
  //   name: "OrganizationsGroupAdd",
  //   component: OrganizationsAdd,
  //   meta: { for: "groups" }
  // },

  // {
  //   path: "/user/organizations/group/create",
  //   name: "UserOrganizationsGroupAdd",
  //   component: OrganizationsAdd,
  //   meta: { for: "user" }
  // },

  // {
  //   path: "/organizations/stream",
  //   name: "OrganizationStreamHome",
  //   component: OrganizationHome
  // },

  // {
  //   path: "/organizations/:organization_id/:organizations_name/home",
  //   name: "OrganizationHome",
  //   component: OrganizationHome
  // },

  // {
  //   path: "/organizations/your_groups",
  //   name: "YourGroups",
  //   component: YourGroups
  // },
  // {
  //   path: "/organizations/external_groups",
  //   name: "ExternalGroups",
  //   component: ExternalGroups
  // },
  /*  {
    path: "/organizations/subscriptions",
    name: "OrganizationSubscriptions",
    component: OrganizationSubscriptions
  },*/

  // {
  //   path: "/organizations/groups",
  //   name: "OrganizationDiscover",
  //   component: OrganizationDiscover,
  //   meta: {
  //     hideTags: true,
  //     title: "Add a Subgroup",
  //     backUrl: "/more/organizations"
  //   }
  // },
  // {
  //   path: "/groups/subgroups",
  //   name: "DiscoverSubGroups",
  //   component: DiscoverGroups,
  //   meta: {
  //     hideTags: true,
  //     title: "Add a Subgroup",
  //     backUrl: "/more/groups",
  //     backgroundColorGray: true,
  //     showGrayFooter: true,
  //     for: "groups"
  //   }
  // },

  // {
  //   path: "/:action/organizations/:id/listmembers",
  //   name: "UserTeamMembersList1",
  //   component: TeamMembersList,
  //   meta: { for: "organizations" }
  // },
  // {
  //   path: "/user/:action/organizations/:id/listmembers",
  //   name: "UserTeamMembersList",
  //   component: TeamMembersList,
  //   meta: { for: "user" }
  // },
  // {
  //   path: "/:action/groups/:id/listmembers",
  //   name: "GroupTeamMembersList",
  //   component: TeamMembersList,
  //   meta: { for: "groups" }
  // },
  // {
  //   path: "/:action/:type/:id/listmembers",
  //   name: "TeamMembersList1",
  //   component: TeamMembersList,
  //   meta: { for: "user" }
  // },
  // {
  //   path: "/:action/organizations/members/add",
  //   name: "AddTeamMembersToOrganization",
  //   component: AddTeamMembersToOrganization,
  //   meta: { for: "organizations" }
  // },
  // {
  //   path: "/:action/user/organizations/members/add",
  //   name: "UserAddTeamMembersToOrganization",
  //   component: AddTeamMembersToOrganization,
  //   meta: { for: "user" }
  // },
  // {
  //   path: "/:action/groups/members/add",
  //   name: "GroupAddTeamMembersToOrganization",
  //   component: AddTeamMembersToOrganization,
  //   meta: { for: "groups" }
  // },
  {
    path: "/:action/:type/members/group",
    name: "AddTeamMembersToOrganization2",
    component: AddTeamMembersToOrganization,
    meta: { for: "user" }
  },
  // {
  //   path: "/:type/:organization_id/create",
  //   name: Create,
  //   component: Create,
  //   meta: { for: "organizations" }
  // },

  // {
  //   path: "/group/:group_id/stream/:id",
  //   name: "StreamPageUser",
  //   component: StreamPage,
  //   meta: { for: "user", from: "user" }
  // },

  {
    path: "/stream/:id",
    name: "StreamPageOrganization",
    component: StreamPage,
    meta: { for: "organization", from: "organization" }
  }
];
