import axios from "axios";
import config from "../config.js";
import store from "../store";
import arraysLib from "../lib/arrays.js";
import toastr from "toastr";

export default {
  saveSender: function(data) {
    return new Promise(function(resolve, reject) {
      var url =
        config.DOMAIN +
        config.API_VERSION +
        "organizations/" +
        store.state.auth.whoami.active_organization.id +
        "/senders";

      axios
        .post(url, data)
        .then(response => {
          resolve(response);
        })
        .catch(e => {
          reject(e.response.data.message);
        });
    }); // promise.
  },
  getSenders: function(data) {
    let self = this;
    return new Promise(function(resolve, reject) {
      var url =
        config.DOMAIN +
        config.API_VERSION +
        "organizations/" +
        store.state.auth.whoami.active_organization.id +
        "/senders";
      if (!!data.hubid) {
        url = url + "?hubid=" + data.hubid;
      }

      if (!!data.sender_ids) {
        url += url.includes("?")
          ? "&sender_ids=" + data.sender_ids
          : "?sender_ids=" + data.sender_ids;
      }

      if (!!data.organization_user_id) {
        url += url.includes("?")
          ? "&organization_user_id=" + data.organization_user_id
          : "?organization_user_id=" + data.organization_user_id;
      }

      if (!!data.limit) {
        let param = "&";
        if (!!data.hubid) url = url + "&limit=" + data.limit;
        else url = url + "?limit=" + data.limit;
      }
      axios
        .get(url)
        .then(response => {
          response.data = self.createOrgUserObjectOnUI(response.data);

          console.log("senders 44", response.data);
          // create organizationUser object
          // response.data.models = response.data.models.filter(el => {
          //   el.organizationUser = {};
          //   el.organizationUser.first_name = el.first_name;
          //   el.organizationUser.last_name = el.last_name;
          //   el.organizationUser.user_id = el.user_id;
          //   el.organizationUser.organization_role_id = el.organization_role_id;
          //   el.organizationUser.company_name = el.company_name;
          //   el.organizationUser.phone = el.phone;
          //   el.organizationUser.shortcode = el.shortcode;
          //   el.organizationUser.about_me = el.about_me;
          //   el.organizationUser.title = el.title;
          //   el.organizationUser.email = el.email;
          //   el.organizationUser.image_id = el.image_id;
          //   return el;
          // });
          console.log("senders 60: ", response);
          resolve(response);
        })
        .catch(e => {
          reject(e.response.data.message);
        });
    }); // promise.
  },
  createOrgUserObjectOnUI: function(data) {
    data.models = data.models.filter(el => {
      el.organizationUser = {};
      el.organizationUser.first_name = el.first_name;
      el.organizationUser.last_name = el.last_name;
      el.organizationUser.user_id = el.user_id;
      el.organizationUser.organization_role_id = el.organization_role_id;
      el.organizationUser.company_name = el.company_name;
      el.organizationUser.phone = el.phone;
      el.organizationUser.shortcode = el.shortcode;
      el.organizationUser.about_me = el.about_me;
      el.organizationUser.title = el.title;
      el.organizationUser.email = el.email;
      el.organizationUser.image_id = el.image_id;
      return el;
    });
    return data;
  },
  deleteSender: function($id) {
    return new Promise(function(resolve, reject) {
      var url =
        config.DOMAIN +
        config.API_VERSION +
        "organizations/" +
        store.state.auth.whoami.active_organization.id +
        "/senders/deleteSender/" +
        $id;

      axios
        .delete(url)
        .then(response => {
          resolve(response);
        })
        .catch(e => {
          reject(e.response.data.message);
        });
    }); // promise.
  },
  updateSender: function(data) {
    return new Promise(function(resolve, reject) {
      var url =
        config.DOMAIN +
        config.API_VERSION +
        "organizations/" +
        store.state.auth.whoami.active_organization.id +
        "/senders/" +
        data.id;

      axios
        .put(url, data)
        .then(response => {
          resolve(response);
        })
        .catch(e => {
          reject(e.response.data.message);
        });
    }); // promise.
  },
  assignSalesPersonToSender(data) {
    return new Promise(function(resolve, reject) {
      var url =
        config.DOMAIN +
        config.API_VERSION +
        "organizations/" +
        store.state.auth.whoami.active_organization.id +
        "/senders/assign/responsible/member";
      axios
        .post(url, data)
        .then(response => {
          resolve(response);
        })
        .catch(e => {
          reject(e.response.data.message);
        });
    }); // promise.
  },
  checkIfSendersExist(data, campId) {
    return new Promise(function(resolve, reject) {
      let url =
        config.DOMAIN +
        config.API_VERSION +
        "organizations/" +
        store.state.auth.whoami.active_organization.id +
        "/senders/exist";
      axios
        .post(url, { senderEmails: data, campaign_id: campId })
        .then(response => {
          resolve(response);
        })
        .catch(e => {
          reject(e.response.data.message);
        });
    });
  },
  addVirtualSenders(data) {
    return new Promise(function(resolve, reject) {
      console.log("data =>", data);
      let sendersArray = arraysLib.chunk(data.senders, 10);
      console.log("sendersArray =>", sendersArray);
      let url =
        config.DOMAIN +
        config.API_VERSION +
        "organizations/" +
        store.state.auth.whoami.active_organization.id +
        "/senders/virtual";

      let requests = [];
      sendersArray.filter(el => {
        let input = {};
        input.senders = el;
        input.organization_id = data.organization_id;
        input.campaign_id = data.campaign_id;
        input.creating_sender = true;

        let req = axios.post(url, input);
        requests.push(req);
      });

      axios
        .all([...requests])
        .then(
          axios.spread((...responses) => {
            // use/access the results
            responses.filter(response => {
              console.log("response -- ", response.data);
            });
            resolve(responses);
          })
        )
        .catch(errors => {
          // react on errors.
          console.log("Connections add error : ", errors);
          toastr.clear();
          toastr.error("Error occured while saving data.", "", {
            TOASTR_TIMEOUT: config.TOASTR_TIMEOUT
          });
          reject(errors);
        });

      /*axios
        .post(url, data)
        .then(response => {
          resolve(response);
        })
        .catch(e => {
          reject(e.response.data.message);
        });*/
    });
  },
  startWarmup: function(data) {
    return new Promise(function(resolve, reject) {
      var url =
        config.DOMAIN +
        config.API_VERSION +
        "organizations/" +
        store.state.auth.whoami.active_organization.id +
        "/senders/warmup/start";

      axios
        .put(url, data)
        .then(response => {
          resolve(response);
        })
        .catch(e => {
          reject(e.response.data.message);
        });
    }); // promise.
  },
  stopWarmup: function(data) {
    return new Promise(function(resolve, reject) {
      var url =
        config.DOMAIN +
        config.API_VERSION +
        "organizations/" +
        store.state.auth.whoami.active_organization.id +
        "/senders/warmup/stop";

      axios
        .put(url, data)
        .then(response => {
          resolve(response);
        })
        .catch(e => {
          reject(e.response.data.message);
        });
    }); // promise.
  },
  getCampaignSenders: function(data) {
    return new Promise(function(resolve, reject) {
      var url =
        config.DOMAIN +
        config.API_VERSION +
        "organizations/" +
        store.state.auth.whoami.active_organization.id +
        "/campaigns/" +
        data.campaignId +
        "/get_senders?";

      axios
        .get(url)
        .then(response => {
          resolve(response);
        })
        .catch(e => {
          reject(e.response.data.message);
        });
    });
  }
};
