import axios from "axios";
import config from "../config.js";
import store from "../store";

export default {
  updatePost: function(post) {
    return new Promise((resolve, reject) => {
      let url =
        config.DOMAIN +
        config.API_VERSION +
        "groups/" +
        post.organization_group_id +
        "/posts/" +
        post.id;

      axios
        .put(url, post)
        .then(response => {
          console.log("Post Updated... 16");
          resolve(response);
        })
        .catch(e => {
          reject(e);
        }); //axios
    });
  },

  deleteImageFromPost(postId, imageId) {
    return new Promise((resolve, reject) => {
      let path =
        config.DOMAIN +
        config.API_VERSION +
        "groups/" +
        store.state.auth.whoami.active_organization_group.id +
        "/posts/" +
        postId +
        "/images/" +
        imageId;

      axios
        .delete(path)
        .then(response => {
          console.log("Image deleted from Database: ", response.data);
          resolve(response);
        })
        .catch(e => {
          console.error("Image not deleted: ", e);
          reject(e);
        }); //axios
    });
  },

  getPost(data) {
    return new Promise((resolve, reject) => {
      let token = !!data.token ? data.token : "";
      let path =
        config.DOMAIN +
        config.API_VERSION +
        "groups/" +
        data.groupId +
        "/posts/" +
        data.postId +
        "?expand=organizationTags,organizationPostChannels,images,organizationGroup,videos,createdby,childPostIds,childPosts,stream,featuredImage";

      axios
        .get(path)
        .then(response => {
          console.log("get Post API: ", response.data);
          resolve(response);
        })
        .catch(e => {
          console.error("Post not fetched: ", e);
          reject(e);
        }); //axios
    });
  },
  async uploadAttachmentBlocksOfPost(payload) {
    return new Promise(async (resolve, reject) => {
      console.log("uploadAttachmentBlocksOfPost", payload);

      let url =
        config.DOMAIN +
        config.API_VERSION +
        "groups/" +
        store.state.auth.whoami.active_organization_group.id +
        "/posts/" +
        payload.postId +
        "/add_attachment";
      let urlUploadAttachment =
        config.DOMAIN +
        config.API_VERSION +
        "groups/" +
        store.state.auth.whoami.active_organization_group.id +
        "/posts/" +
        payload.postId +
        "/upload_post_attachment";

      let promises = [];
      let totalAttachments = 0;
      payload.data_blocks.forEach((block, index) => {
        if (!!block.files) {
          totalAttachments += block.files.length;
        }
      });

      let progress = {
        total: totalAttachments,
        percent: 0,
        current: 0
      };

      await Promise.all(
        payload.data_blocks.map(async (attach, index) => {
          if (!!attach.files) {
            for (var i = 0; i < attach.files.length; i++) {
              if (attach.files[i].size < 2000000000) {
                let totalFileSize = attach.files[i].size;
                const chunkSize = 1024 * 1024; // 1MB (you can adjust the chunk size as needed)
                let start = 0;
                let end = Math.min(chunkSize, attach.files[i].size);
                while (start < attach.files[i].size) {
                  const chunk = attach.files[i].slice(start, end);
                  const formData = new FormData();
                  formData.append("file", chunk);
                  formData.append("start", start);
                  formData.append("end", end);
                  formData.append("totalSize", totalFileSize);
                  formData.append("post_id", payload.postId);
                  formData.append("index", index);
                  formData.append("file_index", i);
                  const res = await axios.post(urlUploadAttachment, formData, {
                    headers: { "Content-Type": "multipart/form-data" }
                  });
                  let { success } = res.data;
                  start = end;
                  end = Math.min(start + chunkSize, attach.files[i].size);
                  if (success) {
                    let data = {
                      fileName: attach.files[i].name,
                      totalSize: attach.files[i].size,
                      file_type: attach.files[i].type,
                      type: attach.type,
                      post_id: payload.postId,
                      index: index,
                      file_index: i
                    };
                    promises.push(axios.post(url, data));
                  }
                }
              }
            }
          }
        })
      );

      await axios
        .all(promises)
        .then(
          axios.spread((...args) => {
            resolve("Attachments saved successfully.");
          })
        )
        .catch(error => {
          reject(error);
        });
    });
  },

  uploadFeaturedImageOfPost(payload) {
    return new Promise((resolve, reject) => {
      console.log("uploadFeaturedImageOfPost", payload);

      let url =
        config.DOMAIN +
        config.API_VERSION +
        "groups/" +
        store.state.auth.whoami.active_organization_group.id +
        "/posts/" +
        payload.postId +
        "/add_featured_image";
      let data = {};

      let formData = new FormData();
      formData.append("post_id", payload.postId);
      formData.append("file", payload.file, payload.file.name);

      axios
        .post(url, formData)
        .then(res => {
          resolve("Featured Image uploaded successfully.");
        })
        .catch(e => {
          console.error("Featured Image not uploaded: ", e);
          reject(e);
        }); //axios;
    });
  },

  getMetaTags(url) {
    return new Promise((resolve, reject) => {
      console.log("Inside getMetaTags :: ", url);
      let vm = this;
      axios
        .get(
          config.DOMAIN +
            config.API_VERSION +
            "group_id/" +
            store.state.auth.whoami.active_organization_group.id +
            "/link/getmetatags?url=" +
            url
        )
        .then(response => {
          console.log(response.data);
          let imageData = null;
          //Checking whether image array is empty or not
          if (response.data && response.data["images"].length > 0) {
            imageData = response.data["images"][0]["url"];
          }
          let metaTags = null;
          if (response.data) {
            metaTags = {
              originalUrl: url,
              headline: response.data["title"],
              message: response.data["description"],
              image: imageData,
              imageURL: imageData
            };
            console.log("metaTags=========", metaTags);
          }
          //commit("setSharePost", metaTags);
          resolve(metaTags);
        })
        .catch(e => {
          // resolve(metaTags);
          console.log("Error: ", e);
        }); //axios
    });
  },

  toggleVisibilityOfPost(post) {
    return new Promise((resolve, reject) => {
      let data = {};
      data.groupId = post.organization_group_id;
      data.postId = post.id;
      this.getPost(data).then(res => {
        post = res.data;
        if (post.visibility_id == 1) post.visibility_id = 2;
        else if (post.visibility_id == 2) post.visibility_id = 1;

        this.updatePost(post)
          .then(r => {
            resolve(r);
          })
          .catch(e => {
            console.log("Error: ", e);
            reject(e);
          });
      });
    });
  },

  downloadPost(streamId) {
    // this.$root.saveActivity("download", this.$route.path);

    let url =
      config.DOMAIN +
      config.API_VERSION +
      "stream/downloadstream/" +
      streamId +
      "?device=mobile";
    axios.get(url).then(response => {
      let fileList = response.data;
      fileList.forEach(ele => {
        setTimeout(this.downloadMultipleFiles, 300, ele);
      });
    });
  },

  downloadMultipleFiles(url) {
    if (url.includes("tmp")) {
      url =
        config.DOMAIN +
        config.API_VERSION +
        "stream/downloadstream/" +
        this.stream.id +
        "?file_type=text";
    } else {
      url = config.DOMAIN + config.API_VERSION + url;
    }
    console.log(config.DOMAIN + config.API_VERSION + url);
    var a = document.createElement("a");
    a.setAttribute("href", url);
    a.setAttribute("download", "");
    a.setAttribute("target", "_blank");
    a.click();
  },

  getPostFileUrl(payload) {
    return new Promise((resolve, reject) => {
      console.log("uploadFeaturedImageOfPost", payload);
      let keyname_str = payload.data.replace(/\//g, "_");
      let url =
        config.DOMAIN +
        config.API_VERSION +
        "groups/" +
        store.state.auth.whoami.active_organization_group.id +
        "/posts/" +
        payload.id +
        "/get_file_url?keyname" +
        keyname_str;

      axios
        .get(url)
        .then(res => {
          resolve(res.data);
        })
        .catch(e => {
          console.error("Unable to fetch Image: ", e);
          reject(e);
        }); //axios;
    });
  }
};
