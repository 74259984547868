<template>
  <div class="" v-if="!!data && !!data._meta">
    <div
      style="float: left;display: flex;align-items: center;"
      id="lists_length"
    >
      <div>Show</div>
      <select
        name="lists_length"
        aria-controls="lists"
        class="custom-select custom-select-sm form-control form-control-sm"
        style="width: 70px;"
        @change="changeLimit"
      >
        <option
          :value="no"
          v-for="no in [1, 2, 5, 20, 50]"
          :selected="no == data._meta.perPage"
          >{{ no }}</option
        >
        <option value="-1">All</option>
      </select>
      <div>entries</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PaginationLimit",
  props: ["data"],
  components: {},
  data() {
    return {};
  },
  watch: {},
  computed: {},
  mounted() {},
  methods: {
    changeLimit(ev) {
      this.$emit("limit-changed", ev.target.value);
    }
  }
};
</script>

<style lang="scss" scoped></style>
