export default {
  routePatterns: [
    ".*/admin/company/switchcompanies",
    ".*/admin/company/add",
    ".*/admin/company/step1",
    ".*/admin/company/step2",
    ".*/admin/company/step2",
    ".*/admin/company/step3",
    ".*/admin/company/step4",
    "/company/review",
    ".*/admin/company/.*/posting/profile/.*",
    ".*/admin/company/.*/posting/profile/edit/.*",
    ".*/admin/company/.*/dashboard",
    ".*/admin/company/.*/postings",
    ".*/admin/company/.*/overview",
    ".*/admin/company/.*/posting/summary/.*",
    ".*/admin/company/.*/details",
    ".*/admin/company/.*/posting/summary/freelancer/.*",
    ".*/admin/company/.*/members",
    ".*/admin/company/.*/chats",
    "/company/.*/offer/edit",
    "/company/.*/contract/.*",
    "/company/.*/contract/edit/.*",
    ".*/admin/company/.*/contracts",
    ".*/admin/company/.*/timer/report/weekly",
    ".*/admin/company/.*/timer/report/contract",
    ".*/admin/company/.*/timer/report/freelancer",
    ".*/admin/company/.*/invoice/list",
    ".*/admin/company/.*/invoice/contact",
    ".*/company/.*/invoice/view/.*",
    ".*/company/.*/invoice/payment_success",
    ".*/company/.*/invoice/payment_failed",
    ".*/company/update-password",
    ".*/admin/company/.*/freelancers"
  ]
};
