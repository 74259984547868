import axios from "axios";
import toastr from "toastr";
import config from "../../config.js";
import * as moment from "moment";

export default {
  namespaced: true,
  state: {
    isModalActive: false,
    sharedLink: null
  },

  getters: {},

  mutations: {
    setIsModalActive(state, data) {
      Vue.set(state, "isModalActive", data);
    },
    setsharedLink(state, data) {
      Vue.set(state, "sharedLink", data);
    }
  },

  actions: {}
};
