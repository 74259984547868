import axios from "axios";
import toastr from "toastr";
import config from "../../config.js";
import * as moment from "moment";

export default {
  namespaced: true,
  state: {
    linksData: [],
    link: {
      url: null,
      id: null,
      ad_id: null,
      ads: [],
      title: null,
      description: null,
      image_url: null,
      imageFile: null
    },
    lastLinkCreated: {
      // used to display on LinkDisplay
      url: null,
      id: null,
      ad_id: null,
      short_code: null
    },
    postUuid: null,
    lastLinkUrl: null,
    lastLinkCreatedModalActive: false,
    HideModelFromLinksDrip: 0,
    callToAction: 1,
    iframe_enabled: null,
    isUrlValid: null,
    createNewLinkClicked: 0,
    closeFormAfterFinishingProcess: 0, // Used for: when creating link from post, we need to close LinkForm after process completes,
    lastLinkDeleted: 0,
    isLinkInEditMode: false
  },

  getters: {},
  mutations: {
    setLinks(state, data) {
      Vue.set(state, "linksData", data);
    },
    createLink(state, data) {
      state.linksData.splice(0, 0, data);
      state.closeFormAfterFinishingProcess =
        state.closeFormAfterFinishingProcess + 1;
    },
    setlastLinkCreated(state, data) {
      console.log("Inside setlastLinkCreated mutation=======", data);
      state.lastLinkCreated = data;
    },
    setlastLinkUrl(state, data) {
      state.lastLinkUrl = data;
    },
    clearLink(state, data) {
      state.link = data;
    },
    setLink(state, data) {
      state.link = data;
    },
    setIsLinkInEditMode(state, data) {
      state.isLinkInEditMode = data;
    },
    updateLinkByLink(state, link) {
      state.link = link;
    },
    updateLink(state, data) {
      const index = state.linksData.findIndex(link => link.id === data.id);
      state.linksData.splice(index, 1, data);
      state.closeFormAfterFinishingProcess =
        state.closeFormAfterFinishingProcess + 1;
    },

    deleteLink(state, linkId) {
      const index = state.linksData.findIndex(link => link.id === linkId);
      state.linksData.splice(index, 1);
      state.lastLinkDeleted = linkId;
    },
    changeAdIdOfLink(state, id) {
      state.link.ad_id = id;
    },
    setIsModalActive(state, flag) {
      state.HideModelFromLinksDrip = state.HideModelFromLinksDrip + 1;
    },

    changeCallToAction(state, value) {
      state.callToAction = value;
    },

    setLastLinkCreatedModalActive(state, value) {
      state.lastLinkCreatedModalActive = value;
    },
    checkUrl(state, value) {
      state.isUrlValid = value;
    },
    getIframe(state, value) {
      if (value) {
        if (value == 1 || value == true) {
          Vue.set(state, "iframe_enabled", true);
        } else {
          Vue.set(state, "iframe_enabled", false);
        }
      } else {
        Vue.set(state, "iframe_enabled", null);
      }
    },

    updateAdsOfLink(state, value) {
      state.link.ads = value;
    },

    updateCreateNewLinkClicked(state, value) {
      state.createNewLinkClicked++;
      console.log("state.createNewLinkClicked: ", state.createNewLinkClicked);
    },

    setPostUuid(state, data) {
      state.postUuid = data;
    }
  },

  actions: {
    getAllLinks({ state, commit, rootState }, linkFor) {
      // console.log("getAllLinks() Called", categoryId);
      let groupId = rootState.auth.activeOrganizationGroup.id;
      let organizationId = rootState.auth.activeOrganization.id;
      // if (categoryId == "all") categoryValue = "all";
      // if (categoryId > 0) categoryValue = categoryId;
      let url =
        config.DOMAIN + config.API_VERSION + "group_id/" + groupId + "/link";
      if (linkFor == "user_dashboard") {
        url += "?linkFor=user_dashboard";
      } else if (linkFor == "organization") {
        url += "?linkFor=organization&organizationId=" + organizationId;
      } else {
        url += "?linkFor=group";
      }
      axios
        .get(url)
        .then(response => {
          console.log(response.data);
          commit("setLinks", response.data.links);
        })
        .catch(e => {
          console.log("Error: ", e);
        }); //axios
    },
    createLink({ state, commit, rootState }, data) {
      let apiUrl =
        config.DOMAIN +
        config.API_VERSION +
        "group_id/" +
        rootState.auth.activeOrganizationGroup.id +
        "/link";
      if (
        data.organization_link_type_id &&
        data.organization_link_type_id == 2
      ) {
        apiUrl += "?link_type=2";
      }
      return new Promise((resolve, reject) => {
        commit("setLoading", true, { root: true });
        console.log("createLink() Called --> line 113");
        if (state.callToAction == 1) data.ad_id = null; // do not send ad if callToAction = 1 =  No Call to Action
        axios
          .post(apiUrl, data)
          .then(response => {
            console.log("createLink-----", data);
            console.log(response.data);
            commit("curatedArticle/setLinkDomainData", response.data, {
              root: true
            });
            let mylink = response.data.link;
            mylink.domain_name = response.data.domain.domain_name;
            mylink.link_has_ad = response.data.link_has_ad;
            if (data.id > 0) commit("updateLink", mylink);
            // else {
            if (data.imageFile) {
              try {
                // save image of ad
                console.log(mylink.id);
                let imageData = new FormData();
                imageData.append("image", data.imageFile, data.imageFile.name);
                axios
                  .post(
                    config.DOMAIN +
                      config.API_VERSION +
                      "group_id/" +
                      rootState.auth.activeOrganizationGroup.id +
                      "/link/" +
                      mylink.id +
                      "/images",
                    imageData
                  )
                  .then(image_response => {
                    console.log("Link Image saved!!! ", image_response.data);
                    // commit("createAd", response.data);
                    toastr.error("Ad Image created successfully.", "", {
                      timeOut: 5000
                    });
                  })
                  .catch(e => {
                    console.log("Image Post Error: ", e);
                  }); //axios
              } catch (e) {}
            }
            commit("createLink", mylink);
            // }
            commit("setlastLinkCreated", mylink);
            commit("setlastLinkUrl", response.data.link_url);
            commit("setIsModalActive", false);
            commit("clearLink");
            commit("setLastLinkCreatedModalActive", true);
            toastr.success("Link created successfully!", "", {
              timeOut: config.TOASTR_TIMEOUT
            });
            commit("setLoading", false, { root: true });
            resolve(response);
          })
          .catch(e => {
            console.log("Error: ", e);
            toastr.error("Unable to update!", e.message, {
              timeOut: config.TOASTR_TIMEOUT
            });
            commit("setLoading", false, { root: true });
          }); //axios
      });
    },
    updateLink({ state, commit, rootState }, data) {
      return new Promise((resolve, reject) => {
        console.log("updateLink() Called ", data);
        let apiUrl =
          config.DOMAIN +
          config.API_VERSION +
          "group_id/" +
          rootState.auth.activeOrganizationGroup.id +
          "/link/" +
          data.id;
        if (
          data.organization_link_type_id &&
          data.organization_link_type_id == 2
        ) {
          apiUrl += "?link_type=2";
        }
        axios
          .put(apiUrl, data)
          .then(response => {
            console.log(response.data);
            // commit("updateLink", response.data.links);

            let mylink = response.data.link;
            mylink.domain_name = response.data.domain.domain_name;
            mylink.link_has_ad = response.data.link_has_ad;
            commit("updateLink", mylink);
            commit("setlastLinkCreated", mylink);
            commit("setlastLinkUrl", response.data.link_url);
            commit("setIsModalActive", false);
            commit("clearLink");
            commit("setLastLinkCreatedModalActive", true);
            resolve(response);
            // toastr.success("Link updated successfully!", "", {
            //   timeOut: config.TOASTR_TIMEOUT
            // });
          })
          .catch(e => {
            console.log("Error: ", e);
            toastr.error("Unable to update!", "", {
              timeOut: config.TOASTR_TIMEOUT
            });
          }); //axios
      });
    },
    editLinkInit(link) {
      // when click on edit button.. trigger this action

      // set link in store
      let mylink = link;
      console.log("Link :: editLink called: ", link);

      commit("links/updateLinkByLink", mylink, { root: true });
      this.$emit("modal1-toggeled", true);
    },

    deleteLink({ state, commit, rootState }, linkId) {
      return new Promise((resolve, reject) => {
        console.log("deleteLink() Called");
        axios
          .delete(
            config.DOMAIN +
              config.API_VERSION +
              "group_id/" +
              rootState.auth.activeOrganizationGroup.id +
              "/link/" +
              linkId
          )
          .then(response => {
            console.log(response.data);
            commit("deleteLink", linkId);
            toastr.error("Link deleted successfully!", "", {
              timeOut: config.TOASTR_TIMEOUT
            });
            resolve(response);
          })
          .catch(e => {
            console.log("Error: ", e);
          }); //axios
      });
    },
    checkUrl({ state, commit, rootState }, value) {
      commit("checkUrl", value);
    },
    getIframe({ state, commit, rootState }, url) {
      return new Promise((resolve, reject) => {
        if (url) {
          if (!url.includes("http://") && !url.includes("https://")) {
            url = "https://" + url;
          } else {
            url = url;
          }
          console.log(url);
          let data = { url: url };
          axios
            .post(
              config.DOMAIN + config.API_VERSION + "displayxd/getframe",
              data
            )
            .then(response => {
              commit("getIframe", response.data);
              //Change background on iframe response
              console.log(response.data);
              commit("ads/changeAdBackground", response.data, { root: true });
              resolve(response.data);
            })
            .catch(e => {
              //Commit error in case of error
              // console.log("Error: ", e);
              commit("getIframe", null);
              commit("ads/changeAdBackground", null, { root: true });
              resolve(null);
            }); //axios
        }
        //Set iframeenabled as null when url is empty
        else {
          commit("getIframe", null);
          commit("ads/changeAdBackground", null, { root: true });
          resolve(null);
        }
      });
    },

    setPostUuid({ state, commit, rootState }, data) {
      commit("setPostUuid", data);
    },
    setIsLinkInEditMode({ state, commit, rootState }, data) {
      commit("setIsLinkInEditMode", data);
    },
    setLink({ state, commit, rootState }, data) {
      commit("setLink", data);
    },
    clearLink({ state, commit, rootState }) {
      let link = {
        url: null,
        id: null,
        ad_id: null,
        ads: [],
        title: null,
        description: null,
        image_url: null,
        imageFile: null
      };
      commit("clearLink", link);
    },
    getContributeLink({ state, commit, rootState }, groupId) {
      return new Promise((resolve, reject) => {
        let url =
          config.DOMAIN +
          config.API_VERSION +
          "group_id/" +
          groupId +
          "/link?linkFor=group&organizationLinkTypeId=2";
        axios
          .get(url)
          .then(response => {
            console.log(response.data);
            resolve(response);
          })
          .catch(e => {
            console.log("Error: ", e);
          }); //axi
      });
    },
    getContributeLinkDetails({ state, commit, rootState }, shortcode) {
      return new Promise((resolve, reject) => {
        let url =
          config.DOMAIN + config.API_VERSION + "contribute/" + shortcode;
        axios
          .get(url)
          .then(response => {
            console.log(response.data);
            resolve(response);
          })
          .catch(e => {
            console.log("Error: ", e);
          }); //axi
      });
    },
    getWebLinks({ state, commit, rootState }) {
      return new Promise((resolve, reject) => {
        let groupId = rootState.auth.activeOrganizationGroup.id;

        let url =
          config.DOMAIN +
          config.API_VERSION +
          "group_id/" +
          groupId +
          "/link?linkFor=group";
        axios
          .get(url)
          .then(response => {
            console.log(response.data);
            commit("setLinks", response.data.links);
            resolve(response);
          })
          .catch(e => {
            console.log("Error: ", e);
          }); //axios
      });
    }
  }
};
