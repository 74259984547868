var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !!_vm.data && !!_vm.data._meta
    ? _c("div", {}, [
        _c(
          "div",
          {
            staticStyle: {
              float: "left",
              display: "flex",
              "align-items": "center"
            },
            attrs: { id: "lists_length" }
          },
          [
            _c("div", [_vm._v("Show")]),
            _vm._v(" "),
            _c(
              "select",
              {
                staticClass:
                  "custom-select custom-select-sm form-control form-control-sm",
                staticStyle: { width: "70px" },
                attrs: { name: "lists_length", "aria-controls": "lists" },
                on: { change: _vm.changeLimit }
              },
              [
                _vm._l([1, 2, 5, 20, 50], function(no) {
                  return _c(
                    "option",
                    {
                      domProps: {
                        value: no,
                        selected: no == _vm.data._meta.perPage
                      }
                    },
                    [_vm._v(_vm._s(no))]
                  )
                }),
                _vm._v(" "),
                _c("option", { attrs: { value: "-1" } }, [_vm._v("All")])
              ],
              2
            ),
            _vm._v(" "),
            _c("div", [_vm._v("entries")])
          ]
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }