import prospectingService from "../../services/prospectingServices";
import userActivitiesService from "../../services/userActivitiesServices";
import store from "../index";
import { helpers } from "../../components/Workspace/Prospecting/helpers";
import {
  FETCH_DATA,
  SHOW_CONTACT_DETAILS,
  FETCH_ACTIVITY_SEARCHES,
  SET_SAVED_SEARCH_NAME,
  DELETE_SAVED_SEARCH,
  UPDATE_APPLIED_FILTER,
  UPDATE_ENTIRE_APPLIED_FILTER,
  SEND_FILTERS_DATA,
  UPDATE_DISPLAY,
  UPDATE_CURRENT_PAGE,
  UPDATE_LIMIT,
  SET_SAVE_FILTER_NAME,
  SET_SEARCH_FILTER,
  CHECK_APPLIED_FILTER_DATA,
  FETCH_FILTER_DATA,
  UPDATE_SEARCH_QUERY,
  FETCH_ORGANIZATION_CREDITS,
  CLEAR_PROSPECTING_DATA,
  UPDATE_WAITING,
  UPDATE_SELECTED_PROSPECTS,
  DOWNLOAD_SELECTED_PROSPECTS,
  CLEAR_SELECTED_PROSPECTS,
  UPDATE_CONTACT_TO_VERIFY,
  SHOW_SELECTED_PROSPECTS,
  UPDATE_COMPANY_NAME_FILTER,
  CLEAR_PROSPECTING_STATE,
  SET_LOADING_FOR_VERIFY,
  GET_SIC_CODES,
  FETCH_CUSTOM_FILTER_DATA,
  CLEAR_APPLIED_FILTERS,
  CLEAR_PROSPECT_STORE_DATA,
  UPDATE_TIMESTAMP_FOR_FILTER_SEARCH,
  GET_PROSPECT_INDUSTRY,
  UPDATE_TIMESTAMP_FOR_FILTER_DATA
} from "./actions.type";
import {
  SET_DATA,
  SET_CONTACT_DATA,
  SET_LOADING,
  SET_SEARCH_DATA,
  SET_RECENT_ACTIVITIES_DATA,
  SET_ALL_ACTIVITIES_DATA,
  UPDATE_SAVED_SEARCH_DATA_ON_DELETE,
  UPDATE_SAVED_SEARCH_DATA_ON_RENAME,
  SET_APPLIED_FILTER,
  SET_ENTIRE_APPLIED_FILTER,
  SET_DISPLAY,
  SET_CURRENT_PAGE,
  SET_PAGE_LIMIT,
  UPDATE_SAVED_SEARCH_DATA,
  UPDATE_SEARCH_FILTER,
  SET_SHOW_SAVE_FILTER_BUTTON,
  SET_FILTER_DATA,
  SET_SEARCH_FILTER_DATA,
  SET_DEFAULT_FILTER_DATA,
  SET_EMPTY_PROSPECTING_DATA,
  SET_WAITING,
  SET_SELECTED_PROSPECTS,
  SET_CONTACT_TO_VERIFY,
  SET_SELECTED_CONTACT_IDS,
  SET_SELECTED_PROSPECT_IDS,
  SET_COMPANY_NAME_FILTER,
  SET_PROSPECTS_WITH_CONTACT_ID,
  SET_PROSPECTS_ALL_LISTS_WITH_CONTACT_ID,
  SET_PROSPECTING_STATE_EMPTY,
  SET_SIC_CODES,
  SET_CUSTOM_FILTER_DATA,
  SET_EMPTY_APPLIED_FILTERS,
  SET_ISLOADSEARCHAPPLIEDFILTER_FLAG,
  SET_INITIAL_PROSPECTING_DATA,
  SET_TIMESTAMP_FOR_FILTER_SEARCH,
  SET_PROSPECT_INDUSTRY,
  SET_TIMESTAMP_FOR_FILTER_DATA
} from "./mutations.type";
import moment from "moment";

/**
 * !NOTE: Please add the initial state for the store.
 * @returns Initial Object for the store
 */
function initialStateForStore() {
  return {
    prospectingData: {
      companies: { results: [], total: 0 },
      contacts: { results: [], total: 0 },
      _meta: {}
    },
    filtersData: [
      { companyName: [] },
      { companyLocation: [] },
      { contactLocation: [] },
      { contactDepartment: [] },
      { contactSeniority: [] }
    ],
    // activitiesList: [],
    savedSearchList: [],
    recentActivitiesList: [],
    allActivitiesList: [],
    defaultFiltersData: [],
    appliedFilters: {
      filters: {},
      display: "contacts",
      fullSearch: "",
      pages: { page: 1, pageSize: 25 }
    },
    showSaveFilterButton: false,
    isWaitingForProspectingData: false,
    isWaitingForFilterData: false,
    selectedProspects: [],
    selectedContactIds: [],
    selectedProspectIds: [],
    availableContactIds: [],
    availableCompanyIds: [],
    verificationStatus: {},
    sicCodeData: [],
    isLoadSearchAppliedFilter: false,
    latestTimeStampForFilterSearch: {
      company_name: null,
      company_location: null,
      company_industry: null,
      company_size: null,
      contact_location: null,
      contact_department: null,
      contact_seniority: null
    },
    latestTimeStampForFilterData: {
      send_data_for_filter: null
    },
    prospectIndustries: []
  };
}

export const state = initialStateForStore();

export const actions = {
  async [FETCH_DATA](context) {
    const data = await prospectingService.prospectingFull(state.filtersData);
    console.log("🚀 ~ file: prospecting.js ~ line 80 ~ data", data);
    context.commit(SET_DATA, data);
    return data;
  },
  async [SHOW_CONTACT_DETAILS](context, contactIdsObj) {
    console.log(
      "🚀 ~ file: prospecting.js ~ line 89 ~ contactIdsObj",
      contactIdsObj
    );
    let dataForLoading = {
      contactIdsObj,
      isShownValue: null
    };
    context.commit(SET_LOADING, dataForLoading);
    try {
      const data = await prospectingService.showContactDetails(contactIdsObj);
      console.log("🚀 ~ file: prospecting.js ~ line 90 ~ data", data);
      let oldContactsState = context.state.prospectingData.contacts;
      // loop for the incoming selected contactIds
      if (data.data.message && data.data.message == "Credits Not available") {
        //stop loader
        dataForLoading = {
          contactIdsObj,
          isShownValue: false
        };
        context.commit(SET_LOADING, dataForLoading);
      } else {
        contactIdsObj.contactIds.map((contactId, index) => {
          let objIndex = oldContactsState.results.findIndex(
            obj => obj.id == contactId
          );
          oldContactsState.results[objIndex] = data.data[index];
        });

        context.commit(SET_CONTACT_DATA, oldContactsState);
        let organization_id = store.state.auth.whoami.active_organization.id;
        context.dispatch(FETCH_ORGANIZATION_CREDITS, organization_id);
        return data;
      }
    } catch (error) {
      console.log("🚀 ~ file: prospecting.js ~ line 100 ~ error", error);
    }
  },
  async [FETCH_ACTIVITY_SEARCHES](context, data) {
    console.log("🚀 ~ file: prospecting.js ~ line 87 ~ data", data);
    let activitiesList = await userActivitiesService.showSavedSearchesDetails(
      data
    );
    // data.isSaved is 0 => Only Recent Activities
    // data.isSaved is 1 => Only Saved Searches
    // data.isSaved is null => All Activities

    activitiesList.map((el, index) => {
      let search = JSON.parse(el.search);
      if (search.companyRevenue) {
        let revenueArray = search.companyRevenue.map(revenue => {
          return {
            ...revenue,
            maxValue: helpers.methods.currencyFormatterInMillionAndBillion(
              revenue.max
            ),
            minValue: helpers.methods.currencyFormatterInMillionAndBillion(
              revenue.min
            )
          };
        });
        search.companyRevenue = revenueArray;
        el.search = JSON.stringify(search);
      }
    });
    console.log(
      "🚀 ~ file: prospecting.js ~ line 114 ~ activitiesList",
      activitiesList
    );
    if (data.isSaved == 1) {
      context.commit(SET_SEARCH_DATA, activitiesList);
    } else if (data.isSaved == 0) {
      context.commit(SET_RECENT_ACTIVITIES_DATA, activitiesList);
    } else {
      context.commit(SET_ALL_ACTIVITIES_DATA, activitiesList);
    }
  },
  async [SET_SAVED_SEARCH_NAME](context, activity) {
    let updatedActivity = await userActivitiesService.renameSavedSearch(
      activity
    );
    context.commit(UPDATE_SAVED_SEARCH_DATA_ON_RENAME, updatedActivity);
  },
  async [DELETE_SAVED_SEARCH](context, activity) {
    let deletedActivity = await userActivitiesService.removeSavedSearch(
      activity
    );
    context.commit(UPDATE_SAVED_SEARCH_DATA_ON_DELETE, deletedActivity);
  },
  async [SET_SAVE_FILTER_NAME](context, activity) {
    let newActivity = await userActivitiesService.saveSearchFilter(activity);
    console.log(
      "🚀 ~ file: prospecting.js ~ line 1203 ~ newActivity",
      newActivity
    );
    context.commit(UPDATE_SAVED_SEARCH_DATA, newActivity);
  },
  async [UPDATE_APPLIED_FILTER](context, newFilterData) {
    let { data, filterName } = newFilterData;
    console.log(
      "🚀 ~ file: prospecting.js ~ line 1174 ~ data,filterName",
      data,
      filterName
    );
    context.commit(SET_APPLIED_FILTER, newFilterData);
    if (
      context.state.appliedFilters.fullSearch.length ||
      Object.keys(context.state.appliedFilters.filters).length
    ) {
      context.dispatch(SEND_FILTERS_DATA);
    } else {
      context.dispatch(CLEAR_PROSPECTING_DATA);
      context.dispatch(CLEAR_SELECTED_PROSPECTS);
    }
    context.dispatch(CHECK_APPLIED_FILTER_DATA);
    // update the store with new filter applied.
    // send the updated filters data to API
  },
  async [UPDATE_ENTIRE_APPLIED_FILTER](context, newFilterData) {
    context.commit(SET_ENTIRE_APPLIED_FILTER, newFilterData);
    context.commit(SET_ISLOADSEARCHAPPLIEDFILTER_FLAG, true);
    context.dispatch(SEND_FILTERS_DATA);
    context.dispatch(CHECK_APPLIED_FILTER_DATA);
  },
  async [SEND_FILTERS_DATA](context) {
    try {
      context.dispatch(UPDATE_WAITING, {
        value: true,
        waitingType: "prospectingData"
      });
      context.dispatch(
        UPDATE_TIMESTAMP_FOR_FILTER_DATA,
        "send_data_for_filter"
      );
      await prospectingService
        .prospectingFull(
          context.state.appliedFilters,
          context.state.latestTimeStampForFilterData["send_data_for_filter"]
        )
        .then(data => {
          context.commit(SET_ISLOADSEARCHAPPLIEDFILTER_FLAG, false);
          console.log("🚀 ~ file: prospecting.js ~ line 1190 ~ data", data);
          if (Object.keys(context.state.appliedFilters.filters).length == 0) {
            context.commit(CLEAR_PROSPECT_STORE_DATA);
          } else {
            if (
              state.latestTimeStampForFilterData["send_data_for_filter"] ==
              data.data["timeStamp"]
            ) {
              context.commit(SET_DATA, data);
            }
          }
        });
    } catch (error) {
      console.log("🚀 ~ file: prospecting.js ~ line 188 ~ error", error);
    } finally {
      context.dispatch(UPDATE_WAITING, {
        value: false,
        waitingType: "prospectingData"
      });
    }
  },
  [CLEAR_PROSPECTING_DATA](context) {
    context.commit(SET_EMPTY_PROSPECTING_DATA);
  },
  [UPDATE_DISPLAY](context, newDisplay) {
    console.log(
      "🚀 ~ file: prospecting.js ~ line 1196 ~ newDisplay",
      newDisplay
    );
    context.commit(SET_DISPLAY, newDisplay);
    if (
      context.state.appliedFilters.fullSearch.length ||
      Object.keys(context.state.appliedFilters.filters).length
    ) {
      context.dispatch(SEND_FILTERS_DATA);
    }
  },
  [UPDATE_CURRENT_PAGE](context, currentPage) {
    console.log(
      "🚀 ~ file: prospecting.js ~ line 1219 ~ currentPage",
      currentPage
    );
    context.commit(SET_CURRENT_PAGE, currentPage);
    context.dispatch(SEND_FILTERS_DATA);
  },
  [UPDATE_LIMIT](context, limit) {
    console.log("🚀 ~ file: prospecting.js ~ line 1224 ~ limit", limit);
    context.commit(SET_PAGE_LIMIT, limit);
    context.dispatch(SEND_FILTERS_DATA);
  },
  async [SET_SEARCH_FILTER](context, data) {
    let updatedActivity = await userActivitiesService.updateSavedSearch(data);
    console.log(
      "🚀 ~ file: prospecting.js ~ line 1256 ~ updatedActivity",
      updatedActivity
    );
    context.commit(UPDATE_SEARCH_FILTER, updatedActivity);
  },
  async [CHECK_APPLIED_FILTER_DATA](context) {
    // Display check for save filter button in filter sidebar
    let filtersData = context.state.appliedFilters.filters;
    console.log(
      "🚀 ~ file: prospecting.js ~ line 1259 ~ filtersData",
      filtersData
    );
    let flag = false;
    if (Object.keys(filtersData).length == 0) {
      context.commit(SET_SHOW_SAVE_FILTER_BUTTON, false);
    } else {
      let keys = Object.keys(filtersData);
      console.log("🚀 ~ file: prospecting.js ~ line 1264 ~ keys", keys);
      for (let i = 0; i < keys.length; i++) {
        if (filtersData[keys[i]].length > 0) {
          flag = true;
          break;
        }
      }
      context.commit(SET_SHOW_SAVE_FILTER_BUTTON, flag);
    }
  },
  async [FETCH_FILTER_DATA](context) {
    try {
      context.dispatch(UPDATE_WAITING, {
        value: true,
        waitingType: "filterData"
      });
      const data = await prospectingService.fetchFilterData();
      console.log("🚀 ~ file: prospecting.js ~ line 1242 ~ data", data);
      context.commit(SET_FILTER_DATA, data);
    } catch (error) {
      console.log("🚀 ~ file: prospecting.js ~ line 250 ~ error", error);
    } finally {
      context.dispatch(UPDATE_WAITING, {
        value: false,
        waitingType: "filterData"
      });
    }
  },
  async [FETCH_CUSTOM_FILTER_DATA](context, filter) {
    try {
      let mapping = {
        company_name: "companyName",
        company_location: "companyLocation",
        company_industry: "companyIndustry",
        company_size: "companySize",
        company_revenue: "companyRevenue",
        contact_location: "contactLocation",
        contact_department: "contactDepartment",
        contact_seniority: "contactSeniority"
      };
      context.dispatch(UPDATE_WAITING, {
        value: true,
        waitingType: "filterData"
      });
      const customFilterData = await prospectingService.fetchCustomFilterData(
        filter
      );
      context.commit(SET_CUSTOM_FILTER_DATA, {
        customFilterData,
        filter: mapping[filter]
      });
    } catch (error) {
      console.log("🚀 ~ file: prospecting.js ~ line 324 ~ error", error);
    } finally {
      context.dispatch(UPDATE_WAITING, {
        value: false,
        waitingType: "filterData"
      });
    }
  },

  async [UPDATE_SEARCH_QUERY](context, searchData) {
    let { searchString, field } = searchData;
    if (searchString) {
      try {
        context.dispatch(UPDATE_WAITING, {
          value: true,
          waitingType: "filterData"
        });
        context.dispatch(UPDATE_TIMESTAMP_FOR_FILTER_SEARCH, field);
        const data = await prospectingService.searchFilterData(
          searchData,
          context.state.latestTimeStampForFilterSearch[field]
        );
        console.log("🚀 ~ file: prospecting.js ~ line 200 ~ data", data);
        if (
          state.latestTimeStampForFilterSearch[field] == data.data["timeStamp"]
        ) {
          if (searchString)
            context.commit(SET_SEARCH_FILTER_DATA, { data, field });
          else
            context.commit(SET_SEARCH_FILTER_DATA, {
              data: { data: [] },
              field
            });
        }
      } catch (error) {
        console.log("🚀 ~ file: prospecting.js ~ line 264 ~ error", error);
      } finally {
        context.dispatch(UPDATE_WAITING, {
          value: false,
          waitingType: "filterData"
        });
      }
    } else {
      // if searchString is empty then this is called
      // context.dispatch(FETCH_CUSTOM_FILTER_DATA, field);
      context.commit(SET_SEARCH_FILTER_DATA, { data: { data: [] }, field });
    }
  },
  [UPDATE_WAITING](context, waitingData) {
    let { value, waitingType } = waitingData;
    console.log(
      "🚀 ~ file: prospecting.js ~ line 284 ~ value, waitingType",
      value,
      waitingType
    );
    context.commit(SET_WAITING, { value, waitingType });
  },
  [UPDATE_TIMESTAMP_FOR_FILTER_SEARCH](context, field) {
    console.log("🚀 ~ file: prospecting.js ~ line 427 ~ field", field);
    context.commit(SET_TIMESTAMP_FOR_FILTER_SEARCH, field);
  },
  [UPDATE_TIMESTAMP_FOR_FILTER_DATA](context, field) {
    context.commit(SET_TIMESTAMP_FOR_FILTER_DATA, field);
  },
  [UPDATE_SELECTED_PROSPECTS](context, selectedProspects) {
    console.log(
      "🚀 ~ file: prospecting.js ~ line 262 ~ selectedProspects",
      selectedProspects
    );
    context.commit(SET_SELECTED_PROSPECTS, selectedProspects);
    context.commit(SET_SELECTED_CONTACT_IDS);
    context.commit(SET_SELECTED_PROSPECT_IDS);
  },
  async [DOWNLOAD_SELECTED_PROSPECTS](context) {
    try {
      let stateSelectedContactIds = context.state.selectedContactIds;
      if (
        stateSelectedContactIds.includes(null) ||
        stateSelectedContactIds.includes(undefined)
      ) {
        let dataWithoutContactId = context.state.selectedProspects.filter(
          prospect => prospect.contact_id == null
        );
        console.log("INSIDE IF 0");
        let dataWithContactId = await prospectingService.enterDataInContactTable(
          dataWithoutContactId
        );
        console.log("INSIDE IF 1");
        context.commit(SET_PROSPECTS_WITH_CONTACT_ID, dataWithContactId.data);

        context.commit(
          SET_PROSPECTS_ALL_LISTS_WITH_CONTACT_ID,
          dataWithContactId.data
        );
        context.commit(SET_SELECTED_CONTACT_IDS);
        console.log("INSIDE IF 2");

        console.log("INSIDE IF 3");
      }
      let data = await prospectingService.downloadSelectedProspects(
        context.state.selectedContactIds
      );
      context.dispatch("subscriptions/incrementUsedDownloadCounter", {
        organization_id: store.state.auth.whoami.active_organization.id,
        count: context.state.selectedContactIds.length
      });
      context.dispatch(CLEAR_SELECTED_PROSPECTS);

      console.log("🚀 ~ file: prospecting.js ~ line 273 ~ data", data);
    } catch (error) {
      console.log("🚀 ~ file: prospecting.js ~ line 289 ~ error", error);
    }
  },
  async [SHOW_SELECTED_PROSPECTS](context) {
    try {
      context.dispatch(SHOW_CONTACT_DETAILS, {
        contactIds: context.state.selectedProspectIds
      });
      // let data = await prospectingService.showContactDetails(context.state.selectedProspectIds);
    } catch (error) {
      console.log("🚀 ~ file: prospecting.js ~ line 341 ~ error", error);
    }
  },
  [CLEAR_SELECTED_PROSPECTS](context) {
    context.commit(SET_SELECTED_PROSPECTS, []);
  },
  async [UPDATE_CONTACT_TO_VERIFY](context, data) {
    let { type } = data;
    console.log("🚀 ~ file: prospecting.js ~ line 402 ~ contact", type);
    let selectedProspects = state.selectedProspects;
    context.commit(SET_LOADING_FOR_VERIFY, selectedProspects);
    let verificationData = await prospectingService.verifyContacts({
      prospectsData: selectedProspects,
      type: type,
      organization_user_id: store.state.auth.whoami.organization_user.id
    });
    if (type == "single") {
      context.commit(SET_CONTACT_TO_VERIFY, [verificationData]);
    }
    let organization_id = store.state.auth.whoami.active_organization.id;
    context.dispatch("auth/whoAmI", null, { root: true });
    context.dispatch(FETCH_ORGANIZATION_CREDITS, organization_id);
    if (type == "bulk") {
      context.dispatch(CLEAR_SELECTED_PROSPECTS);
    } else {
      context.dispatch(CLEAR_SELECTED_PROSPECTS);
    }
    // context.commit(SET_PROSPECTS_WITH_CONTACT_ID, data);
    // context.commit(SET_SELECTED_CONTACT_IDS);

    // }
  },
  [UPDATE_COMPANY_NAME_FILTER](context, companyDataArray) {
    console.log(
      "🚀 ~ file: prospecting.js ~ line 379 ~ companyDataArray",
      companyDataArray
    );
    context.commit(SET_COMPANY_NAME_FILTER, companyDataArray);
    context.dispatch(
      UPDATE_ENTIRE_APPLIED_FILTER,
      context.state.appliedFilters.filters
    );
  },
  [CLEAR_PROSPECTING_STATE](context) {
    context.commit(SET_PROSPECTING_STATE_EMPTY);
  },
  [CLEAR_PROSPECT_STORE_DATA](context) {
    context.commit(SET_INITIAL_PROSPECTING_DATA);
  },
  async [GET_SIC_CODES](context, searchString = null) {
    let organization_id = store.state.auth.whoami.active_organization.id;
    let sicCodes = await prospectingService.getSicCodes(
      organization_id,
      searchString
    );
    console.log("🚀 ~ file: prospecting.js ~ line 439 ~ sicCodes", sicCodes);
    context.commit(SET_SIC_CODES, sicCodes);
  },
  [CLEAR_APPLIED_FILTERS](context) {
    context.commit(SET_EMPTY_APPLIED_FILTERS);
    context.dispatch(CLEAR_PROSPECTING_DATA);
    context.dispatch(CLEAR_SELECTED_PROSPECTS);
    context.dispatch(CHECK_APPLIED_FILTER_DATA);
  },
  async [GET_PROSPECT_INDUSTRY](context, searchString = null) {
    let organization_id = store.state.auth.whoami.active_organization.id;
    let prospectIndustries = await prospectingService.getProspectIndustry(
      organization_id,
      searchString
    );
    console.log(
      "🚀 ~ file: prospecting.js ~ line 561 ~ prospectIndustries",
      prospectIndustries
    );
    context.commit(SET_PROSPECT_INDUSTRY, prospectIndustries);
  }
};

export const mutations = {
  [SET_DATA](state, prospectsData) {
    console.log(
      "🚀 ~ file: prospecting.js ~ line 89 ~ prospectsData",
      prospectsData
    );
    state.prospectingData.companies = prospectsData.data.companies;
    state.prospectingData.contacts = prospectsData.data.contacts;
    state.prospectingData._meta = prospectsData.data._meta;
    state.availableContactIds = [];
    state.prospectingData.contacts.results.map(contact => {
      state.availableContactIds.push(contact.id);
    });
    state.availableContactIds.sort();

    state.availableCompanyIds = [];
    state.prospectingData.companies.results.map(company => {
      state.availableCompanyIds.push(company.id);
    });
    state.availableCompanyIds.sort();
    console.log("🚀 ~ file: prospecting.js ~ line 125 ~ state", state);
  },
  [SET_CONTACT_DATA](state, data) {
    state.prospectingData.contacts.results = [...data.results];
  },
  [SET_LOADING](state, data) {
    let { contactIdsObj, isShownValue } = data;
    if (state.prospectingData.contacts.results.length > 0) {
      contactIdsObj.contactIds.map(contactId => {
        let objIndex = state.prospectingData.contacts.results.findIndex(
          obj => obj.id == contactId
        );
        state.prospectingData.contacts.results[objIndex].isShown = isShownValue;
      });
    }
  },
  [SET_SEARCH_DATA](state, data) {
    state.savedSearchList = [...data];
  },
  [SET_RECENT_ACTIVITIES_DATA](state, data) {
    state.recentActivitiesList = [...data];
  },
  [SET_ALL_ACTIVITIES_DATA](state, data) {
    state.allActivitiesList = [...data];
  },
  [UPDATE_SAVED_SEARCH_DATA_ON_DELETE](state, data) {
    state.savedSearchList.splice(
      state.savedSearchList.findIndex(obj => obj.id == data.id),
      1
    );
    state.recentActivitiesList.splice(
      state.recentActivitiesList.findIndex(obj => obj.id == data.id),
      1
    );
    state.allActivitiesList.splice(
      state.allActivitiesList.findIndex(obj => obj.id == data.id),
      1
    );
  },
  [UPDATE_SAVED_SEARCH_DATA_ON_RENAME](state, data) {
    state.savedSearchList.map(el => {
      if (el.id == data.id) {
        el.name = data.name;
      }
    });
    state.recentActivitiesList.map(el => {
      if (el.id == data.id) {
        el.name = data.name;
      }
    });
    state.allActivitiesList.map(el => {
      if (el.id == data.id) {
        el.name = data.name;
      }
    });
  },
  [UPDATE_SAVED_SEARCH_DATA](state, data) {
    console.log("🚀 ~ file: prospecting.js ~ line 1285 ~ data", data);
    state.allActivitiesList.push(data);
    if (data.is_saved == 1) {
      state.savedSearchList.push(data);
    } else {
      state.recentActivitiesList.push(data);
    }
  },
  [UPDATE_SEARCH_FILTER](state, data) {
    state.savedSearchList.map(el => {
      if (el.id == data.id) {
        el.search = JSON.stringify(data.search);
      }
    });
    state.recentActivitiesList.map(el => {
      if (el.id == data.id) {
        el.search = JSON.stringify(data.search);
      }
    });
    state.allActivitiesList.map(el => {
      if (el.id == data.id) {
        el.search = JSON.stringify(data.search);
      }
    });
  },
  [SET_APPLIED_FILTER](state, newFilterData) {
    let { data, filterName } = newFilterData;
    console.log(
      "🚀 ~ file: prospecting.js ~ line 1212 ~ data, filterName",
      data,
      filterName
    );
    if (filterName != "fullSearch") {
      state.appliedFilters.filters[filterName] = [...data];
    } else if (filterName == "fullSearch") {
      state.appliedFilters.fullSearch = data;
    }
    const keysToKeep = Object.keys(state.appliedFilters.filters).filter(key => {
      return state.appliedFilters.filters[key].length > 0;
    });
    const newObj = {};

    keysToKeep.forEach(key => {
      newObj[key] = state.appliedFilters.filters[key];
    });
    state.appliedFilters.filters = newObj;
    state.appliedFilters.pages = { page: 1, pageSize: 25 };
    // state.showSaveFilterButton = true;
  },
  [SET_ENTIRE_APPLIED_FILTER](state, newFilterData) {
    try {
      state.appliedFilters.filters = JSON.parse(newFilterData);
    } catch (error) {
      state.appliedFilters.filters = { ...newFilterData };
    }

    const keysToKeep = Object.keys(state.appliedFilters.filters).filter(key => {
      return state.appliedFilters.filters[key].length > 0;
    });
    const newObj = {};

    keysToKeep.forEach(key => {
      newObj[key] = state.appliedFilters.filters[key];
    });
    state.appliedFilters.filters = newObj;
    // state.showSaveFilterButton = true;
  },
  [SET_DISPLAY](state, newDisplay) {
    state.appliedFilters.display = newDisplay;
  },
  [SET_CURRENT_PAGE](state, currentPage) {
    state.appliedFilters.pages.page = currentPage;
  },
  [SET_PAGE_LIMIT](state, limit) {
    state.appliedFilters.pages.pageSize = parseInt(limit);
  },
  [SET_SHOW_SAVE_FILTER_BUTTON](state, value) {
    state.showSaveFilterButton = value;
  },
  [SET_FILTER_DATA](state, data) {
    // add data to the state as {key:[value]} style.
    state.filtersData = Object.keys(data.data).map(key => {
      let obj = {};
      obj[key] = data.data[key];
      return obj;
    });
    state.defaultFiltersData = Object.keys(data.data).map(key => {
      let obj = {};
      obj[key] = data.data[key];
      return obj;
    });
  },
  [SET_SEARCH_FILTER_DATA](state, newSearchFilterData) {
    let { data, field } = newSearchFilterData;
    console.log(
      "🚀 ~ file: prospecting.js ~ line 768 ~ newSearchFilterData",
      newSearchFilterData
    );
    let replaceString = field.substring(field.indexOf("_")).substring(0, 2);
    let replaceWith = field
      .substring(field.indexOf("_") + 1)
      .substring(0, 1)
      .toUpperCase();
    let keyName = helpers.methods.replaceWithGivenString(
      field,
      replaceString,
      replaceWith
    );
    state.filtersData.map(el => {
      if (Object.keys(el)[0] == keyName) {
        el[keyName] = data.data[keyName];
      }
    });
  },
  [SET_DEFAULT_FILTER_DATA](state, selectedField) {
    let { field } = selectedField;
    let replaceString = field.substring(field.indexOf("_")).substring(0, 2);
    let replaceWith = field
      .substring(field.indexOf("_") + 1)
      .substring(0, 1)
      .toUpperCase();
    let keyName = helpers.methods.replaceWithGivenString(
      field,
      replaceString,
      replaceWith
    );
    let defaultData = [];
    state.defaultFiltersData.map(data => {
      if (Object.keys(data)[0] == keyName) {
        defaultData = [...data[keyName]];
      }
    });
    state.filtersData.map(el => {
      if (Object.keys(el)[0] == keyName) {
        el[keyName] = defaultData;
      }
    });
  },
  [SET_EMPTY_PROSPECTING_DATA](state) {
    state.prospectingData.contacts = { results: [], total: 0 };
    state.prospectingData.companies = { results: [], total: 0 };
    state.prospectingData._meta = {};
  },
  [SET_WAITING](state, waitingData) {
    let { value, waitingType } = waitingData;
    console.log(
      "🚀 ~ file: prospecting.js ~ line 487 ~ value, waitingType",
      value,
      waitingType
    );
    if (waitingType == "prospectingData") {
      state.isWaitingForProspectingData = value;
    } else if (waitingType == "filterData") {
      state.isWaitingForFilterData = value;
    }
  },
  [SET_TIMESTAMP_FOR_FILTER_SEARCH](state, field) {
    console.log("🚀 ~ file: prospecting.js ~ line 774 ~ field", field);
    // state.latestTimeStampForFilterSearch[field] = moment();
    state.latestTimeStampForFilterSearch[field] = moment().valueOf();
  },
  [SET_TIMESTAMP_FOR_FILTER_DATA](state, field) {
    state.latestTimeStampForFilterData[field] = moment().valueOf();
  },
  [SET_SELECTED_PROSPECTS](state, selectedProspects) {
    console.log(
      "🚀 ~ file: prospecting.js ~ line 458 ~ selectedProspects",
      selectedProspects
    );
    state.selectedProspects = selectedProspects;
    if (!selectedProspects.length) {
      state.selectedContactIds = [];
      state.selectedProspectIds = [];
    }
  },
  [SET_SELECTED_CONTACT_IDS](state) {
    try {
      if (state.selectedProspects.length) {
        state.selectedContactIds = [];
        state.selectedContactIds = state.selectedProspects.map(
          prospect => prospect.contact_id
        );
        state.selectedContactIds.sort();
      }
    } catch (error) {
      console.log("🚀 ~ file: prospecting.js ~ line 578 ~ error", error);
    }
  },
  [SET_SELECTED_PROSPECT_IDS](state) {
    try {
      if (state.selectedProspects.length) {
        state.selectedProspectIds = state.selectedProspects.map(
          prospect => prospect.id
        );
        state.selectedProspectIds.sort();
      }
    } catch (error) {
      console.log("🚀 ~ file: prospecting.js ~ line 578 ~ error", error);
    }
  },
  [SET_CONTACT_TO_VERIFY](state, contactArr) {
    state.verificationStatus = contactArr.map(contact => {
      return { status: contact.status, message: contact.message };
    });
    let newProspectIds = contactArr.map(contact => contact.contact.prospect_id);
    let newData = state.prospectingData.contacts.results.map(result => {
      if (newProspectIds.includes(result.id)) {
        console.log(newProspectIds.indexOf(result.id));
        result.is_verified =
          contactArr[newProspectIds.indexOf(result.id)].contact.is_verified;
        result.verified_on_date =
          contactArr[
            newProspectIds.indexOf(result.id)
          ].contact.verified_on_date;
        result.contact_id =
          contactArr[newProspectIds.indexOf(result.id)].contact.id;
        result.all_lists =
          contactArr[newProspectIds.indexOf(result.id)].contact.all_lists;
      }
      return result;
    });
    state.prospectingData.contacts.results = [...newData];
  },
  [SET_COMPANY_NAME_FILTER](state, companyDataArray) {
    let dataToSet = companyDataArray.map(data => {
      return {
        domain: data.company_domain,
        id: data.id,
        name: data.company_name
      };
    });
    state.appliedFilters.filters.companyName = dataToSet;
    state.appliedFilters.display = "contacts";
  },
  [SET_PROSPECTS_WITH_CONTACT_ID](state, dataWithContactId) {
    console.log(
      "🚀 ~ file: prospecting.js ~ line 848 ~ dataWithContactId",
      dataWithContactId
    );
    // dataWithContactId.data.map(data => {
    //   state.selectedProspects.map(selected => {
    //     if (selected.id == data.prospect_id) {
    //       selected.contact_id = data.contact_id;
    //     }
    //   });
    // });
    dataWithContactId.map(data => {
      state.prospectingData.contacts.results.map(selected => {
        if (selected.prospect_id == data.prospect_id) {
          selected.contact_id = data.contact_id;
          selected.id = data.prospect_id;
        }
      });
    });
  },
  [SET_PROSPECTS_ALL_LISTS_WITH_CONTACT_ID](state, dataWithContactId) {
    dataWithContactId.map(data => {
      state.prospectingData.contacts.results.map(selected => {
        if (selected.prospect_id == data.prospect_id) {
          if (!!data.all_lists) {
            selected.all_lists = data.all_lists;
          } else if (data.hasOwnProperty("contact_data")) {
            selected.all_lists = data.contact_data.all_lists;
          } else {
            selected.all_lists = data.contact.all_lists;
          }
        }
      });
    });
  },
  [SET_PROSPECTING_STATE_EMPTY](state) {
    let initial = initialStateForStore();

    let keys = Object.keys(initial);

    state = keys.map(key => {
      state[key] = initial[key];
      return state;
    });
  },
  [SET_LOADING_FOR_VERIFY](state, contactsForLoading) {
    contactsForLoading = contactsForLoading.map(contact => contact.id);
    state.prospectingData.contacts.results.map(result => {
      if (contactsForLoading.includes(result.id)) {
        result.is_verified = 4;
      }
    });
  },
  [SET_SIC_CODES](state, sicCodes) {
    state.sicCodeData = [...sicCodes];
  },
  [SET_CUSTOM_FILTER_DATA](state, data) {
    let { customFilterData, filter } = data;
    let keys = [];

    state.filtersData.map(el => {
      keys.push(Object.keys(el)[0]);
    });
    if (keys.includes(filter)) {
      state.filtersData.map(el => {
        if (Object.keys(el)[0] == filter) {
          el[filter] = [...customFilterData.data[filter]];
        }
      });
    } else {
      state.filtersData.push(customFilterData.data);
    }
  },
  [SET_EMPTY_APPLIED_FILTERS](state) {
    state.appliedFilters = {
      filters: {},
      display: "contacts",
      fullSearch: "",
      pages: { page: 1, pageSize: 25 }
    };
  },
  [SET_ISLOADSEARCHAPPLIEDFILTER_FLAG](state, data) {
    state.isLoadSearchAppliedFilter = data;
  },
  [SET_INITIAL_PROSPECTING_DATA]() {
    state.prospectingData = {
      companies: { results: [], total: 0 },
      contacts: { results: [], total: 0 },
      _meta: {}
    };
  },
  [SET_PROSPECT_INDUSTRY](state, prospectIndustries) {
    state.prospectIndustries = [...prospectIndustries];
  }
};

export const getters = {
  prospectingData(state) {
    return state.prospectingData;
  },
  filtersData(state) {
    return state.filtersData;
  },
  savedSearchList(state) {
    return state.savedSearchList;
  },
  recentActivitiesList(state) {
    return state.recentActivitiesList;
  },
  allActivitiesList(state) {
    return state.allActivitiesList;
  },
  display(state) {
    return state.appliedFilters.display;
  },
  // temporary getter
  getPageMeta(state) {
    return state.prospectingData._meta;
  },
  appliedFilters(state) {
    return state.appliedFilters;
  },
  showSaveFilterButton(state) {
    return state.showSaveFilterButton;
  },
  isWaitingForProspectingData(state) {
    return state.isWaitingForProspectingData;
  },
  isWaitingForFilterData(state) {
    return state.isWaitingForFilterData;
  },
  selectedProspects(state) {
    return state.selectedProspects;
  },
  selectedContactIds(state) {
    return state.selectedContactIds;
  },
  selectedProspectIds(state) {
    return state.selectedProspectIds;
  },
  availableCompanyIds(state) {
    return state.availableCompanyIds;
  },
  availableContactIds(state) {
    return state.availableContactIds;
  },
  verificationStatus(state) {
    return state.verificationStatus[0];
  },
  sicCodeData(state) {
    return state.sicCodeData;
  },
  isLoadSearchAppliedFilter(state) {
    return state.isLoadSearchAppliedFilter;
  },
  prospectIndustries(state) {
    return state.prospectIndustries;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
