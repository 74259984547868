import axios from "axios";
import toastr from "toastr";
import config from "../../config.js";
import utils from "../../utils.js";
import * as moment from "moment";

import postServices from "../../services/posts.js";

export default {
  namespaced: true,
  state: {
    socialPosts: [],
    socialDraftPosts: [],
    loading: false,
    postSuccess: false,
    appendPostId: null,
    socialPost: {
      id: null,
      organization_id: null,
      content: "",
      twitter: "",
      post_url: null,
      date: moment.parseZone().format("YYYY-MM-DD HH:mm:ss"),
      custom_time: moment.parseZone().format("YYYY-MM-DD HH:mm:ss"),
      likes: null,
      shares: null,
      comments: null,
      createdby_id: null,
      created: null,
      updated: null,
      organization_posttype_id: 1,
      posted: null,
      organizationTags: [],
      organization_post_time_id: null,
      organization_page_id: null,
      socialprofile_id: null,
      posted: null,
      fileList: [],
      videoFileList: null,
      link: "",
      selectedChannelProfiles: [],
      organizationPostChannels: [],
      images: [],
      videos: [],
      links: [],
      uuid: null,
      suggestedMessages: [],
      status_id: 1,
      authors: [],
      groups: [],
      destination_type_id: 1,
      og_url: null,
      og_title: null,
      og_description: null,
      destination_type_id: 1,
      html: null,
      visibility_id: 2,
      show_author: 1,
      allow_download: 1,
      filter_organization_content_type_id: 6,
      feature_image_alt: "",
      url: ""
    },
    autoPost: {
      startTime: "08:00 AM",
      endTime: "04:00 PM",
      count: "1",
      days: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
      postChannels: []
    }
  },

  getters: {},

  mutations: {
    setSocialPost(state, data) {
      Vue.set(state, "socialPost", data);
      console.log("54 SocialPost Set: ", data);
    },
    setLoading(state, data) {
      console.log("setLoading", data);
      Vue.set(state, "loading", data);
    },
    setPostSuccess(state, data) {
      Vue.set(state, "postSuccess", data);
    },
    appendPostId(state, data) {
      Vue.set(state, "appendPostId", data);
    },

    setOrganizationPosttypeId(state, data) {
      console.log(data);
      state.socialPost.organization_posttype_id = data;
    },
    /* This 2 mutations just changes content and twitter*/
    updatePostContent(state, data) {
      state.socialPost.content = data;
    },
    updateTweetContent(state, data) {
      state.socialPost.twitter = data;
    },

    /* This 2 mutations just changes imageFile and imageFile*/
    updatePostImage(state, data) {
      state.socialPost.organization_posttype_id = 2;
      state.socialPost.fileList = data;
    },
    updatePostVideo(state, data) {
      state.socialPost.organization_posttype_id = 3;
      state.socialPost.fileList = data;
      state.socialPost.videoFileList = data;
    },

    // Step3
    attachSocialChannelsToSocialPost(state, data) {
      state.socialPost.organizationPostChannels.splice(0, 0, data);
    },
    detachSocialChannelsToSocialPost(state, data) {
      const index = state.socialPost.organizationPostChannels.findIndex(
        element => element.id === data.id
      );
      state.socialPost.organizationPostChannels.splice(index, 1);
    },

    // groups
    attachGroupToSocialPost(state, data) {
      state.socialPost.groups.splice(0, 0, data);
    },
    detachGroupToSocialPost(state, data) {
      const index = state.socialPost.groups.findIndex(
        element => element.id === data.id
      );
      state.socialPost.groups.splice(index, 1);
    },
    setAllGroupsOfSocialPost(state, data) {
      // can be used to add or remove object of groups
      state.socialPost.groups = data;
    },

    // Settings
    attachSelectedTag(state, data) {
      state.socialPost.organizationTags.splice(0, 0, data);
    },
    detachSelectedTag(state, data) {
      const index = state.socialPost.organizationTags.findIndex(
        element => element.id === data.id
      );
      state.socialPost.organizationTags.splice(index, 1);
    },

    detachSelectedTagById(state, tagId) {
      const index = state.socialPost.organizationTags.findIndex(
        element => element.id === tagId
      );
      state.socialPost.organizationTags.splice(index, 1);
    },
    setSuggestedMessages(state, data) {
      console.log("inside setSuggestedMessages mutation ===========");
      state.socialPost.suggestedMessages.push(data);
      console.log("state.socialPost==========", state.socialPost);
    },
    assignSuggestedMessages(state, data) {
      state.socialPost.suggestedMessages = data;
    },
    attachSelectedAuthors(state, data) {
      state.socialPost.authors = data;
      console.log(state.socialPost);
    },
    setLinksToSocialPost(state, data) {
      state.socialPost.links = [...state.socialPost.links, ...data];
    },
    setSocialPostLink(state, data) {
      state.socialPost.link = data;
    },
    setSocialPostLinkImage(state, data) {
      console.log("In commit", data);
      state.socialPost.og_url = data;
      console.log(state.socialPost.og_url);
    },
    setSocialPostLinkTitle(state, data) {
      state.socialPost.og_title = data;
    },
    setSocialPostLinkDescription(state, data) {
      state.socialPost.og_description = data;
    }
    //Auto Post
    // attachSocialChannelsToAutoPost(state, data) {
    //   state.autoPost.postChannels.splice(0, 0, data);
    // },
    // detachSocialChannelsToAutoPost(state, data) {
    //   const index = state.autoPost.postChannels.findIndex(
    //     element => element.id === data.id
    //   );
    //   state.autoPost.postChannels.splice(index, 1);
    // },
  },

  actions: {
    /* Step1: This 2 actions just changes content and twitter*/
    updatePostContent({ state, commit, rootState }, data) {
      commit("updatePostContent", data);
    },
    updateTweetContent({ state, commit, rootState }, data) {
      commit("updateTweetContent", data);
    },
    appendPostId({ state, commit, rootState }, data) {
      commit("appendPostId", data);
    },

    /* Step2: This 2 actions just changes image and video*/
    updatePostImage({ state, commit, rootState }, data) {
      console.log("updatePostImage: 73 - ", data);
      commit("updatePostImage", data);
    },
    updatePostVideo({ state, commit, rootState }, data) {
      commit("updatePostVideo", data);
    },

    // Step3
    attachSocialChannelsToSocialPost({ state, commit, rootState }, data) {
      commit("attachSocialChannelsToSocialPost", data);
    },
    detachSocialChannelsToSocialPost({ state, commit, rootState }, data) {
      commit("detachSocialChannelsToSocialPost", data);
    },
    attachGroupToSocialPost({ state, commit, rootState }, data) {
      commit("attachGroupToSocialPost", data);
    },
    detachGroupToSocialPost({ state, commit, rootState }, data) {
      commit("detachGroupToSocialPost", data);
    },
    setAllGroupsOfSocialPost({ state, commit, rootState }, data) {
      commit("setAllGroupsOfSocialPost", data);
    },
    setPostSuccess({ state, commit, rootState }, data) {
      commit("setPostSuccess", data);
    },
    // socialpost
    setSocialPost({ state, commit, rootState }, data) {
      commit("setSocialPost", data);
    },
    // Status
    setStatusOfSocialPost({ state, commit, rootState }, data) {
      commit("setSocialPost", { ...state.socialPost, status_id: data });
      console.log("Status updated..");
    },

    // Settings: Tags are replaced
    setOrganizationTagsOfSocialPost({ state, commit, rootState }, data) {
      commit("setSocialPost", { ...state.socialPost, organizationTags: data });
    },
    setSuggestedMessages({ state, commit, rootState }, data) {
      console.log("Inside setSuggestedMessages action social post");
      commit("setSuggestedMessages", data);
    },
    assignSuggestedMessages({ state, commit, rootState }, data) {
      commit("assignSuggestedMessages", data);
    },
    updateSuggestedMessage({ state, commit, rootState }, data) {
      const index = state.socialPost.suggestedMessages.findIndex(
        message => message.uuid === data.uuid
      );
      state.socialPost.suggestedMessages.splice(index, 1, data);
    },
    getSuggestedMesaages({ state, commit, rootState }) {
      let data = state.socialPost.suggestedMessages;
      commit("settings/setSuggestedMessages", data, { root: true });
    }, // no use

    // Settings: Tags
    attachSelectedTag({ state, commit, rootState }, data) {
      const index = state.socialPost.organizationTags.findIndex(
        element => element.id === data.id
      );

      if (index == -1) commit("attachSelectedTag", data);
    },
    detachSelectedTag({ state, commit, rootState }, data) {
      commit("detachSelectedTag", data);
    },
    saveSocialPost({ state, commit, rootState, dispatch }, postData) {
      return new Promise((resolve, reject) => {
        postData.status_id = 1;
        postData.status_id_selected = 1;
        postData.zone = moment.tz.guess();
        dispatch("createSocialPost", postData)
          .then(response => {
            console.log("resolve 281");
            resolve(response);
          })
          .catch(e => {
            console.log("reject 284", e);
            reject(e);
          });
      });
    },
    publishSocialPost({ state, commit, rootState, dispatch }, postData) {
      return new Promise((resolve, reject) => {
        postData.status_id = 3;
        postData.status_id_selected = 3;

        if (postData.type == "message" && "type" in postData) {
          postData.status_id = 1;
          postData.status_id_selected = 1;
        }

        postData.zone = moment.tz.guess();
        dispatch("createSocialPost", postData).then(response => {
          resolve(response);
          dispatch("socialPosts/clearSocialPost", null, {
            root: true
          });
        });
      });
    },
    createSocialPost({ state, commit, rootState, dispatch }, postData) {
      return new Promise((resolve, reject) => {
        // validation and formatting
        // we are doing in store bcz: this action can be fired from anywhere
        let socialPost = { ...postData };
        console.log("PostData: ", postData, socialPost);

        // socialPost.createdby_id =
        //   rootState.auth.whoami.active_organization_user_id;
        if (socialPost.authors && socialPost.authors.length > 0) {
          socialPost.author_id = socialPost.authors[0].id;
        }
        // set priority
        socialPost.priority = false;
        if (
          !!postData.webuser == false &&
          rootState.auth.whoami.active_organization.active_subscription
        )
          socialPost.priority = true;

        // set post type
        // 1=text, 2=image, 3=vdo, 4=link
        // if (socialPost.fileList && socialPost.fileList.length > 0)
        //   socialPost.organization_posttype_id = 2;
        // // image
        // else socialPost.organization_posttype_id = 1; // text

        // socialPost.status_id = 1;
        // set timezone
        var zone = moment.tz.guess();
        console.log("Timezone:", zone);
        socialPost.zone = zone;

        let error = "";

        // validation for post content is empty or not
        // if (
        //   socialPost.organization_posttype_id == 1 &&
        //   (socialPost.content == "" || socialPost.content == null || socialPost.html == null)
        // )
        //   error += "Post content is empty.<br>";

        if (
          false &&
          socialPost.organization_posttype_id == 1 &&
          (socialPost.twitter == "" || socialPost.twitter == null)
        )
          error += "Twitter content is empty.<br>";

        if (socialPost.organization_post_time_id == 1) {
          socialPost.custom_time = utils.getCurrentTime();
        }
        console.log("TTT: ", socialPost.date, socialPost.custom_time);

        socialPost.is_draft = false;
        let isValid = moment(
          socialPost.custom_time,
          "YYYY-MM-DD HH:mm:ss"
        ).isValid();
        if (isValid) {
          socialPost.organization_post_time_id = 2;
          socialPost.date = moment(
            socialPost.custom_time,
            "YYYY-MM-DD HH:mm:ss"
          ).format("YYYY-MM-DD");
          socialPost.custom_time = moment(
            socialPost.custom_time,
            "YYYY-MM-DD HH:mm:ss"
          ).format("HH:mm");

          // post.date = moment(post.date).format("YYYY-MM-DD");
        } else socialPost.organization_post_time_id = 1;

        console.log("TTT2: ", socialPost.date, socialPost.custom_time);
        if (error == "") {
          commit("setLoading", true);

          if (socialPost.id && socialPost.id > 0)
            dispatch("post/updatePost", socialPost, { root: true })
              .then(async response => {
                // call api of featured image, if featured image is attached
                if (!!socialPost.featuredImage) {
                  let featuredImage = {
                    postId: response.data.id,
                    file: socialPost.featuredImage
                  };

                  await postServices
                    .uploadFeaturedImageOfPost(featuredImage)
                    .then(res => {
                      console.log("Featured image uploaded.");
                    })
                    .catch(e => {
                      console.error("Featured not uploaded: ", e);
                    }); //axios;
                } else {
                }

                let payload = {
                  postId: response.data.id,
                  data_blocks: socialPost.data_blocks
                };
                await postServices
                  .uploadAttachmentBlocksOfPost(payload)
                  .then(res => {
                    console.log("resolved 378");
                  });

                commit("setLoading", false);
                resolve(response);
              })
              .catch(e => {
                commit("setLoading", false);
                reject(e);
              });
          else {
            var postProgressId = Math.random();
            dispatch("post/createPost", socialPost, { root: true })
              .then(
                async response => {
                  //Set post progresses
                  //var postProgressId = Math.random();
                  let postProgress = {
                    id: postProgressId,
                    message: "Creating Post...",
                    isCreatingPost: true
                  };
                  dispatch("post/addPostProgress", postProgress, {
                    root: true
                  });
                  // call api of featured image, if featured image is attached
                  if (!!socialPost.featuredImage) {
                    let featuredImage = {
                      postId: response.data.id,
                      file: socialPost.featuredImage
                    };
                    await postServices
                      .uploadFeaturedImageOfPost(featuredImage)
                      .then(res => {
                        console.log("Featured image uploaded.");
                      })
                      .catch(e => {
                        console.error("Featured not uploaded: ", e);
                      }); //axios
                  }

                  console.log("386 Post Response:", response);
                  let payload = {
                    postId: response.data.id,
                    data_blocks: socialPost.data_blocks
                  };
                  console.log("390 payload::", payload);
                  await postServices
                    .uploadAttachmentBlocksOfPost(payload)
                    .then(res => {
                      console.log("resolved 391", response);
                      resolve(response);
                    })
                    .catch(e => {
                      console.error("Attachements not uploaded: ", e);
                      //Set post progresses
                      let postProgress = {
                        id: postProgressId,
                        message: "Error while saving post",
                        isCreatingPost: false
                      };
                      dispatch("post/updatePostProgress", postProgress, {
                        root: true
                      });
                      setTimeout(function() {
                        let postProgress = {
                          id: postProgressId,
                          message: "Error while saving post",
                          isCreatingPost: false
                        };
                        dispatch("post/deletePostProgress", postProgress, {
                          root: true
                        });
                      }, 2000);
                      commit("setLoading", false);
                    }); //axios
                  postProgress = {
                    id: postProgressId,
                    message: "Created Successfully",
                    isCreatingPost: false
                  };
                  dispatch("post/updatePostProgress", postProgress, {
                    root: true
                  });
                  setTimeout(function() {
                    postProgress = {
                      id: postProgressId,
                      message: "Created Successfully",
                      isCreatingPost: false
                    };
                    dispatch("post/deletePostProgress", postProgress, {
                      root: true
                    });
                  }, 2000);
                  commit("setLoading", false);
                  resolve(response);
                  console.log("response: ", response);
                  console.log("resolved...502");
                },
                err => {
                  //Set post progresses
                  let postProgress = {
                    id: postProgressId,
                    message: "Error while saving post",
                    isCreatingPost: false
                  };
                  dispatch("post/updatePostProgress", postProgress, {
                    root: true
                  });
                  setTimeout(function() {
                    let postProgress = {
                      id: postProgressId,
                      message: "Error while saving post",
                      isCreatingPost: false
                    };
                    dispatch("post/deletePostProgress", postProgress, {
                      root: true
                    });
                  }, 2000);
                  dispatch("socialPosts/clearSocialPost", null, {
                    root: true
                  });
                  commit("setLoading", false);
                  reject(err);
                  console.log("449", err);
                }
              )
              .catch(e => {
                console.log("catch 533");
                //Set post progresses
                let postProgress = {
                  id: postProgressId,
                  message: "Error while saving post",
                  isCreatingPost: false
                };
                dispatch("post/updatePostProgress", postProgress, {
                  root: true
                });
                setTimeout(function() {
                  let postProgress = {
                    id: postProgressId,
                    message: "Error while saving post",
                    isCreatingPost: false
                  };
                  dispatch("post/deletePostProgress", postProgress, {
                    root: true
                  });
                }, 2000);

                commit("setLoading", false);
                reject(e);
              });
          }
        } else {
          //Set post progresses
          let postProgress = {
            id: postProgressId,
            message: "Error while saving post",
            isCreatingPost: false
          };
          dispatch("post/updatePostProgress", postProgress, { root: true });
          setTimeout(function() {
            let postProgress = {
              id: postProgressId,
              message: "Error while saving post",
              isCreatingPost: false
            };
            dispatch("post/deletePostProgress", postProgress, { root: true });
          }, 2000);
          commit("setLoading", false);
          console.error("Error: ", error);
          // toastr.error(error, "", { timeOut: config.TOASTR_TIMEOUT });
          toastr.error(error, "", { timeOut: config.TOASTR_TIMEOUT });
        }
      });
    },
    attachSelectedAuthors({ state, commit, rootState }, data) {
      commit("attachSelectedAuthors", data);
    },
    setDefaultAuthor({ state, commit, rootState }) {
      console.log("inside setDefaultAuthor");
      let authorData = [
        {
          first_name: rootState.auth.whoami.name,
          last_name: "",
          user_id: rootState.auth.whoami.userid
        }
      ];
      console.log("authorData0", authorData);
      commit("attachSelectedAuthors", authorData);
      commit("settings/setSelectedAuthors", authorData, { root: true });
    },
    setLinksToSocialPost({ state, commit, rootState }, data) {
      commit("setLinksToSocialPost", data);
    },
    setSocialPostLink({ state, commit, rootState }, data) {
      commit("setSocialPostLink", data);
    },
    setSocialPostLinkImage({ state, commit, rootState }, data) {
      commit("setSocialPostLinkImage", data);
    },
    setSocialPostLinkTitle({ state, commit, rootState }, data) {
      state.socialPost.og_title = data;
    },
    setSocialPostLinkDescription({ state, commit, rootState }, data) {
      state.socialPost.og_description = data;
    },
    setOrganizationPosttypeId({ state, commit }, data) {
      commit("setOrganizationPosttypeId", data);
    },
    clearSocialPost({ state, commit, rootState }) {
      let newSocialPost = {
        id: null,
        organization_id: null,
        content: "",
        twitter: "",
        post_url: null,
        date: moment.parseZone().format("YYYY-MM-DD HH:mm:ss"),
        custom_time: moment.parseZone().format("YYYY-MM-DD HH:mm:ss"),
        likes: null,
        shares: null,
        comments: null,
        createdby_id: null,
        created: null,
        updated: null,
        organization_posttype_id: 1,
        posted: null,
        organizationTags: [],
        organization_post_time_id: null,
        socialprofile_id: null,
        posted: null,
        fileList: [],
        videoFileList: null,
        link: "",
        selectedChannelProfiles: [],
        organizationPostChannels: [],
        images: [],
        videos: [],
        links: [],
        uuid: null,
        suggestedMessages: [],
        groups: [],
        destination_type_id: 1,
        filter_organization_content_type_id: 6,
        feature_image_alt: ""
      };
      commit("setSocialPost", newSocialPost);
    }
    //Auto Post
    // attachSocialChannelsToAutoPost({ state, commit, rootState }, data) {
    //   commit("attachSocialChannelsToAutoPost", data);
    // },
    // detachSocialChannelsToAutoPost({ state, commit, rootState }, data) {
    //   commit("detachSocialChannelsToAutoPost", data);
    // }
  }
};
