export const SET_DATA = "setData";
export const SET_CONTACT_DATA = "setContactData";
export const SET_LOADING = "setLoading";
export const SET_SEARCH_DATA = "setSearchData";
export const SET_RECENT_ACTIVITIES_DATA = "setRecentActivitiesData";
export const SET_ALL_ACTIVITIES_DATA = "setAllActivitiesData";
export const UPDATE_SAVED_SEARCH_DATA_ON_DELETE =
  "updateSavedSearchDataOnDelete";
export const UPDATE_SAVED_SEARCH_DATA_ON_RENAME =
  "updateSavedSearchDataOnRename";
export const SET_APPLIED_FILTER = "setAppliedFilter";
export const SET_ENTIRE_APPLIED_FILTER = "setEntireAppliedFilter";
export const SET_DISPLAY = "setDisplay";
export const SET_CURRENT_PAGE = "setCurrentPage";
export const SET_PAGE_LIMIT = "setPageLimit";
export const UPDATE_SAVED_SEARCH_DATA = "updateSavedSearchData";
export const UPDATE_SEARCH_FILTER = "updateSearchFilter";
export const SET_SHOW_SAVE_FILTER_BUTTON = "setShowSaveFilterButton";
export const SET_FILTER_DATA = "setFilterData";
export const UPDATE_CREDITS = "updateCredits";
export const SET_SEARCH_FILTER_DATA = "setSearchFilterData";
export const SET_DEFAULT_FILTER_DATA = "setDefaultFilterData";
export const SET_EMPTY_PROSPECTING_DATA = "setEmptyProspectingData";
export const SET_WAITING = "setWaiting";
export const SET_SELECTED_PROSPECTS = "setSelectedProspects";
export const SET_CONTACT_TO_VERIFY = "setContactToVerify";
export const SET_SELECTED_CONTACT_IDS = "setSelectedContactIds";
export const SET_SELECTED_PROSPECT_IDS = "setSelectedProspectIds";
export const SET_COMPANY_NAME_FILTER = "setCompanyNameFilter";
export const SET_PROSPECTS_WITH_CONTACT_ID = "setProspectsWithContactId";
export const SET_PROSPECTS_ALL_LISTS_WITH_CONTACT_ID =
  "setProspectsAllListWithContactId";
export const SET_PROSPECTING_STATE_EMPTY = "setProspectingStateEmpty";
export const SET_SIC_CODES = "setSicCodes";
export const SET_CUSTOM_FILTER_DATA = "setCustomFilterData";
export const SET_EMPTY_APPLIED_FILTERS = "setEmptyAppliedFilters";
export const SET_ISLOADSEARCHAPPLIEDFILTER_FLAG =
  "setIsLoadSearchAppliedFilterFlag";
export const SET_INITIAL_PROSPECTING_DATA = "setInitialProspectingData";
export const SET_TIMESTAMP_FOR_FILTER_SEARCH = "setTimeStampForFilterSearch";
export const SET_TIMESTAMP_FOR_FILTER_DATA = "setTimeStampForFilterData";
export const SET_PROSPECT_INDUSTRY = "setProspectIndustry";
