import axios from "axios";
import toastr from "toastr";
import config from "../../config.js";
import * as moment from "moment";

export default {
  namespaced: true,
  state: {
    selectedSendToContact: []
  },

  getters: {},

  mutations: {
    setSelectedContact(state, data) {
      state.selectedSendToContact.push(data);
    },
    resetSelectedContact(state) {
      state.selectedSendToContact = [];
    }
  },

  actions: {
    setSelectedContactToEmail({ state, commit, dispatch, rootState }, data) {
      if (!state.selectedSendToContact.includes(data))
        commit("setSelectedContact", data);
    },
    resetSelectedContactToEmail({ state, commit, dispatch, rootState }) {
      commit("resetSelectedContact");
    }
  }
};
