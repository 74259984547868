var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !!_vm.data && !!_vm.data._meta
    ? _c("div", { staticClass: "float-right" }, [
        _c(
          "div",
          {},
          [
            _vm.data._meta.pageCount != 0
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-outline-primary",
                    attrs: {
                      href: "#",
                      disabled: _vm.data._meta.currentPage == 1 ? true : false
                    },
                    on: {
                      click: function($event) {
                        return _vm.$emit(
                          "page-changed",
                          _vm.data._meta.currentPage - 1
                        )
                      }
                    }
                  },
                  [_vm._v("\n      <\n    ")]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm._l(_vm.data._meta.pageCount, function(pageNo) {
              return _vm.showOrHide(pageNo)
                ? _c(
                    "button",
                    {
                      staticClass: "btn ml-1",
                      class:
                        _vm.data._meta.currentPage == pageNo
                          ? "btn-primary"
                          : "btn-outline-primary",
                      attrs: { href: "#" },
                      on: {
                        click: function($event) {
                          return _vm.$emit("page-changed", pageNo)
                        }
                      }
                    },
                    [_vm._v("\n      " + _vm._s(pageNo) + "\n    ")]
                  )
                : _vm._e()
            }),
            _vm._v(" "),
            _vm.data._meta.pageCount != 0
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-outline-primary",
                    attrs: {
                      href: "#",
                      disabled:
                        _vm.data._meta.currentPage >= _vm.data._meta.pageCount
                          ? true
                          : false
                    },
                    on: {
                      click: function($event) {
                        return _vm.$emit(
                          "page-changed",
                          _vm.data._meta.currentPage + 1
                        )
                      }
                    }
                  },
                  [_vm._v("\n      >\n    ")]
                )
              : _vm._e()
          ],
          2
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }