import axios from "axios";
import config from "../../config.js";
import * as moment from "moment";
import router from "../../router";
import toastr from "toastr";

export default {
  namespaced: true,
  state: {
    selectedSocialProfile: null,
    channelsData: [],
    channel: {
      name: null,
      image: null,
      organization_channel_type_id: null,
      organization_id: null,
      socialprofile_id: null
    },
    moderators: [],
    selectedModerators: [],
    groupChannelSettings: false,
    channelsFor: null
  },

  getters: {},
  mutations: {
    setSelectedSocialProfile(state, data) {
      Vue.set(state, "selectedSocialProfile", data);
    },
    setChannelsData(state, data) {
      state.channelsData = data;
    },
    deleteChannel(state, data) {
      console.log("Calling mutation: deleteChannel", state, data);
      const index = state.channelsData.findIndex(
        channel => channel.id === data
      );
      state.channelsData.splice(index, 1);
    },
    setChannel(state, data) {
      state.channel = data;
      console.log(state.channel);
    },
    updateChannel(state, data) {
      const index = state.channelsData.findIndex(
        channel => channel.id === data.id
      );
      state.channelsData.splice(index, 1, data);
    },
    setModerators(state, data) {
      state.moderators = data;
    },
    addIntoSelectedModeratorsArray(state, data) {
      console.log(
        "addIntoSelectedModeratorsArray mutation===================="
      );
      state.selectedModerators.splice(0, 0, data);
    },
    deleteFromSelectedModeratorsArray(state, data) {
      console.log(
        "deleteFromSelectedModeratorsArray mutation===================="
      );
      const index = state.selectedModerators.findIndex(
        element => element.id === data.id
      );
      state.selectedModerators.splice(index, 1);
    },
    deleteModerator(state, data) {
      const index = state.moderators.findIndex(
        element => element.id === data.id
      );
      state.moderators.splice(index, 1);
    },
    deleteOrganizationChannelUser(state, data) {
      const index = state.channel.organization_channel_user.findIndex(
        element => element.id === data.id
      );
      state.channel.organization_channel_user.splice(index, 1);
    },
    clearSelectedModerators(state) {
      state.selectedModerators = [];
    },
    setGroupChannelSettings(state, data) {
      state.groupChannelSettings = data;
    },
    setRouteMeta(state, data) {
      state.channelsFor = data;
    }
  },

  actions: {
    getChannels({ state, commit, dispatch, rootState }, data) {
      return new Promise(function(resolve, reject) {
        let vm = this;
        let postfix = "";
        if (data && data.for == "user_dashboard") {
          postfix += "?usersonly=true";
          commit("setRouteMeta", "user_dashboard");
        } else {
          commit("setRouteMeta", null);
        }
        //Filter for getting channels of passed group id
        if (data && data.group_id) {
          var group_id = data.group_id;
        } else {
          var group_id = rootState.auth.activeOrganizationGroup.id;
        }
        // let self= this;
        // console.log("printing route",router.apps[0]._route.meta.for);
        axios
          .get(
            config.DOMAIN +
              config.API_VERSION +
              "organizations/" +
              rootState.auth.whoami.active_organization.id +
              "/organizationgroup/" +
              group_id +
              "/channels" +
              postfix
          )
          .then(response => {
            resolve(response);
            console.log("Fetched all added channels", response.data.channels);
            commit("setChannelsData", response.data.channels);
          })
          .catch(e => {
            console.log("Error: ", e);
          }); //axios
      }); //Promise
    },
    addChannels({ state, commit, dispatch, rootState }, data) {
      console.log("Inside addSocialChannels :: ", data);
      let vm = this;
      let channelData = data;
      axios
        .post(
          config.DOMAIN +
            config.API_VERSION +
            "organizations/" +
            rootState.auth.whoami.active_organization.id +
            "/organizationgroup/" +
            rootState.auth.activeOrganizationGroup.id +
            "/channels",
          channelData
        )
        .then(response => {
          router.push("/channels");
          // if (
          //   router.apps[0]._route.meta.for == "user_dashboard" ||
          //   state.channelsFor == "user_dashboard"
          // ) {
          //   router.push(this.$root.org+"user_dashboard/channels");
          // } else {
          //   router.push(
          //     "/groups/" +
          //       rootState.auth.whoami.active_organization_group.id +
          //       "/" +
          //       encodeURIComponent(
          //         rootState.auth.whoami.active_organization_group.name.replace(
          //           /\s/g,
          //           ""
          //         )
          //       ) +
          //       "/more_option/channels"
          //   );
          // }
        })
        .catch(e => {
          console.log("Error: ", e);
        }); //axios
    },
    editChannel({ state, commit, dispatch, rootState }, data) {
      new Promise(function(resolve, reject) {
        let vm = this;
        let channelData = data;
        axios
          .post(
            config.DOMAIN +
              config.API_VERSION +
              "organizations/" +
              rootState.auth.whoami.active_organization.id +
              "/organizationgroup/" +
              rootState.auth.activeOrganizationGroup.id +
              "/channels/editchannel",
            channelData
          )
          .then(response => {
            commit("updateChannel", response.data);
            commit("clearSelectedModerators");
            resolve(response);
            //router.push("/more_option/channels");
          })
          .catch(e => {
            console.log("Error: ", e);
          }); //axios
      }); //promise
    },
    deleteChannelById({ state, commit, dispatch, rootState }, id) {
      return new Promise(function(resolve, reject) {
        // get whoami
        axios
          .delete(
            config.DOMAIN +
              config.API_VERSION +
              "organizations/" +
              rootState.auth.whoami.active_organization.id +
              "/organizationgroup/" +
              rootState.auth.activeOrganizationGroup.id +
              "/channels/" +
              id
          )
          .then(response => {
            console.log("Deleted - " + response.data);
            commit("deleteChannel", id);
            toastr.clear();
            toastr.error("Channel deleted successfully!", "", {
              timeOut: config.TASK_TOASTR_TIMEOUT
            });
            resolve(response);
          })
          .catch(e => {
            console.log("Error: " + e);
            resolve();
          }); //axios
      }); // promise
    },
    setSelectedSocialProfile({ state, commit, rootState }, data) {
      commit("setSelectedSocialProfile", data);
    },
    setChannelsData({ state, commit, rootState }, data) {
      let updatedChannels = state.channelsData;
      updatedChannels.push(data);
      commit("setChannelsData", updatedChannels);
    },
    deleteChannel({ state, commit, rootState }, data) {
      commit("deleteChannel", data);
    },
    setChannel({ state, commit, rootState }, data) {
      commit("setChannel", data);
    },
    getOrganizationChannelModerators({ state, commit, rootState }, data) {
      return new Promise((resolve, reject) => {
        let vm = this;
        axios
          .post(
            config.DOMAIN +
              config.API_VERSION +
              "organizations/" +
              rootState.auth.whoami.active_organization.id +
              "/organizationgroup/" +
              rootState.auth.activeOrganizationGroup.id +
              "/channels/" +
              data.id +
              "/getmoderators",
            data
          )
          .then(response => {
            console.log("Fetched all approvers", response.data);
            commit("setModerators", response.data);
            resolve(response);
          })
          .catch(e => {
            console.log("Error: ", e);
          }); //axios
      });
    },
    getGroupsChannelModerators({ state, commit, rootState }, moderatorType) {
      return new Promise((resolve, reject) => {
        let vm = this;
        axios
          .get(
            config.DOMAIN +
              config.API_VERSION +
              "organizations/" +
              rootState.auth.whoami.active_organization.id +
              "/organizationgroup/" +
              rootState.auth.activeOrganizationGroup.id +
              "/channels/getgroupsmoderators?organization_channel_usertype_id=" +
              moderatorType
          )
          .then(response => {
            console.log("Fetched all approvers", response.data);
            //commit("setGroupsModerators", response.data);
            resolve(response);
          })
          .catch(e => {
            console.log("Error: ", e);
          }); //axios
      });
    },
    addIntoSelectedModeratorsArray(
      { state, commit, dispatch, rootState },
      data
    ) {
      console.log("addIntoSelectedModeratorsArray action==============");
      const isDuplicateEntry = state.selectedModerators.findIndex(element => {
        element.id == data.id;
      });
      if (isDuplicateEntry == -1)
        commit("addIntoSelectedModeratorsArray", data);
    },
    deleteFromSelectedModeratorsArray(
      { state, commit, dispatch, rootState },
      data
    ) {
      console.log("deleteFromSelectedModeratorsArray action==============");
      commit("deleteFromSelectedModeratorsArray", data);
    },
    setRouteMeta({ state, commit, dispatch, rootState }, data) {
      commit("setRouteMeta", data);
    },
    deleteOrganizationChannelModerator(
      { state, commit, dispatch, rootState },
      data
    ) {
      axios
        .post(
          config.DOMAIN +
            config.API_VERSION +
            "organizations/" +
            rootState.auth.whoami.active_organization.id +
            "/organizationgroup/" +
            rootState.auth.activeOrganizationGroup.id +
            "/channels/deletemoderator",
          data
        )
        .then(response => {
          console.log("Deleted moderator", response.data);
          commit("deleteModerator", response.data);
          commit("deleteOrganizationChannelUser", response.data);
        })
        .catch(e => {
          console.log("Error: ", e);
        }); //axios
    },
    setGroupChannelSettings({ state, commit, rootState }, data) {
      commit("setGroupChannelSettings", data);
    },
    deleteOrganizationChannelModeratorForGroup(
      { state, commit, dispatch, rootState },
      data
    ) {
      axios
        .post(
          config.DOMAIN +
            config.API_VERSION +
            "organizations/" +
            rootState.auth.whoami.active_organization.id +
            "/organizationgroup/" +
            rootState.auth.activeOrganizationGroup.id +
            "/channels/deletegroupmoderator",
          data
        )
        .then(response => {
          console.log("Deleted moderator", response.data);
          commit("deleteModerator", response.data);
          commit("deleteOrganizationChannelUser", response.data);
        })
        .catch(e => {
          console.log("Error: ", e);
        }); //axios
    }
  }
};
