import axios from "axios";
import toastr from "toastr";
import config from "../../config.js";
import * as moment from "moment";
import mattermostChat from "../../services/mattermostChat.js";

export default {
  namespaced: true,
  state: {
    selectedTab: "recent",
    selectedChannel: null,
    selectedPerson: null,
    currentUser: null,
    currentPage: 0,
    team: null,
    channelMessages: [],
    channelSavedMessages: [],
    chatInfo: {},
    teams: [],
    typingUserInfo: {},
    recentChats: [],
    hasMoreMessages: true
  },

  getters: {
    channelMessages(state) {
      return state.channelMessages;
    },
    selectedTab(state) {
      return state.selectedTab;
    },
    selectedChannel(state) {
      return state.selectedChannel;
    },
    currentUser(state) {
      return state.currentUser;
    },
    currentPage(state) {
      return state.currentPage;
    },
    team(state) {
      return state.team;
    },
    teams(state) {
      return state.teams;
    },
    chatInfo(state) {
      return state.chatInfo;
    },
    typingUserInfo(state) {
      return state.typingUserInfo;
    },
    recentChats(state) {
      return state.recentChats;
    },
    hasMoreMessages(state) {
      return state.hasMoreMessages;
    }
  },

  mutations: {
    setChannelMessages(state, data) {
      state.channelMessages = data;
    },
    setSelectedTab(state, data) {
      state.selectedTab = data;
    },
    setSelectedChannel(state, data) {
      state.selectedChannel = data;
    },
    setCurrentUser(state, data) {
      state.currentUser = data;
    },
    setCurrentPage(state, data) {
      state.currentPage = data;
    },
    setTeam(state, data) {
      state.team = data;
    },
    setTeams(state, data) {
      state.teams = data;
    },
    setChatInfo(state, data) {
      state.chatInfo = data;
    },
    setTypingUserInfo(state, data) {
      state.typingUserInfo = data;
    },
    setRecentChats(state, data) {
      state.recentChats = data;
    },
    setHasMoreMessages(state, data) {
      state.hasMoreMessages = data;
    }
  },

  actions: {
    setChannelMessages({ state, commit }, data) {
      commit("setChannelMessages", data);
    },
    setSelectedTab({ state, commit }, data) {
      commit("setSelectedTab", data);
    },
    setSelectedChannel({ state, commit }, data) {
      commit("setSelectedChannel", data);
    },
    setCurrentUser({ state, commit }, data) {
      commit("setCurrentUser", data);
    },
    setCurrentPage({ state, commit }, data) {
      commit("setCurrentPage", data);
    },
    setTeam({ state, commit }, data) {
      commit("setTeam", data);
    },
    async setTeams({ commit }) {
      try {
        const res = await mattermostChat.fetchUserTeams();
        if (res) {
          commit("setTeams", res.data);
        }
      } catch (error) {
        console.log(error);
      }
    },
    setChatInfo({ state, commit }, data) {
      commit("setChatInfo", data);
    },
    setTypingUserInfo({ state, commit }, data) {
      commit("setTypingUserInfo", data);
    },
    setRecentChats({ state, commit }, data) {
      commit("setRecentChats", data);
    },
    setHasMoreMessages({ state, commit }) {
      commit("setHasMoreMessages", false);
    }
  }
};
