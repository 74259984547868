export const FETCH_DATA = "fetchData";
export const SHOW_CONTACT_DETAILS = "showContactDetails";
export const FETCH_ACTIVITY_SEARCHES = "fetchActivitySearches";
export const SET_SAVED_SEARCH_NAME = "setSavedSearchName";
export const DELETE_SAVED_SEARCH = "deleteSavedSearch";
export const UPDATE_APPLIED_FILTER = "updateAppliedFilter";
export const UPDATE_ENTIRE_APPLIED_FILTER = "updateEntireAppliedFilter";
export const SEND_FILTERS_DATA = "sendFiltersData";
export const UPDATE_DISPLAY = "updateDisplay";
export const UPDATE_CURRENT_PAGE = "updateCurrentPage";
export const UPDATE_LIMIT = "updateLimit";
export const SET_SAVE_FILTER_NAME = "setSaveFilterName";
export const SET_SEARCH_FILTER = "updateSearchFilter";
export const CHECK_APPLIED_FILTER_DATA = "checkAppliedFilterData";
export const FETCH_FILTER_DATA = "fetchFilterData";
export const FETCH_ORGANIZATION_CREDITS = "fetchOrganizationCredits";
export const UPDATE_SEARCH_QUERY = "updateSearchQuery";
export const CLEAR_PROSPECTING_DATA = "clearProspectingData";
export const UPDATE_WAITING = "setWaiting";
export const UPDATE_SELECTED_PROSPECTS = "updateSelectedProspects";
export const DOWNLOAD_SELECTED_PROSPECTS = "downloadSelectedProspects";
export const CLEAR_SELECTED_PROSPECTS = "clearSelectedProspects";
export const UPDATE_CONTACT_TO_VERIFY = "updateContactToVerify";
export const SHOW_SELECTED_PROSPECTS = "showSelectedProspects";
export const UPDATE_COMPANY_NAME_FILTER = "updateCompanyNameFilter";
export const CLEAR_PROSPECTING_STATE = "clearProspectingState";
export const SET_LOADING_FOR_VERIFY = "setLoadingForVerify";
export const GET_SIC_CODES = "getSicCodes";
export const UPDATE_CREDITS_INFO = "updateCreditsInfo";
export const FETCH_CUSTOM_FILTER_DATA = "fetchCustomFilterData";
export const CLEAR_APPLIED_FILTERS = "clearAppliedFilters";
export const CLEAR_PROSPECT_STORE_DATA = "clearProspectStoreData";
export const UPDATE_TIMESTAMP_FOR_FILTER_SEARCH =
  "updateTimeStampForFilterSearch";
export const UPDATE_TIMESTAMP_FOR_FILTER_DATA = "updateTimeStampForFilterData";
export const GET_PROSPECT_INDUSTRY = "getProspectIndustry";
