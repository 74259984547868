import axios from "axios";
import config from "../config.js";

export default {
  showSavedSearchesDetails(data) {
    return new Promise((resolve, reject) => {
      let url =
        config.DOMAIN +
        config.API_VERSION +
        "organizations/" +
        data.organization_id +
        "/user_activities/recentlyused";

      axios
        .post(url, data)
        .then(response => {
          resolve(response.data);
        })
        .catch(e => {
          reject(e);
        }); //axios
    });
  },

  renameSavedSearch(activity) {
    return new Promise((resolve, reject) => {
      let url =
        config.DOMAIN +
        config.API_VERSION +
        "organizations/" +
        activity.organization_id +
        "/user_activities/renamesavedsearch/" +
        activity.id;
      let data = {
        name: activity.name
      };
      axios
        .put(url, data)
        .then(response => {
          resolve(response.data);
        })
        .catch(e => {
          reject(e);
        }); //axios
    });
  },

  removeSavedSearch(activity) {
    return new Promise((resolve, reject) => {
      let url =
        config.DOMAIN +
        config.API_VERSION +
        "organizations/" +
        activity.organization_id +
        "/user_activities/removesavedsearch/" +
        activity.id;

      axios
        .delete(url)
        .then(response => {
          resolve(response.data);
        })
        .catch(e => {
          reject(e);
        }); //axios
    });
  },

  saveSearchFilter(data) {
    console.log(
      "🚀 ~ file: prospectingServices.js ~ line 119 ~ saveSearchFilter ~ data",
      data
    );
    return new Promise((resolve, reject) => {
      let url =
        config.DOMAIN +
        config.API_VERSION +
        "organizations/" +
        data.organization_id +
        "/user_activities/savesearch";
      axios
        .post(url, data)
        .then(response => {
          resolve(response.data);
        })
        .catch(e => {
          reject(e);
        }); //axios
    });
  },

  updateSavedSearch(activity) {
    return new Promise((resolve, reject) => {
      let url =
        config.DOMAIN +
        config.API_VERSION +
        "organizations/" +
        activity.organization_id +
        "/user_activities/updatesavedsearch/" +
        activity.id;

      axios
        .put(url, activity)
        .then(response => {
          resolve(response.data);
        })
        .catch(e => {
          reject(e);
        }); //axios
    });
  },

  getActivityData(data) {
    return new Promise((resolve, reject) => {
      let url =
        config.DOMAIN +
        config.API_VERSION +
        "organizations/" +
        data.organization_id +
        "/user_activities/getactivitydata/" +
        data.activityId;

      axios
        .get(url)
        .then(response => {
          resolve(response.data);
        })
        .catch(e => {
          reject(e);
        }); //axios
    });
  }
};
