let windowUrl = window.location.origin;
if (!windowUrl) {
  console.error("Unable to fetch window url");
}
export default {
  DOMAIN: windowUrl,
  API_VERSION: "/api/v1/",
  TOASTR_TIMEOUT: 5000,
  TASK_TOASTR_TIMEOUT: 3000,
  GOOGLE_ANALYTICS_ID: "UA-108376326-5",
  CHARGEBEE_SITE_URL: "https://sociallift-test.chargebee.com",
  CUSTOM_DOMAIN_PREFIX: "http://",
  IMAGE_MINIMUM_HEIGHT: 190,
  IMAGE_MINIMUM_WIDTH: 190,
  IMAGE_MINIMUM_ASPECT_RATIO: 0.3,
  MM_WEBSOCKET_URL: "wss://mm.salesinnovator.com/api/v4/websocket",
  ADMINISTRATOR_ORGANIZATION_ID: 1755
};
