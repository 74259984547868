import axios from "axios";
import toastr from "toastr";
import config from "../../config.js";
import * as moment from "moment";
import groupServices from "../../services/groups";

export default {
  namespaced: true,
  state: {
    userModerationsData: []
  },
  mutations: {
    setUserModerations(state, data) {
      state.userModerationsData = data;
    },
    updateUserModerationsData(state, data) {
      const indexToRemove = state.userModerationsData.findIndex(element => {
        return element.id == data.id;
      });
      state.userModerationsData.splice(indexToRemove, 1);
    }
  },
  actions: {
    getAllUserModerations({ state, commit, rootState }) {
      return new Promise((resolve, reject) => {
        console.log(rootState.auth);
        // organizations/3/organizationgroups/1/request/users
        let data = {};
        data.organization_id = rootState.auth.whoami.active_organization.id;
        data.organization_group_id = rootState.auth.activeOrganizationGroup.id;
        groupServices
          .getUserJoinRequestOfGroup(data)
          .then(response => {
            console.log(response.data);
            commit("setUserModerations", response.data);
            resolve(response);
          })
          .catch(e => {
            reject(e);
            console.log("Error: ", e);
          }); //axios
      });
    },
    //Action to approve or reject user request
    reviewUserRequest({ state, commit, rootState }, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(
            config.DOMAIN +
              config.API_VERSION +
              "organizations/" +
              rootState.auth.whoami.active_organization.id +
              "/organizationgroups/" +
              rootState.auth.activeOrganizationGroup.id +
              "/request/edit",
            data
          )
          .then(response => {
            console.log(response.data);
            commit("updateUserModerationsData", response.data);
            response.data.is_approved == 1
              ? toastr.error("User request accepted", "", { timeOut: 1000 })
              : toastr.error("User request declined", "", { timeOut: 1000 });
            resolve(response.data);
          })
          .catch(e => {
            console.log("Error: ", e);
            toastr.error("Unable to perform the selected action", "", {
              timeOut: 1000
            });
          }); //axios
      }); //promise
    }
  }
};
