import axios from "axios";
import toastr from "toastr";
import config from "../../config.js";
import * as moment from "moment";

export default {
  namespaced: true,
  state: {
    connections: [],
    connection: {
      organization_connection_type_id: null,
      name: "",
      url: "",
      group_id: -1
    }
  },

  getters: {},

  mutations: {
    setConnections(state, data) {
      state.connections = data;
    },
    updateConnections(state, data) {
      const index = state.connections.findIndex(
        element => element.id === data.id
      );
      state.connections.splice(index, 1);
    },
    clearConnection(state, data) {
      state.connection = data;
    }
  },

  actions: {
    setConnections({ state, commit }, data) {
      commit("setConnections", data);
    },
    updateConnections({ state, commit }, data) {
      commit("updateConnections", data);
    },
    clearConnection({ state, commit }, data) {
      commit("clearConnection", {
        organization_connection_type_id: null,
        name: "",
        url: ""
      });
    }
  }
};
