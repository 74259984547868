const LoginScreen = () =>
  import(/* webpackPrefetch: true */ "../components/Auth/Login");
const SignUp = () => import("../components/Auth/SignUp");
const ForgotPassword = () => import("../components/Auth/ForgotPassword");
const TemporaryPassword = () => import("../components/Auth/TemporaryPassword");
const AcceptInvite = () => import("../components/Auth/AcceptInvite");

const Message = () => import("../pages/Message");
const RegistrationFailure = () => import("../pages/RegistrationFailure");

/*
const StreamsDrip = () => import("../components/Streams/StreamsDrip");
const Welcome = () => import("../pages/Welcome");
const EmailNotificationSettings = () =>
  import("../components/EmailNotificationSettings");*/
const VerifyEmail = () => import("../components/Auth/VerifyEmail");
/*const GroupPageFailure = () => import("../pages/GroupPageFailure");
const DomainsDrip = () => import("../components/CustomDomains/DomainDrip");
const ListWithImage = () => import("../components/Widgets/ListWithImage");
const EditUrl = () => import("../components/Widgets/EditUrl");
const Subscriptions = () => import("../components/Subscriptions/Subscriptions");

const ChannelsAdd = () => import("../components/Channels/ChannelsAdd");
const SocialChannelsConnect = () =>
  import("../components/Channels/SocialChannelsConnect");
const SocialChannelsAdd = () =>
  import("../components/Channels/SocialChannelsAdd");
const ChannelsDrip = () => import("../components/Channels/ChannelsDrip");
const SelectFacebookGroup = () =>
  import("../components/Channels/SelectFacebookGroup");
const ChannelSettings = () =>
  import("../components/Channels/AdvancedSettings/ChannelSettings");
const ChannelModeratorsList = () =>
  import("../components/Channels/AdvancedSettings/ChannelModeratorsList");
const AddChannelModerators = () =>
  import("../components/Channels/AdvancedSettings/AddChannelModerators");

const StreamsSelection = () => import("../components/Streams/StreamsSelection");
const PostStatus = () => import("../components/Streams/PostStatus");
const PostType = () => import("../components/Streams/PostType");
const StreamsChannels = () => import("../components/Streams/StreamsChannels");
const StreamsChannelsAdd = () =>
  import("../components/Streams/StreamsChannelsAdd");
const StreamDisplay = () => import("../components/Streams/StreamDisplay");

const PostContentTypes = () =>
  import("../components/CuratedArticle/ContentTypes/PostContentTypes");
const ContentTypeList = () =>
  import("../components/CuratedArticle/ContentTypes/ContentTypeList");
const CreateContentType = () =>
  import("../components/CuratedArticle/ContentTypes/CreateContentType");

const UserSettingsList = () =>
  import("../components/UserSettings/UserSettingsList");
const Profile = () => import("../components/UserSettings/Profile");

const MainSlidingComponent = () =>
  import("../components/POC/MainSlidingComponent");



const TryAgain = () => import("../components/Auth/TryAgain");
const UnableToConnect = () => import("../components/Auth/UnableToConnect");

const About = () => import("../components/Intro/About");
const ImageCarousel = () => import("../components/Widgets/ImageCarousel");

const Links = () => import("../components/LinksDrip/Links.vue");
const LinkEdit = () => import("../components/LinksDrip/LinkEdit.vue");
const LinkForm = () => import("../components/Advocacy/AdvocacyDrip");
// const LinkPreview = () => import("../components/Advocacy/");

const UserProfile = () => import("../components/Organizations/Profile");
const UserModerations = () =>
  import("../components/Moderation/UserModerations/UserModerations.vue");

const OpportunityCreate = () => import("../components/Opportunity/Create.vue");

const Members = () => import("../components/Members/Members.vue");
const GroupMemberLists = () =>
  import("../components/Members/Wrappers/GroupMemberLists.vue");
const InviteMembers = () => import("../components/Members/InviteMembers.vue");
const Permissions = () =>
  import("../components/Members/Wrappers/Permissions.vue");

const PublicStreams = () =>
  import("../components/ContentDrip/PublicStreams/PublicStreams.vue");

const AddDetailsForm = () =>
  import("../components/WordpressConnect/AddDetailsForm.vue");
*/

export default [
  {
    path: "/",
    name: "Home",
    //component: StreamsDrip,
    meta: { for: "page", domain: "pageDomain" },
    component: () => import("../components/ContentDrip/Hubs/Hub.vue")
  },

  {
    path: "/:organization_slug/admin/getting_started",
    name: "GettingStarted",
    component: () => import("../components/Workspace/AdminOverview/Main.vue")
    // component: () => import("../components/Workspace/GettingStarted/Main.vue")
  },

  /*  {
    path: "/subscriptions",
    name: "Subscriptions",
    component: Subscriptions
  },*/
  {
    path: "/message",
    name: "Message",
    component: Message
  },
  {
    path: "/verify_email",
    name: "VerifyEmail",
    component: VerifyEmail
  },
  {
    path: "/registration_failure",
    name: "RegistrationFailure",
    component: RegistrationFailure
  },
  /*
  {
    path: "/group_page_failure",
    name: "GroupPageFailure",
    component: GroupPageFailure
  },

  // domains
  {
    path: "/domains",
    name: "DomainsDrip",
    component: DomainsDrip
  },
  */

  {
    path: "/accept_invite",
    name: "AcceptInvite",
    component: AcceptInvite
  },

  /*
  {
    path: "/widgets/list_with_image",
    name: "ListWithImage",
    component: ListWithImage
  },
  {
    path: "/widgets/edit_url",
    name: "EditUrl",
    component: EditUrl
  },
  {
    path: "/groups/:group_id/:group_name/channels/add_channel",
    name: "ChannelsAdd",
    component: ChannelsAdd,
    meta: { notPublicForGroupSubscribers: true }
  },
  {
    path: "/groups/:group_id/:group_name/channels/add_social_channel",
    name: "SocialChannelsConnect",
    component: SocialChannelsConnect,
    meta: { notPublicForGroupSubscribers: true }
  },
  {
    path: "/groups/:group_id/:group_name/channels/select_channels",
    name: "SocialChannelsAdd",
    component: SocialChannelsAdd,
    meta: { notPublicForGroupSubscribers: true }
  },
  {
    /*path: "/channels",*
    path: "/groups/:group_id/:group_name/more_option/channels",
    name: "ChannelsDrip",
    component: ChannelsDrip
  },
  {
    path: "/groups/:group_id/:group_name/channels/select_group",
    name: "SelectFacebookGroup",
    component: SelectFacebookGroup
  },
  {
    path: "/groups/:group_id/:group_name/more_option/channels/edit",
    name: "ChannelSettings",
    component: ChannelSettings
  },
  {
    path:
      "/groups/:group_id/:group_name/more_option/channels/edit/moderate/:type",
    name: "ChannelModeratorsList",
    component: ChannelModeratorsList
  },
  {
    path:
      "/groups/:group_id/:group_name/more_option/channels/edit/groups/moderate/:type",
    name: "ChannelModeratorsLisGroups",
    component: ChannelModeratorsList,
    meta: { groupsChannelSettings: true }
  },
  {
    path:
      "/groups/:group_id/:group_name/more_option/channels/edit/moderate/add/:type",
    name: "AddChannelModerators",
    component: AddChannelModerators
  },
  {
    path:
      "/groups/:group_id/:group_name/more_option/channels/edit/groups/moderate/add/:type",
    name: "AddChannelModeratorsGroups",
    component: AddChannelModerators,
    meta: { groupsChannelSettings: true }
  },
  */
  /*{
    path: "/curatedarticle/create_category",
    name: "CreateCategory",
    component: CreateCategory
  },*/
  /*
  {
    path: "/streams",
    name: "StreamsDrip",
    component: StreamsDrip
  },

  {
    path: "/streams/stream_display",
    name: "StreamDisplay",
    component: StreamDisplay
  },
  {
    path: "/streams/stream_selection/post_type",
    name: "PostType",
    component: PostType
  },

  {
    path: "/sliding_component",
    name: "MainSlidingComponent",
    component: MainSlidingComponent
  },
  */

  // {
  //   path: "/login1",
  //   name: LoginScreen,
  //   component: LoginScreen,
  //   meta: { isPublic: false }
  // },

  // {
  //   path: "/sign_up1",
  //   name: SignUp,
  //   component: SignUp
  // },

  {
    path: "/login",
    name: "LoginScreen",
    // component: () => import("../components/Auth/Login2"),
    component: () => import("../components/Auth/LoginWrapper.vue"),
    meta: { isPublic: false }
  },

  {
    path: "/sign_up",
    name: "SignUp",
    // component: () => import("../components/Auth/SignUp2"),
    component: () => import("../components/Auth/SignupWrapper.vue"),
    meta: { isPublic: false }
  },

  {
    path: "/appointment",
    name: "Demo",
    component: () => import("../components/Auth/Demo"),
    meta: { isPublic: false }
  },

  {
    path: "/forgot_password",
    name: ForgotPassword,
    component: ForgotPassword
  },
  {
    path: "/temporary_password",
    name: TemporaryPassword,
    component: TemporaryPassword
  },

  /*
  {
    path: "/try_again",
    name: TryAgain,
    component: TryAgain
  },
  {
    path: "/retry_connecting",
    name: UnableToConnect,
    component: UnableToConnect
  },
  {
    path: "/about",
    name: About,
    component: About
  },
  {
    path: "/widgets/image_carousel",
    name: "ImageCarousel",
    component: ImageCarousel
  },

  {
    path: "/organizations/profile",
    name: "OrganizationProfile",
    component: UserProfile
  },
  {
    path: "/organizations/:organization_id/profile/:organization_user_id",
    name: UserProfile,
    component: UserProfile
  },
*/

  /*
  //New Links
  {
    path: "/groups/:group_id/:group_name/links",
    name: "Links",
    component: Links,
    meta: { for: "groups" }
  },
  {
    path: "/groups/:group_id/:group_name/links/edit",
    name: "LinkEdit",
    component: LinkEdit,
    meta: { for: "groups" }
  },
  {
    path: "/organizations/:organization_id/:organization_name/links",
    name: "OrganizationLinks",
    component: Links,
    meta: { for: "organizations" }
  },
  {
    path: "/organizations/:organization_id/:organization_name/links/edit",
    name: "OrganizationLinkEdit",
    component: LinkEdit,
    meta: { for: "organizations" }
  },
  {
    path: "/link_form",
    name: "LinkForm",
    component: LinkForm,
    meta: { for: "user_dashboard" }
  },

  //User Moderation
  {
    path: "/moderate/user",
    name: "UserModerations",
    component: UserModerations
  },
*/
  //Create opportunity
  /*
  {
    path: "/:organization_slug/admin/organizations/create/project",
    name: "ProjectCreate",
    component: OpportunityCreate
  },
  {
    path: "/:organization_slug/admin/organizations/create/project/:id",
    name: "ProjectEdit",
    component: OpportunityCreate
  },
  {
    path: "/:organization_slug/admin/socialcampaigns/edit/:id",
    name: "SocialCampaignEdit",
    component: OpportunityCreate
  },

  //Edit opportunity
  {
    path: "/organizations/opportunity/create/:id",
    name: "OpportunityCreate",
    component: OpportunityCreate
  },
*/
  //Invite Members
  /*
  {
    path: "/user_dashboard/invite",
    name: "Members",
    component: Members,
    children: [
      {
        path: "/user_dashboard/invite/members/:id",
        name: "InviteMembers",
        component: InviteMembers
      },
      {
        path: "/user_dashboard/listmembers/:id",
        name: "GroupMemberLists",
        component: GroupMemberLists
      },
      {
        path: "/user_dashboard/permissions/:id",
        name: "Permissions",
        component: Permissions
      },
      {
        path: "/user_dashboard/notifications/:id",
        name: "UserNotifications",
        component: GroupMemberLists
      }
    ]
  },
  */
  // {
  //   path: "/project/:id",
  //   name: "PublicStreams",
  //   component: PublicStreams
  // },
  // {
  //   path: "/wordpress/connect",
  //   name: "WordpressConnect",
  //   component: AddDetailsForm
  // },
  // {
  //   path: "/wordpress/connect/:id",
  //   name: "AddDetailsForm",
  //   component: AddDetailsForm
  // },
  {
    path: "/:organization_slug/admin/more/organizations/members",
    name: "TeamMembersList",
    component: () => import("../components/UserSettings/TeamMembers/List"),
    meta: { for: "organizations" }
  },
  {
    path: "/:organization_slug/admin/members/add",
    name: "AddTeamMember",
    component: () =>
      import("../components/UserSettings/TeamMembers/AddTeamMember"),
    meta: { for: "organizations" }
  }
];
