const UpdateOldPassword = () =>
  import("../components/UserSettings/UpdateOldPassword");
const DomainDrip = () => import("../components/UserSettings/Domain/DomainDrip");
const DomainCreate = () =>
  import("../components/UserSettings/Domain/DomainCreate");
const AddRedirectUrl = () =>
  import("../components/UserSettings/Domain/AddRedirectUrl");

// const Settings = () => import("../components/Widgets/Settings/Settings");
// // User Settings
const Cancel = () => import("../components/UserSettings/Cancel");
const OrganizationEdit = () =>
  import("../components/Organizations/EditOrganization/OrganizationEdit");

const EditProfileForm = () =>
  import("../components/ContentDrip/UserProfile/EditProfileForm");

import store from "../store";
import Swal from "sweetalert2";
import router from "../router";

function checkStatus() {
  Swal.fire({
    title: "Upgrade Plan",
    text: "Upgrade your plan to use these features",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Upgrade"
  }).then(result => {
    if (result.isConfirmed) {
      router.push({
        name: "BillingWrapper",
        params: { selectedTab: "Plan" }
      });
    }
  });
}

export default [
  // {
  //   path: "/settings",
  //   name: "Settings",
  //   component: Settings
  // },

  {
    path: "/:organization_slug/admin/more/organizations/details",
    name: "OrganizationEdit",
    component: OrganizationEdit,
    meta: { for: "organizations" },
    beforeEnter: (to, from, next) => {
      if (
        store.state.auth.whoami.subscription_allowed_features.menu_options
          .settings_organization_details == true
      ) {
        next();
      } else {
        checkStatus();
      }
    }
  },
  {
    path: "/more/profile/details",
    name: "EditProfileForm",
    component: EditProfileForm,
    meta: { for: "organizations" }
  },
  {
    path: "/more/update_password",
    name: "UpdateOldPassword",
    component: UpdateOldPassword,
    meta: { for: "organizations" }
  },
  {
    path: "/more/cancel",
    name: "Cancel",
    component: Cancel,
    meta: { for: "organizations" }
  },
  {
    path: "/:organization_slug/admin/more/organizations/domains",
    name: "DomainDrip",
    component: DomainDrip,
    beforeEnter: (to, from, next) => {
      if (
        store.state.auth.whoami.subscription_allowed_features.menu_options
          .settings_domain_details == true
      ) {
        next();
      } else {
        checkStatus();
      }
    }
  },
  {
    path: "/:organization_slug/admin/more/organizations/domain/add",
    name: "DomainCreate",
    component: DomainCreate
  },
  {
    path: "/more/organizations/domain/update",
    name: "AddRedirectUrl",
    component: AddRedirectUrl
  }
];
