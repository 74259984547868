import axios from "axios";
import config from "../config.js";

export default {
  getOrganizationCredits(id) {
    return new Promise((resolve, reject) => {
      const url =
        config.DOMAIN +
        config.API_VERSION +
        "subscriptions/credits/get-org-credits/" +
        id;
      axios
        .get(url)
        .then(resolve)
        .catch(reject);
    });
  }
};
