// const DashboardGroup = () =>
//   import("../components/ContentDrip/Dashboards/DashboardGroup.vue");
// const DashboardOrganization = () =>
//   import("../components/ContentDrip/Dashboards/DashboardOrganization.vue");

// const Library = () =>
//   import("../components/ContentDrip/ContentLibrary/ContentLibrary.vue");
// // const UsersGroupList = () =>
// //   import("../components/ContentDrip/Dashboards/UsersGroupList.vue");

// const StreamsOfUser = () =>
//   import("../components/ContentDrip/Dashboards/Pages/StreamsOfUser.vue");
// const UploadMedia = () =>
//   import("../components/ContentDrip/ContentAdd/UploadMedia");
// const UploadPreview = () =>
//   import("../components/ContentDrip/ContentAdd/UploadPreview");

// const CampaignHome = () =>
//   import("../components/ContentDrip/Campaigns/CampaignHome");
// const FunnelGraph = () =>
//   import("../components/ContentDrip/Campaigns/FunnelGraph");

// const ChatViewer = () => import("../components/Chat/ChatViewer");
import store from "../store";
import Swal from "sweetalert2";
import router from "../router";

function checkStatus() {
  Swal.fire({
    title: "Upgrade Plan",
    text: "Upgrade your plan to use these features",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Upgrade",
  }).then((result) => {
    if (result.isConfirmed) {
      router.push({
        name: "BillingWrapper",
        params: { selectedTab: "Plan" },
      });
    }
  });
}

export default [
  // {
  //   path:
  //     "/:organization_slug/admin/groups/:group_id/:group_name/group_dashboard",
  //   name: "DashboardGroup",
  //   component: DashboardGroup,
  //   meta: { for: "groups" }
  // },

  // {
  //   path: "/:organization_slug/admin/:organization_name/organization_dashboard",
  //   name: "DashboardOrganization",
  //   component: DashboardOrganization,
  //   meta: { for: "organization" }
  // },

  // {
  //   path:
  //     "/:organization_slug/admin/groups/:group_id/:group_name/content_library",
  //   name: "GroupContentLibrary",
  //   component: Library,
  //   meta: { for: "groups" }
  // },
  // {
  //   path: "/:organization_slug/admin/:organization_name/content_library",
  //   name: "OrganizationContentLibrary",
  //   component: Library,
  //   meta: { for: "organization" }
  // },
  // {
  //   path: "/:organization_slug/admin/user_dashboard/content_library",
  //   name: "UserContentLibrary",
  //   component: Library,
  //   meta: { for: "groups" }
  // },
  // {
  //   path: "/groups/:group_id/:group_name/dashboard/groups",
  //   name: "UsersGroupList",
  //   component: UsersGroupList,
  //   meta: { for: "group" }
  // },
  // {
  //   path: "/user_dashboard/groups", // old
  //   name: "UsersGroupList",
  //   component: UsersGroupList,
  //   meta: { for: "user" }
  // },

  // user dashboard
  // {
  //   path: "/user_dashboard/dashboard",
  //   name: "DashboardUser",
  //   component: DashboardUser,
  //   meta: { for: "user" }
  // },
  // {
  //   path: "/:organization_slug/admin/socialcampaigns/:campaign_slug",
  //   name: "StreamsOfUser",
  //   component: StreamsOfUser,
  //   meta: { for: "user" }
  // },
  // {
  //   path: "/:organization_slug/admin/user_dashboard/streams",
  //   name: "UserStreamsOfUser",
  //   component: StreamsOfUser,
  //   meta: { for: "user" }
  // },

  {
    path: "/:organization_slug/admin/content_library",
    name: "OrganizationContentLibraryNew",
    component: () =>
      import("../components/ContentDrip/ContentLibrary/ContentLibraryNew.vue"),
    meta: { for: "user" },
    beforeEnter: (to, from, next) => {
      if (
        store.state.auth.whoami.subscription_allowed_features.content
          .content_library == true
      ) {
        next();
      } else {
        checkStatus();
      }
    },
  },

  // {
  //   path: "/:organization_slug/admin/user_dashboard/channels", // old: not in use
  //   name: "ChannelsOfUser",
  //   component: () => import("../components/Channels/ChannelsDrip.vue"),
  //   meta: { for: "user_dashboard" }
  // },
  // {
  //   path: "/:organization_slug/admin/user_dashboard/channels/add_channel",
  //   name: "ChannelsAddUser",
  //   component: () => import("../components/Channels/ChannelsAdd.vue"),
  //   meta: { for: "user_dashboard" }
  // },
  // {
  //   path:
  //     "/:organization_slug/admin/user_dashboard/channels/add_social_channel",
  //   name: "SocialChannelsConnectUser",
  //   component: () => import("../components/Channels/SocialChannelsConnect.vue"),
  //   meta: { for: "user_dashboard", notPublicForGroupSubscribers: true }
  // },
  // {
  //   path: "/:organization_slug/admin/user_dashboard/channels/select_channels",
  //   name: "SocialChannelsAddUser",
  //   component: () => import("../components/Channels/SocialChannelsAdd.vue"),
  //   meta: { for: "user_dashboard", notPublicForGroupSubscribers: true }
  // },
  // {
  //   path: "/:organization_slug/admin/user_dashboard/channels/select_group",
  //   name: "SelectFacebookGroupUser",
  //   component: () => import("../components/Channels/SelectFacebookGroup.vue"),
  //   meta: { for: "user_dashboard" }
  // },
  // {
  //   path: "/user_dashboard/more_option/channels/edit",
  //   name: "ChannelSettingsUser",
  //   component: ChannelSettings,
  //   meta: { for: "user_dashboard" }
  // },
  // {
  //   path:
  //     "/user_dashboard/more_option/channels/edit/moderate/:type",
  //   name: "ChannelModeratorsListUser",
  //   component: ChannelModeratorsList,
  //   meta: { for: "user_dashboard" }
  // },
  // {
  //   path:
  //     "/user_dashboard/more_option/channels/edit/moderate/add/:type",
  //   name: "AddChannelModeratorsUser",
  //   component: AddChannelModerators
  // },

  {
    path: "/:organization_slug/admin/links",
    name: "LinksOfUser",
    component: () => import("../components/LinksDrip/Links.vue"),
    // meta: { for: "user_dashboard", title: "User Links" }
  },
  // {
  //   path: "/user_dashboard/links/edit", // not in use
  //   name: "EditLinksOfUser",
  //   component: () => import("../components/LinksDrip/LinkEdit.vue"),
  //   meta: { for: "user_dashboard", title: "Link Edit" }
  // },
  // {
  //   path: "/:type/upload_media", // not in use
  //   name: "UploadMedia",
  //   component: UploadMedia
  // },
  // {
  //   path: "/:type/upload_preview", // not in use
  //   name: "UploadPreview",
  //   component: UploadPreview
  // },

  // {
  //   path: "/user_dashboard/campaigns", // old
  //   name: "CampaignHome",
  //   component: CampaignHome,
  //   meta: { for: "groups" }
  // },
  // {
  //   path: "/user_dashboard/campaigns/funnel_graph", // not in use
  //   name: "FunnelGraph",
  //   component: FunnelGraph
  // },

  //Workspaces
  {
    path: "/:organization_slug/admin/user_dashboard/workspaces",
    name: "WorkspacesHome",
    component: () =>
      import(
        "../components/ContentDrip/Dashboards/Workspaces/WorkspacesHome.vue"
      ),
  },

  //Profile
  // {
  //   path: "/profile",
  //   name: "Profile",
  //   component: () => import("../components/ContentDrip/Profile/Profile.vue")
  // },
  {
    path: "/profile/:shortcode",
    name: "UserProfile",
    component: () =>
      import("../components/ContentDrip/UserProfile/Profile.vue"),
  },
  //For page domain
  {
    path: "/blog/profile/:shortcode",
    name: "UserProfileForPageDoamin",
    meta: { for: "pageDomain" },
    component: () =>
      import("../components/ContentDrip/UserProfile/Profile.vue"),
  },
  //Organization Profile
  {
    path: "/:shortcode/admin",
    name: "OrganizationAdmin",
    component: () =>
      import("../components/ContentDrip/OrganizationProfile/Profile.vue"),
  },
  //Group Profile
  // {
  //   path: "/projects/:shortcode",
  //   name: "ProjectProfile",
  //   component: () =>
  //     import("../components/ContentDrip/ProjectProfile/Profile.vue")
  // },

  //New Channels
  // {
  //   path: "/channels",
  //   name: "ChannelsHome",
  //   component: () =>
  //     import("../components/ContentDrip/Channels/ChannelsHome.vue")
  // },
  // {
  //   path: "/channels/:id/settings",
  //   name: "ChannelConnectionSettings",
  //   component: () =>
  //     import("../components/ContentDrip/Channels/ChannelConnectionSettings.vue")
  // },
  {
    path: "/contribute",
    name: "Contribute",
    component: () =>
      import("../components/ContentDrip/Contribute/Contribute.vue"),
  },
  {
    path: "/contribute/:shortcode",
    name: "EditContribute",
    component: () =>
      import("../components/ContentDrip/Contribute/EditContribute.vue"),
  },
  {
    path: "/invite/contribute/:shortcode",
    name: "InviteContribute",
    meta: { for: "create" },
    component: () =>
      import("../components/ContentDrip/Contribute/InviteContribute.vue"),
  },
  {
    path: "/invite/contribute/:shortcode/:postId/:email/:token",
    name: "ContributorEditPostForm",
    meta: { for: "edit" },
    component: () =>
      import("../components/ContentDrip/Contribute/InviteContribute.vue"),
  },

  //Project Customize

  // {
  //   path: "/organizations/:id/pages/create",
  //   name: "CustomizeMain",
  //   component: () => import("../components/ContentDrip/Hubs/Customize/Main.vue")
  // },
  {
    path: "/:organization_slug/admin/pages/create",
    name: "CustomizeMain",
    component: () =>
      import("../components/ContentDrip/Hubs/Customize/Main.vue"),
    beforeEnter: (to, from, next) => {
      if (
        store.state.auth.whoami.subscription_allowed_features.content
          .personalized_landing_pages == true
      ) {
        next();
      } else {
        checkStatus();
      }
    },
  },

  {
    // path: "/projects/:shortcode/customize/:page_shortcode",
    path: "/:organization_slug/admin/pages/edit/:page_shortcode",
    name: "CustomizeMainEdit",
    component: () =>
      import("../components/ContentDrip/Hubs/Customize/Main.vue"),
  },

  // {
  //   // path: "/projects/:shortcode/customize/:page_shortcode",
  //   path: "/admin/pages/edit/:page_shortcode",
  //   name: "CustomizeMainEdit1",
  //   component: () => import("../components/ContentDrip/Hubs/Customize/Main.vue")
  // },

  // //Project Profile for Client
  // {
  //   path: "/projects/:shortcode/:clientId",
  //   name: "Profile",
  //   component: () =>
  //     import("../components/ContentDrip/Hubs/Profile.vue")
  // },

  {
    path: "/:organization_slug/admin/pages",
    name: "Hubs",
    component: () => import("../components/ContentDrip/Hubs/HubsList.vue"),
    beforeEnter: (to, from, next) => {
      if (
        store.state.auth.whoami.subscription_allowed_features.menu_options
          .page_manage == true
      ) {
        next();
      } else {
        checkStatus();
      }
    },
  },

  // admin page for hub
  {
    path: "/:organization_slug/admin/pages/shortcode/:shortcode",
    name: "Hub",
    meta: { for: "page" },
    component: () => import("../components/ContentDrip/Hubs/Hub.vue"),
  },
  {
    path: "/:organization_slug/admin/pages/contact/:id/shortcode/:shortcode",
    name: "ContactHub",
    meta: { for: "page" },
    component: () => import("../components/ContentDrip/Hubs/Hub.vue"),
  },
  {
    path: "/:organization_slug/admin/pages/content",
    name: "HubContent",
    component: () =>
      import("../components/ContentDrip/Hubs/Content/HubContent.vue"),
    beforeEnter: (to, from, next) => {
      if (
        store.state.auth.whoami.subscription_allowed_features.menu_options
          .page_add_content == true
      ) {
        next();
      } else {
        checkStatus();
      }
    },
  },
  {
    path: "/:organization_slug/admin/pages/create/subscriptionpage",
    name: "SubscriptionPage",
    component: () =>
      import("../components/ContentDrip/SubscriptionPage/SubscriptionMain.vue"),
    // beforeEnter: (to, from, next) => {
    //   if (
    //     store.state.auth.whoami.subscription_allowed_features.menu_options
    //       .page_add_content == true
    //   ) {
    //     next();
    //   } else {
    //     checkStatus();
    //   }
    // }
  },
  {
    path: "/:organization_slug/admin/pages/edit/subscriptionpage/:page_shortcode",
    name: "EditSubscriptionPage",
    component: () =>
      import("../components/ContentDrip/SubscriptionPage/SubscriptionMain.vue"),
  },
  {
    // path: "/organizations/:id/pages/shortcode/:shortcode",
    path: "/admin/hub/subscriptionpage",
    name: "SubscriptionPage1",
    // meta: {storeViewActivity: true},
    component: () => import("../components/ContentDrip/Hubs/Hub.vue"),
  },
  {
    path: "/:organization_slug/admin/pages/:shortcode/editcontent",
    name: "HubEditContent",
    component: () =>
      import("../components/ContentDrip/Hubs/Content/HubEditContent.vue"),
  },
  {
    path: "/:organization_slug/admin/socialcampaigns",
    name: "SocialCampaignsHome",
    component: () =>
      import(
        "../components/ContentDrip/SocialCampaigns/SocialCampaignsHome.vue"
      ),
  },
];
