import axios from "axios";
import Swal from "sweetalert2";
import config from "../config.js";
import store from "../store";

export default {
  prospectingFull: function(payloadData, timestamp) {
    console.log(
      "🚀 ~ file: prospectingServices.js ~ line 6 ~ payloadData",
      payloadData
    );
    return new Promise((resolve, reject) => {
      let url =
        config.DOMAIN +
        config.API_VERSION +
        "organizations/" +
        store.state.auth.whoami.active_organization.id +
        "/prospecting_full/senddataforfilters";

      axios
        .post(url, payloadData)
        .then(response => {
          console.log(
            "🚀 ~ file: prospectingServices.js ~ line 19 ~ .then ~ response",
            response
          );
          response.data["timeStamp"] = timestamp;
          resolve(response);
        })
        .catch(e => {
          console.log(
            "🚀 ~ file: prospectingServices.js ~ line 26 ~ returnnewPromise ~ e",
            e
          );
          reject(e);
        });
    });
  },
  showContactDetails: function(contactIdsObj) {
    console.log(
      "🚀 ~ file: prospectingServices.js ~ line 35 ~ contactIdsObj",
      contactIdsObj
    );

    return new Promise((resolve, reject) => {
      let url =
        config.DOMAIN +
        config.API_VERSION +
        "organizations/" +
        store.state.auth.whoami.active_organization.id +
        "/user_contacts/show_contact_details";
      axios
        .post(url, { contactIds: contactIdsObj.contactIds.toString() })
        .then(response => {
          console.log(
            "🚀 ~ file: prospectingServices.js ~ line 43 ~ .then ~ response",
            response
          );
          if (
            response.data.message &&
            response.data.message == "Credits Not available"
          ) {
            Swal.fire({
              title: "Sufficient credits not available",
              text:
                "You don't have sufficient credits left, please upgrade your plan to view more contacts ",
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#3085d6",
              confirmButtonText: "Upgrade",
              cancelButtonText: "Cancel",
              showConfirmButton: true
            }).then(result => {
              console.log("result => ", result);
              if (result.isConfirmed) {
                let url = "/admin/billing";
                this.$router.push(url);
              }
            });

            // reject(response);
          }

          resolve(response);
        })
        .catch(e => {
          console.log(
            "🚀 ~ file: prospectingServices.js ~ line 49 ~ returnnewPromise ~ e",
            e
          );

          reject(e);
        });
    });
  },
  fetchFilterData() {
    return new Promise((resolve, reject) => {
      let url =
        config.DOMAIN +
        config.API_VERSION +
        "organizations/" +
        store.state.auth.whoami.active_organization.id +
        "/prospecting_full/filterdata";

      axios
        .get(url)
        .then(response => {
          console.log(
            "🚀 ~ file: prospectingServices.js ~ line 128 ~ .then ~ response",
            response
          );

          resolve(response);
        })
        .catch(e => {
          console.log(
            "🚀 ~ file: prospectingServices.js ~ line 133 ~ returnnewPromise ~ e",
            e
          );

          reject(e);
        });
    });
  },
  fetchCustomFilterData(filter) {
    return new Promise((resolve, reject) => {
      let url =
        config.DOMAIN +
        config.API_VERSION +
        "organizations/" +
        store.state.auth.whoami.active_organization.id +
        "/prospecting_full/filterdata?filter=" +
        filter;
      axios
        .get(url)
        .then(res => {
          resolve(res);
        })
        .catch(error => {
          console.log(
            "🚀 ~ file: prospectingServices.js ~ line 110 ~ axios.get ~ error",
            error
          );
          reject(error);
        });
    });
  },
  searchFilterData(searchData, timestamp) {
    let { searchString, field } = searchData;
    let url = `${config.DOMAIN}${config.API_VERSION}organizations/${store.state.auth.whoami.active_organization.id}/prospecting_full/filterdata/search?${field}=${searchString}`;
    return new Promise((resolve, reject) => {
      axios
        .get(url)
        .then(response => {
          console.log(
            "🚀 ~ file: prospectingServices.js ~ line 182 ~ axios.get ~ response",
            response
          );
          response.data["timeStamp"] = timestamp;
          resolve(response);
        })
        .catch(e => {
          console.log(
            "🚀 ~ file: prospectingServices.js ~ line 185 ~ axios.get ~ e",
            e
          );
          reject(e);
        });
    });
  },
  downloadSelectedProspects: function(selectedProspects) {
    console.log(
      "🚀 ~ file: prospectingServices.js ~ line 122 ~ downloadSelectedProspects ~ selectedProspects",
      selectedProspects
    );
    let url =
      config.DOMAIN +
      config.API_VERSION +
      "organizations/" +
      store.state.auth.whoami.active_organization.id +
      "/campaigncontacts/download-selected-contact?contact_ids=";
    url += selectedProspects.toString();
    console.log("🚀 ~ file: prospectingServices.js ~ line 127 ~ url", url);
    // uncommenting for now
    return new Promise((resolve, reject) => {
      axios
        .get(url)
        .then(response => {
          console.log(
            "🚀 ~ file: prospectingServices.js ~ line 132 ~ downloadSelectedProspects ~ response",
            response
          );
          var a = document.createElement("a");
          a.setAttribute("href", url);
          a.setAttribute("download", "");
          a.setAttribute("target", "_blank");
          a.click();
          resolve(response);
        })
        .catch(error => {
          console.log(
            "🚀 ~ file: prospectingServices.js ~ line 136 ~ downloadSelectedProspects ~ error",
            error
          );
          reject(error);
        });
    });
  },
  verifySingleContact(contact) {
    console.log(
      "🚀 ~ file: prospectingServices.js ~ line 159 ~ verifySingleContact ~ contact",
      contact
    );

    return new Promise((resolve, reject) => {
      let url =
        config.DOMAIN +
        config.API_VERSION +
        "organizations/" +
        store.state.auth.whoami.active_organization.id +
        "/verify_contact/verify_single_contact";
      axios
        .post(url, { contact })
        .then(response => {
          console.log("response ", response);

          resolve(response.data);
        })
        .catch(e => {
          console.log("e ", e);

          reject(e);
        });
    });
  },
  enterDataInContactTable(prospectData) {
    return new Promise((resolve, reject) => {
      let url =
        config.DOMAIN +
        config.API_VERSION +
        "organizations/" +
        store.state.auth.whoami.active_organization.id +
        "/campaigncontacts/enter-data-in-contact-table";
      axios
        .post(url, prospectData)
        .then(response => {
          console.log(
            "🚀 ~ file: prospectingServices.js ~ line 195 ~ axios.post ~ response",
            response
          );
          resolve(response);
        })
        .catch(error => {
          console.log(
            "🚀 ~ file: prospectingServices.js ~ line 198 ~ axios.post ~ error",
            error
          );
          reject(error);
        });
    });
  },
  verifyContacts(contactsArray) {
    return new Promise((resolve, reject) => {
      let url =
        config.DOMAIN +
        config.API_VERSION +
        "organizations/" +
        store.state.auth.whoami.active_organization.id +
        "/verify_contact/verify_contacts";
      axios
        .post(url, contactsArray)
        .then(response => {
          console.log(
            "🚀 ~ file: prospectingServices.js ~ line 228 ~ returnnewPromise ~ response",
            response
          );
          resolve(response.data);
        })
        .catch(e => {
          console.log("e ", e);
          reject(e);
        });
    });
  },
  getSicCodes(organizationId, searchQuery = null) {
    return new Promise((resolve, reject) => {
      let url =
        config.DOMAIN +
        config.API_VERSION +
        "organizations/" +
        organizationId +
        "/company_sic_codes/get_company_sic_codes";

      if (searchQuery) {
        url += `?search=${searchQuery}`;
      }
      axios
        .get(url)
        .then(response => {
          console.log(
            "🚀 ~ file: prospectingServices.js ~ line 248 ~ axios.get ~ response",
            response
          );
          resolve(response.data);
        })
        .catch(e => {
          console.log(
            "🚀 ~ file: prospectingServices.js ~ line 251 ~ axios.get ~ e",
            e
          );
          reject(e);
        });
    });
  },
  getProspectIndustry(organizationId, searchString = null) {
    return new Promise((resolve, reject) => {
      let url =
        config.DOMAIN +
        config.API_VERSION +
        "organizations/" +
        organizationId +
        "/prospect_industries/get_prospect_industry?limit=20";
      if (searchString) {
        url += `&search=${searchString}`;
      }
      axios
        .get(url)
        .then(response => {
          resolve(response.data);
        })
        .catch(e => {
          console.log(
            "🚀 ~ file: prospectingServices.js ~ line 251 ~ axios.get ~ e",
            e
          );
          reject(e);
        });
    });
  },
  /**
   * Service to verify the contact list or single contact
   * @param {Array} data Array of list contacts to verify
   * @returns Array of objects with verification status
   */
  verifyListContacts(contactsData) {
    return new Promise((resolve, reject) => {
      let url =
        config.DOMAIN +
        config.API_VERSION +
        "organizations/" +
        store.state.auth.whoami.active_organization.id +
        "/verify_contact/verify_list_contacts";
      axios
        .post(url, contactsData)
        .then(response => {
          console.log(
            "🚀 ~ file: prospectingServices.js ~ line 352 ~ returnnewPromise ~ response",
            response
          );
          resolve(response);
        })
        .catch(e => {
          console.log("e ", e);
          reject(e);
        });
    });
  }
};
