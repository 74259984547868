import axios from "axios";
import toastr from "toastr";
import config from "../../config.js";
import * as moment from "moment";
import companyApplicationService from "../../services/companyApplication.js";

export default {
  namespaced: true,
  state: {
    companyPostings: [],
    selectedCompanyPost: {}
  },
  getters: {
    selectedCompanyPost: state => {
      return state.selectedCompanyPost;
    }
  },
  mutations: {
    setCompanyPost: (state, selectedCompanyPost) => {
      state.selectedCompanyPost = selectedCompanyPost;
    },
    removeCompanyPost: state => {
      state.selectedCompanyPost = {};
    }
  },
  actions: {
    setCompanyPost: async ({ commit }, id) => {
      let result = await companyApplicationService.getPostDetailsById(id);
      commit("setCompanyPost", result.data);
    },
    removeCompanyPost: async ({ commit }) => {
      commit("removeCompanyPost");
    }
  }
};
