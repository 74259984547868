import axios from "axios";
import config from "../config.js";
import store from "../store";

export default {
  getStreams: function(params) {
    return new Promise((resolve, reject) => {
      let apiUrl = config.DOMAIN + config.API_VERSION + "stream?";

      params.filter(el => {
        apiUrl += el.name + "=" + el.value + "&";
      });

      axios
        .get(apiUrl)
        .then(response => {
          resolve(response);
        })
        .catch(e => {
          reject(e);
        }); //axios
    });
  },
  // this will return single stream object
  getStream: function(data) {
    // http://localhost:8000/api/v1/stream?stream_ids=160
    return new Promise((resolve, reject) => {
      let url =
        config.DOMAIN +
        config.API_VERSION +
        "stream?stream_ids=" +
        data.stream_id;
      if (!!data.needPublic) url += "&publicstreamview=1";
      if (!!data.group_id) url += "&group_id=" + data.group_id;
      console.log("data:", data);
      axios
        .get(url)
        .then(response => {
          resolve(response.data.streams[0]);
        })
        .catch(e => {
          reject(e);
        }); //axios
    });
  },

  getPost: function(url) {
    return new Promise((resolve, reject) => {
      axios
        .get(url)
        .then(response => {
          console.log("getPost", response);
          resolve(response);
        })
        .catch(e => {
          console.log("Error: ", e);
        }); //axios
    });
  },
  streamRead: function(url, data) {
    console.log("Inside service stream");

    return new Promise((resolve, reject) => {
      axios
        .post(url, data)
        .then(response => {
          console.log("streamRead", response);
          resolve(response);
        })
        .catch(e => {
          console.log("Error: ", e);
        }); //axios
    });
  },
  getPermalink: function(url, data) {
    console.log("Insied getPermalink");

    return new Promise((resolve, reject) => {
      axios
        .post(url, data)
        .then(response => {
          console.log("permalink", response);
          resolve(response);
        })
        .catch(e => {
          console.log("Error: ", e);
        }); //axios
    });
  },

  downloadStreamPost: function(url) {
    console.log("Inside downloadStreamPost");

    return new Promise((resolve, reject) => {
      axios
        .get(url)
        .then(response => {
          console.log("permalink", response);
          resolve(response);
        })
        .catch(e => {
          console.log("Error: ", e);
        }); //axios
    });
  },

  getSummaryViewOfStreamsByFilter: function(filterString) {
    return new Promise((resolve, reject) => {
      let organization_id = store.state.auth.whoami.active_organization
        ? store.state.auth.whoami.active_organization.id
        : null;

      var url =
        config.DOMAIN +
        config.API_VERSION +
        "stream?&summary_view=true" +
        "&organization_id=" +
        organization_id +
        "&per_page=500";
      if (filterString) {
        url += filterString;
      }

      axios
        .get(url)
        .then(response => {
          resolve(response.data.streams);
        })
        .catch(e => {
          console.log("Error: ", e);
        }); //axios
    });
  },

  deleteStream: function(data) {
    return new Promise((resolve, reject) => {
      var url = config.DOMAIN + config.API_VERSION + "stream/" + data.streamId;
      axios
        .delete(url)
        .then(response => {
          console.log("Deleted", response);
          resolve(response);
        })
        .catch(e => {
          console.log("Error: ", e);
          reject(e);
        }); //axios
    });
  },

  getHubsOfStream: function(data) {
    return new Promise((resolve, reject) => {
      let url =
        config.DOMAIN + config.API_VERSION + "stream/" + data.id + "/get_hubs";

      axios
        .get(url)
        .then(response => {
          resolve(response);
        })
        .catch(e => {
          reject(e);
        }); //axios
    });
  },

  getContentTypesOfPost: function(data) {
    return new Promise((resolve, reject) => {
      let url =
        config.DOMAIN +
        config.API_VERSION +
        "stream/organization/" +
        data.organization_id +
        "/post-content-types";

      axios
        .get(url)
        .then(response => {
          resolve(response);
        })
        .catch(e => {
          reject(e);
        }); //axios
    });
  }
};
