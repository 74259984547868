import axios from "axios";
import config from "../config";

let userId = "";
let authToken = "";
export default {
  fetchUserChannel: function(teamId) {
    return new Promise((resolve, reject) => {
      let url;
      if (teamId) {
        url =
          config.DOMAIN +
          config.API_VERSION +
          "chat/get_user_channels?team_id=" +
          teamId;
      } else {
        url = config.DOMAIN + config.API_VERSION + "chat/get_user_channels";
      }

      axios
        .get(url)
        .then(response => {
          resolve(response);
        })
        .catch(e => {
          reject(e);
        }); //axios
    });
  },
  fetchChannelMessages: function(data) {
    return new Promise((resolve, reject) => {
      let url =
        config.DOMAIN +
        config.API_VERSION +
        "chat/get_channel_posts?channel_id=" +
        data.channel_id +
        "&page_no=" +
        data.page_no;

      axios
        .get(url)
        .then(response => {
          resolve(response);
        })
        .catch(e => {
          reject(e);
        }); //axios
    });
  },
  fetchMyProfile: function() {
    return new Promise((resolve, reject) => {
      let url = config.DOMAIN + config.API_VERSION + "chat/get_user_status";

      axios
        .get(url)
        .then(response => {
          resolve(response);
        })
        .catch(e => {
          reject(e);
        }); //axios
    });
  },
  postChannelMessage: function(data) {
    return new Promise((resolve, reject) => {
      let url =
        config.DOMAIN + config.API_VERSION + "chat/create_post_in_channel";

      axios
        .post(url, data)
        .then(response => {
          resolve(response);
        })
        .catch(e => {
          reject(e);
        });
    });
  },
  updateChannelMessage: function(data) {
    return new Promise((resolve, reject) => {
      let url = config.DOMAIN + config.API_VERSION + "chat/edit_channel_post";

      axios
        .post(url, data)
        .then(response => {
          resolve(response);
        })
        .catch(e => {
          reject(e);
        });
    });
  },
  deleteChannelMessage: function(data) {
    return new Promise((resolve, reject) => {
      let url = config.DOMAIN + config.API_VERSION + "chat/delete_channel_post";

      axios
        .post(url, data)
        .then(response => {
          resolve(response);
        })
        .catch(e => {
          reject(e);
        });
    });
  },
  fetchUserTeams: function() {
    return new Promise((resolve, reject) => {
      let url = config.DOMAIN + config.API_VERSION + "chat/get_user_teams";

      axios
        .get(url)
        .then(response => {
          resolve(response);
        })
        .catch(e => {
          reject(e);
        }); //axios
    });
  },
  fetchSearchedPosts: function(data) {
    return new Promise((resolve, reject) => {
      let url =
        config.DOMAIN +
        config.API_VERSION +
        "chat/search_post?team_id=" +
        data.team_id +
        "&channel_id=" +
        data.channel_id;
      if (data?.post) {
        url += "&post=" + data.post;
      }
      axios
        .get(url)
        .then(response => {
          resolve(response);
        })
        .catch(e => {
          reject(e);
        }); //axios
    });
  },
  searchTeam: function(teamName) {
    return new Promise((resolve, reject) => {
      let url =
        config.DOMAIN +
        config.API_VERSION +
        "chat/search_team_by_name?team_name=" +
        teamName;

      axios
        .get(url)
        .then(response => {
          resolve(response);
        })
        .catch(e => {
          reject(e);
        }); //axios
    });
  },
  searchChannel: function(data) {
    return new Promise((resolve, reject) => {
      let url =
        config.DOMAIN +
        config.API_VERSION +
        "chat/search_channel_by_name?team_id=" +
        data.teamId +
        "&channel_name=" +
        data.channelName;

      axios
        .get(url)
        .then(response => {
          resolve(response);
        })
        .catch(e => {
          reject(e);
        }); //axios
    });
  },
  updateUserStatus: function(data) {
    return new Promise((resolve, reject) => {
      let url = config.DOMAIN + config.API_VERSION + "chat/update_user_status";

      axios
        .post(url, data)
        .then(response => {
          resolve(response);
        })
        .catch(e => {
          reject(e);
        });
    });
  },
  pinChannelMessage: function(data) {
    return new Promise((resolve, reject) => {
      let url = config.DOMAIN + config.API_VERSION + "chat/pin_channel_post";

      axios
        .post(url, data)
        .then(response => {
          resolve(response);
        })
        .catch(e => {
          reject(e);
        });
    });
  },
  unpinChannelMessage: function(data) {
    return new Promise((resolve, reject) => {
      let url = config.DOMAIN + config.API_VERSION + "chat/unpin_channel_post";

      axios
        .post(url, data)
        .then(response => {
          resolve(response);
        })
        .catch(e => {
          reject(e);
        });
    });
  },
  uploadFile: function(data) {
    return new Promise((resolve, reject) => {
      let url = config.DOMAIN + config.API_VERSION + "chat/upload_file";

      axios
        .post(url, data)
        .then(response => {
          resolve(response);
        })
        .catch(e => {
          reject(e);
        });
    });
  },
  downloadFile: function(fileId) {
    return new Promise((resolve, reject) => {
      let url;
      if (fileId) {
        url =
          config.DOMAIN +
          config.API_VERSION +
          "chat/download_file?file_id=" +
          fileId;
      } else {
        url = config.DOMAIN + config.API_VERSION + "chat/get_user_channels";
      }

      axios
        .get(url)
        .then(response => {
          resolve(response);
        })
        .catch(e => {
          reject(e);
        }); //axios
    });
  },
  saveChannelMessage: function(data) {
    return new Promise((resolve, reject) => {
      let url = config.DOMAIN + config.API_VERSION + "chat/save_channel_post";

      axios
        .post(url, data)
        .then(response => {
          resolve(response);
        })
        .catch(e => {
          reject(e);
        });
    });
  },
  removeSavedChannelMessage: function(postId) {
    return new Promise((resolve, reject) => {
      let url =
        config.DOMAIN + config.API_VERSION + "chat/delete_saved_channel_post";

      axios
        .post(url, postId)
        .then(response => {
          resolve(response);
        })
        .catch(e => {
          reject(e);
        });
    });
  },
  chatAuth: () => {
    return new Promise((resolve, reject) => {
      let url = config.DOMAIN + config.API_VERSION + "chat";

      axios
        .get(url)
        .then(response => {
          resolve(response);
        })
        .catch(e => {
          reject(e);
        }); //axios
    });
  },
  getTeamMembers: teamId => {
    return new Promise((resolve, reject) => {
      let url =
        config.DOMAIN +
        config.API_VERSION +
        "chat/get_team_member?team_id=" +
        teamId;

      axios
        .get(url)
        .then(response => {
          resolve(response);
        })
        .catch(e => {
          reject(e);
        }); //axios
    });
  },
  createDirectMessage: userId => {
    return new Promise((resolve, reject) => {
      let url =
        config.DOMAIN + config.API_VERSION + "chat/create_direct_message";
      const data = {
        mm_user_id: userId
      };
      axios
        .post(url, data)
        .then(response => {
          resolve(response);
        })
        .catch(e => {
          reject(e);
        });
    });
  },
  viewChannel: channelId => {
    return new Promise((resolve, reject) => {
      let url = config.DOMAIN + config.API_VERSION + "chat/view_channel";
      const data = {
        channel_id: channelId
      };
      axios
        .post(url, data)
        .then(response => {
          resolve(response);
        })
        .catch(e => {
          reject(e);
        });
    });
  },
  getUserDetailsByMmUserId: mm_user_id => {
    return new Promise((resolve, reject) => {
      let url =
        config.DOMAIN +
        config.API_VERSION +
        "chat/get_user_name_by_mm_user_id?mm_user_id=" +
        mm_user_id;

      axios
        .get(url)
        .then(response => {
          resolve(response);
        })
        .catch(e => {
          reject(e);
        }); //axios
    });
  }
};
