export default [
  // {
  //   path: "/:organization_slug/salesperson/apply",
  //   name: "SalespersonLogin",
  //   component: () =>
  //     import("../components/Workspace/SalesPersonApplication/SignUp.vue")
  // },
  {
    path: "/:organization_slug/admin/salesperson/step1",
    name: "SalespersonRegistrationStep1",
    meta: { role_id: 2 },
    component: () =>
      import(
        "../components/Workspace/SalesPersonApplication/Registration/Step1.vue"
      )
  },
  {
    path: "/:organization_slug/admin/salesperson/step2",
    name: "SalespersonRegistrationStep2",
    meta: { role_id: 2 },
    component: () =>
      import(
        "../components/Workspace/SalesPersonApplication/Registration/Step2.vue"
      )
  },
  {
    path: "/:organization_slug/admin/salesperson/step2",
    name: "SalespersonRegistrationStep2Skills",
    meta: { role_id: 2 },
    component: () =>
      import(
        "../components/Workspace/SalesPersonApplication/Registration/StepExperience"
      )
  },
  {
    path: "/:organization_slug/admin/salesperson/step2",
    name: "SalespersonRegistrationStep2Industry",
    meta: { role_id: 2 },
    component: () =>
      import(
        "../components/Workspace/SalesPersonApplication/Registration/StepIndustry"
      )
  },
  {
    path: "/:organization_slug/admin/salesperson/step2",
    name: "SalespersonRegistrationStep2Role",
    meta: { role_id: 2 },
    component: () =>
      import(
        "../components/Workspace/SalesPersonApplication/Registration/StepRole"
      )
  },
  {
    path: "/:organization_slug/admin/salesperson/step3",
    name: "SalespersonRegistrationStep3",
    meta: { role_id: 2 },
    component: () =>
      import(
        "../components/Workspace/SalesPersonApplication/Registration/Step3.vue"
      )
  },
  {
    path: "/:organization_slug/admin/salesperson/step4",
    name: "SalespersonRegistrationStep4",
    meta: { role_id: 2 },
    component: () =>
      import(
        "../components/Workspace/SalesPersonApplication/Registration/Step4.vue"
      )
  },
  {
    path: "/:organization_slug/admin/salesperson/confirm-email",
    name: "SalespersonRegistrationConfirmEmail",
    meta: { role_id: 2 },
    component: () =>
      import(
        "../components/Workspace/SalesPersonApplication/Registration/ConfirmEmail.vue"
      )
  },
  {
    path: "/salesperson/review",
    name: "SalespersonApplicationReview",
    meta: { role_id: 2 },
    component: () =>
      import(
        "../components/Workspace/SalesPersonApplication/ApplicationReview.vue"
      )
  },
  {
    path: "/:organization_slug/admin/salesperson/update-password",
    name: "SalespersonUpdatePassword",
    meta: { role_id: 2 },
    component: () =>
      import(
        "../components/Workspace/SalesPersonApplication/UserSettings/UpdateOldPassword"
      )
  },
  {
    path: "/:organization_slug/admin/salesperson/step2-experience",
    name: "SalespersonStep2Experience",
    meta: { role_id: 2 },
    component: () =>
      import(
        "../components/Workspace/SalesPersonApplication/Registration/StepExperience"
      )
  },
  {
    path: "/:organization_slug/admin/salesperson/profile/:salesperson_id",
    name: "SalespersonDetails",
    meta: { role_id: 2 },
    component: () =>
      import(
        "../components/Workspace/SalesPersonApplication/Profile/SalePersonProfile"
      )
  },
  {
    path: "/salesperson/profile/view/:salesperson_id",
    name: "SalespersonViewDetails",
    meta: { role_id: 2 },
    component: () =>
      import(
        "../components/Workspace/SalesPersonApplication/Profile/SalePersonProfileView"
      )
  },
  {
    path: "/:organization_slug/admin/salesperson/profile/edit/:salesperson_id",
    name: "SalespersonEditDetails",
    meta: { role_id: 2 },
    component: () =>
      import(
        "../components/Workspace/SalesPersonApplication/Profile/SalePersonProfile"
      )
  },
  {
    path: "/:organization_slug/admin/salesperson/dashboard",
    name: "FreelancerDashboard",
    meta: { role_id: 2 },
    component: () =>
      import(
        "../components/Workspace/SalesPersonApplication/Dashboard/Dashboard.vue"
      )
  },
  {
    path: "/:organization_slug/admin/salesperson/postings",
    name: "FreelancerPostings",
    meta: { role_id: 2 },
    component: () =>
      import(
        "../components/Workspace/SalesPersonApplication/PostingSearch/Postings.vue"
      )
  },
  {
    path:
      "/:organization_slug/admin/salesperson/posting/profile/:company_posting_id",
    name: "SalespersonPostingProfile",
    meta: { role_id: 2 },
    component: () =>
      import(
        "../components/Workspace/SalesPersonApplication/PostingSearch/PostingProfile.vue"
      )
  },
  {
    path: "/:organization_slug/admin/salesperson/chats",
    name: "FreelancerChats",
    meta: { role_id: 2 },
    component: () =>
      import(
        "../components/Workspace/SalesPersonApplication/Chats/FreelancerChats.vue"
      )
  },
  {
    path: "/:organization_slug/admin/salesperson/contract/:contract_id",
    name: "FreelancerContract",
    meta: { role_id: 2 },
    component: () =>
      import(
        "../components/Workspace/Contract/SalespersonView/ViewContractReadOnly.vue"
      )
  },
  {
    path: "/salesperson/accept/contract",
    name: "AcceptContract",
    meta: { role_id: 2 },
    component: () =>
      import("../components/Workspace/Contract/SalespersonView/AcceptOffer.vue")
  },
  {
    path: "/:organization_slug/admin/salesperson/contracts",
    name: "FreelancerContractsMain",
    meta: { role_id: 2 },
    component: () =>
      import(
        "../components/Workspace/SalesPersonApplication/Contract/ContractsMain.vue"
      )
  },
  {
    path: "/:organization_slug/admin/salesperson/timer",
    name: "FreelancerTimerMain",
    meta: { role_id: 2 },
    component: () =>
      import(
        "../components/Workspace/SalesPersonApplication/Timer/TimerMain.vue"
      )
  },
  {
    path: "/:organization_slug/admin/salesperson/timer/report/client",
    name: "FreelancerTimerMain",
    meta: { role_id: 2 },
    component: () =>
      import(
        "../components/Workspace/SalesPersonApplication/TimerReport/CompanyReportMain.vue"
      )
  },
  {
    path: "/:organization_slug/admin/salesperson/timer/report/contract",
    name: "ContractReportMain",
    meta: { role_id: 2 },
    component: () =>
      import(
        "../components/Workspace/SalesPersonApplication/TimerReport/ContractReportMain.vue"
      )
  },
  {
    path: "/:organization_slug/admin/salesperson/timer/report/weekly",
    name: "WeeklyReportMain",
    meta: { role_id: 2 },
    component: () =>
      import(
        "../components/Workspace/SalesPersonApplication/TimerReport/WeeklyReportMain.vue"
      )
  },
  {
    path: "/:organization_slug/admin/salesperson/payment-list",
    name: "SalespersonPaymentList",
    meta: { role_id: 2 },
    component: () =>
      import("../components/Workspace/SalesPersonApplication/Payment/List.vue")
  },
  {
    path: "/:organization_slug/admin/salesperson/payment/view/:payment_id",
    name: "ViewPayment",
    meta: { role_id: 2 },
    component: () =>
      import(
        "../components/Workspace/SalesPersonApplication/Payment/ViewPayment.vue"
      )
  },
  {
    path: "/:organization_slug/salesperson/join",
    name: "SalespersonSignUp",
    meta: { role_id: 2 },
    component: () =>
      import("../components/Workspace/SalesPersonApplication/SignUp.vue")
  },
  {
    path: "/:organization_slug/salesperson/login",
    name: "SalespersonLoginTest",
    meta: { role_id: 2 },
    component: () => import("../components/Auth/LoginWrapper.vue")
  },
  {
    path: "/:organization_slug/salesperson/forgot_password",
    name: "SalespersonForgotPassword",
    meta: { role_id: 2 },
    component: () => import("../components/Auth/ForgotPassword")
  }
];
