import axios from "axios";
import toastr from "toastr";
import config from "../../config.js";
import router from "../../router";
import teamServices from "../../services/teams";

export default {
  namespaced: true,
  state: {
    team: {
      message: "",
      organization_role_id: 3,
      group_role_id: 4,
      organization_category_usertype_id: "",
      email: ""
    },
    invites: [],
    group_invites: [],
    inviteSingleMember: {
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      appointment_scheduling_url: "",
      organization_role_id: 3,
      group_role_id: 4,
      organization_category_usertype_id: "",
      company_name: "",
      title: "",
      linkedin_profile_url: "",
      twitter_profile_url: "",
      facebook_profile_url: "",
      company: {},
      isSendingInvite: false
    }
  },

  getters: {},

  mutations: {
    setInvites(state, data) {
      Vue.set(state, "invites", data);
    },
    createInvite(state, data) {
      state.invites.splice(0, 0, data);
    },
    deleteInvite(state, inviteId) {
      const index = state.invites.findIndex(i => i.id === inviteId);
      state.invites.splice(index, 1);
    },
    clearTeam(state, data) {
      state.team = data;
    },
    clearSingleTeam(state, data) {
      state.inviteSingleMember = data;
    },
    updateInviteUser(state, data) {
      var index = state.invites.findIndex(function(invite) {
        return invite.email == data.email;
      });
      state.invites[index] = data;
    },
    setIsSendingInvite(state, data) {
      Vue.set(state, "isSendingInvite", data);
    }
  },

  actions: {
    inviteUserToTeam({ state, commit, dispatch, rootState }, data) {
      return new Promise(function(resolve, reject) {
        console.log("data -- ", data);
        commit("setIsSendingInvite", true);
        let team_input = data.team;
        team_input.organization_id = data.organization_id;
        team_input.organization_group_id = data.organization_group_id;
        teamServices
          .inviteUsersToOrgOrGroup(team_input)
          .then(response => {
            resolve(response);
            if (data.inviteType == "multiple") {
              $.each(response.data, function(key, value) {
                let invite = value;
                invite.organization_role_id = state.team.organization_role_id;
                invite.group_role_id = state.team.group_role_id;
                invite.is_invite_accepted = 0;
                commit("createInvite", invite);
              });
              commit("clearTeam", {});
              commit("setIsSendingInvite", false);
            } else if (data.inviteType == "single") {
              if (state.inviteSingleMember.organization_role_id == 9)
                data.hideSuccessNotification = true;

              $.each(response.data, function(key, value) {
                let invite = value;
                invite.organization_role_id =
                  state.inviteSingleMember.organization_role_id;
                invite.group_role_id = state.inviteSingleMember.group_role_id;
                invite.is_invite_accepted = 0;
                commit("createInvite", invite);
              });
              commit("clearSingleTeam", {
                first_name: "",
                last_name: "",
                email: "",
                phone: "",
                organization_role_id: 3,
                group_role_id: 4,
                organization_category_usertype_id: "",
                company: {}
              });
              commit("setIsSendingInvite", false);
            }
            console.log(
              " showSuccessNotification => ",
              data.hideSuccessNotification
            );
            if (!!data.hideSuccessNotification) {
              console.log("Does not show invitation notificatio.");
            } else {
              toastr.success("Invitation sent successfully.", "", {
                timeOut: 5000
              });
              commit("setIsSendingInvite", false);
            }
          })
          .catch(e => {
            console.log("failed invitation -->", e);
            toastr.success("", e.response.data.message, {
              timeOut: config.TOASTR_TIMEOUT
            });
            commit("setIsSendingInvite", false);
            resolve(e.response.data.message);
          });
      }); //promise
    }, // inviteUserToTeam

    getAllInvites({ state, commit, rootState }) {
      return new Promise(function(resolve, reject) {
        console.log("getAllInvites() Called");
        let url =
          config.DOMAIN +
          config.API_VERSION +
          "organizations/" +
          rootState.auth.whoami.active_organization.id +
          /*"/categories/" +
              rootState.auth.whoami.active_organization_category_id +*/
          "/invite";
        axios
          .get(url)
          .then(response => {
            console.log("Invites GET API:: ", response.data);
            commit("setInvites", response.data.invites);
            resolve(response);
          })
          .catch(e => {
            console.log("Error: ", e);
          }); //axios
      }); //axios
    },

    acceptInvite({ state, commit, rootState }, data) {
      return new Promise(function(resolve, reject) {
        axios
          .get(
            config.DOMAIN +
              config.API_VERSION +
              "users/accept_invite?email=" +
              data.email +
              "&token=" +
              data.token
          )
          .then(
            response => {
              toastr.success(response.data.message, " ", {
                timeOut: config.TOASTR_TIMEOUT
              });

              if (response.data.group) router.push("user_dashboard/workspaces");
              else router.push("organizations");
              resolve({ status: "ok" });
            },

            error => {
              toastr.success("", error.response.data.message, {
                timeOut: config.TOASTR_TIMEOUT
              });
            }
          )
          .catch(e => {
            toastr.error("Something went wrong! ", "Error:  ", {
              timeOut: config.TOASTR_TIMEOUT
            });
            resolve({ status: "cancel" });
          }); //axios
      }); // promise
    },

    deleteInvite({ state, commit, rootState, dispatch }, data) {
      console.log("data ---,", data);
      axios
        .delete(
          config.DOMAIN +
            config.API_VERSION +
            "organizations/" +
            rootState.auth.whoami.active_organization.id +
            /*"/categories/" +
            rootState.auth.whoami.active_organization_category_id +*/
            "/invite/" +
            data
        )
        .then(
          response => {
            console.log("Invites GET API:: ", response.data);
            commit("deleteInvite", response.data);
            toastr.success("Invite Deleted successfully", "", {
              timeOut: 5000
            });
            dispatch("auth/whoAmI", null, { root: true });
          },
          error => {
            toastr.error(error.response.data.message, "", {
              timeOut: 1000
            });
          }
        )
        .catch(e => {
          console.log("Error: ", e);
        }); //axios
    },
    updaterole({ state, commit, rootState }, data) {
      console.log("data ---,", data);
      new Promise(function(resolve, reject) {
        axios
          .put(
            config.DOMAIN +
              config.API_VERSION +
              "organizations/" +
              rootState.auth.whoami.active_organization.id +
              "/invite/updaterole",
            data
          )
          .then(response => {
            resolve(response);
            commit("updateInviteUser", response.data);
            /*router.push("/more/organizations/members");

          console.log("Invites GET API:: ", response.data);
          toastr.success("Role Updated successfully", "", {
            timeOut: 5000
          });*/
          })
          .catch(e => {
            console.log("Error: ", e);
          }); //axios
      }); // promise
    },
    setClearInvite({ state, commit, rootState }) {
      commit("clearSingleTeam", {
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        organization_role_id: 3,
        group_role_id: 4,
        organization_category_usertype_id: "",
        company: {}
      });
    },
    setClearTeam({ state, commit, rootState }) {
      commit("clearTeam", {
        message: "",
        organization_role_id: 3,
        group_role_id: 4,
        organization_category_usertype_id: "",
        email: ""
      });
    },
    setIsSendingInvite({ state, commit, rootState }, data) {
      commit("setIsSendingInvite", data);
    }
  }
};
