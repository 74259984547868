import store from "../store";
import Swal from "sweetalert2";
import router from "../router";

function checkStatus() {
  Swal.fire({
    title: "Upgrade Plan",
    text: "Upgrade your plan to use these features",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Upgrade"
  }).then(result => {
    if (result.isConfirmed) {
      router.push({
        name: "BillingWrapper",
        params: { selectedTab: "Plan" }
      });
    }
  });
}

export default [
  {
    path: "/:organization_slug/admin/integrations",
    name: "IntegrationsMain",
    component: () => import("../components/Integrations/Main.vue"),
    beforeEnter: (to, from, next) => {
      if (
        store.state.auth.whoami.subscription_allowed_features.menu_options
          .settings_integration == true
      ) {
        next();
      } else {
        checkStatus();
      }
    }
  },

  {
    path: "/:organization_slug/admin/integrations/add",
    name: "IntegrationAddForm",
    component: () =>
      import("../components/Integrations/MenuComponents/Form.vue"),
    beforeEnter: (to, from, next) => {
      if (
        store.state.auth.whoami.subscription_allowed_features.menu_options
          .settings_add_integration == true
      ) {
        next();
      } else {
        checkStatus();
      }
    }
  }
];
