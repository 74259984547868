import axios from "axios";
import toastr from "toastr";
import config from "../../config.js";
import * as moment from "moment";
import contentLibraryServices from "../../services/contentLibrary";

export default {
  namespaced: true,
  state: {
    selectedContentTypeId: 0,
    selectedStatusId: null,
    contentTypeCount: {
      SocialMediaPosts: 0,
      Images: 0,
      Videos: 0,
      Messages: 0,
      Blogs: 0,
      Pdf: 0,
      Audio: 0
    },
    streamsInitialCall: true,
    contentData: [],
    contentLibraryFor: null,
    uploadedFiles: [],
    uploadFileId: null,
    uploadPercentage: 0,
    uploadedFileIds: [],
    toDisplayPopup: false
  },

  getters: {},

  mutations: {
    setUploadedFiles(state, data) {
      state.uploadedFiles = data;
    },
    setselectedContentType(state, data) {
      state.selectedContentTypeId = data;
    },
    setContentTypeCount(state, data) {
      if (data) {
        state.contentTypeCount[data.contentType] = data.count;
      }
    },
    setStreamsInitialCall(state, data) {
      state.streamsInitialCall = data;
    },
    setSelectedStatusId(state, data) {
      state.selectedStatusId = data;
    },
    setContentData(state, data) {
      state.contentData = [...state.contentData, ...data];

      state.contentData = state.contentData.filter(
        (obj, index, self) =>
          index === self.findIndex(el => el["id"] === obj["id"])
      );
    },
    setContentLibraryFor(state, data) {
      state.contentLibraryFor = data;
    },
    updateFileProgress(state, data) {
      // const index = state.uploadedFiles.findIndex(
      //   uploadedFile => uploadedFile.id === data.id
      // );
      state.uploadPercentage = data;
    },
    setUploadFileId(state, data) {
      state.uploadFileId = data;
    },
    addUploadedFileId(state, data) {
      state.uploadedFileIds.push(data);
    },
    setToDisplayPopup(state, data) {
      state.toDisplayPopup = data;
    },
    clearContentData(state) {
      state.contentData = [];
    }
  },

  actions: {
    // getCountOfContenttypes({ state, commit, rootState }) {
    //   var url = config.DOMAIN +
    //       config.API_VERSION +
    //       "stream?&posttype_id=3&group_ids=" +
    //       rootState.auth.activeOrganizationGroup.id;
    // }
    getContentByType({ state, commit, rootState, dispatch }, contentTypeData) {
      console.log(
        "Inside getContentByType-------------- ==================",
        state.contentLibraryFor
      );

      return new Promise((resolve, reject) => {
        var url = config.DOMAIN + config.API_VERSION + "stream";

        if (state.contentLibraryFor == "organizations") {
          url += "?&organization_id=" + rootState.auth.activeOrganization.id;
        } else if (state.contentLibraryFor == "groups") {
          url +=
            "?&group_ids=" +
            rootState.auth.activeOrganizationGroup.id +
            "&per_page=30";
        } else if (state.contentLibraryFor == "user") {
          url +=
            "?&organization_user_id=" +
            rootState.auth.whoami.organization_user.id +
            "&mystreams=1";
        }

        if (state.selectedStatusId)
          url += "&status_ids=" + state.selectedStatusId;

        if (contentTypeData.notStreamIds) {
          var filterString = contentTypeData.notStreamIds.join(",");
          url += "&not_stream_ids=" + filterString;
        }

        if (
          rootState.auth.whoami.organization_user.organization_role_id == "8"
        ) {
          url += "&streams_of=" + rootState.auth.whoami.organization_user.id;
        }

        if (contentTypeData.type == "count")
          url += "&per_page=500&summary_view=true";
        else url += "&per_page=30";

        url +=
          "&mystreams=1&organization_content_type_id=" +
          contentTypeData.contentTypeId;

        console.log("url", url);
        axios
          .get(url)
          .then(response => {
            if (contentTypeData.type != "count") {
              if (!contentTypeData.notStreamIds) {
                commit("streams/setStreams", response.data.streams, {
                  root: true
                });
              } else {
                // console.log(rootState.streams.streams);
                // console.log(response.data.streams);
                let appendedData = [
                  ...rootState.streams.streams,
                  ...response.data.streams
                ];
                commit("streams/setStreams", appendedData, {
                  root: true
                });
              }
            }
            let data = {
              streams: response.data.streams,
              contentTypeId: contentTypeData.contentTypeId
            };
            console.log(data);
            resolve(response.data.streams);
            if (contentTypeData.type == "count") {
              console.log("in count");
              console.log(data);
              dispatch("getContentTypeCount", data);
            }
          })
          .catch(e => {
            console.log("Error: ", e);
          });
      });
    },
    getContentByStatus({ state, commit, rootState, dispatch }, statusId) {
      if (state.contentLibraryFor == "organizations") {
        var url =
          config.DOMAIN +
          config.API_VERSION +
          "stream?&organization_id=" +
          rootState.auth.activeOrganization.id +
          "&status_ids=" +
          statusId +
          "&per_page=30";
      } else if (state.contentLibraryFor == "groups") {
        var url =
          config.DOMAIN +
          config.API_VERSION +
          "stream?&group_ids=" +
          rootState.auth.activeOrganizationGroup.id +
          "&status_ids=" +
          statusId +
          "&per_page=30";
      } else {
        var url =
          config.DOMAIN +
          config.API_VERSION +
          "stream?&organization_user_id=" +
          rootState.auth.whoami.organization_user.id +
          "&status_ids=" +
          statusId +
          "&per_page=30";
      }
      if (rootState.auth.whoami.organization_user.organization_role_id == "8") {
        url += "&streams_of=" + rootState.auth.whoami.organization_user.id;
      }
      axios
        .get(url)
        .then(response => {
          commit("streams/setStreams", response.data.streams, {
            root: true
          });
          let data = {
            streams: response.data.streams,
            contentTypeId: state.selectedContentTypeId
          };
          console.log(data);
          // if(contentTypeData.type == "count")
          dispatch("getContentTypeCount", response.data.streams);
        })
        .catch(e => {
          console.log("Error: ", e);
        });
      // url += "&mystreams=1";
    },
    getContentTypeCount({ state, commit, rootState, dispatch }, data) {
      if (data) {
        let contentCount = 0;
        console.log(data);
        if (data.streams) {
          console.log("data.streams", data.streams);
          contentCount = data.streams.length;
          console.log("contentCount", contentCount);
        }
        // console.log(contentCount);
        if (contentCount >= 0) {
          let contentTypes = [
            { id: 1, name: "Social Media Posts" },
            { id: 2, name: "Images" },
            { id: 3, name: "Videos" },
            { id: 4, name: "Announcements" },
            { id: 5, name: "Feeds" },
            { id: 6, name: "Blogs" },
            { id: 7, name: "Pdf" },
            { id: 8, name: "Audio" }
          ];

          // reusable code created, no need to add new block for each contentType
          contentTypes.forEach(el => {
            if (el.id == data.contentTypeId) {
              let countdata = {
                count: contentCount,
                contentType: el.name.replace(/ /g, "")
              };
              commit("setContentTypeCount", countdata);
            }
          });
        }
      }
    },
    setselectedContentType({ state, commit, dispatch }, data) {
      console.log(data);
      commit("setselectedContentType", data);
      commit("setStreamsInitialCall", false);
      if (data == 2 || data == 3) {
        commit("clearContentData");
        dispatch("getContent", {
          contentTypeId: data,
          type: null,
          status_id: state.selectedStatusId
        });
      } else if (data == 7) {
        commit("clearContentData");
        dispatch("getContent", {
          contentTypeId: data,
          type: null,
          status_id: state.selectedStatusId
        });
      } else if (data == 8) {
        commit("clearContentData");
        dispatch("getContent", {
          contentTypeId: data,
          type: null,
          status_id: state.selectedStatusId
        });
      } else {
        dispatch("getContentByType", { contentTypeId: data });
      }
    },
    setSelectedStatusId({ state, commit, dispatch }, data) {
      commit("setSelectedStatusId", data);
    },
    getContent({ state, commit, rootState, dispatch }, data) {
      return new Promise((resolve, reject) => {
        console.log("DATA--> ", data);
        // {contentTypeId: 2, type: "count"}
        data.organization_id = rootState.auth.whoami.active_organization.id;
        if (state.contentLibraryFor == "groups") {
          data.organization_group_id =
            rootState.auth.whoami.active_organization_group.id;
        } else if (state.contentLibraryFor == "organizations") {
          data.organization_id = rootState.auth.whoami.active_organization.id;
        } else if (state.contentLibraryFor == "user") {
          data.organization_user_id =
            rootState.auth.whoami.organization_user.id;
        }

        contentLibraryServices
          .getImageVideoContentTypes(data)
          .then(response => {
            if (data.type == "count") {
              commit("setContentTypeCount", {
                contentType:
                  data.contentTypeId == 2
                    ? "Images"
                    : data.contentTypeId == 3
                    ? "Videos"
                    : data.contentTypeId == 7
                    ? "Pdf"
                    : data.contentTypeId == 8
                    ? "Audio"
                    : null,
                count: response.data
              });
            } else {
              commit("setContentData", response.data);
              resolve(response.data);
            }
          });
      });
    },
    setContentLibraryFor({ state, commit, rootState, dispatch }, data) {
      commit("setContentLibraryFor", data);
    },
    setUploadedFiles({ state, commit, rootState, dispatch }, data) {
      commit("setUploadedFiles", data);
    },
    uploadMedia({ state, commit, rootState, dispatch }, data) {
      const configData = {
        onUploadProgress: function(progressEvent) {
          var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          commit("updateFileProgress", percentCompleted);
          console.log(percentCompleted);
        }
      };
      let fileData = new FormData();
      fileData.append("file", data.file);
      console.log(fileData);
      commit("setUploadFileId", data.id);
      let uploadUrl =
        config.DOMAIN +
        config.API_VERSION +
        "organizations/" +
        rootState.auth.activeOrganization.id +
        "/contents/addmedia";

      if (state.contentLibraryFor == "groups") {
        uploadUrl += "?&group_id=" + rootState.auth.activeOrganizationGroup.id;
      }
      axios
        .post(uploadUrl, fileData, configData)
        .then(response => {
          commit("addUploadedFileId", data.id);
          if (data.type.indexOf("video") != -1) {
            dispatch("setselectedContentType", 3);
          } else {
            dispatch("setselectedContentType", 2);
          }
        })
        .catch(e => {
          console.log("Error: ", e);
        });
    },
    setToDisplayPopup({ state, commit, rootState, dispatch }, data) {
      commit("setToDisplayPopup", data);
    }
  }
};
