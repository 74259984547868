import axios from "axios";
import router from "../../router";
import toastr from "toastr";
import config from "../../config.js";

export default {
  namespaced: true,
  state: {
    appid: "",
    facebookGroups: []
  },

  getters: {
    // computed properties
  },

  mutations: {
    setFacebookGroups(state, data) {
      console.log("Calling mutation: setFacebookGroups");
      state.facebookGroups = data;
    },
    setFacebookAppId(state, data) {
      console.log("Calling mutation: setFacebookAppId");
      state.appid = data;
    }
  },

  actions: {
    getAllFacebookGroups({ state, commit, rootState }) {
      new Promise(function(resolve, reject) {
        console.log("getAllFacebookGroups() Called");
        axios
          .get(
            config.DOMAIN +
              config.API_VERSION +
              "socialprofiles/facebookgroups?per-page=10000"
          )
          .then(response => {
            console.log(response.data);
            commit("setFacebookGroups", response.data.socialprofiles);
            resolve();
          })
          .catch(e => {
            console.log("Error: " + e);
            resolve();
          }); //axios
      }); // promise
    }, // getAllFacebookGroups
    getFacebookAppId({ state, commit, rootState }) {
      new Promise(function(resolve, reject) {
        console.log("getFacebookAppId() Called");
        axios
          .get(config.DOMAIN + config.API_VERSION + "facebooks/facebookappid")
          .then(response => {
            console.log(response.data);
            commit("setFacebookAppId", response.data.id);
            resolve();
          })
          .catch(e => {
            console.log("Error: " + e);
            resolve();
          }); //axios
      }); // promise
    }
  }
};
