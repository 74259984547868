export default {
  routePatterns: [
    ".*/salesperson/step1",
    ".*/salesperson/step2",
    ".*/salesperson/step3",
    ".*/salesperson/step4",
    ".*/salesperson/confirm-email",
    "/salesperson/review",
    ".*/salesperson/update-password",
    ".*/salesperson/step2-experience",
    "/salesperson/profile/.*",
    "/salesperson/profile/view/.*",
    "/salesperson/profile/edit/.*",
    ".*/salesperson/dashboard",
    ".*/salesperson/postings",
    "/salesperson/contract/.*",
    "/salesperson/contracts",
    "salesperson/accept/contract",
    ".*/salesperson/timer",
    "salesperson/timer/report/client",
    "salesperson/timer/report/contract",
    "/salesperson/timer/report/weekly",
    "/salesperson/payment-list",
    "/salesperson/payment/view",
    ".*/salesperson/chats",
    ".*/salesperson/posting/profile/.*"
  ]
};
