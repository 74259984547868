import creditsServices from "../../services/creditsServices";

import {
  FETCH_ORGANIZATION_CREDITS,
  UPDATE_CREDITS_INFO
} from "./actions.type";
import { UPDATE_CREDITS } from "./mutations.type";

const initialState = {
  availableCredits: 0,
  shows: 0
};

export const state = { ...initialState };

export const actions = {
  [UPDATE_CREDITS_INFO]({ commit, state }, data) {
    commit("updateCredits", data);
  },

  async [FETCH_ORGANIZATION_CREDITS](context, organization_id) {
    await creditsServices
      .getOrganizationCredits(organization_id)
      .then(response => {
        const { data } = response;
        if (data.status) {
          const { availableCredits, shows } = data;
          context.commit(UPDATE_CREDITS, { availableCredits, shows });
        }
      })
      .catch(e => console.log(e));
  }
};

export const mutations = {
  [UPDATE_CREDITS](state, data) {
    state.availableCredits = data.availableCredits;
    state.shows = data.shows;
  }
};

export const getters = {
  creditsData(state) {
    return {
      availableCredits: state.availableCredits,
      shows: state.shows
    };
  }
};

export default {
  // namespaced: true,
  state,
  getters,
  mutations,
  actions
};
