import axios from "axios";
import config from "../config.js";
import organization from "../router/organization.js";
import store from "../store";
import sendersService from "./senders.js";

export default {
  getOrganizationByShortcode: function(data) {
    return new Promise(function(resolve, reject) {
      let url =
        config.DOMAIN +
        config.API_VERSION +
        "organizations/shortcode/" +
        data.shortcode +
        "?expand=subscription";

      axios
        .get(url)
        .then(response => {
          resolve(response);
        })
        .catch(e => {
          console.error("Error: ", e);
          reject(e);
        }); //axios
    });
  },
  getOrganizationSenders: function(data) {
    return new Promise(function(resolve, reject) {
      let url =
        config.DOMAIN +
        config.API_VERSION +
        "organizations/" +
        data.organization_id +
        "/senders?";

      if (!!data.status) {
        url += "&status=" + data.status;
      }
      if (!!data.hubid) {
        url += "&hubid=" + data.hubid;
      }

      if (!!data.search) {
        url += "&search=" + data.search;
      }

      console.log("URK=====================", url);
      axios
        .get(url)
        .then(response => {
          response.data = sendersService.createOrgUserObjectOnUI(response.data);
          console.log("46: os: ", response.data);
          resolve(response);
        })
        .catch(e => {
          console.error("Error: ", e);
          reject(e);
        }); //axios
    });
  },
  getOrganizationContacts: function(data) {
    return new Promise(function(resolve, reject) {
      let url =
        config.DOMAIN +
        config.API_VERSION +
        "organizations/" +
        data.organization_id +
        "/campaigncontacts";

      if (!!data.search) url += "?search=" + data.search;
      axios
        .get(url)
        .then(response => {
          resolve(response);
        })
        .catch(e => {
          console.error("Error: ", e);
          reject(e);
        }); //axios
    });
  },
  saveOrgUserImage: function(data) {
    console.log("before saveOrgUserImage data => ", data);
    return new Promise(function(resolve, reject) {
      console.log("saveOrgUserImage data => ", data);
      let url =
        config.DOMAIN +
        config.API_VERSION +
        "organizations/" +
        data.organization_id +
        "/org_user_image";
      if (!!data.organization_user_id)
        url = url + "?organization_user_id=" + data.organization_user_id;
      axios
        .post(url, data.form_data)
        .then(
          response => {
            resolve(response);
          },
          error => {
            toastr.error(error.response.data.message, "", {
              timeOut: config.TOASTR_TIMEOUT
            });
          }
        )
        .catch(e => {
          console.log("ERRORS: " + e);
        });
    });
  },
  getOrganizationById: function(data) {
    return new Promise(function(resolve, reject) {
      let url =
        config.DOMAIN +
        config.API_VERSION +
        "organizations/id/" +
        data.organizationId;

      axios
        .get(url)
        .then(response => {
          resolve(response);
        })
        .catch(e => {
          console.error("Error: ", e);
          reject(e);
        }); //axios
    });
  }
};
