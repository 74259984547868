import axios from "axios";
import toastr from "toastr";
import config from "../../config.js";
import * as moment from "moment";

export default {
  namespaced: true,
  state: {
    adsData: [],

    defaultColor: "#ccc",
    adPresetSelectedId: null,
    selectedImage: config.DOMAIN + "/images/profile_placeholder.png",
    selectedLogo: config.DOMAIN + "/images/Sales_Innovator_Logo_Long_Black.png",
    adPreviewBackgroundImg: config.DOMAIN + "/images/ad_bg.png",
    adPreviewBackground: "default",
    adTempImage: null,
    adTempLogo: null,
    adPresetSelectedAd: {
      ad_theme_id: 1,
      background_color: "#fff",
      button_color: "#26a69a",
      button_url: "",
      created: "",
      display_image_id: null,
      display_image_name: null,
      id: null,
      button_text_color: "#000",
      headline_text_color: "#000",
      logo_image_id: null,
      logo_type: 1,
      logo_image_name: null,

      button_text: "Click Here",
      message: "Input your message here",
      headline: "",

      button_text_tablet: "Click Here",
      message_tablet: "Input your message here",
      headline_tablet: "",

      button_text_mobile: "Click Here",
      message_mobile: "Input your message here",
      headline_mobile: "",

      name: "",
      position: "Left Bottom",
      text_color: "#000",
      updated: "",
      image: null,
      logo: null,
    },
    adValidationCount: {
      message_text_count: 0,
      button_text_count: 0,
      headline_text_count: 0,

      tablet_message_text_count: 0,
      tablet_button_text_count: 0,
      tablet_headline_text_count: 0,

      mobile_message_text_count: 0,
      mobile_button_text_count: 0,
      mobile_headline_text_count: 0,
    },
    adPresetSelectedAdChangeAllowed: true,
    createNewAdClicked: 0,
    adPreviewMode: "desktop-mode",
    selectedAdThemeDetails: null,
  },

  getters: {},
  mutations: {
    setAds(state, data) {
      Vue.set(state, "adsData", data);
    },
    updateAdValidationCount(state, data) {
      console.log("updateAdValidationCount=========", data);
      if (data.message_text_count) {
        state.adValidationCount.message_text_count = data.message_text_count;
      } else if (data.button_text_count) {
        state.adValidationCount.button_text_count = data.button_text_count;
      } else if (data.headline_text_count) {
        state.adValidationCount.headline_text_count = data.headline_text_count;
      } else if (data.tablet_message_text_count) {
        state.adValidationCount.tablet_message_text_count =
          data.tablet_message_text_count;
      } else if (data.mobile_message_text_count) {
        state.adValidationCount.mobile_message_text_count =
          data.mobile_message_text_count;
      } else if (data.tablet_headline_text_count) {
        state.adValidationCount.tablet_headline_text_count =
          data.tablet_headline_text_count;
      } else if (data.mobile_headline_text_count) {
        state.adValidationCount.mobile_headline_text_count =
          data.mobile_headline_text_count;
      } else if (data.mobile_button_text_count) {
        state.adValidationCount.mobile_button_text_count =
          data.mobile_button_text_count;
      } else if (data.tablet_button_text_count) {
        state.adValidationCount.tablet_button_text_count =
          data.tablet_button_text_count;
      }
    },
    setSelectedAdThemeDetails(state, data) {
      console.log("Inside setSelectedAdThemeDetails mutation", data);
      state.selectedAdThemeDetails = data;
    },
    setSelectedAdImage(state, data) {
      Vue.set(state, "selectedImage", data);
    },
    setSelectedAdLogo(state, data) {
      Vue.set(state, "selectedLogo", data);
    },
    createAd(state, data) {
      console.log("Create Ad Mutation.");
      state.adsData.splice(0, 0, data);
    },
    updateAd(state, data) {
      const index = state.adsData.findIndex((ad) => ad.id === data.id);
      state.adsData.splice(index, 1, data);
    },
    deleteAd(state, adId) {
      const index = state.adsData.findIndex((ad) => ad.id === adId);
      state.adsData.splice(index, 1);
    },
    changeAdPresetSelectedAdByCallToAction(state, newVal) {
      console.log(
        "commit -> Mutation: changeAdPresetSelectedAdByCallToAction: ",
        newVal,
        state.adPresetSelectedId,
        state.adPresetSelectedAd,
        state.adsData
      );

      if (true || state.adPresetSelectedAdChangeAllowed) {
        if (newVal == 3) {
          state.adPresetSelectedAd = {
            ad_theme_id: 1,
            background_color: "#fff",
            button_color: "#26a69a",
            button_text: "Click Here",
            button_url: "",
            category_id: null,
            created: "",
            createdby_id: null,
            display_image_id: null,
            display_image_name: null,
            id: null,
            button_text_color: "#000",
            headline_text_color: "#000",
            logo_image_id: null,
            logo_type: 1,
            logo_image_name: null,
            message: "Input your message here",
            headline: "Input Headline",

            button_text_tablet: "Click Here",
            message_tablet: "Input your message here",
            headline_tablet: "",

            button_text_mobile: "Click Here",
            message_mobile: "Input your message here",
            headline_mobile: "",

            name: "",
            position: "Left Bottom",
            text_color: "#000",
            updated: "",
            image: null,
            logo: null,
          };

          // if create new ad selected, reset ad image and logo
          state.selectedImage =
            config.DOMAIN + "/images/profile_placeholder.png";
          state.selectedLogo =
            config.DOMAIN + "/images/Sales_Innovator_Logo_Long_Black.png";
        } else if (state.adPresetSelectedId > 0) {
          console.log("adPresetSelectedId else1 ");
          // check selected adPresetSelectedAd to render in input boxes

          // change ad only if previously selected ad is different, so watch function works well
          if (state.adPresetSelectedAd.id != state.adPresetSelectedId) {
            state.adPresetSelectedAd = {
              ...state.adsData.filter(
                (uc) => uc.id == state.adPresetSelectedId
              )[0],
            };
          }
        } else {
          // while adPresetSelectedId == null and we selected callToAction from select box, then here default is asigned
          console.log("adPresetSelectedId else2 ");
          state.adPresetSelectedAd = { ...state.adsData[0] };
          state.adPresetSelectedId = state.adPresetSelectedAd.id;
        }

        console.log("Selected Ad:: ", state.adPresetSelectedAd);
      }
    },

    updateAdPresetSelectedAdByAdId(state, adId) {
      console.log(
        "Mutation-> updateAdPresetSelectedAdByAdId ",
        state.adPresetSelectedAd
      );
      state.adPresetSelectedAd = {
        ...state.adsData.filter((uc) => uc.id == adId)[0],
      };
    },

    setAdPresetSelectedAdIdNull(state, value) {
      // when user changing some content of ad, just create new ad, so this is for that
      state.adPresetSelectedAd.id = null;
      state.adPresetSelectedId = null;
    },
    changeAdPresetSelectedAd(state, value) {
      console.log(
        "commit --> Mutation : changeAdPresetSelectedAd. ==> ",
        value
      );
      state.adPresetSelectedAd = { ...value };
      console.log("changeAdPresetSelectedAd: ", state.adPresetSelectedAd.image);
      try {
        state.selectedImage = URL.createObjectURL(
          state.adPresetSelectedAd.image
        );
      } catch (e) {}
    },

    setAdPresetSelectedAdImage(state, value) {
      if (value == null)
        state.selectedImage = config.DOMAIN + "/images/profile_placeholder.png";
      else {
        state.adPresetSelectedAd.image = value;
        state.selectedImage = URL.createObjectURL(value);
        state.adTempImage = value;
      }
    },

    setAdPresetSelectedAdLogoImage(state, value) {
      console.log("setAdPresetSelectedAdLogoImage", value);
      if (value == null) {
        state.selectedLogo =
          config.DOMAIN + "/images/Sales_Innovator_Logo_Long_Black.png";
        try {
          document.getElementById("logo").value = "";
        } catch (e) {}
      } else {
        state.adPresetSelectedAd.logo = value;
        state.selectedLogo = URL.createObjectURL(value);
        state.adTempLogo = value;
      }
    },

    setAdPresetSelectedId(state, value) {
      console.log("commit --> Mutation: setAdPresetSelectedId: ", value);
      state.adPresetSelectedId = value;
    },

    setAdPresetSelectedAdThemeId(state, value) {
      state.adPresetSelectedAd.ad_theme_id = value;
    },
    setAdPresetSelectedAdChangeAllowed(state, value) {
      console.log(
        "commit --> adPresetSelectedAdChangeAllowed Mutation: ",
        value
      );
      state.adPresetSelectedAdChangeAllowed = value;
    },
    hideAdPreview(state, value) {
      state.adPreviewBackground = value;
      console.log(state.adPreviewBackground + "after");
    },
    changeAdBackground(state, value) {
      if (value == "ERROR") {
        state.adPreviewBackgroundImg = null;
      } else {
        state.adPreviewBackgroundImg = config.DOMAIN + "/images/ad_bg.png";
      }
    },
    updateCreateNewAdClicked(state, value) {
      state.createNewAdClicked++;
    },
    changeAdMode(state, value) {
      console.log(value);
      state.adPreviewMode = value;
      console.log(state.adPreviewMode);
    },
    changeDefaultPositionInFullScreen(state, value) {
      console.log(value);
      state.adPresetSelectedAd.position = value;
    },
  },

  actions: {
    getAllAds({ state, commit, rootState }, filterBy = null) {
      console.log(
        "getAllAds() Called",
        rootState.auth.whoami.active_organization_category_id
      );
      axios
        .get(
          config.DOMAIN +
            config.API_VERSION +
            "organizations/" +
            rootState.auth.whoami.active_organization.id +
            "/categories/" +
            rootState.auth.whoami.active_organization_category_id +
            "/ad"
        )
        .then((response) => {
          console.log(response.data);
          if (filterBy != null) {
            console.log("Inside filter=====");
            var ads = response.data.ads;
            console.log("ads----------", ads);
            var fullScreenAds = ads.filter((ad) => {
              if (ad.ad_theme_id == 2 || ad.ad_theme_id == 3) return ad;
            });
            commit("setAds", fullScreenAds);
          } else {
            commit("setAds", response.data.ads);
          }
        })
        .catch((e) => {
          console.log("Error: ", e);
        }); //axios
    },
    getSelectedAdImage({ state, commit, rootState }, adId) {
      console.log("ads.js getSelectedAdImage() Called");
      let url =
        config.DOMAIN +
        config.API_VERSION +
        "organizations/" +
        rootState.auth.whoami.active_organization.id +
        "/categories/" +
        rootState.auth.whoami.active_organization_category_id +
        "/ad/" +
        adId +
        "/image";
      commit("setSelectedAdImage", url);
    },
    getSelectedAdLogo({ state, commit, rootState }, adId) {
      console.log("ads.js getSelectedAdImage() Called");
      let url =
        config.DOMAIN +
        config.API_VERSION +
        "organizations/" +
        rootState.auth.whoami.active_organization.id +
        "/categories/" +
        rootState.auth.whoami.active_organization_category_id +
        "/ad/" +
        adId +
        "/logo";
      commit("setSelectedAdLogo", url);
    },
    createAd({ state, commit, rootState, dispatch }, data) {
      data.createdby_id = rootState.auth.whoami.userid;
      data.category_id = rootState.auth.whoami.active_organization_category_id;
      axios
        .post(
          config.DOMAIN +
            config.API_VERSION +
            "organizations/" +
            rootState.auth.whoami.active_organization.id +
            "/categories/" +
            rootState.auth.whoami.active_organization_category_id +
            "/ad",
          data
        )
        .then((response) => {
          console.log("Ad saved!!! ", response.data);
          commit("createAd", response.data);
          commit("setAdPresetSelectedId", response.data.id);
          commit("links/changeCallToAction", 2, { root: true });
          commit("changeAdPresetSelectedAdByCallToAction", 2);
          dispatch("takeAdScreenshot", response.data.id);

          toastr.error("Ad created successfully.", "", { timeOut: 5000 });

          try {
            // save image of ad
            let imageData = new FormData();
            imageData.append("image", data.image, data.image.name);
            axios
              .post(
                config.DOMAIN +
                  config.API_VERSION +
                  "organizations/" +
                  rootState.auth.whoami.active_organization.id +
                  "/categories/" +
                  rootState.auth.whoami.active_organization_category_id +
                  "/ad/" +
                  response.data.id +
                  "/images",
                imageData
              )
              .then((image_response) => {
                console.log("Ad Image saved!!! ", image_response.data);
                // commit("createAd", response.data);
                toastr.error("Ad Image created successfully.", "", {
                  timeOut: 5000,
                });
              })
              .catch((e) => {
                console.log("Image Post Error: ", e);
              }); //axios
          } catch (e) {}

          try {
            // save logo image of ad
            let logoData = new FormData();
            logoData.append("logo", data.logo, data.logo.name);
            axios
              .post(
                config.DOMAIN +
                  config.API_VERSION +
                  "organizations/" +
                  rootState.auth.whoami.active_organization.id +
                  "/categories/" +
                  rootState.auth.whoami.active_organization_category_id +
                  "/ad/" +
                  response.data.id +
                  "/images",
                logoData
              )
              .then((image_response) => {
                console.log("Ad Logo Image saved!!! ", image_response.data);
                // commit("createAd", response.data);
                toastr.error("Ad Logo Image created successfully.", "", {
                  timeOut: 5000,
                });
              })
              .catch((e) => {
                console.log("Logo Image Post Error: ", e);
              }); //axios
          } catch (e) {}

          //
        })
        .catch((e) => {
          console.log("Ad Post Error: ", e);
        }); //axios
    },
    updateAd({ state, commit, rootState }, data) {
      console.log("updateAd() Called");
      axios
        .put(
          config.DOMAIN +
            config.API_VERSION +
            "organizations/" +
            rootState.auth.whoami.active_organization.id +
            "/categories/" +
            rootState.auth.whoami.active_organization_category_id +
            "/ad",
          data
        )
        .then((response) => {
          console.log(response.data);
          commit("updateAd", response.data.ads);
        })
        .catch((e) => {
          console.log("Error: ", e);
        }); //axios
    },
    deleteAd({ state, commit, rootState }, adId) {
      console.log("deleteAd() Called");
      axios
        .delete(
          config.DOMAIN +
            config.API_VERSION +
            "organizations/" +
            rootState.auth.whoami.active_organization.id +
            "/categories/" +
            rootState.auth.whoami.active_organization_category_id +
            "/ad/" +
            adId
        )
        .then((response) => {
          console.log(response.data);
          commit("deleteAd", adId);
          toastr.success("Ad deleted successfully!", "", {
            timeOut: config.TOASTR_TIMEOUT,
          });
        })
        .catch((e) => {
          console.log("Error: ", e);
          toastr.error("Ad not deleted, Something went wrong!", "", {
            timeOut: config.TOASTR_TIMEOUT,
          });
        }); //axios
    },
    hideAdPreview({ state, commit, rootState }, value) {
      //Need link , iframe_enabled data,
      console.log(value);
      commit("hideAdPreview", value);
    },
    changeAdBackground({ state, commit, rootState }, value) {
      //Need link , iframe_enabled data,
      console.log(value);
      commit("changeAdBackground", value);
    },

    changeAdMode({ state, commit, rootState }, value) {
      console.log(value);
      commit("changeAdMode", value);
      console.log("state===========", state.adPresetSelectedAd);
      // if(value=="tablet-mode"|| value=="mobile-mode"){
      //    if(state.adPresetSelectedAd.message!=""){
      //     state.adPresetSelectedAd.message_tablet=state.adPresetSelectedAd.message;
      //     state.adPresetSelectedAd.message_mobile=state.adPresetSelectedAd.message;
      //    }
      //    if(state.adPresetSelectedAd.button_text!=""){
      //      state.adPresetSelectedAd.button_text_tablet=state.adPresetSelectedAd.button_text;
      //     state.adPresetSelectedAd.button_text_mobile=state.adPresetSelectedAd.button_text;
      //    }
      //   if(state.adPresetSelectedAd.headline!=""){
      //      state.adPresetSelectedAd.headline_tablet=state.adPresetSelectedAd.headline;
      //     state.adPresetSelectedAd.headline_mobile=state.adPresetSelectedAd.headline;
      //    }
      // }
    },
    updateAdValidationCount({ state, commit, rootState }, value) {
      console.log(value);
      commit("updateAdValidationCount", value);
    },
    setSelectedAdThemeDetails({ state, commit, rootState }, adThemeId) {
      console.log("Inside action setSelectedAdThemeDetails=============");
      axios
        .get(
          config.DOMAIN +
            config.API_VERSION +
            "organizations/" +
            rootState.auth.whoami.active_organization.id +
            "/categories/" +
            rootState.auth.whoami.active_organization_category_id +
            "/ad/" +
            adThemeId
        )
        .then((response) => {
          console.log(response.data);
          commit("setSelectedAdThemeDetails", response.data);
        })
        .catch((e) => {
          console.log("Error: ", e);
        }); //axios
    },
    takeAdScreenshot({ state, commit, rootState }, adId) {
      console.log("Inside action takeAdScreenshot=============");
      axios
        .get(
          config.DOMAIN +
            config.API_VERSION +
            "organizations/" +
            rootState.auth.whoami.active_organization.id +
            "/categories/" +
            rootState.auth.whoami.active_organization_category_id +
            "/ad/" +
            adId +
            "/takescreenshot"
        )
        .then((response) => {
          console.log(response.data);
          commit("setSelectedAdThemeDetails", response.data);
        })
        .catch((e) => {
          console.log("Error: ", e);
        }); //axios
    },
  },
};
