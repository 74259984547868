import axios from "axios";
import config from "../config.js";
import store from "../store";

export default {
  registerUser: function(data) {
    return new Promise((resolve, reject) => {
      let url = config.DOMAIN + config.API_VERSION + "users/register";
      axios
        .post(url, data)
        .then(response => {
          resolve(response);
        })
        .catch(e => {
          reject(e);
        });
    });
  },
  saveApplication: function(data) {
    return new Promise((resolve, reject) => {
      let url = config.DOMAIN + config.API_VERSION + "sales_person_details";
      axios
        .post(url, data)
        .then(response => {
          resolve(response);
        })
        .catch(e => {
          reject(e);
        }); //axios
    });
  },
  getSalespersonTypes(data) {
    // http://localhost/api/v1/salesapplicationtypes
    return new Promise(function(resolve, reject) {
      var url = config.DOMAIN + config.API_VERSION + "salesapplicationtypes";

      if (data?.is_visible) url += "?is_visible=" + data.is_visible;

      axios
        .get(url)
        .then(response => {
          resolve(response);
        })
        .catch(e => {
          reject(e.response.data.message);
        });
    });
  },

  getSalespersonDetails(data) {
    // http://localhost/api/v1/sales_person_details?search=lead
    return new Promise(function(resolve, reject) {
      var url = config.DOMAIN + config.API_VERSION + "sales_person_details?";

      if (!!data.search) {
        url = url + "&search=" + data.search;
      }
      if (!!data.sales_application_type_id) {
        url =
          url + "&sales_application_type_id=" + data.sales_application_type_id;
      }
      if (data?.company_info_id)
        url += "&company_info_id=" + data.company_info_id;
      if (data?.for == "filter") url += "&for=filter";
      if (!!data.is_visible) {
        url = url + "&is_visible=" + data.is_visible;
      }
      if (!!data.organization_id) {
        url = url + "&organization_id=" + data.organization_id;
      }
      axios
        .get(url)
        .then(response => {
          if (!!data.timestamp) response.data["timeStamp"] = data.timestamp;
          resolve(response);
        })
        .catch(e => {
          reject(e.response.data.message);
        });
    });
  },
  fetchPreferences(data) {
    // http://localhost/api/v1/preferences?sales_application_type_id=1&preferences_type_id=2
    return new Promise(function(resolve, reject) {
      var url = config.DOMAIN + config.API_VERSION + "preferences";

      url =
        url +
        "?sales_application_type_id=" +
        data.sales_application_type_id +
        "&preferences_type_id=" +
        data.preferences_type_id;

      axios
        .get(url)
        .then(response => {
          resolve(response);
        })
        .catch(e => {
          reject(e.response.data.message);
        });
    });
  },
  fetchUserPreferences(data) {
    // http://localhost/api/v1/preferences?sales_application_type_id=1&preferences_type_id=2
    return new Promise(function(resolve, reject) {
      var url =
        config.DOMAIN +
        config.API_VERSION +
        "userpreferences/getuserpreferences";

      url = url + "?role_id=" + data.role_id;
      if (!!data.user_id) {
        url = url + "&user_id=" + data.user_id;
      }
      if (!!data.user_ids) {
        url = url + "&user_ids=" + data.user_ids;
      }
      if (!!data.preferences_type_id) {
        url = url + "&preferences_type_id=" + data.preferences_type_id;
      }
      if (!!data.sales_application_type_id) {
        url =
          url + "&sales_application_type_id=" + data.sales_application_type_id;
      }
      if (!!data.company_posting_id) {
        url = url + "&company_posting_id=" + data.company_posting_id;
      }
      if (data?.sales_person_details_id)
        url += "&sales_person_details_id=" + data.sales_person_details_id;

      axios
        .get(url)
        .then(response => {
          resolve(response);
        })
        .catch(e => {
          reject(e.response.data.message);
        });
    });
  },
  fetchProfileDetailsById(data) {
    // http://localhost/api/v1/sales_person_details/:id
    return new Promise(function(resolve, reject) {
      var url =
        config.DOMAIN +
        config.API_VERSION +
        "sales_person_details?id=" +
        data.sales_application_type_id;
      axios
        .get(url)
        .then(response => {
          resolve(response);
        })
        .catch(e => {
          reject(e.response.data.message);
        });
    });
  },
  fetchProfileDetailsByIdClient(data) {
    return new Promise(function(resolve, reject) {
      var url =
        config.DOMAIN +
        config.API_VERSION +
        "sales_person_details?id=" +
        data.sales_application_type_id;
      if (data?.company_info_id)
        url += "&company_info_id=" + data.company_info_id;
      if (data?.company_posting_id)
        url += "&company_posting_id=" + data.company_posting_id;
      if (data?.view_only) url += "&view_only=" + data.view_only;
      if (!data?.view_only) url += "&for=client";
      axios
        .get(url)
        .then(response => {
          resolve(response);
        })
        .catch(e => {
          reject(e.response.data.message);
        });
    });
  },
  fetchUserPreferenceByUserId(data) {
    // http://localhost/api/v1/sales_person_details/:id
    return new Promise(function(resolve, reject) {
      var url =
        config.DOMAIN +
        config.API_VERSION +
        "userpreferences?user_id=" +
        data.user_id;
      if (!!data.role_id) url = url + "&role_id=" + data.role_id;
      if (data?.sales_person_details_id)
        url = url + "&sales_person_details_id=" + data.sales_person_details_id;
      axios
        .get(url)
        .then(response => {
          resolve(response);
        })
        .catch(e => {
          reject(e.response.data.message);
        });
    });
  },
  prospectingSearch: function(payloadData, timestamp) {
    // http://localhost/api/v1/sales_person_details/sendsalespersondetailsforappliedfilters
    return new Promise((resolve, reject) => {
      let url =
        config.DOMAIN +
        config.API_VERSION +
        "sales_person_details/sendsalespersondetailsforappliedfilters";

      axios
        .post(url, payloadData)
        .then(response => {
          console.log(
            "🚀 ~ file: prospectingServices.js ~ line 19 ~ .then ~ response",
            response
          );
          response.data["timeStamp"] = timestamp;
          resolve(response);
        })
        .catch(e => {
          console.log(
            "🚀 ~ file: prospectingServices.js ~ line 26 ~ returnnewPromise ~ e",
            e
          );
          reject(e);
        });
    });
  },
  editProfileDetails: function(data) {
    return new Promise((resolve, reject) => {
      let url =
        config.DOMAIN +
        config.API_VERSION +
        "sales_person_details/edit_sale_person_profile";
      axios
        .post(url, data)
        .then(response => {
          resolve(response);
        })
        .catch(e => {
          reject(e);
        }); //axios
    });
  },
  enterDataInContactTable(salespersonData) {
    return new Promise((resolve, reject) => {
      let url =
        config.DOMAIN +
        config.API_VERSION +
        "organizations/" +
        store.state.auth.whoami.active_organization.id +
        "/campaigncontacts/enter-salesperson-data-in-contact-table";
      axios
        .post(url, salespersonData)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  setMarkAsBouncedProspects(data) {
    return new Promise((resolve, reject) => {
      let url =
        config.DOMAIN +
        config.API_VERSION +
        "sales_person_details/set_sale_person_bounced";
      axios
        .post(url, data)
        .then(response => {
          resolve(response);
        })
        .catch(e => {
          reject(e);
        });
    });
  },
  pauseSalePersonCampaigns(data) {
    return new Promise((resolve, reject) => {
      let url =
        config.DOMAIN +
        config.API_VERSION +
        "sales_person_details/pause_sale_person_campaigns";
      axios
        .post(url, data)
        .then(response => {
          resolve(response);
        })
        .catch(e => {
          reject(e);
        });
    });
  },
  addAllSalespersonToList(filter) {
    return new Promise((resolve, reject) => {
      let url =
        config.DOMAIN +
        config.API_VERSION +
        "sales_person_details/add_all_sale_person_to_list";
      axios
        .post(url, filter)
        .then(response => {
          resolve(response);
        })
        .catch(e => {
          reject(e);
        });
    });
  },
  updateSalesPerson: function(data) {
    return new Promise((resolve, reject) => {
      let url =
        config.DOMAIN + config.API_VERSION + "sales_person_details/" + data.id;
      axios
        .put(url, data)
        .then(response => {
          resolve(response);
        })
        .catch(e => {
          reject(e);
        }); //axios
    });
  },
  getFeaturedSalesperson(data) {
    return new Promise(function(resolve, reject) {
      var url =
        config.DOMAIN + config.API_VERSION + "sales_person_details?featured=1";
      axios
        .get(url)
        .then(response => {
          resolve(response);
        })
        .catch(e => {
          reject(e.response.data.message);
        });
    });
  },
  uploadVideo(form_data) {
    return new Promise((resolve, reject) => {
      let url =
        config.DOMAIN +
        config.API_VERSION +
        "sales_person_details/upload_video";
      axios
        .post(url, form_data, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          resolve(response);
        })
        .catch(e => {
          reject(e);
        });
    });
  },
  saveVideo(form_data) {
    return new Promise((resolve, reject) => {
      let url =
        config.DOMAIN +
        config.API_VERSION +
        "sales_person_details/upload_complete";
      axios
        .post(url, form_data)
        .then(response => {
          resolve(response);
        })
        .catch(e => {
          reject(e);
        });
    });
  },
  getSalespersonUploadedVideos(data) {
    return new Promise(function(resolve, reject) {
      var url =
        config.DOMAIN +
        config.API_VERSION +
        "salesperson_video?sales_person_details_id=" +
        data.id;
      axios
        .get(url)
        .then(response => {
          resolve(response);
        })
        .catch(e => {
          reject(e.response.data.message);
        });
    });
  },
  removeUploadedVideoOfFreelancer: function(data) {
    return new Promise((resolve, reject) => {
      let url =
        config.DOMAIN + config.API_VERSION + "salesperson_video/remove_video";
      let method = "post";

      axios({
        method: method,
        url: url,
        data: data
      })
        .then(response => {
          resolve(response);
        })
        .catch(e => {
          reject(e);
        }); //axios
    });
  },
  createStripeAccountAndGenerateLink: function(data) {
    return new Promise((resolve, reject) => {
      let url =
        config.DOMAIN +
        config.API_VERSION +
        "stripe_payment/create_account_and_generate_link";
      axios
        .post(url, data)
        .then(response => {
          resolve(response);
        })
        .catch(e => {
          reject(e);
        }); //axios
    });
  },
  getStripeAccountBankDetails: function(data) {
    return new Promise((resolve, reject) => {
      let url =
        config.DOMAIN +
        config.API_VERSION +
        "stripe_payment/get_connected_account?sales_person_details_id=" +
        data.sales_person_details_id;
      axios
        .get(url)
        .then(response => {
          resolve(response);
        })
        .catch(e => {
          reject(e);
        }); //axios
    });
  }
};
