import axios from "axios";
import toastr from "toastr";
import config from "../../config.js";
import * as moment from "moment";

export default {
  namespaced: true,
  state: {
    isEditModeOn: false,
    editModeType: "Select",
    userCategories: [],
    category: {},
    selectedCategoryId: null,
    selectedCategoryName: null,
    postProgresses: []
  },

  getters: {},

  mutations: {
    setCategories(state, data) {
      Vue.set(state, "userCategories", data);
    },

    changeEditMode(state, data) {
      state.isEditModeOn = data;
    },
    changeEditModeType(state, data) {
      state.editModeType = data;
    },

    // changeCategoryIsSelected(state, data) {
    //   state.userCategories.forEach(function(category) {
    //     // set isSelected for all categories
    //     if (category.id == data) category.isSelected = true;
    //     else category.isSelected = false;
    //   });
    // },
    changeCategoryIsSelected(state, data) {
      console.log("in changeCategoryIsSelected ---", state, data);
      state.userCategories.forEach(function(category) {
        // set isSelected for all categories
        if (category.id == data) category.isSelected = true;
        else category.isSelected = false;

        if (category.id == data) {
          category.isSelected = true;
        } else {
          category.isSelected = false;
        }
      });
    },

    createCategory(state, data) {
      state.userCategories.forEach(function(category) {
        category.isSelected = false;
      });
      data.isSelected = true;
      state.userCategories.push(data);
    },

    updateCategory(state, data) {
      const index = state.userCategories.findIndex(uc => uc.id == data.id);
      data.isSelected = true;
      state.userCategories.splice(index, 1, data);
    },

    deleteCategory(state, data) {
      const index = state.userCategories.findIndex(uc => uc.id === data);
      state.userCategories.splice(index, 1);
      //set first category as selected
      let isSelectedSet = false;
      state.userCategories.forEach(function(category) {
        if (!isSelectedSet) {
          category.isSelected = true;
          isSelectedSet = true;
        }
      });
    },

    setSelectedCategoryId(state, data) {
      console.log("Inside setSelectedCategoryId==============", data);
      state.selectedCategoryId = data;
    },
    setSelectedCategoryName(state, data) {
      state.selectedCategoryName = data;
    }
  },

  actions: {
    getAllCategories({ state, commit, rootState }) {
      console.log("getAllCategories() Called");
      axios
        .get(
          config.DOMAIN +
            config.API_VERSION +
            "organizations/" +
            rootState.auth.whoami.active_organization.id +
            "/categories"
        )
        .then(response => {
          console.log(response.data);
          response.data.category.forEach(function(category) {
            // set selected category in select input = active category of user
            if (
              category.id ==
              rootState.auth.whoami.active_organization_category_id
            )
              category.isSelected = true;
            else category.isSelected = false;
          });
          commit("setCategories", response.data.category);
        })
        .catch(e => {
          console.log("Error: ", e);
        }); //axios
    },

    changeActiveCategory({ state, commit, dispatch, rootState }, event) {
      // console.log(event.target.value);
      console.log("user_category: ");
      commit("auth/changeActiveCategory", event.target.value, { root: true });
      dispatch("post/getAllPosts", null, { root: true });
    },

    categorySelectIndexChanged({ state, commit, dispatch, rootState }, value) {
      commit("changeCategoryIsSelected", value);
      commit("setSelectedCategoryId", value);
      commit("auth/changeActiveCategory", value, { root: true });

      // dispatch("post/getAllPosts", null, { root: true });
    },
    changeSelectedCategoryName({ state, commit, dispatch, rootState }, value) {
      commit("setSelectedCategoryName", value);
    },

    categoryDefault({ state, commit, dispatch, rootState }, value) {
      console.log("in categoryDefault-------------------");
      commit("setSelectedCategoryId", value);
      //commit("auth/changeActiveCategory", value, { root: true });

      // dispatch("post/getAllPosts", null, { root: true });
    },

    changeEditMode({ state, commit, dispatch, rootState }, mode) {
      if (
        mode == "Add" &&
        rootState.auth.activeOrganization.active_subscription == false
      ) {
        dispatch("subscriptions/checkout", null, { root: true });
        return false;
      }

      commit("changeEditModeType", mode);
      commit("changeEditMode", mode == "Select" ? false : true);

      if (state.editModeType == "Edit") {
        state.category = state.userCategories.filter(
          uc => uc.isSelected == true
        )[0];
      } else if (state.editModeType == "Select" || state.editModeType == "Add")
        state.category = {
          id: "",
          name: "",
          public: "",
          created: "",
          updated: ""
        };
    },

    createCategory({ state, commit, dispatch, rootState }, data) {
      data.organization_id = rootState.auth.whoami.active_organization.id;
      data.organization_tag_type_id = 1;
      axios
        .post(
          config.DOMAIN +
            config.API_VERSION +
            "organizations/" +
            rootState.auth.whoami.active_organization.id +
            "/categories",
          data
        )
        .then(response => {
          commit("createCategory", response.data);
          commit("auth/changeActiveCategory", response.data.id, {
            root: true
          });
          toastr.success("Category created successfully.", "", {
            timeOut: 5000
          });

          dispatch("changeEditMode", "Select");
          dispatch("post/getAllPosts", "all", { root: true });

          console.log("postEditModeOn --- ", this.postEditModeOn);

          dispatch("categorySelectIndexChanged", response.data.id);
          dispatch("categoryDefault", response.data.id);
        })
        .catch(e => {
          console.log("Error: ", e);
          toastr.error("Something went wrong!", "", { timeOut: 5000 });
        }); //axios
    }, // createCategory

    updateCategory({ state, commit, dispatch, rootState }, data) {
      data.organization_id = rootState.auth.whoami.active_organization.id;
      data.organization_tag_type_id = 1;
      axios
        .put(
          config.DOMAIN +
            config.API_VERSION +
            "organizations/" +
            rootState.auth.whoami.active_organization.id +
            "/categories/" +
            data.id,
          data
        )
        .then(response => {
          // edit success
          commit("updateCategory", response.data);
          commit("auth/changeActiveCategory", response.data.id, {
            root: true
          });
          toastr.success("Category updated successfully.", "", {
            timeOut: 5000
          });

          dispatch("changeEditMode", "Select");
          dispatch("post/getAllPosts", "all", { root: true });
        })
        .catch(e => {
          console.log("Error: ", e);
          toastr.error("Something went wrong!", "", { timeOut: 5000 });
        }); //axios
    }, // updateCategory

    deleteCategory({ state, commit, dispatch, rootState }, data) {
      state.category = state.userCategories.filter(
        uc => uc.isSelected == true
      )[0];

      let category_id = state.category.id;
      console.log("Category To Delete: " + category_id);
      axios
        .delete(
          config.DOMAIN +
            config.API_VERSION +
            "organizations/" +
            rootState.auth.whoami.active_organization.id +
            "/categories/" +
            category_id,
          data
        )
        .then(response => {
          toastr.success("Category deleted successfully.", "", {
            timeOut: 5000
          });
          dispatch("changeEditMode", "Select");
          commit("deleteCategory", category_id);

          let active_category = state.userCategories.filter(
            uc => uc.isSelected == true
          )[0];
          commit("auth/changeActiveCategory", active_category.id, {
            root: true
          });
          dispatch("post/getAllPosts", "all", { root: true });
        })
        .catch(e => {
          console.log("Error: ", e);
          toastr.error("Something went wrong!", "", { timeOut: 5000 });
        }); //axios
    } // deleteCategory
  }
};
