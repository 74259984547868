import axios from "axios";
import router from "../../router";
import toastr from "toastr";
import config from "../../config.js";

export default {
  namespaced: true,
  state: {
    social_profiles: []
  },

  getters: {
    // computed properties
  },

  mutations: {
    setSocialProfiles(state, data) {
      console.log("Calling mutation: setSocialProfiles");
      state.social_profiles = data;
    },
    deleteSocialProfile(state, data) {
      let updatedSocial_profiles = state.social_profiles.filter(element => {
        return element.socialprofile_type_id != data;
      });
      state.social_profiles = updatedSocial_profiles;
    }
  },

  actions: {
    getAllSocialProfiles({ state, commit, rootState }) {
      new Promise(function(resolve, reject) {
        console.log("getAllSocialProfiles() Called");
        axios
          .get(
            config.DOMAIN + config.API_VERSION + "socialprofiles?per-page=10000"
          )
          .then(response => {
            console.log(response.data);
            commit("setSocialProfiles", response.data.socialprofiles);
            resolve();
          })
          .catch(e => {
            console.log("Error: " + e);
            resolve();
          }); //axios
      }); // promise
    }, // getAllSocialProfiles
    deleteSocialProfile(
      { state, commit, dispatch, rootState },
      socialprofile_type_id
    ) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            config.DOMAIN +
              config.API_VERSION +
              "socialprofiles/deletesocialprofile?socialprofile_type_id=" +
              socialprofile_type_id
          )
          .then(response => {
            console.log("Deleted - " + response.data);
            commit("deleteSocialProfile", response.data);
            toastr.clear();
            toastr.error("Social profile deleted successfully!", "", {
              timeOut: config.TOASTR_TIMEOUT
            });
            resolve(response);
          })
          .catch(error => {
            console.log("Error: " + e);
            resolve();
          });
      });
    },
    deleteSocialProfileById({ state, commit, dispatch, rootState }, id) {
      new Promise(function(resolve, reject) {
        // get whoami
        axios
          .delete(config.DOMAIN + config.API_VERSION + "socialprofiles/" + id)
          .then(response => {
            console.log("Deleted - " + response.data);
            dispatch("getAllSocialProfiles");
            toastr.clear();
            toastr.error("Social profile deleted successfully!", "", {
              timeOut: config.TOASTR_TIMEOUT
            });
            resolve();
          })
          .catch(e => {
            console.log("Error: " + e);
            resolve();
          }); //axios
      }); // promise
    },
    connectWordpressBlogs({ state, commit, dispatch, rootState }, data) {
      return new Promise(function(resolve, reject) {
        let org_id = rootState.auth.whoami.active_organization.id;
        axios
          .post(
            config.DOMAIN +
              config.API_VERSION +
              "organizations/" +
              org_id +
              "/wordpressxmlrpcclients/connect",
            data
          )
          .then(response => {
            console.log("response - ", response);
            resolve(response);
          })
          .catch(e => {
            console.log("Error: " + e);
            reject(e.response.data.message);
          }); //axios
      }); // promise
    },
    getSocialProfile({ state, commit, rootState }, id) {
      return new Promise(function(resolve, reject) {
        axios
          .get(config.DOMAIN + config.API_VERSION + "socialprofiles/" + id)
          .then(response => {
            console.log("inside getSocialProfile - ", response);
            resolve(response);
          })
          .catch(e => {
            console.log("Error: " + e);
            resolve(e.response.data.message);
          }); //axios
      }); // promise
    } // getAllSocialProfiles
  }
};
