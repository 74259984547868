import axios from "axios";
import toastr from "toastr";
import config from "../../config.js";
import swal from "sweetalert2";
import { FETCH_ORGANIZATION_CREDITS } from "./actions.type";

export default {
  namespaced: true,
  state: {
    isIframeLoaded: false,
    portalUrl: null,
    subscriptionProgress: {
      isUpdatingSubscription: false
    },
    trialDaysRemaining: null,
    organization_addon: {
      organization_id: "",
      license: "",
      storage: "",
      contacts: "",
      messages: ""
    },
    isSubscribed: false
  },

  getters: {},

  mutations: {
    setPortalUrl(state, data) {
      state.portalUrl = data;
    },
    updateSubscriptionProgress(state, data) {
      console.log("Calling mutation: updateSubscriptionProgress", state, data);
      state.subscriptionProgress = data;
    },
    setTrialDaysRemaining(state, days) {
      state.trialDaysRemaining = days;
    },
    setOrganizationAddon(state, data) {
      state.organization_addon = data;
    },
    setIsSubscribed(state, data) {
      state.isSubscribed = data;
    }
  },

  actions: {
    setIsSubscribed(state, data) {
      state.isSubscribed = data;
    },
    checkout({ state, commit, dispatch, rootState }, data) {
      let chargebeeInstance = Chargebee.getInstance();
      let planName = data.name.toLowerCase();
      let plan_id = null;
      plan_id = planName + "-" + data.period;
      if (planName == "start") plan_id = "starter" + "-" + data.period;
      if (planName == "grow") plan_id = "growth" + "-" + data.period;
      let plan_expiry_date =
        rootState.auth.whoami.active_organization.subscription.expiry_date;

      chargebeeInstance.openCheckout({
        hostedPage: function() {
          return axios
            .get(
              config.DOMAIN +
                config.API_VERSION +
                "subscriptions/hostedpageurl/" +
                rootState.auth.whoami.active_organization.id +
                "?plan_period=" +
                data.period +
                "&plan_name=" +
                data.name
            )
            .then(response => response.data);
        },
        loaded: function() {
          // Optional
          // will be called once checkout page is loaded
          // if(!state.isIframeLoaded)
          // {
          //   var itm = document.getElementById("cb-container");
          //   var cln = itm.cloneNode(true);
          //   document.getElementById("social_lift_chargebee").appendChild(cln);
          //   itm.parentNode.removeChild(itm);
          //   state.isIframeLoaded = true;
          //   console.log("isIframeLoaded: "+state.isIframeLoaded);
          // }
        },
        error: function(error) {
          // Optional
          // will be called if the promise passed causes an error
        },
        step: function(step) {
          // Optional
          // will be called for each step involved in the checkout process
        },
        success: function(hostedPageId) {
          let subscriptionProgress = {
            isUpdatingSubscription: true
          };
          commit("updateSubscriptionProgress", subscriptionProgress);
          chargebeeInstance.closeAll();
          // Optional
          // will be called when a successful checkout happens.
          commit("setIsSubscribed", false);
          var interval = setInterval(function() {
            //const showLoading = function() {

            // };

            let activeOrganization = rootState.auth.whoami.active_organization;
            console.log(
              "activeSubscription",
              rootState.auth.whoami.active_organization.active_subscription,
              plan_id,
              activeOrganization.plan_name
            );
            if (!activeOrganization.active_subscription) {
              swal.fire({
                title: "Your subscription is updating...",
                allowEscapeKey: false,
                allowOutsideClick: false,
                timer: 3000,
                onOpen: () => {
                  swal.showLoading();
                }
              });
              dispatch(
                "auth/getSubscriptionStatus",
                rootState.auth.whoami.active_organization.id,
                { root: true }
              );
            } else {
              console.log(
                "before iffffffff plan id = >",
                plan_id,
                rootState.auth.whoami.active_organization.plan_name
              );
              // sending passing  != db value
              // once both matches means it is updated
              // existing epiry != db expiry

              if (
                plan_id !=
                  rootState.auth.whoami.active_organization.plan_name &&
                plan_expiry_date ==
                  rootState.auth.whoami.active_organization.subscription
                    .expiry_date
              ) {
                console.log(
                  "in else not equal plan id => ",
                  plan_id,
                  rootState.auth.whoami.active_organization.plan_name
                );
                swal.fire({
                  title: "Your subscription is updating...",
                  allowEscapeKey: false,
                  allowOutsideClick: false,
                  timer: 3000,
                  onOpen: () => {
                    swal.showLoading();
                  }
                });

                dispatch(
                  "auth/getSubscriptionStatus",
                  rootState.auth.whoami.active_organization.id,
                  { root: true }
                );
              } else {
                console.log("before clear interval");
                clearInterval(interval);
                let subscriptionProgress = {
                  isUpdatingSubscription: false
                };
                commit("updateSubscriptionProgress", subscriptionProgress);
                commit("setIsSubscribed", true);

                dispatch("auth/whoAmI", null, { root: true });

                dispatch(
                  FETCH_ORGANIZATION_CREDITS,
                  rootState.auth.whoami.active_organization.id,
                  { root: true }
                );
                toastr.clear();
                toastr.error("Subscription updated successfully!", "", {
                  timeOut: config.TOASTR_TIMEOUT
                });
              }
            }
          }, 2000);
          dispatch("auth/whoAmI", null, { root: true });
        },
        close: function() {}
      });
    }, // checkout

    getPortalSession({ state, commit, rootState }, post) {
      axios
        .get(config.DOMAIN + config.API_VERSION + "subscriptions/portalsession")
        .then(response => {
          commit("setPortalUrl", response.data.access_url);
        });
    }, // portalSession1
    getWeights({ state, commit, rootState }) {
      return new Promise(function(resolve, reject) {
        axios
          .get(config.DOMAIN + config.API_VERSION + "subscriptions/weights")
          .then(response => {
            resolve(response.data);
          })
          .catch(e => {
            console.log("ERRORS: " + e);
            reject(e);
          });
      });
    },
    upgrade({ state, commit, dispatch, rootState }, data) {
      let chargebeeInstance = Chargebee.getInstance();

      chargebeeInstance.openCheckout({
        hostedPage: function() {
          return axios
            .post(
              config.DOMAIN +
                config.API_VERSION +
                "subscriptions/update/hostedpageurl/" +
                rootState.auth.whoami.active_organization.id,
              data
            )
            .then(response => response.data);
        },
        loaded: function() {
          // Optional
          // will be called once checkout page is loaded
          // if(!state.isIframeLoaded)
          // {
          //   var itm = document.getElementById("cb-container");
          //   var cln = itm.cloneNode(true);
          //   document.getElementById("social_lift_chargebee").appendChild(cln);
          //   itm.parentNode.removeChild(itm);
          //   state.isIframeLoaded = true;
          //   console.log("isIframeLoaded: "+state.isIframeLoaded);
          // }
        },
        error: function(error) {
          // Optional
          // will be called if the promise passed causes an error
        },
        step: function(step) {
          // Optional
          // will be called for each step involved in the checkout process
        },
        success: function(hostedPageId) {
          let subscriptionProgress = {
            isUpdatingSubscription: true
          };
          commit("updateSubscriptionProgress", subscriptionProgress);
          chargebeeInstance.closeAll();
          // Optional
          // will be called when a successful checkout happens.
          var interval = setInterval(function() {
            let activeOrganization = rootState.auth.whoami.active_organization;
            console.log(
              "activeSubscription",
              rootState.auth.whoami.active_organization.active_subscription
            );
            if (!activeOrganization.active_subscription) {
              swal.fire({
                title: "Your subscription has been updating...",
                allowEscapeKey: false,
                allowOutsideClick: false,
                timer: 10000,
                onOpen: () => {
                  swal.showLoading();
                }
              });
              dispatch(
                "auth/getSubscriptionStatus",
                rootState.auth.whoami.active_organization.id,
                { root: true }
              );
            } else {
              clearInterval(interval);
              dispatch("auth/whoAmI", null, { root: true });
              dispatch(
                FETCH_ORGANIZATION_CREDITS,
                rootState.auth.whoami.active_organization.id,
                { root: true }
              );
              let subscriptionProgress = {
                isUpdatingSubscription: false
              };
              commit("updateSubscriptionProgress", subscriptionProgress);
              toastr.clear();
              toastr.error("Subscription updated successfully!", "", {
                timeOut: config.TOASTR_TIMEOUT
              });
            }
          }, 2000);
          dispatch("auth/whoAmI", null, { root: true });
        },
        close: function() {}
      });
    },
    setTrialDaysRemaining({ state, commit, rootState }, days) {
      commit("setTrialDaysRemaining", days);
    },
    updateSubscriptionCreditsAddon(
      { state, commit, rootState, dispatch },
      data
    ) {
      console.log(
        "🚀 ~ file: subscriptions.js ~ line 288 ~ updateSubscriptionCreditsAddon ~ data",
        data
      );

      return new Promise(function(resolve, reject) {
        let orgId = rootState.auth.whoami.active_organization.id;
        axios
          .post(
            config.DOMAIN +
              config.API_VERSION +
              "subscriptions/update/credits/addon/" +
              orgId,
            { accountStatus: data }
          )
          .then(response => {
            dispatch("auth/whoAmI", null, { root: true });
            resolve(response.data);
          })
          .catch(e => {
            console.log("ERRORS: " + e);
            reject(e);
          });
      });
    },
    updateSubscriptionAddon({ state, commit, rootState, dispatch }, data) {
      console.log(":202 updateSubscriptionAddon", data);
      return new Promise(function(resolve, reject) {
        console.log(":in updateSubscriptionAddon =>", data);
        let orgId = rootState.auth.whoami.active_organization.id;
        axios
          .post(
            config.DOMAIN +
              config.API_VERSION +
              "subscriptions/update/addon/" +
              orgId,
            data
          )
          .then(response => {
            dispatch("auth/whoAmI", null, { root: true });
            resolve(response.data);
          })
          .catch(e => {
            console.log("ERRORS: " + e);
            reject(e);
          });
      });
    },
    getSubscriptionAddon({ state, commit, rootState }) {
      return new Promise(function(resolve, reject) {
        let orgId = rootState.auth.whoami.active_organization.id;
        axios
          .get(
            config.DOMAIN + config.API_VERSION + "subscriptions/addons/" + orgId
          )
          .then(response => {
            resolve(response.data);
            commit("setOrganizationAddon", response.data);
          })
          .catch(e => {
            console.log("ERRORS: " + e);
            reject(e);
          });
      });
    },
    incrementUsedDownloadCounter(context, data) {
      return new Promise(function(resolve, reject) {
        console.log(":in incrementUsedDownloadCounter =>", data);
        axios
          .post(
            config.DOMAIN +
              config.API_VERSION +
              "subscriptions/increment-used-download-counter",
            data
          )
          .then(response => {
            context.dispatch("auth/whoAmI", null, { root: true });
            resolve(response.data);
          })
          .catch(e => {
            console.log("Error while incrementing the count: " + e);
            reject(e);
          });
      });
    }
  }
};
