const MembersDashboard = () => import("../components/Admin/SuperAdmin");
const AddMember = () => import("../components/Admin/AddMember");

export default [
  {
    path: "/superadmin",
    name: "SuperAdmin",
    component: () => import("../components/Workspace/SuperAdmin/main.vue")
  },
  {
    path: "/superadmin/members",
    name: "SuperAdminDashboard",
    component: MembersDashboard
  },
  {
    path: "/superadmin/members/add",
    name: "SuperAdminMembers",
    component: AddMember
  },
  {
    path: "/superadmin/members/edit",
    name: "EditSuperAdminMembers",
    component: () => import("../components/Admin/AddMember.vue"),
    props: true
  }
];
