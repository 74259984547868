import store from "../store";
import Swal from "sweetalert2";
import router from "../router";

export default [
  {
    path: "/:organization_slug/admin/tasks",
    name: "TaskManager",
    component: () => import("../components/Workspace/Tasks/TaskManager.vue"),
    beforeEnter: (to, from, next) => {
      if (
        store.state.auth.whoami.subscription_allowed_features
          .email_outreach_and_automation.tasks == true
      ) {
        next();
      } else {
        Swal.fire({
          title: "Upgrade Plan",
          text: "Upgrade your plan to use these features",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Upgrade"
        }).then(result => {
          if (result.isConfirmed) {
            router.push({
              name: "BillingWrapper",
              params: { selectedTab: "Plan" }
            });
          }
        });
      }
    }
  },
  {
    path: "/:organization_slug/admin/my_tasks/:organization_user_id",
    name: "TaskManager",
    component: () => import("../components/Workspace/Tasks/MyTasks.vue"),
    beforeEnter: (to, from, next) => {
      if (
        store.state.auth.whoami.subscription_allowed_features
          .email_outreach_and_automation.tasks == true
      ) {
        next();
      } else {
        Swal.fire({
          title: "Upgrade Plan",
          text: "Upgrade your plan to use these features",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Upgrade"
        }).then(result => {
          if (result.isConfirmed) {
            router.push({
              name: "BillingWrapper",
              params: { selectedTab: "Plan" }
            });
          }
        });
      }
    }
  }
];
