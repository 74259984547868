import axios from "axios";
import config from "../../config.js";
import * as moment from "moment";
import router from "../../router";
import toastr from "toastr";

export default {
  namespaced: true,
  state: {
    categories: [],
    tags: [],
    selectedCategories: [],
    selectedTags: [],
    category: {
      id: "",
      name: "",
      description: "",
      image_id: ""
    },
    tag: {
      id: "",
      name: [],
      description: "",
      image_id: ""
    },
    selectedContentTypes: [],
    content_types: [],
    getSelectedTagsOfModel: [],
    content_type: {
      id: "",
      name: "",
      description: "",
      image_id: ""
    },
    tagImage: null
  },

  getters: {},
  mutations: {
    // 3- tag type set
    setAllCategories(state, data) {
      state.categories = data;
    },
    setAllTags(state, data) {
      state.tags = data;
    },
    setAllContentTypes(state, data) {
      state.content_types = data;
    },

    setCategory(state, data) {
      state.category = data;
    },
    setTag(state, data) {
      state.tag = data;
    },
    setContentType(state, data) {
      state.content_type = data;
    },

    setSelectedCategories(state, data) {
      state.selectedCategories = data;
    },
    setSelectedTags(state, data) {
      state.selectedTags = data;
    },
    setSelectedContentTypes(state, data) {
      state.selectedContentTypes = data;
    },

    setTagImage(state, data) {
      console.log("Inside mutation setTagImage", data);
      state.tagImage = data;
    },
    //tags
    getAllTags(state, data) {
      state.tags = data;
    },
    setSelectedTags(state, data) {
      state.selectedTags = data;
    },
    //Content Type
    getAllContentTypes(state, data) {
      state.content_types = data;
    },
    setSelectedContentTypes(state, data) {
      state.selectedContentTypes = data;
    }
  },

  actions: {
    /* == getAllGroupTags() => this is global function for 3 types =========
        @organization_tag_type = 1 : will getch all categories
        @organization_tag_type = 2 : will getch all tags
        @organization_tag_type = 3 : will getch all content_types
    ================================================================== */
    getAllGroupTags({ state, commit, rootState }, organization_tag_type) {
      console.log("getAllTags() Called-> ", organization_tag_type);
      axios
        .get(
          config.DOMAIN +
            config.API_VERSION +
            "organizations/" +
            rootState.auth.whoami.active_organization.id +
            "/grouptags?organization_tag_type_id=" +
            organization_tag_type
        )
        .then(response => {
          console.log("All tags GET API:: ", response.data);
          if (organization_tag_type == 1)
            commit("setAllCategories", response.data.organization_group_tag);
          else if (organization_tag_type == 2)
            commit("setAllTags", response.data.organization_group_tag);
          else if (rganization_tag_type == 3)
            commit("setAllContentTypes", response.data.organization_group_tag);
        })
        .catch(e => {
          console.log("Error: ", e);
        }); //axios
    },
    getAllGroupContentTypes({ state, commit, rootState }, curatedObj) {
      console.log("getAllContentTypes() Called");
      axios
        .get(
          config.DOMAIN +
            config.API_VERSION +
            "organizations/" +
            rootState.auth.whoami.active_organization.id +
            "/grouptags?organization_tag_type_id=3"
        )
        .then(response => {
          console.log("All content type GET API:: ", response.data);
          commit("getAllContentTypes", response.data.organization_group_tag);
        })
        .catch(e => {
          console.log("Error: ", e);
        }); //axios
    }
  }
};
