import axios from "axios";
import toastr from "toastr";
import config from "../../config.js";
import * as moment from "moment";

export default {
  namespaced: true,
  state: {
    senders: [],
    selectedSender: {},
    callgetAllSendersApi: false,
    createdSenderIds: []
  },

  getters: {},

  mutations: {
    setSenders(state, data) {
      state.senders = data;
    },
    updateSenders(state, data) {
      console.log("inside muta updateSenders", data);
      state.senders = data;
    },
    setSelectedSender(state, data) {
      state.selectedSender = !!data ? data : {};
      console.log("setSelectedSender -- ", state.selectedSender);
    },
    removeSenders(state, data) {
      const index = state.senders.findIndex(element => element.id === data.id);
      state.senders.splice(index, 1);
    },
    setCallgetAllSendersApis(state, data) {
      state.callgetAllSendersApi = data;
    },
    setCreatedSenderIds(state, data) {
      state.createdSenderIds.push(data);
    }
  },

  actions: {
    setSenders({ state, commit }, data) {
      commit("setSenders", data);
    },
    updateSenders({ state, commit }, data) {
      console.log("inside action updateSenders", data);
      commit("updateSenders", data);
    },
    setSelectedSender({ state, commit }, data) {
      commit("setSelectedSender", data);
    },
    removeSenders({ state, commit }, data) {
      commit("removeSenders", data);
    },
    setCallgetAllSendersApis({ state, commit }, data) {
      commit("setCallgetAllSendersApis", data);
    },
    setCreatedSenderIds({ state, commit }, data) {
      commit("setCreatedSenderIds", data);
    }
  }
};
