import store from "../store";
import router from "../router";
import Swal from "sweetalert2";
import moment from "moment";

function checkStatus() {
  Swal.fire({
    title: "Upgrade Plan",
    text: "Upgrade your plan to use these features",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Upgrade"
  }).then(result => {
    if (result.isConfirmed) {
      router.push({
        name: "BillingWrapper",
        params: { selectedTab: "Plan" }
      });
    }
  });
}
function checkForMarketPlace(key) {
  if (
    key in store.state.auth.whoami.subscription_allowed_features.menu_options
  ) {
    return store.state.auth.whoami.subscription_allowed_features.menu_options[
      key
    ];
  }
  let endDate = moment.utc(store.state.auth.whoami.subscription_expiry_date);
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  let now = moment.tz(userTimeZone);
  let convertedUTC = now.clone().utc();

  if (convertedUTC > endDate) {
    return false;
  }
  return true;
}

export default [
  /*
  {
    path: "/:organization_slug/admin/invoices/list",
    name: "InvoicesList",
    // meta: { for: "edit" },
    component: () => import("../components/Workspace/Invoices/List.vue")
  },
  {
    path: "/chat",
    name: "ChatHome",
    // meta: { for: "edit" },
    component: () => import("../components/Workspace/Chat/ChatHome.vue")
  },
  {
    path: "/:organization_slug/admin/client/add",
    name: "AddContactForm",
    component: () =>
      import("../components/Workspace/Contact/AddContactForm.vue")
  },
  */
  {
    path: "/:organization_slug/admin/chats",
    name: "Chats",
    component: () => import("../components/Workspace/NewChat/AdminChats.vue"),
    beforeEnter: (to, from, next) => {
      if (checkForMarketPlace("chat")) {
        next();
      } else {
        checkStatus();
      }
    }
  },
  {
    path: "/:organization_slug/admin/leads",
    name: "Leads",
    component: () => import("../components/Workspace/Leads/Leads.vue")
  },
  {
    path: "/:organization_slug/admin/signature/create",
    name: "EditSignature",
    component: () =>
      import("../components/Workspace/Signature/EditSignature.vue")
  },
  {
    path: "/:organization_slug/admin/leads/profile/:contact_id",
    name: "LeadsProfile",
    component: () =>
      import("../components/Workspace/Leads/LeadsProfile/Main.vue")
  },
  // {
  //   path: "/:organization_slug/admin/project/:project_id",
  //   name: "ProjectOverview",
  //   component: () =>
  //     import("../components/Workspace/Project/ProjectOverview.vue")
  // },
  // {
  //   path: "/:organization_slug/admin/adaccounts",
  //   //path: !!store.state.auth.domainOrgId ? "/:organization_slug/admin/adaccounts" : "/admin/adaccounts",
  //   name: "AdAccounts",
  //   component: () => import("../components/Workspace/Leads/AdAccounts.vue")
  // },
  // {
  //   path: "/:organization_slug/admin/forms",
  //   name: "LeadsForms",
  //   component: () => import("../components/Workspace/Leads/LeadsForm.vue")
  // },
  {
    path: "/:organization_slug/admin/forms/connect",
    name: "ConnectLeadsForms",
    component: () =>
      import("../components/Workspace/Leads/ConnectLeadsForm.vue")
  },
  // {
  //   path: "/:organization_slug/admin/connections",
  //   name: "ConnectionLists",
  //   component: () =>
  //     import("../components/Workspace/Leads/Connections/ConnectionLists.vue")
  // },
  // {
  //   path: "/:organization_slug/admin/connections/create",
  //   name: "CreateConnection",
  //   component: () =>
  //     import("../components/Workspace/Leads/Connections/CreateConnection.vue")
  // },
  // {
  //   path: "/:organization_slug/admin/connections/create/:group_id",
  //   name: "CreateGroupConnection",
  //   component: () =>
  //     import("../components/Workspace/Leads/Connections/CreateConnection.vue")
  // },
  {
    path: "/:organization_slug/admin/members",
    name: "MembersHome",
    component: () => import("../components/Workspace/Members/MembersHome.vue"),
    beforeEnter: (to, from, next) => {
      if (
        store.state.auth.whoami.subscription_allowed_features.menu_options
          .senders_add == true
      ) {
        next();
      } else {
        checkStatus();
      }
    }
  },
  {
    path: "/:organization_slug/admin/leadslist",
    name: "LeadsList",
    // meta: {  v: store.state.domainOrgId },
    component: () => import("../components/Workspace/Leads/LeadsList.vue")
  },
  {
    path: "/:organization_slug/admin/leadslist/:id",
    name: "LeadDetail",
    component: () => import("../components/Workspace/Leads/LeadDetail.vue")
  },
  {
    path: "/:organization_slug/admin/senders",
    name: "Sender",
    component: () => import("../components/Workspace/Sender/Sender.vue"),
    beforeEnter: (to, from, next) => {
      if (
        store.state.auth.whoami.subscription_allowed_features.menu_options
          .senders_add == true
      ) {
        next();
      } else {
        checkStatus();
      }
    }
  },
  {
    path: "/:organization_slug/admin/senders/list",
    name: "Sender",
    component: () => import("../components/Workspace/Sender/List.vue"),
    beforeEnter: (to, from, next) => {
      if (
        store.state.auth.whoami.subscription_allowed_features.menu_options
          .senders_manage == true
      ) {
        next();
      } else {
        checkStatus();
      }
    }
  },
  {
    path: "/:organization_slug/admin/contacts",
    name: "ContactsHome",
    component: () => import("../components/Workspace/Contact/ContactsHome.vue"),
    beforeEnter: (to, from, next) => {
      if (
        store.state.auth.whoami.subscription_allowed_features.menu_options
          .leads_contacts == true
      ) {
        next();
      } else {
        checkStatus();
      }
    }
  },
  {
    path: "/:organization_slug/admin/create/contact",
    name: "CreateContact",
    component: () =>
      import("../components/Workspace/Contact/CreateContact.vue"),
    beforeEnter: (to, from, next) => {
      if (
        store.state.auth.whoami.subscription_allowed_features.menu_options
          .leads_add_contacts == true
      ) {
        next();
      } else {
        checkStatus();
      }
    }
  },
  {
    path: "/:organization_slug/admin/contact/lists",
    name: "ContactLists",
    component: () =>
      import("../components/Workspace/Leads/ContactLists/ContactLists.vue"),
    beforeEnter: (to, from, next) => {
      if (
        store.state.auth.whoami.subscription_allowed_features.menu_options
          .leads_manage_lists == true
      ) {
        next();
      } else {
        checkStatus();
      }
    }
  },
  {
    path: "/:organization_slug/admin/view_list_contacts",
    name: "ViewListContactsHome",
    component: () =>
      import("../components/Workspace/Contact/ViewListContactsHome.vue"),
    beforeEnter: (to, from, next) => {
      if (
        store.state.auth.whoami.subscription_allowed_features.menu_options
          .leads_manage_lists == true
      ) {
        next();
      } else {
        checkStatus();
      }
    }
  },
  {
    path: "/:organization_slug/admin/contact/addtolist",
    name: "AddToList",
    component: () =>
      import("../components/Workspace/Leads/ContactLists/AddToList.vue"),
    beforeEnter: (to, from, next) => {
      if (
        store.state.auth.whoami.subscription_allowed_features.menu_options
          .leads_add_to_list == true
      ) {
        next();
      } else {
        checkStatus();
      }
    }
  },
  {
    path: "/:organization_slug/admin/contact/comparelists",
    name: "CompareLists",
    component: () =>
      import("../components/Workspace/Leads/CompareLists/CompareLists.vue"),
    beforeEnter: (to, from, next) => {
      if (
        store.state.auth.whoami.subscription_allowed_features.prospecting
          .contact_list_duplicate_checking == true
      ) {
        next();
      } else {
        checkStatus();
      }
    }
  },
  {
    path: "/:organization_slug/admin/contact/comparisonresult",
    name: "ComparisonResult",
    component: () =>
      import("../components/Workspace/Leads/CompareLists/ComparisonResult.vue")
  },
  {
    path: "/:organization_slug/admin/emailconnections",
    name: "EmailConnections",
    component: () =>
      import(
        "../components/Workspace/Campaign/EmailConnections/Connections.vue"
      ),
    beforeEnter: (to, from, next) => {
      if (
        store.state.auth.whoami.subscription_allowed_features.menu_options
          .settings_connections == true
      ) {
        next();
      } else {
        checkStatus();
      }
    }
  },
  {
    path: "/:organization_slug/admin/domaintracking",
    name: "DomainTrackingList",
    component: () =>
      import("../components/Workspace/DomainTracking/DomainTrackingList.vue"),
    beforeEnter: (to, from, next) => {
      if (
        store.state.auth.whoami.subscription_allowed_features.content
          .website_link_tracking == true
      ) {
        next();
      } else {
        checkStatus();
      }
    }
  },
  {
    path: "/more/connections",
    name: "Connections",
    meta: { for: "user" },
    component: () =>
      import(
        "../components/Workspace/Campaign/EmailConnections/Connections.vue"
      )
  },
  {
    path: "/admin/billing",
    name: "BillingWrapper",
    component: () =>
      import("../components/Workspace/Billing/BillingWrapper.vue"),
    props: { default: true }
  },
  //Added below route for https://go.salesinnovator.com/SalesInnovatorprod/admin/billing
  {
    path: "/:organization_slug/admin/billing",
    name: "BillingWrapper1",
    component: () =>
      import("../components/Workspace/Billing/BillingWrapper.vue"),
    props: { default: true }
  },
  {
    path: "/user_registration/step1",
    name: "UserRegistrationStep1",
    meta: { for: "newUserRegistration" },
    component: () =>
      import("../components/UserRegistration/UserRegistrationStep1.vue")
  },
  {
    path: "/user_registration/step2",
    name: "UserRegistrationStep2",
    meta: { for: "newUserRegistration" },
    component: () =>
      import("../components/UserRegistration/UserRegistrationStep2.vue")
  },
  {
    path: "/user_registration/step3",
    name: "UserRegistrationStep3",
    meta: { for: "newUserRegistration" },
    component: () =>
      import("../components/UserRegistration/UserRegistrationStep3.vue")
  },

  //Companies
  {
    path: "/:organization_slug/admin/companies",
    name: "Company",
    component: () => import("../components/Workspace/Company/Company.vue"),
    beforeEnter: (to, from, next) => {
      if (
        store.state.auth.whoami.subscription_allowed_features.menu_options
          .dashboard_companies == true
      ) {
        next();
      } else {
        checkStatus();
      }
    }
  },

  //Content Dashboard
  {
    path: "/:organization_slug/admin/content",
    name: "ContentHome",
    component: () =>
      import("../components/Workspace/Dashboard/Content/ContentHome.vue"),
    beforeEnter: (to, from, next) => {
      if (
        store.state.auth.whoami.subscription_allowed_features.menu_options
          .dashboard_content == true
      ) {
        next();
      } else {
        checkStatus();
      }
    }
  },
  //Prospects Dashboard
  {
    path: "/:organization_slug/admin/prospect",
    name: "ProspectHome",
    component: () =>
      import("../components/Workspace/Dashboard/Prospect/ProspectHome.vue"),
    beforeEnter: (to, from, next) => {
      if (
        store.state.auth.whoami.subscription_allowed_features
          .email_outreach_and_automation.lead_scoring == true
      ) {
        next();
      } else {
        checkStatus();
      }
    }
  },

  //Prospects Dashboard
  {
    path: "/:organization_slug/admin/prospect/links",
    name: "ProspectLinkHome",
    component: () =>
      import("../components/Workspace/Dashboard/Prospect/ProspectLinkHome.vue"),
    beforeEnter: (to, from, next) => {
      if (
        store.state.auth.whoami.subscription_allowed_features.content
          .link_analytics == true
      ) {
        next();
      } else {
        checkStatus();
      }
    }
  },

  //Companies Dashboard
  {
    path: "/:organization_slug/admin/companies/dashboard",
    name: "CompanyHome",
    component: () =>
      import("../components/Workspace/Dashboard/Companies/CompanyHome.vue"),
    beforeEnter: (to, from, next) => {
      if (
        store.state.auth.whoami.subscription_allowed_features.menu_options
          .dashboard_companies == true
      ) {
        next();
      } else {
        checkStatus();
      }
    }
  },

  //Notifications Settings
  {
    path: "/:organization_slug/admin/notifications",
    name: "NotificationsMain",
    component: () => import("../components/Workspace/NotificationSettings/Main")
  },
  {
    path: "/admin/notifications",
    name: "NotificationsMain",
    component: () => import("../components/Workspace/NotificationSettings/Main")
  },

  //Playbooks
  {
    path: "/:organization_slug/admin/playbooks",
    name: "PlaybookLibrary",
    component: () =>
      import("../components/Workspace/Playbooks/PlaybookLibrary"),
    beforeEnter: (to, from, next) => {
      if (
        store.state.auth.whoami.subscription_allowed_features.menu_options
          .playbooks_library == true
      ) {
        next();
      } else {
        checkStatus();
      }
    }
  },
  {
    path: "/admin/playbooks",
    name: "PlaybookLibrary",
    component: () =>
      import("../components/Workspace/Playbooks/PlaybookLibrary"),
    beforeEnter: (to, from, next) => {
      if (
        store.state.auth.whoami.subscription_allowed_features.menu_options
          .playbooks_library == true
      ) {
        next();
      } else {
        checkStatus();
      }
    }
  },
  {
    path: "/:organization_slug/admin/playbooks/add",
    name: "PlaybookAdd",
    component: () => import("../components/Workspace/Playbooks/PlaybookAdd"),
    beforeEnter: (to, from, next) => {
      if (
        store.state.auth.whoami.subscription_allowed_features.menu_options
          .playbooks_add == true
      ) {
        next();
      } else {
        checkStatus();
      }
    }
  },
  {
    path: "/:organization_slug/admin/playbooks/add/:id",
    name: "PlaybookAdd",
    component: () => import("../components/Workspace/Playbooks/PlaybookAdd"),
    beforeEnter: (to, from, next) => {
      if (
        store.state.auth.whoami.subscription_allowed_features.menu_options
          .playbooks_add == true
      ) {
        next();
      } else {
        checkStatus();
      }
    }
  },
  {
    path: "/:organization_slug/admin/playbooks/:id/preview",
    name: "PlaybookPreview",
    component: () => import("../components/Workspace/Playbooks/PlaybookPreview")
  },
  {
    path: "/:organization_slug/admin/playbooks/:id/sequence",
    name: "PlaybookSequence",
    component: () =>
      import("../components/Workspace/Playbooks/PlaybookSequence")
  },
  {
    path: "/:organization_slug/admin/emails",
    name: "EmailsHome",
    component: () =>
      import("../components/Workspace/Dashboard/Emails/EmailsHome.vue"),
    beforeEnter: (to, from, next) => {
      if (
        store.state.auth.whoami.subscription_allowed_features.menu_options
          .dashboard_emails == true
      ) {
        next();
      } else {
        checkStatus();
      }
    }
  },

  //Warmup Dashboard
  {
    path: "/:organization_slug/admin/campaign",
    name: "WarmupHome",
    component: () =>
      import("../components/Workspace/Dashboard/Warmup/WarmupHome.vue"),
    beforeEnter: (to, from, next) => {
      if (
        store.state.auth.whoami.subscription_allowed_features.menu_options
          .dashboard_campaign == true
      ) {
        next();
      } else {
        checkStatus();
      }
    }
  },

  //Prospects Dashboard
  {
    path: "/:organization_slug/admin/website",
    name: "WebSiteHome",
    component: () =>
      import("../components/Workspace/Dashboard/Website/WebsiteHome.vue"),
    beforeEnter: (to, from, next) => {
      if (
        store.state.auth.whoami.subscription_allowed_features.content
          .website_link_tracking == true
      ) {
        next();
      } else {
        checkStatus();
      }
    }
  },

  //Manage Funnel
  {
    path: "/:organization_slug/admin/funnel",
    name: "FunnelMain",
    component: () => import("../components/Workspace/Funnel/Main"),
    beforeEnter: (to, from, next) => {
      if (
        store.state.auth.whoami.subscription_allowed_features.menu_options
          .opportunities_manage_funnel == true
      ) {
        next();
      } else {
        checkStatus();
      }
    }
  },
  {
    path: "/admin/funnel",
    name: "FunnelMain",
    component: () => import("../components/Workspace/Funnel/Main")
  },
  {
    path: "/:organization_slug/admin/funnel/view",
    name: "FunnelView",
    component: () => import("../components/Workspace/Funnel/FunnelView"),
    beforeEnter: (to, from, next) => {
      if (
        store.state.auth.whoami.subscription_allowed_features.menu_options
          .opportunities_view_funnel == true
      ) {
        next();
      } else {
        checkStatus();
      }
    }
  },
  {
    path: "/admin/funnel/view",
    name: "FunnelView",
    component: () => import("../components/Workspace/Funnel/FunnelView")
  },

  //Inbox
  {
    path: "/admin/team_inbox",
    name: "TeamInbox",
    component: () => import("../components/Workspace/Inbox/TeamInbox"),
    beforeEnter: (to, from, next) => {
      if (
        store.state.auth.whoami.subscription_allowed_features.team.team_inbox ==
        true
      ) {
        next();
      } else {
        checkStatus();
      }
    }
  },
  {
    path: "/:organization_slug/admin/team_inbox",
    name: "TeamInbox",
    component: () => import("../components/Workspace/Inbox/TeamInbox"),
    beforeEnter: (to, from, next) => {
      if (
        store.state.auth.whoami.subscription_allowed_features.team.team_inbox ==
        true
      ) {
        next();
      } else {
        checkStatus();
      }
    }
  },

  //Sender Warmup Dashboard
  {
    path: "/:organization_slug/admin/sender/warmup",
    name: "SenderWarmupHome",
    component: () =>
      import("../components/Workspace/Dashboard/SenderWarmup/Home.vue"),
    beforeEnter: (to, from, next) => {
      if (
        store.state.auth.whoami.subscription_allowed_features
          .email_deliverability.email_warmup == true
      ) {
        next();
      } else {
        checkStatus();
      }
    }
  },
  // {
  //   path: "/admin/funnel",
  //   name: "Compose",
  //   component: () => import("../components/Workspace/Funnel/Main")
  // }
  //Super Admin Dashboard
  {
    path: "/super_admin/filters",
    name: "SuperAdminHome",
    component: () => import("../components/Workspace/SuperAdmin/main.vue")
  },
  // prospecting
  {
    path: "/:organization_slug/admin/prospecting/search",
    name: "ProspectingSearch",
    meta: { hideSideBar: true },
    component: () =>
      import("../components/Workspace/FreelancerProspecting/Search/main.vue"),
    props: true,
    beforeEnter: (to, from, next) => {
      if (checkForMarketPlace("freelancer")) {
        next();
      } else {
        checkStatus();
      }
    }
  },
  {
    path: "/:organization_slug/admin/timer/report/weekly",
    name: "WeeklyReportMain",
    meta: { hideSideBar: true },
    component: () =>
      import("../components/Workspace/AdminTimerReports/WeeklyReportMain.vue"),
    beforeEnter: (to, from, next) => {
      if (checkForMarketPlace("weekly_report")) {
        next();
      } else {
        checkStatus();
      }
    }
  },
  {
    path: "/:organization_slug/admin/timer/report/contract",
    name: "ContractReportMain",
    meta: { hideSideBar: true },
    component: () =>
      import(
        "../components/Workspace/AdminTimerReports/ContractReportMain.vue"
      ),
    beforeEnter: (to, from, next) => {
      if (checkForMarketPlace("contract_report")) {
        next();
      } else {
        checkStatus();
      }
    }
  },
  {
    path: "/:organization_slug/admin/timer/report/company",
    name: "CompanyReportMain",
    meta: { hideSideBar: true },
    component: () =>
      import("../components/Workspace/AdminTimerReports/CompanyReportMain.vue"),
    beforeEnter: (to, from, next) => {
      if (checkForMarketPlace("company_report")) {
        next();
      } else {
        checkStatus();
      }
    }
  },
  {
    path: "/:organization_slug/admin/invoice/list",
    name: "InvoiceList",
    component: () => import("../components/Workspace/Invoice/InvoiceList.vue")
  },
  {
    path: "/:organization_slug/admin/invoice/view/:invoice_id",
    name: "ViewInvoice",
    component: () => import("../components/Workspace/Invoice/ViewInvoice.vue")
  },
  {
    path: "/:organization_slug/admin/invoice/create",
    name: "CreateInvoice",
    component: () => import("../components/Workspace/Invoice/CreateInvoice.vue")
  },
  {
    path: "/:organization_slug/admin/invoice/payment",
    name: "PaymentList",
    component: () => import("../components/Workspace/Invoice/PaymentList.vue")
  },
  {
    path: "/:organization_slug/admin/invoice/budget",
    name: "Budget",
    component: () => import("../components/Workspace/Invoice/Budget/Main.vue")
  },
  {
    path: "/:organization_slug/admin/payment/view/:payment_id",
    name: "ViewInvoice",
    component: () => import("../components/Workspace/Invoice/ViewPayment.vue")
  }
];
