import axios from "axios";
import toastr from "toastr";
import config from "../../config.js";
import router from "../../router";
import arraysLib from "../../lib/arrays.js";

export default {
  namespaced: true,
  state: {
    contact: {
      id: null,
      first_name: null,
      last_name: null,
      email: null,
      phone: null,
      company_name: null,
      title: null,
      company: {}
    },
    contacts: [],
    limit: 5,
    offset: 0,
    contactsCount: 0,
    senders: [],
    selectedContactSender: "",
    selectedContactList: null,
    createdContactIds: [],
    callgetAllContactApi: false,
    contactsFromFile: [],
    selectedContactFilter: [],
    latestCallURL: ""
  },

  getters: {},

  mutations: {
    setContactsCount(state, data) {
      state.contactsCount = data;
    },
    clearContactDetails(state, data) {
      state.contact = data;
    },
    setContacts(state, data) {
      state.contacts = data;
    },
    updateContacts(state, data) {
      const index = state.contacts.models.findIndex(i => i.id == data.id);
      state.contacts.models.splice(index, 1);
    },
    addNewContact(state, data) {
      for (var i = 0; i < data.length; i++) {
        state.contacts.models.splice(0, 0, data[i]);
      }
    },
    deleteAllContacts(state, data) {
      state.contacts = [];
    },
    updateContactData(state, data) {
      for (var i = 0; i < state.contacts.length; i++) {
        if (state.contacts[i].id == data.id) state.contacts[i] = data;
      }
    },
    setSenders(state, data) {
      state.senders = data;
    },
    setSelectedContactSender(state, data) {
      console.log("ina mutations =>", data);
      state.selectedContactSender = data;
    },
    setSelectedContactList(state, data) {
      state.selectedContactList = data;
    },
    setSelectedContactsFilter(state, data) {
      state.selectedContactFilter = data;
    },
    unsetSelectedContactsFilter(state, data) {
      state.selectedContactFilter = data;
    },
    updateSenders(state, data) {
      const index = state.senders.findIndex(i => i.id == data.id);
      state.senders.splice(index, 1);
    },
    setCreatedContactIds(state, data) {
      state.createdContactIds.push(data);
    },
    setCallgetAllContactApis(state, data) {
      state.callgetAllContactApi = data;
    },
    setContactsFromFile(state, data) {
      state.contactsFromFile = [...data];
    },
    setLatestCallURL(state, data) {
      state.latestCallURL = data;
    }
  },

  actions: {
    setSelectedContactSender({ state, commit, rootState }, data) {
      console.log("ina action =>", data);
      commit("setSelectedContactSender", data);
    },
    setSelectedContactList({ state, commit, rootState }, data) {
      commit("setSelectedContactList", data);
    },
    setSelectedContactsFilter({ state, commit, rootState }, data) {
      commit("setSelectedContactsFilter", data);
    },
    unsetSelectedContactsFilter({ state, commit, rootState }, data) {
      commit("setSelectedContactsFilter", data);
    },
    setSenders({ state, commit, rootState }, data) {
      commit("setSenders", data);
    },
    updateSenders({ state, commit, rootState }, data) {
      commit("updateSenders", data);
    },
    setClearContactDetails({ state, commit, rootState }) {
      commit("clearContactDetails", {
        id: null,
        first_name: null,
        last_name: null,
        email: null,
        phone: null,
        company_name: null,
        title: null,
        company: {}
      });
    },
    createContact({ state, commit, rootState }, data) {
      return new Promise(async function(resolve, reject) {
        data = data.filter(el => !!el.email);
        console.log("98- data", data);
        let contactsArray = arraysLib.chunk(data, 100);
        console.log("98- contactsArray", contactsArray);
        let url =
          config.DOMAIN +
          config.API_VERSION +
          "organizations/" +
          rootState.auth.whoami.active_organization.id +
          "/campaigncontacts";
        let responses = [];
        for (let idx = 0; idx < contactsArray.length; idx++) {
          const data = contactsArray[idx];
          try {
            let response = await axios.post(url, data);
            commit("addNewContact", response.data);
            responses.push(response);
          } catch (e) {
            console.log("Some ERR ", e);
            reject(e);
            break;
          }
        }
        resolve(responses);
      }); // promise
    },
    getContacts({ state, commit, rootState }, data) {
      return new Promise(function(resolve, reject) {
        console.log("inside getContatcs data --", data);
        let url =
          config.DOMAIN +
          config.API_VERSION +
          "organizations/" +
          rootState.auth.whoami.active_organization.id +
          "/campaigncontacts?";

        if (!!data.organization_user_id)
          url = url + "&organization_user_id=" + data.organization_user_id;
        if (!!data.limit) url = url + "&limit=" + data.limit;
        if (!!data.offset) url = url + "&offset=" + data.offset;

        axios
          .get(url)
          .then(response => {
            console.log("response -- ", response.data);
            commit("setContacts", response.data);
            resolve(response);
          })
          .catch(e => {
            console.log("Post Request join group Error: ", e);
            reject(e.response.data.message);
          }); //axios
      }); // promise
    },
    getContactsCount({ state, commit, rootState }, data) {
      return new Promise(function(resolve, reject) {
        axios
          .get(
            config.DOMAIN +
              config.API_VERSION +
              "organizations/" +
              rootState.auth.whoami.active_organization.id +
              "/campaigncontacts?count_required=true&organization_user_id=" +
              data.organization_user_id
          )
          .then(response => {
            console.log("response -- ", response.data);
            commit("setContactsCount", response.data);
            state.offset += 5;
            resolve(response);
          })
          .catch(e => {
            console.log("Post Request join group Error: ", e);
            reject(e.response.data.message);
          }); //axios
      }); // promise
    },
    removeContact({ state, commit, rootState }, contact) {
      return new Promise(function(resolve, reject) {
        axios
          .delete(
            config.DOMAIN +
              config.API_VERSION +
              "organizations/" +
              rootState.auth.whoami.active_organization.id +
              "/campaigncontacts/" +
              contact.id
          )
          .then(
            response => {
              console.log("response -- ", response.data);
              //commit("updateContacts", response.data);
              resolve(response);
            },
            error => {
              reject(e.response.data.message);
            }
          )
          .catch(e => {
            console.log("Delete Request to remove group Member: ", e);
          }); //axios
      }); // promise
    },
    removeAllContacts({ state, commit, rootState }, data) {
      return new Promise(function(resolve, reject) {
        axios
          .post(
            config.DOMAIN +
              config.API_VERSION +
              "organizations/" +
              rootState.auth.whoami.active_organization.id +
              "/campaigncontacts/deleteall",
            data
          )
          .then(
            response => {
              console.log("response -- ", response.data);
              commit("deleteAllContacts", []);
              resolve(response);
            },
            error => {
              reject(e.response.data.message);
            }
          )
          .catch(e => {
            console.log("Delete Request to remove group Member: ", e);
          }); //axios
      }); // promise
    },
    getSampleContactCsvFileData({ state, commit, rootState }) {
      return new Promise(function(resolve, reject) {
        axios
          .get(
            config.DOMAIN +
              config.API_VERSION +
              "campaigncontacts/contactsample"
          )
          .then(response => {
            //resolve(response);
          })
          .catch(e => {
            console.log("getSampleContactCsvFileData", e);
            reject(e.response.data.message);
          }); //axios
      });
    },
    updateContact({ state, commit, rootState }, data) {
      return new Promise(function(resolve, reject) {
        axios
          .put(
            config.DOMAIN +
              config.API_VERSION +
              "organizations/" +
              rootState.auth.whoami.active_organization.id +
              "/campaigncontacts/" +
              data.id,
            data
          )
          .then(response => {
            console.log("response -- ", response.data);
            resolve(response);
            commit("updateContactData", response.data[0]);
          })
          .catch(e => {
            console.log("Post Request join group Error: ", e);
            reject(e.response.data.message);
          }); //axios
      }); // promise
    },
    setContacts({ state, commit, rootState }, data) {
      commit("setContacts", data);
    },
    setCreatedContactIds({ state, commit }, data) {
      commit("setCreatedContactIds", data);
    },
    setCallgetAllContactApis({ state, commit }, data) {
      commit("setCallgetAllContactApis", data);
    },
    setContactsFromFile({ state, commit, rootState }, data) {
      commit("setContactsFromFile", data);
    },
    setLatestCallURL({ state, commit, rootState }, data) {
      commit("setLatestCallURL", data);
    }
  }
};
