var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("div", { staticClass: "list__header" }, [
        _vm.uploadedFileIds.length < _vm.uploadedFiles.length
          ? _c("p", { staticClass: "list__header_title" }, [
              _vm._v(
                "\n      Uploading " +
                  _vm._s(_vm.uploadedFileIds.length) +
                  "/" +
                  _vm._s(_vm.uploadedFiles.length) +
                  " Items\n    "
              )
            ])
          : _c("p", { staticClass: "list__header_title" }, [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.uploadedFileIds.length) +
                  " Uploads Complete\n    "
              )
            ]),
        _vm._v(" "),
        _c("div", { staticClass: "list__header_controls" }, [
          !_vm.isCollapsed
            ? _c("i", {
                staticClass: "fas fa-chevron-down",
                on: {
                  click: function($event) {
                    _vm.isCollapsed = true
                  }
                }
              })
            : _c("i", {
                staticClass: "fas fa-chevron-up",
                on: {
                  click: function($event) {
                    _vm.isCollapsed = false
                  }
                }
              }),
          _vm._v(" "),
          _c("i", {
            staticClass: "fas fa-times",
            on: { click: _vm.closePopup }
          })
        ])
      ]),
      _vm._v(" "),
      _c(
        "keep-alive",
        [
          !_vm.isCollapsed
            ? _c("transition", [
                _c(
                  "div",
                  { staticClass: "list__wrapper" },
                  _vm._l(_vm.filesToUpload, function(listElement) {
                    return _c(
                      "div",
                      { staticClass: "list__element" },
                      [
                        _c("p", { staticClass: "list__element_name" }, [
                          _vm._v(_vm._s(listElement.name))
                        ]),
                        _vm._v(" "),
                        _c("p", { staticClass: "list__element_size" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.formatBytes(listElement.size)) +
                              "\n          "
                          )
                        ]),
                        _vm._v(" "),
                        _c("Progress", {
                          attrs: {
                            value: _vm.uploadedFileIds.includes(listElement.id)
                              ? 100
                              : _vm.uploadFileId == listElement.id
                              ? _vm.uploadPercent
                              : 0,
                            strokeColor: "#3c7bc1",
                            radius: 20
                          }
                        })
                      ],
                      1
                    )
                  }),
                  0
                )
              ])
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }