import { mapGetters, mapActions, mapState } from "vuex";
import mattermostChat from "../services/mattermostChat";

export const mmChats = {
  data() {
    return {};
  },
  computed: {
    ...mapState({
      selectedChannel: state => state.chats.selectedChannel,
      currentPage: state => state.chats.currentPage,
      channelMessages: state => state.chats.channelMessages
    })
  },
  methods: {
    ...mapActions("chats", {
      setChannelMessages: "setChannelMessages",
      setHasMoreMessages: "setHasMoreMessages"
    }),
    async fetchMessages() {
      //this.$root.loading = true;
      const data = {
        channel_id: this.selectedChannel.id,
        page_no: this.currentPage
      };
      try {
        const res = await mattermostChat.fetchChannelMessages(data);
        if (res) {
          const msgOrder = res.data.order.reverse();
          const msgPosts = res.data.posts;
          const groupMessages = msgOrder.map(postId => {
            if (msgPosts[postId]) {
              return msgPosts[postId];
            }
          });
          if (this.currentPage > 0) {
            if (groupMessages.length < 20) {
              this.setHasMoreMessages();
            }
            this.setChannelMessages([
              ...groupMessages,
              ...this.channelMessages
            ]);
            //this.$root.loading = false;
          } else {
            this.setChannelMessages(groupMessages);
            //this.$root.loading = false;
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
  }
};
