<template>
  <div class="container">
    <div class="list__header">
      <p
        v-if="uploadedFileIds.length < uploadedFiles.length"
        class="list__header_title"
      >
        Uploading {{ uploadedFileIds.length }}/{{ uploadedFiles.length }} Items
      </p>
      <p v-else class="list__header_title">
        {{ uploadedFileIds.length }} Uploads Complete
      </p>
      <div class="list__header_controls">
        <i
          v-if="!isCollapsed"
          class="fas fa-chevron-down"
          @click="isCollapsed = true"
        ></i>
        <i v-else class="fas fa-chevron-up" @click="isCollapsed = false"></i>
        <i class="fas fa-times" @click="closePopup"></i>
      </div>
    </div>
    <keep-alive>
      <transition v-if="!isCollapsed">
        <div class="list__wrapper">
          <div class="list__element" v-for="listElement in filesToUpload">
            <p class="list__element_name">{{ listElement.name }}</p>
            <p class="list__element_size">
              {{ formatBytes(listElement.size) }}
            </p>
            <Progress
              :value="
                uploadedFileIds.includes(listElement.id)
                  ? 100
                  : uploadFileId == listElement.id
                  ? uploadPercent
                  : 0
              "
              strokeColor="#3c7bc1"
              :radius="20"
            ></Progress>
          </div>
        </div>
      </transition>
    </keep-alive>
  </div>
</template>
<script>
import { mapState, mapGetters, mapActions } from "vuex";
import FileUpload from "vue-upload-component";
import utils from "../../../utils.js";
import config from "./../../../config";
import * as moment from "moment-timezone";
import Progress from "easy-circular-progress";

export default {
  name: "UploadProgress",
  props: ["filesToUpload"],
  data() {
    return {
      isCollapsed: false
    };
  },
  components: {
    FileUpload,
    Progress
  },
  computed: {
    ...mapState({
      whoami: state => state.auth.whoami,
      isAuth: state => state.auth.isAuth,
      activeOrganization: state => state.auth.activeOrganization,
      uploadPercentage: state => state.contentlibrary.uploadPercentage,
      uploadFileId: state => state.contentlibrary.uploadPercent,
      uploadedFileIds: state => state.contentlibrary.uploadedFileIds,
      uploadedFiles: state => state.contentlibrary.uploadedFiles,
      toDisplayPopup: state => state.contentlibrary.toDisplayPopup
    })
  },
  watch: {
    filesToUpload: function(newVal, oldVal) {
      console.log(filesToUpload);
    }
  },
  methods: {
    ...mapActions("contentlibrary", {
      setToDisplayPopup: "setToDisplayPopup"
    }),
    formatBytes(a, b) {
      if (0 == a) return "0 Bytes";
      var c = 1024,
        d = b || 2,
        e = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
        f = Math.floor(Math.log(a) / Math.log(c));
      return parseFloat((a / Math.pow(c, f)).toFixed(d)) + " " + e[f];
    },
    closePopup() {
      console.log("In closepopup");
      this.setToDisplayPopup(false);
    }
  },
  mounted() {}
};
</script>
<style scoped>
.container {
  display: inline-block;
  z-index: 100;
  width: 350px;
  max-height: 300px;
  background-color: rgb(255, 255, 255);
  bottom: 0px;
  right: 0px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  position: fixed;
}
.list__element {
  display: flex;
  padding-left: 20px;
  width: 100%;
  border-bottom: 1px solid rgb(204, 204, 204);
  justify-content: space-between;
}
.list__element_name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 180px;
}
.list__element_size {
  color: rgb(137, 131, 131);
}
.list__header_title {
  color: white;
  margin: 0px;
  height: 50px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  font-weight: bold;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
.list__wrapper {
  overflow-y: scroll;
  height: 250px;
  overflow-x: hidden;
}
.list__header {
  display: flex;
  background: black;
  color: rgb(255, 255, 255);
  justify-content: space-between;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
.list__header_controls {
  float: right;
  margin-right: 20px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 60px;
  font-size: 1.5em;
}
</style>
<style>
.vue-circular-progress .percent__int {
  font-size: 12px;
}
span.percent__dec,
span.dot {
  display: none;
}
.vue-circular-progress,
.vue-circular-progress .percent_sign {
  font-size: 12px;
}
</style>
