import axios from "axios";
import toastr from "toastr";
import config from "../../config.js";
import * as moment from "moment";

export default {
  namespaced: true,
  state: {
    salespersons: [],
    selectedSalespersons: {},
    callgetAllSalespersonsApi: false,
    createdSenderIds: []
  },

  getters: {},

  mutations: {
    setSalespersons(state, data) {
      state.salespersons = data;
    },
    addSalespersonToList(state, data) {
      console.log("inside muta addSalespersonToList", data);
      state.salespersons.push(data);
    }
    // setSelectedSender(state, data) {
    //   state.selectedSender = !!data ? data : {};
    //   console.log("setSelectedSender -- ", state.selectedSender);
    // },
    // removeSenders(state, data) {
    //   const index = state.senders.findIndex(element => element.id === data.id);
    //   state.senders.splice(index, 1);
    // },
    // setCallgetAllSalespersonsApis(state, data) {
    //   state.callgetAllSalespersonsApi = data;
    // }
  },

  actions: {
    setSalespersons({ state, commit }, data) {
      commit("setSalespersons", data);
    },
    addSalespersonToList({ state, commit }, data) {
      console.log("inside action addSalespersonToList", data);
      commit("addSalespersonToList", data);
    }
    // setSelectedSender({ state, commit }, data) {
    //   commit("setSelectedSender", data);
    // },
    // removeSenders({ state, commit }, data) {
    //   commit("removeSenders", data);
    // },
    // setCallgetAllSalespersonsApis({ state, commit }, data) {
    //   commit("setCallgetAllSalespersonsApis", data);
    // }
  }
};
