import utils from "../../../utils";
import * as moment from "moment";

export const helpers = {
  data() {
    return {
      moment: moment
    };
  },
  methods: {
    /**
     *  returns the number in US locale formatted way
     * @param {number} number number to format
     * @returns {number} returns the number in US locale formatted way
     */
    numberFormatterToUS(number) {
      if (!number) return 0;
      const formattedNumber = new Intl.NumberFormat("en-US").format(number);
      return formattedNumber == "NaN" ? "0" : formattedNumber;
    },

    /**
     * this is first letter capitalizer
     * @param {String} word word to capitalize
     * @returns returns first letter in capitalized
     */
    capitalizeFirstLetter(word) {
      return (
        word
          .toString()
          .charAt(0)
          .toUpperCase() + word.slice(1)
      );
    },

    /**
     *
     * @param {String} string String to do replace operation
     * @param {String} replace String that needs to be replace
     * @param {String} replaceWith String that need to be replaced with.
     * @returns String with removed given string
     */
    replaceWithGivenString(string, replace, replaceWith) {
      if (!string) return null;
      return string.replace(replace, replaceWith);
    },

    /**
     * This is a capitalizer of first letter of each word.
     * @param {string} str string of words to make string capitalize.
     * @returns capitalize first letter of each word
     */

    capitalizeFirstLetterOfEachWord(str) {
      return str.toString().replace(/(^\w|\s\w)/g, m => m.toUpperCase());
    },

    /**
     * This removes the last word from given sentence
     * @param {String} str String to remove the last word
     * @returns String with removed last word from original sentence
     */
    removeLastWord(str) {
      const lastIndexOfSpace = str.lastIndexOf(" ");

      if (lastIndexOfSpace === -1) {
        return str;
      }

      return str.substring(0, lastIndexOfSpace);
    },

    /**
     * This will format normal number to US currency
     * @param {Number} number number to convert to US currency
     * @returns Returns formatted number with US currency
     */
    numberToUSCurrencyFormatter(number) {
      var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD"
      });
      let formatted = formatter.format(number);
      return formatted == "$NaN" ? "$0.00" : formatted;
    },

    /**
     * This will check if HTTP/HTTPS is there or not, if not then will append it.
     * @param {String} url URL to be checked
     * @returns URL with http or https
     */
    getValidateURL(url) {
      let ret = url ? utils.checkHttpAppendIfNot(url) : "/";
      return ret;
    },

    /**
     * This will format the given price in terms of millions and billions dollar
     * @param {*} price
     * @returns formatted string as per price
     */
    currencyFormatterInMillionAndBillion(price) {
      price = parseInt(price);
      if (price >= 1000000000) {
        return "$" + price / 1000000000 + "B";
      } else if (price >= 1000000) {
        return "$" + price / 1000000 + "M";
      } else if (price >= 1000) {
        return "$" + price / 1000 + "K";
      } else {
        return "$" + price;
      }
    },

    /**
     * This will format date to format passed Moment.js
     * @param {Number} date number to convert to US currency
     * @param {String} format format that the date will be converted to.
     * @returns Returns formatted number with US currency
     */
    datetimeFormatter(updatedDate, formatToConvert) {
      var zone = moment.tz.guess();
      var utcCutoff = moment.utc(updatedDate, "YYYYMMDD HH:mm:ss");
      var displayCutoff = utcCutoff.clone().tz(zone);
      return displayCutoff.format(formatToConvert);
    }
  }
};
