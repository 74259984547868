import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import slRoutes from "./routes";
import settings from "./settings";
// import socialPosts from "./socialPosts";
// import moderate from "./moderate";
import organization from "./organization";
import contentDrip from "./contentDrip";
import workspace from "./workspace";
import campaign from "./campaign";
import hub from "./hub";
import poc from "./poc";
import integrations from "./integrations";
import tasks from "./tasks";
import common from "./common";
import socketInit from "../utils/socket";
import { timers } from "jquery";
import superAdmin from "./admin";
import SalesPersonApplication from "./salesPersonApplication";
import CompanyApplication from "./companyApplication";
import Swal from "sweetalert2";
import companyRoutePatterns from "../objects/RoutePatternAccess/companyRoutePatterns";
import salespersonRoutePatterns from "../objects/RoutePatternAccess/salespersonRoutePatterns";

//import account from "./account";

Vue.use(VueRouter);

var allRoutes = [];
allRoutes = allRoutes.concat(
  slRoutes,
  // socialPosts,
  settings,
  // moderate,
  organization,
  contentDrip,
  workspace,
  campaign,
  hub,
  poc,
  integrations,
  tasks,
  common,
  superAdmin,
  SalesPersonApplication,
  CompanyApplication
);

let domainOrgId = !!document.getElementById("domainOrgId")
  ? document.getElementById("domainOrgId").value
  : null;

let domainOrgSlug = !!document.getElementById("domainOrgSlug")
  ? document.getElementById("domainOrgSlug").value
  : null;

let isGlobal = !!document.getElementById("isGlobal")
  ? document.getElementById("isGlobal").value
  : null;

// console.log(domainOrgId);
// console.log("isGlobal", isGlobal);
// console.log(!!domainOrgId && domainOrgId > 0);

if (!!domainOrgId && domainOrgId > 0) {
  console.log("38- changing routes");
  allRoutes = allRoutes.filter(el => {
    el.path = el.path.replace(":organization_slug/", "");
    // console.log("routes el", el);
    return el;
  });
}
// console.log("38- not changing routes");

// console.log("router allRoutes 38", allRoutes);

const routes = allRoutes;
var router = new VueRouter({
  mode: "history",
  routes,
  linkActiveClass: "link-active",
  computed: {}
});

router.beforeEach((to, from, next) => {
  // console.log("router 43--> ", to);
  // console.log("45--> ", next);
  // let isPublic = to.matched.some(record => record.meta.isPublic)
  const publicPages = [
    "/verify_email",
    "/login",
    "/sign_up",
    "/appointment",
    "/register",
    "/about",
    "/forgot_password",
    "/message",
    "/registration_failure",
    "/accept_invite",
    "/retry_connecting",
    "/temporary_password",
    "/project/.*",
    "/invite/contribute/.*",
    "/stream/.*",
    "/projects/.*",
    "/profile/.*",
    "organization/.*",
    "/group/.*/stream/.*",
    ".*/uhub/.*/.*",
    ".*/hub/.*",
    ".*/campaign/.*",
    ".*/admin/unsubscribe/.*/.*",
    "/blog/.*",
    "/blog/profile/:*",
    "/apply",
    "salesperson/join",
    "salesperson/login",
    "company/login",
    "salesperson/forgot_password",
    "company/forgot_password",
    "company/get_started",
    ".*/live-subscription-page/.*"
  ];

  //const paidPages = ["/links_drip", "/ads_drip", "/domains"];
  const paidPages = [];
  console.log("route -- ", to.path, to);
  let authRequired = true;

  authRequired =
    publicPages.filter(function(pattern) {
      return new RegExp(pattern).test(to.path);
    }).length == 0;

  // setting profile routes that need authorization
  if (
    to.path.includes("/salesperson/profile/") ||
    to.path.includes("/more/profile/details") ||
    to.path.includes("campaign/create") ||
    to.path.includes("/leads/profile/")
  ) {
    // urls require authorization
    authRequired = true;
  }
  if (to.path.includes("/verify_email") && to.query?.role_id) {
    to.meta.role_id = 2;
  }

  const subscriptionRequired = paidPages.includes(to.path);
  console.log("authRequired - ", authRequired);

  const doNotShowChatBubbleOnUrl = [
    "/appointment/book/event",
    "/appointment/book/scheduleevents",
    "/appointment/book/confirmappointment",
    "/curatedarticle/createstep1",
    "/curatedarticle/addauthor",
    "/advocacy_drip?is_extension=true",
    "/ideas?is_extension=true",
    "/score?is_extension=true",
    "/analytics?is_extension=true",
    "/notifications?is_extension=true"
  ];

  if (
    doNotShowChatBubbleOnUrl.includes(to.path) ||
    doNotShowChatBubbleOnUrl.includes(to.fullPath)
  )
    store.commit("setLoadChatBubble", false);

  if (to.path == "/") {
    let localUserRole = JSON.parse(localStorage.getItem("userRole"));
    if (localUserRole && localUserRole.role_id) {
      store.dispatch("setUserRole", localUserRole, { root: true });
    }
  } else if (to.meta && to.meta.role_id) {
    store.dispatch("setUserRole", { role_id: to.meta.role_id }, { root: true });
  } else {
    store.dispatch("setUserRole", { role_id: 1 }, { root: true });
  }

  console.warn("Query", to.query);
  console.warn("Path", to.path, to.fullPath);

  let previewUrl = to.fullPath;
  if (previewUrl.indexOf("?preview=1") != -1)
    store.dispatch("setCampaignPreviewUrl", previewUrl, { root: true });

  let refresh = false;
  if (!!to.query.refresh) {
    refresh = true;
  }

  // =============================================================
  // if: !WhoAmI || isFirstCheck => usually after refresh
  if (
    refresh ||
    Object.keys(store.state.auth.whoami).length == 0 ||
    store.state.auth.isFirstCheck
  ) {
    // 1. set is first check
    store.commit("auth/setIsFirstCheck", false);
    // 2. get whoAmI
    store.dispatch("auth/whoAmI", null, { root: true }).then(response => {
      console.log("response===========", response);
      console.log("92 in whoami", response.data);
      store.commit("auth/setWhoAmI", response.data);

      getSetDefaultOrgAndGroup(
        authRequired,
        subscriptionRequired,
        response.data,
        next,
        to
      );
      // next(redirectTo);
    });
  } else {
    // ==============================================================
    // else: When we navigate via Vue routes without refresh
    console.log("In else 106");
    let whoami = store.state.auth.whoami;
    getSetDefaultOrgAndGroup(
      authRequired,
      subscriptionRequired,
      whoami,
      next,
      to
    );
  }
});

//======================================================================
// this will fetch all organizations, will set defaultOrganization and defaultOrganizationGroup
function getSetDefaultOrgAndGroup(
  authRequired,
  subscriptionRequired,
  whoami,
  next,
  to
) {
  // console.log("next --> ", next);
  socketInit(router, to, whoami, domainOrgSlug);

  // if (!authRequired && to.path != "/") return next();
  // else if (!authRequired && to.path == "/" && !!whoami.page_domains) {
  //   console.log("Line:218=====================");
  //   let res = whoami.page_domains.find(
  //     pd => pd.domain_name == window.location.hostname
  //   );
  //   console.log("Res==============", res);
  //   if (!!res) {
  //     console.log("Line::: 224");
  //     return next();
  //   } else {
  //     console.log("Line::: 227");
  //     authRequired = true;
  //   }
  // }
  if (to.path == "/" && !!whoami.page_domains) {
    let res = whoami.page_domains.find(
      pd => pd.domain_name == window.location.hostname
    );
    if (!!res) {
      // console.log("Line::: 220");
      return next();
    }
  }

  /// redirect to join the org
  // console.log("whoami 228", whoami.organization_join_link_id);
  if (whoami.userid > 0 && parseInt(whoami.organization_join_link_id) > 0) {
    let link =
      "/api/v1/organization_join_link/" +
      whoami.organization_join_link_id +
      "?test=ui&id=" +
      whoami.userid;
    window.location.href = link;
    return;
  }

  if (!authRequired) return next();
  else if (authRequired && whoami.userid == null) {
    console.warn(":227 ->", to.path, to.fullPath);
    let previewPathStr = to.fullPath;
    if (previewPathStr.indexOf("preview") != -1) {
      store.dispatch("setCampaignPreviewUrl", previewPathStr, { root: true });
    }

    localStorage.setItem("historyUrl", to.path);
    store.commit("auth/logoutUser");
    return next("/login");
  }
  // else if ( !!whoami.userid && (to.path == "/login" || to.path == "/register"))
  //   return next("/organizations");
  else {
    // 1. getAllOrganizations ========================================
    store.dispatch("auth/getAllOrganizations", null, { root: true });
    // 2. set activeOrganization & activeOrganizationGroup ===========
    let activeOrganization = whoami.active_organization;
    // console.log("148", activeOrganization);
    store.commit("auth/setActiveOrganization", activeOrganization);
    store.commit(
      "auth/setActiveOrganizationGroup",
      whoami.active_organization_group
    );
    // this will check which page should allow to subscriber and which should not?

    // 3. check subscription and redirect accordingly
    if (
      !subscriptionRequired ||
      (subscriptionRequired == true &&
        activeOrganization.active_subscription == 1)
    ) {
      let nextLink = "";
      // check before login, if any route is there in history
      let historyUrl = localStorage.getItem("historyUrl");

      if (!!historyUrl && historyUrl.length > 0 && historyUrl != "/") {
        localStorage.setItem("historyUrl", "");
        window.location.replace(historyUrl);
      }

      if (to.path == "/") {
        defaultSlashRedirect(whoami, nextLink, next);
      } else {
        let currentwhoami = localStorage.getItem("whoami");
        let loggedInRole = JSON.parse(currentwhoami).role_id;

        if (whoami.role_id != loggedInRole) {
          SwalAlertForRoleMismatch(whoami, nextLink, next);
          return;
        }

        // const salestoolPages = [
        //   "/.*/admin/getting_started",
        //   "/.*/admin/posting/profile/.*",
        //   "/.*/admin/posting/search",
        //   "/.*/admin/posting/search",
        //   "/.*/admin/posting/dashboard",
        //   "/.*/admin/companies/summary",
        //   "/.*/admin/posting/summary/.*",
        //   "/.*/admin/posting/dashboard",
        //   "/.*/admin/posting/summary/freelancer/.*",
        //   "/.*/admin/company/add"
        // ];

        const companyApplicationPages = companyRoutePatterns.routePatterns;
        let isCompanyRoute =
          companyApplicationPages.filter(function(pattern) {
            return new RegExp(pattern).test(to.path);
          }).length > 0;

        const salespersonApplicationPages =
          salespersonRoutePatterns.routePatterns;
        let isSalespersonRoute =
          salespersonApplicationPages.filter(function(pattern) {
            return new RegExp(pattern).test(to.path);
          }).length > 0;

        if (isSalespersonRoute) {
          console.log("isSalespersonRoute", isSalespersonRoute);
          if (whoami.role_id == 2 && loggedInRole == 2) {
            return next();
          } else {
            SwalAlertForRoleMismatch(whoami, nextLink, next);
            return;
          }
        } else if (isCompanyRoute) {
          console.log("isCompanyRoute", isCompanyRoute);
          if (whoami.role_id == 3 && loggedInRole == 3) {
            return redirectToCompanyRouteCheck(whoami, to.path, next);
          } else {
            SwalAlertForRoleMismatch(whoami, nextLink, next);
            return;
          }
        } else {
          if (whoami.role_id == 2 || whoami.role_id == 3) {
            SwalAlertForRoleMismatch(whoami, nextLink, next);
            return;
          } else return next();
        }
      }
    } else {
      store.dispatch("subscriptions/checkout");
      return false;
    }
  }
}

function defaultSlashRedirect(whoami, nextLink, next) {
  if (whoami.role_id == 2) {
    nextLink = "/admin/salesperson/" + whoami.redirect_to_step;
  } else if (whoami.role_id == 3) {
    if (whoami?.company_info?.slug) {
      nextLink = "/admin/company/" + whoami.company_info.slug + "/overview";
    } else nextLink = "/admin/company/" + whoami.redirect_to_step;
  } else if (whoami.organizations_count <= 0) {
    // redirected to user registration step 1 when there is no organization available for the logged in user
    // nextLink = "/organizations";
    nextLink = "/user_registration/step1";
  } else if (!!domainOrgId) {
    let campaignPreviewUrl = localStorage.getItem("campaignPreviewUrl");
    if (
      !!localStorage.getItem("campaignPreviewUrl") &&
      campaignPreviewUrl != "null"
    ) {
      nextLink = localStorage.getItem("campaignPreviewUrl");
      localStorage.setItem("campaignPreviewUrl", "");
    }
  } else if (
    whoami.active_organization &&
    whoami.active_organization.shortcode
  ) {
    let campaignPreviewUrl = localStorage.getItem("campaignPreviewUrl");

    //typeof localStorage.getItem("campaignPreviewUrl") retun as string
    if (!!campaignPreviewUrl && campaignPreviewUrl != "null") {
      nextLink = campaignPreviewUrl;
      localStorage.setItem("campaignPreviewUrl", "");
    } else {
      if (!!whoami.page_domains) {
        let res = whoami.page_domains.find(
          pd => pd.domain_name == window.location.hostname
        );
        if (res) {
          return next();
        } else {
          nextLink += "/" + whoami.active_organization.shortcode;
          nextLink += "/admin/getting_started";
          return next(nextLink);
        }
      } else {
        nextLink += "/" + whoami.active_organization.shortcode;
        nextLink += "/admin/getting_started";
      }
    }
  }
  console.log("nextLink -- >", nextLink);
  if (!!nextLink == false) nextLink = "/admin/getting_started";
  return next(nextLink);
}

function SwalAlertForRoleMismatch(whoami, nextLink, next) {
  Swal.fire({
    title: "Wrong role for the current page",
    text:
      "Your role was changed in another window. The current page may no longer work as expected.",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Log out",
    showConfirmButton: true,
    allowEscapeKey: false
  }).then(result => {
    if (result.isConfirmed) {
      store.dispatch("auth/logoutUser", null, { root: true });
    }
  });
  // Swal.fire({
  //   title: "Wrong role for the current page",
  //   text:
  //     "Your role was changed in another window. The current page may no longer work as expected.",
  //   icon: "warning",
  //   showCancelButton: true,
  //   confirmButtonColor: "#3085d6",
  //   cancelButtonColor: "#d33",
  //   confirmButtonText: "Switch to latest role",
  //   showConfirmButton: true,
  //   allowEscapeKey: false
  // }).then(result => {
  //   if (result.isConfirmed) {
  //     store.dispatch("auth/whoAmI", null, { root: true }).then(response => {
  //       store.commit("auth/setWhoAmI", response.data);
  //       whoami = store.state.auth.whoami;
  //       defaultSlashRedirect(whoami, nextLink, next);
  //     });
  //   }
  // });
}
function redirectToCompanyRouteCheck(whoami, toPath, next) {
  if (whoami?.company_info) {
    return next();
  } else if (
    toPath.includes("/company/switchcompanies") ||
    toPath.includes("/company/add") ||
    toPath.includes("/company/step")
  ) {
    return next();
  } else {
    toPath = "/admin/company/switchcompanies";
    return next(toPath);
  }
}
export default router;
