import router from "../../router";
import config from "../../config.js";
import Swal from "sweetalert2";

export default {
  namespaced: true,
  state: {},
  getters: {
    // computed properties
  },

  mutations: {},
  actions: {
    showLicenseUsageLimitReachedNotification({ state, commit, rootState }) {
      let title = "";
      let text = "";
      let organization_role =
        rootState.auth.whoami.organization_user.organization_role_id;
      let addonName = "";
      let showConfirmButton = true;
      let hasUserPermission = organization_role == 5 || organization_role == 4;
      let teamText = "";

      if (!hasUserPermission) showConfirmButton = false;
      addonName = "Licenses";
      title = "License Limit Reached!";
      let adminText =
        "Your organization has reached its max license limit. No more users can be added.";
      teamText =
        "Your organization has reached its max license limit. No more users can be added. Please contact your account owner to add more users.";
      text = hasUserPermission ? adminText : teamText;

      if (title != "") {
        Swal.fire({
          title: title,
          text: text,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#3085d6",
          confirmButtonText: "Add More " + addonName,
          cancelButtonText: "OK",
          showConfirmButton: showConfirmButton
        }).then(result => {
          console.log("result => ", result);
          if (result.isConfirmed) {
            let url = "/admin/billing";
            router.push(url);
          } else {
            if (!hasUserPermission) router.push("/organizations");
          }
        });
      }
    },
    showNotification({ state, commit, rootState }, data) {
      /**
       * I saw this code on Jan 19, 2022.
       *
       * This code should be cleaned.
       * I added 1 change for now in directly current code, Because that was not written well
       * earlier.
       *
       * @TODO
       * Clean this function
       */
      console.log(
        "inside showNotification data =>",
        data,
        data.active_organization
      );
      let title = "";
      let text = "";
      let organization_role =
        rootState.auth.whoami.organization_user.organization_role_id;
      let addonName = "";
      let showConfirmButton = true;
      let hasUserPermission = organization_role == 5 || organization_role == 4;
      let teamText = "";

      if (!hasUserPermission) showConfirmButton = false;

      let a = !!data.active_organization;
      if (
        a &&
        parseInt(
          !!data.active_organization.contacts
            ? data.active_organization.contacts
            : 0
        ) >= parseInt(data.active_organization.max_contacts)
      ) {
        addonName = "Contacts";
        title = "Contact Limit Reached!";
        let adminText =
          "Your organization has reached its max number of contacts. No more contacts can be added.";
        teamText =
          "Your organization has reached its max number of contacts. No more contacts can be added. Please contact your account owner to add more contacts.";
        text = hasUserPermission ? adminText : teamText;
      } else if (
        (parseInt(data.count) > 0 ?? false) ||
        (a &&
          parseInt(data.active_organization.messages) >=
            parseInt(data.active_organization.max_messages))
      ) {
        let adminText = "";
        addonName = "Messages";
        title = "Message Limit Reached!";
        adminText =
          "Your organization has reached your max number of email messages and page views for shared links your plan allows per month. Please upgrade your plan to continue using the system.";
        teamText =
          "Your organization has reached your max number of email messages and page views for shared links your plan allows per month. Please upgrade your plan to continue using the system. Please contact your account owner to add more messages.";

        if (data.count > 0) {
          title = "Message Limit Will Be Reached!";
          adminText =
            "Your organization will reach your max number of email messages after this campaign and page views for shared links your plan allows per month. Please upgrade your plan to continue using the system.";
          teamText =
            "Your organization will reach your max number of email messages after this campaign and page views for shared links your plan allows per month. Please upgrade your plan to continue using the system. Please contact your account owner to add more messages.";
        }
        text = hasUserPermission ? adminText : teamText;
      } else if (
        a &&
        data.subscription_addon != null &&
        data.subscription_addon.isStorageUsageLimitReached
      ) {
        addonName = "Storage";
        title = "Storage Limit Reached!";
        let adminText =
          "Your organization has reached its max storage limit. No more gigs of storage can be used.";
        teamText =
          "Your organization has reached its max storage limit. No more gigs of storage can be used. Please contact your account owner to add more storage.";
        text = hasUserPermission ? adminText : teamText;
      } /*
      //Comment: Now, we are not conisder Licenses on billing
      else if (
        data.subscription_addon != null &&
        data.subscription_addon.isLicenseUsageLimitReached
      ) {
        addonName = "Licenses";
        title = "License Limit Reached!";
        let adminText =
          "Your organization has reached its max license limit. No more users can be added.";
        teamText =
          "Your organization has reached its max license limit. No more users can be added. Please contact your account owner to add more users.";
        text = hasUserPermission ? adminText : teamText;
      }*/ else if (
        a &&
        parseInt(data.active_organization.dynamic_landing_pages) >=
          parseInt(data.active_organization.max_dynamic_landing_pages)
      ) {
        addonName = "Dynamic Landing Pages";
        title = "Dynamic Landing Pages Limit Reached!";
        let adminText =
          "Your organization has reached its max dynamic landing pages limit. No more pages can be added.";
        teamText =
          "Your organization has reached its max dynamic landing pages limit. No more pages can be added. Please contact your account owner to add more dynamic landing pages.";
        text = hasUserPermission ? adminText : teamText;
      }
      if (title != "") {
        Swal.fire({
          title: title,
          text: text,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#3085d6",
          confirmButtonText: "Add more " + addonName.toLowerCase(),
          cancelButtonText: "OK",
          showConfirmButton: showConfirmButton
        }).then(result => {
          console.log("result => ", result);
          if (result.isConfirmed) {
            let url = "/admin/billing";

            let subscribedPlan =
              rootState.auth.whoami.active_organization.plan_name;
            //if subscribed to highest plan then we should redirect to AccountStatus tab
            if (subscribedPlan.includes("growth"))
              router.push(url + "?accountstatus=true");
            else router.push(url);
          } else {
            if (!hasUserPermission) router.push("/organizations");
          }
        });
      }
    },
    showRemoveUserPopup({ state, commit, rootState }, data) {
      console.log("inside showRemoveUserPopup");
      let title = "";
      let text = "";
      let organization_role =
        rootState.auth.whoami.organization_user.organization_role_id;
      let addonName = "";
      let showConfirmButton = false;
      let hasUserPermission = organization_role == 5 || organization_role == 4;
      let teamText = "";

      addonName = "Licenses";
      title = "Please Remove!";

      let currentLicenseCount = parseInt(
        rootState.auth.whoami.subscription_addon.license_count
      );
      let subscribedPlanLicenseCount = parseInt(
        rootState.auth.whoami.subscription_addon.license
      );
      var userDiff = currentLicenseCount - subscribedPlanLicenseCount;

      text = "You need to remove " + userDiff + " users before proceeding.";
      if (title != "") {
        Swal.fire({
          title: title,
          text: text,
          icon: "warning",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#3085d6",
          confirmButtonText: "OK",
          cancelButtonText: "",
          showConfirmButton: true
        }).then(result => {
          console.log("result => ", result, rootState);
          if (result.isConfirmed) {
            let url =
              /*"/" +
              rootState.auth.whoami.active_organization.shortcode +*/
              "/admin/members";
            router.push(url);
          } else {
            if (!hasUserPermission) router.push("/organizations");
          }
        });
      }
    },
    showRemoveHubPopup({ state, commit, rootState }, data) {
      console.log("inside showRemoveHubPopup");
      let title = "";
      let text = "";
      let organization_role =
        rootState.auth.whoami.organization_user.organization_role_id;
      let addonName = "";
      let showConfirmButton = false;
      let hasUserPermission = organization_role == 5 || organization_role == 4;
      let teamText = "";

      addonName = "Dynamic Landing Pages";
      title = "Please Remove!";

      let currentHubCount = parseInt(
        rootState.auth.whoami.active_organization.dynamic_landing_pages
      );
      let subscribedPlanHubMaxCount = parseInt(
        rootState.auth.whoami.active_organization.max_dynamic_landing_pages
      );
      var hubDiff = currentHubCount - subscribedPlanHubMaxCount;

      text =
        "You need to remove " +
        hubDiff +
        " dynamic landing pages before proceeding.";
      if (title != "") {
        Swal.fire({
          title: title,
          text: text,
          icon: "warning",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#3085d6",
          confirmButtonText: "OK",
          cancelButtonText: "",
          showConfirmButton: true
        }).then(result => {
          console.log("result => ", result);
          if (result.isConfirmed) {
          } else {
            if (!hasUserPermission) router.push("/organizations");
          }
        });
      }
    }
  }
};
