import axios from "axios";
import toastr from "toastr";
import config from "../../config.js";
import utils from "../../utils.js";
import * as moment from "moment";
import router from "../../router";
import groupsServices from "../../services/groups";
import store from "./../../store";

export default {
  namespaced: true,
  state: {
    organizations: {
      organizationTags: [],
      settingsOptions: []
    },
    moderators: [],
    selectedModerators: [],
    autoPostChannels: [],
    organization: {
      about: "",
      active: "",
      address_one: "",
      address_second: "",
      autopost_end_time: null,
      autopost_friday: 1,
      autopost_monday: 1,
      autopost_per_day: 1,
      autopost_saturday: 0,
      autopost_start_time: null,
      autopost_sunday: 0,
      autopost_thursday: 1,
      autopost_tuesday: 1,
      autopost_wednesday: 1,
      city: "",
      country: "",
      email: "",
      enable_subscriptions: 0,
      group_user: {},
      id: 3,
      image_id: null,
      join_anyone: 0,
      join_approval_members: 0,
      join_approval_required: 1,
      join_only_org: 1,
      join_request: {},
      logo_id: null,
      name: "",
      number_of_members: "",
      organization_grouptype_id: "",
      organization_id: "",
      parent_id: null,
      phone: "",
      posts_per_day: 0,
      public: 0,
      state: "",
      visible: 0,
      website: "",
      zipcode: "",
      invites: [],
      logo: "",
      audience: null,
      organizationAddresses: [],
      public: null,
      autopost_timezone: "America/Denver",
      channels: [],
      groups: [],
      tagline: "",
      shortcode: "",
      visibility_id: 1
    },
    group: {
      name: "",
      about: "",
      website: "",
      phone: "",
      image_id: "",
      logo_id: "",
      image: "",
      invites: [],
      logo: "",
      email: "",
      parent_id: null,
      audience: null,
      organizationAddresses: [],
      public: null,
      channels: [],
      groups: [],
      autopost_end_time: null,
      autopost_friday: 1,
      autopost_monday: 1,
      autopost_per_day: null,
      autopost_saturday: 0,
      autopost_start_time: null,
      autopost_sunday: 0,
      autopost_thursday: 1,
      autopost_tuesday: 1,
      autopost_wednesday: 1,
      active: 1,
      enable_subscriptions: 0,
      join_anyone: 0,
      join_approval_members: 0,
      join_approval_required: 0,
      join_only_org: 0,
      organizationEmails: [],
      organizationPhones: [],
      organizationUrls: [],
      parent_id: null,
      visible: 0,
      posting_approval_required: 1,
      allow_autoposts: 0,
      default_group_role_id: 4,
      enable_public_viewers: 0,
      tagline: "",
      shortcode: "",
      visibility_id: 1
    },
    groups: [],
    selectedOrganization: {},
    allOrganizationsData: [],
    isShowMainOrganization: true,
    organizationEntitiesCount: [],
    externalgroups: [],
    selectedGroupToAddMembers: [],
    allow_posting_sharing: 0,
    allow_post_scheduling: 0,
    allow_post_sharing: 0,
    allow_post_download: 0,
    allow_posts_sharing_to_workspaces: 0,
    member_unique_facebook_group_connections: 0,
    public_viewers_link_id: null,
    updatedGroupLogo: false,
    updatedGroupImage: false,
    uploadLogoPercentage: 0,
    uploadImagePercentage: 0,

    uploadUserBackgroundImage: false,
    uploadUserBackgroundImageProgress: {}
  },

  getters: {},

  mutations: {
    setUploadUserBackgroundImageProgress(state, data) {
      state.uploadUserBackgroundImageProgress = data;
    },
    setUploadUserBackgroundImage(state, data) {
      state.uploadUserBackgroundImage = data;
    },
    setUploadedLogoPercentage(state, data) {
      state.uploadLogoPercentage = data;
    },
    setUploadedImagePercentage(state, data) {
      state.uploadImagePercentage = data;
    },
    setUploadedGroupImage(state, data) {
      state.updatedGroupImage = data;
    },
    setUploadedGroupLogo(state, data) {
      state.updatedGroupLogo = data;
    },
    removeExternalGroup(state, data) {
      const index = state.externalgroups.findIndex(
        element => element.id === data.id
      );
      state.externalgroups.splice(index, 1);
    },
    setExternalGroups(state, data) {
      state.externalgroups = data;
    },
    setIsShowMainOrganization(state, value) {
      state.isShowMainOrganization = value;
    },
    setSettingsOptions(state, data) {
      console.log("setSettingsOptions  -- ", data);
      state.organizations.settingsOptions = data;
      console.log("state - ", state.organizations.settingsOptions);
    },
    // Settings
    attachSelectedTag(state, data) {
      state.organizations.organizationTags.splice(0, 0, data);
    },
    detachSelectedTag(state, data) {
      const index = state.organizations.organizationTags.findIndex(
        element => element.id === data.id
      );
      state.organizations.organizationTags.splice(index, 1);
    },

    detachSelectedTagById(state, tagId) {
      const index = state.organizations.organizationTags.findIndex(
        element => element.id === tagId
      );
      state.organizations.organizationTags.splice(index, 1);
    },
    setOrganization(state, data) {
      state.organization = data;
    },
    setGroups(state, data) {
      state.groups = data;
    },
    updateSubOrganizations(state, data) {
      state.groups.push(data);
    },
    clearOrganizationForm(state, data) {
      state.organization = data;
    },
    setSelectedOrganization(state, data) {
      state.selectedOrganization = data;
    },
    pushSelectedOrganizationsData(state, data) {
      if (data == null) {
        state.allOrganizationsData = [];
      } else {
        if (state.allOrganizationsData.length == 0) {
          state.allOrganizationsData.push(data);
        } else {
          var hasFoundOrganization = false;
          for (var i = 0; i < state.allOrganizationsData.length; i++) {
            if (state.allOrganizationsData[i].id == data.id) {
              hasFoundOrganization = true;
            }
          }
          if (!hasFoundOrganization) {
            state.allOrganizationsData.push(data);
          }
        }
      }
    },
    setAllOrganizationsData(state, data) {
      state.allOrganizationsData.push(data);
    },
    addIntoSelectedModeratorsArray(state, data) {
      console.log(
        "addIntoSelectedModeratorsArray mutation===================="
      );
      state.selectedModerators.splice(0, 0, data);
    },
    deleteFromSelectedModeratorsArray(state, data) {
      console.log(
        "deleteFromSelectedModeratorsArray mutation===================="
      );
      const index = state.selectedModerators.findIndex(
        element => element.id === data.id
      );
      state.selectedModerators.splice(index, 1);
    },
    setParentIdToOrganization(state, parent_id) {
      console.log("Calling mutation: setParentIdToOrganization: " + parent_id);
      state.organization.parent_id = parent_id;
    },
    setOrganizationEntitiesCount(state, data) {
      state.organizationEntitiesCount = data;
    },
    //Auto Post Channels
    attachSocialChannelsToAutoPost(state, data) {
      state.organization.channels.splice(0, 0, data);
    },
    detachSocialChannelsToAutoPost(state, data) {
      const index = state.organization.channels.findIndex(
        element => element.id === data.id
      );
      state.organization.channels.splice(index, 1);
    },
    //Auto Post Groups
    attachGroupsToAutoPost(state, data) {
      console.log("Inside commit attachGroupsToAutoPost");
      state.organization.groups.splice(0, 0, data);
    },
    detachGroupsToAutoPost(state, data) {
      console.log("Inside commit detachGroupsToAutoPost");
      const index = state.organization.groups.findIndex(
        element => element.id === data.id
      );
      state.organization.groups.splice(index, 1);
    },
    setAutoPostChannels(state, data) {
      console.log("Inside setAutoPostChannels commit ----");
      // state.organizations.channels.splice(0, 0, data);
      state.organization.channels = data;
      console.log("Afetr setAutoPostChannels commit ----", state.organization);
    },
    setSelectedGroupToAddMembers(state, data) {
      if (state.selectedGroupToAddMembers.length >= 1) {
        var count = state.selectedGroupToAddMembers.length;
        state.selectedGroupToAddMembers.splice(count - 1, 1);
      }
      state.selectedGroupToAddMembers.splice(0, 0, data);
    },
    removeSelectedGroupToAddMembers(state, data) {
      const index = state.selectedGroupToAddMembers.findIndex(
        element => element.id === data.id
      );
      state.selectedGroupToAddMembers.splice(index, 1);
    },
    clearselectedGroupToAddMembers(state) {
      state.selectedGroupToAddMembers = [];
    },
    clearGroupData(state) {
      state.group = {
        name: "",
        about: "",
        website: "",
        phone: "",
        image_id: "",
        logo_id: "",
        image: "",
        invites: [],
        logo: "",
        email: "",
        parent_id: null,
        audience: null,
        organizationAddresses: [],
        public: null,
        channels: [],
        groups: [],
        autopost_end_time: null,
        autopost_friday: 1,
        autopost_monday: 1,
        autopost_per_day: null,
        autopost_saturday: 0,
        autopost_start_time: null,
        autopost_sunday: 0,
        autopost_thursday: 1,
        autopost_tuesday: 1,
        autopost_wednesday: 1,
        active: 1,
        enable_subscriptions: 0,
        join_anyone: 0,
        join_approval_members: 0,
        join_approval_required: 0,
        join_only_org: 0,
        organizationEmails: [],
        organizationPhones: [],
        organizationUrls: [],
        parent_id: null,
        visible: 0,
        posting_approval_required: 1,
        allow_posting_sharing: 0,
        allow_post_scheduling: 0,
        allow_post_sharing: 0,
        allow_post_download: 0,
        allow_posts_sharing_to_workspaces: 0,
        member_unique_facebook_group_connections: 0,
        allow_autoposts: 0,
        default_group_role_id: 4,
        enable_public_viewers: 0,
        public_viewers_link_id: null,
        tagline: "",
        shortcode: "",
        visibility_id: 1
      };
    },
    setGroup(state, data) {
      state.group = data;
    }
  },

  actions: {
    pushSelectedOrganizationsData({ state, commit, rootState }, organization) {
      commit("pushSelectedOrganizationsData", organization);
    },
    setIsShowMainOrganization({ state, commit, rootState }, value) {
      commit("setIsShowMainOrganization", value);
    },
    setAllOrganizationsData({ state, commit, rootState }, data) {
      commit("setAllOrganizationsData", data);
    },
    setSelectedOrganization({ state, commit, rootState }, data) {
      commit("setSelectedOrganization", data);
    },
    setDefaultOrganizationGroup({ state, commit, rootState }, data) {
      commit("setDefaultOrganizationGroup", data);
    },

    clearOrganizationForm({ state, commit, rootState }) {
      var organizationForm = {
        about: "",
        active: "",
        address_one: "",
        address_second: "",
        autopost_end_time: null,
        autopost_friday: 1,
        autopost_monday: 1,
        autopost_per_day: 1,
        autopost_saturday: 0,
        autopost_start_time: null,
        autopost_sunday: 0,
        autopost_thursday: 1,
        autopost_tuesday: 1,
        autopost_wednesday: 1,
        city: "",
        country: "",
        email: "",
        enable_subscriptions: 0,
        group_user: {},
        id: 3,
        image_id: null,
        join_anyone: 0,
        join_approval_members: 0,
        join_approval_required: 1,
        join_only_org: 1,
        join_request: {},
        logo_id: null,
        name: "",
        number_of_members: "",
        organization_grouptype_id: "",
        organization_id: "",
        parent_id: null,
        phone: "",
        posts_per_day: 0,
        public: 0,
        state: "",
        visible: 0,
        website: "",
        zipcode: "",
        invites: [],
        logo: "",
        autopost_timezone: "America/Denver",
        channels: [],
        groups: [],
        tagline: "",
        shortcode: "",
        visibility_id: 1
      };
      commit("clearOrganizationForm", organizationForm);
    },
    setOrganizationsSettingsOptions({ state, commit, rootState }, data) {
      commit("setSettingsOptions", data);
    },
    // Settings: Tags
    attachSelectedTag({ state, commit, rootState }, data) {
      const index = state.organizations.organizationTags.findIndex(
        element => element.id === data.id
      );

      if (index == -1) commit("attachSelectedTag", data);
    },
    detachSelectedTag({ state, commit, rootState }, data) {
      commit("detachSelectedTag", data);
    },
    setOrganization({ state, commit, rootState }, data) {
      commit("setOrganization", data);
    },
    getAllGroups({ state, commit }, data) {
      return new Promise(function(resolve, reject) {
        console.log("data - ", data);
        var id = data.organization_id;
        if (
          data.query_params != undefined &&
          data.hasOwnProperty("query_params")
        ) {
          var query_params = data.query_params;
          var url =
            config.DOMAIN +
            config.API_VERSION +
            "organizations/" +
            id +
            "/organizationgroups?expand=group_user,join_request&" +
            query_params;
        } else {
          var url =
            config.DOMAIN +
            config.API_VERSION +
            "organizations/" +
            id +
            "/organizationgroups" +
            "?expand=group_user,join_request";
        }
        if (data.searchText) {
          url += "&search_text=" + data.searchText;
        }
        if (data.externalGroups) {
          url += "&external_groups_only=" + data.externalGroups;
        }
        console.log("getAllGroups() Called");
        axios
          .get(url)
          .then(response => {
            resolve(response);
            if (!data.searchText) {
              commit("setGroups", response.data);
            }
          })
          .catch(e => {
            console.log("Failed!", e);
            reject(e);
          });
      }); // promise
    }, // getAllGroups
    getAllSubscribedSubOrganizations({ state, commit }, id) {
      return new Promise(function(resolve, reject) {
        axios
          .get(
            config.DOMAIN +
              config.API_VERSION +
              "organizations/" +
              id +
              "/organizationgroups?subscribed=1" +
              "&expand=group_user,join_request"
          )
          .then(response => {
            commit("setGroups", response.data.groups);
            resolve(response);
          })
          .catch(e => {
            console.log("Failed!", e);
            reject(e);
          });
      }); // promise
    },
    addSubOrganization({ state, commit, dispatch, rootState }, data) {
      console.log("in addSubOrganization called ----", data);
      var organization = data.organization;
      organization.createNewOragnizationImage = false;
      organization.createNewOragnizationLogo = false;
      var isInviteMembers = data.isInviteMembers;

      if (organization.image == "" || organization.image == null) {
        organization.createNewOragnizationImage = true;
      }
      if (organization.logo == "" || organization.logo == null) {
        organization.createNewOragnizationLogo = true;
      }

      console.log(
        "rootState.auth.whoami.active_organization.id --",
        rootState.auth.whoami.active_organization.id
      );
      return new Promise(function(resolve, reject) {
        axios
          .post(
            config.DOMAIN +
              config.API_VERSION +
              "organizations/" +
              rootState.auth.whoami.active_organization.id +
              "/organizationgroups" +
              "?expand=group_user,join_request",
            organization
          )
          .then(response => {
            console.log("response", response.data);
            commit("updateSubOrganizations", response.data);
            if (organization.createNewOragnizationImage == false) {
              var postOrganizationImage = {};
              postOrganizationImage.image = organization.image;
              postOrganizationImage.id = response.data.id;
              postOrganizationImage.imageForNewOrg = true;
              dispatch(
                "organizations/updateOrganizationGroupImage",
                postOrganizationImage,
                {
                  root: true
                }
              );
            }
            if (organization.createNewOragnizationLogo == false) {
              var postOrganizationLogo = {};
              postOrganizationLogo.id = response.data.id;
              postOrganizationLogo.logo = organization.logo;
              dispatch(
                "organizations/updateOrganizationGroupLogo",
                postOrganizationLogo,
                {
                  root: true
                }
              );
            }
            commit("setOrganization", response.data);

            if (response.data.id && organization.invites.length != 0) {
              for (var i = 0; i < organization.invites.length; i++) {
                var team = organization.invites[i];
                team.organization_group_id = response.data.id;
                console.log("team --", team);
                dispatch("organizations/inviteUserToGroupTeam", team, {
                  root: true
                });
              }
            }
            dispatch("clearOrganizationForm");
            resolve(response);
          })
          .catch(e => {
            console.log("Image Post Error: ", e);
            reject(e.response.data.message);
          }); //axios
      }); // promise
    }, // getAllGroups
    updateSubOrganization({ state, commit, dispatch, rootState }, data) {
      console.log("updateSubOrganization called() ", data.organization);
      var group = data.group;
      console.log("data.group ", data.group, data);
      return new Promise((resolve, reject) => {
        axios
          .put(
            config.DOMAIN +
              config.API_VERSION +
              "organizations/" +
              rootState.auth.whoami.active_organization.id +
              "/organizationgroups/" +
              group.id,
            group
          )
          .then(response => {
            resolve(response);
          })
          .catch(e => {
            console.log("Failed!", e);
          });
      });
    }, // updateOrganizations
    leaveOrganization({ state, commit, dispatch }, organizationId) {
      console.log("leaveOrganization called() ", organizationId);
      return new Promise(function(resolve, reject) {
        let url =
          config.DOMAIN +
          config.API_VERSION +
          "organizations/" +
          organizationId +
          "/leave";
        axios
          .delete(url)
          .then(
            response => {
              resolve(response);
            },
            error => {
              resolve(error.response.data.message);
              /*toastr.success("", error.response.data.message, {
                timeOut: config.TOASTR_TIMEOUT
              });*/
            }
          )
          .catch(e => {
            console.error("Error: ", e);
            resolve(e);
          }); //axios
      });
    }, //Leave Organizations
    deleteOrganization({ state, commit, dispatch, rootState }, organizationId) {
      console.log("leaveOrganization called() ", organizationId);
      console.log("organizations -- ", rootState.auth.organizations);
      var remainingOrgs = rootState.auth.organizations.filter(org => {
        return org.id != organizationId;
      });
      console.log("remainingOrgs - ", remainingOrgs);
      axios
        .delete(
          config.DOMAIN + config.API_VERSION + "organizations/" + organizationId
        )
        .then(response => {
          if (remainingOrgs.length > 0) {
            let activeOrg = remainingOrgs[0];
            let data = { organization_id: activeOrg.id };

            dispatch("auth/changeActiveOrganizationAndCategory", data, {
              root: true
            });
          }
          router.push("/organizations");
          toastr.success("", "Organization deleted successfully.", {
            timeOut: 5000
          });
        })
        .catch(e => {
          console.log("Failed!", e);
        });
    }, // Delete Organizations
    addOrganization({ state, commit, dispatch, rootState }, data) {
      return new Promise(function(resolve, reject) {
        var organization = data.organization;
        axios
          .post(
            config.DOMAIN + config.API_VERSION + "organizations",
            organization
          )
          .then(response => {
            console.log("response -- ", response.data);
            commit("setOrganization", response.data);
            resolve(response.data);
          })
          .catch(e => {
            console.log("Image Post Error: ", e);
            reject(e.response.data.message);
          }); //axios
      }); // promise
    },
    updateOrganizationGroupImage({ rootState, state, commit, dispatch }, data) {
      console.log("Update Organization Image: ", data);
      // save image
      let imageData = new FormData();
      imageData.append("image", data.image, data.image.name);
      new Promise(function(resolve, reject) {
        let totalAttachments = 1;
        let progress = {
          total: totalAttachments,
          percent: 0,
          current: 0
        };
        let fileProgress = [];

        const configData = {
          onUploadProgress: function(progressEvent) {
            // save the individual file's progress percentage in object
            fileProgress[progress.current] =
              (progressEvent.loaded * 100) / progressEvent.total;
            // sum up all file progress percentages to calculate the overall progress
            let totalPercent = 0;
            // divide the total percentage by the number of files
            if (progress.total > 1) {
              totalPercent = fileProgress.filter(ele => ele == 100).length;
              progress.percent = parseInt(
                Math.round((totalPercent / progress.total) * 100)
              );
            } else {
              totalPercent = fileProgress
                ? Object.values(fileProgress).reduce((sum, num) => sum + num, 0)
                : 0;
              progress.percent = parseInt(Math.round(totalPercent));
            }
            /*store.dispatch("post/updatePostPercentage", progress, {
              root: true
            });*/
            commit("setUploadedImagePercentage", progress);
            if (progressEvent.loaded === progressEvent.total) {
              progress.current++;
            }
          }
        };

        axios
          .post(
            config.DOMAIN +
              config.API_VERSION +
              "organizations/" +
              rootState.auth.whoami.active_organization.id +
              "/organizationgroups/" +
              data.id +
              "/image",
            imageData,
            configData
          )
          .then(image_response => {
            commit("setUploadedGroupImage", true);
            commit("setUploadedImagePercentage", 0);
            resolve(image_response);
            toastr.success(
              "",
              "Project background image updated successfully.",
              {
                timeOut: config.TOASTR_TIMEOUT
              }
            );
          })
          .catch(e => {
            console.log("Image Post Error: ", e);
          }); //axios
      });
    },
    updateOrganizationGroupLogo({ rootState, state, commit, dispatch }, data) {
      console.log("Update Organization Logo: ", data);
      console.log("data -- ", data);
      // save logo
      new Promise(function(resolve, reject) {
        let imageData = new FormData();
        imageData.append("image", data.logo, data.logo.name);

        let totalAttachments = 1;
        let progress = {
          total: totalAttachments,
          percent: 0,
          current: 0
        };
        let fileProgress = [];

        const configData = {
          onUploadProgress: function(progressEvent) {
            // save the individual file's progress percentage in object
            fileProgress[progress.current] =
              (progressEvent.loaded * 100) / progressEvent.total;
            // sum up all file progress percentages to calculate the overall progress
            let totalPercent = 0;
            // divide the total percentage by the number of files
            if (progress.total > 1) {
              totalPercent = fileProgress.filter(ele => ele == 100).length;
              progress.percent = parseInt(
                Math.round((totalPercent / progress.total) * 100)
              );
            } else {
              totalPercent = fileProgress
                ? Object.values(fileProgress).reduce((sum, num) => sum + num, 0)
                : 0;
              progress.percent = parseInt(Math.round(totalPercent));
            }
            commit("setUploadedLogoPercentage", progress);
            if (progressEvent.loaded === progressEvent.total) {
              progress.current++;
            }
          }
        };

        axios
          .post(
            config.DOMAIN +
              config.API_VERSION +
              "organizations/" +
              rootState.auth.whoami.active_organization.id +
              "/organizationgroups/" +
              data.id +
              "/logo",
            imageData,
            configData
          )
          .then(image_response => {
            commit("setUploadedGroupLogo", true);
            commit("setUploadedLogoPercentage", 0);
            toastr.success("", "Poject logo uploaded successfully.", {
              timeOut: config.TOASTR_TIMEOUT
            });
            resolve(image_response);
          })
          .catch(e => {
            console.log("Logo Post Error: ", e);
          }); //axios
      });
    },
    addSelectedContentTypesToGroup({ state, commit, rootState }, data) {
      console.log("in addSelectedContentTypesToGroup action");
      var input_data = {
        group_id: data.group_id,
        selectedContentTypes: data.selectedContentTypes
      };
      new Promise(function(resolve, reject) {
        axios
          .post(
            config.DOMAIN +
              config.API_VERSION +
              "organizations/" +
              data.organization_id +
              "/grouptags",
            input_data
          )
          .then(response => {
            console.log("response -- ", response.data);
          })
          .catch(e => {
            console.log("Image Post Error: ", e);
          }); //axios
      }); // promise
    },
    sendOrganizationGroupRequest({ state, commit, rootState }, data) {
      //http://localhost:8000/api/v1/organizations/1/organizationgroups/1/request/create
      return new Promise(function(resolve, reject) {
        axios
          .post(
            config.DOMAIN +
              config.API_VERSION +
              "organizations/" +
              rootState.auth.whoami.active_organization.id +
              "/organizationgroups/" +
              data.organization_group_id +
              "/request/create" +
              "?expand=group_user,join_request",
            data
          )
          .then(response => {
            console.log("response -- ", response.data);
            resolve(response);
          })
          .catch(e => {
            console.log("Post Request join group Error: ", e);
          }); //axios
      }); // promise
    },
    inviteUserToGroupTeam({ state, commit, rootState }, data) {
      new Promise(function(resolve, reject) {
        axios
          .post(
            config.DOMAIN +
              config.API_VERSION +
              "organizations/" +
              rootState.auth.whoami.active_organization.id +
              "/organizationgroups/" +
              data.organization_group_id +
              "/users",
            data
          )
          .then(response => {
            console.log("response -- ", response.data);
          })
          .catch(e => {
            console.log("Post Request join group Error: ", e);
          }); //axios
      }); // promise
    },
    addIntoSelectedModeratorsArray(
      { state, commit, dispatch, rootState },
      data
    ) {
      console.log("addIntoSelectedModeratorsArray action==============");
      commit("addIntoSelectedModeratorsArray", data);
    },
    deleteFromSelectedModeratorsArray(
      { state, commit, dispatch, rootState },
      data
    ) {
      console.log("deleteFromSelectedModeratorsArray action==============");
      commit("deleteFromSelectedModeratorsArray", data);
    },
    getMembersOfGroup({ state }, data) {
      let url =
        config.DOMAIN +
        config.API_VERSION +
        "organizations/" +
        data.organization_id +
        "/organizationgroups/" +
        data.organization_group_id +
        "/members";
      if (data.filterString) {
        url += data.filterString;
      }
      return new Promise(function(resolve, reject) {
        axios
          .get(url)
          .then(response => {
            resolve(response);
          })
          .catch(e => {
            console.error("Error: ", e);
          }); //axios
      });
    },
    getMembersOfOrganization({ state }, data) {
      return new Promise(function(resolve, reject) {
        let url =
          config.DOMAIN +
          config.API_VERSION +
          "organizations/" +
          data.organization_id +
          "/members";
        axios
          .get(url)
          .then(response => {
            resolve(response);
          })
          .catch(e => {
            console.error("Error: ", e);
          }); //axios
      });
    },
    modifySettings({ state, commit, rootState }, data) {
      new Promise(function(resolve, reject) {
        axios
          .post(
            config.DOMAIN +
              config.API_VERSION +
              "organizations/" +
              rootState.auth.whoami.active_organization.id +
              "/organizationgroups/" +
              rootState.auth.whoami.active_organization_group.id +
              "/modifysettings",
            data
          )
          .then(response => {
            resolve(response);
            console.log("response -- ", response.data);
            toastr.error("Settings saved successfully.", "", {
              timeOut: 5000
            });
          })
          .catch(e => {
            console.log("Post Request join group Error: ", e);
          }); //axios
      }); // promise
    },
    getOrganizationAllGroupsMembers({ state }, data) {
      return new Promise(function(resolve, reject) {
        let url =
          config.DOMAIN +
          config.API_VERSION +
          "organizations/" +
          data.organization_id +
          "/members";
        axios
          .get(url)
          .then(response => {
            resolve(response);
          })
          .catch(e => {
            console.error("Error: ", e);
          }); //axios
      });
    },
    getGroup({ state, commit }, data) {
      // this will return only single group data
      return new Promise(function(resolve, reject) {
        console.log("getGroup called");
        let url =
          config.DOMAIN +
          config.API_VERSION +
          "organizations/" +
          data.organization_id +
          "/organizationgroups/" +
          data.group_id +
          "?expand=group_user,join_request,organizationLink";
        axios
          .get(url)
          .then(response => {
            commit("setGroup", response.data);
            resolve(response);
          })
          .catch(e => {
            console.error("Error: ", e);
          }); //axios
      });
    },
    getSubGroupsofGroup({ state }, data) {
      // this will return only single group data
      return new Promise(function(resolve, reject) {
        let url =
          config.DOMAIN +
          config.API_VERSION +
          "organizations/" +
          data.organization_id +
          "/organizationgroups/" +
          data.group_id +
          "/subgroups";
        axios
          .get(url)
          .then(response => {
            resolve(response);
          })
          .catch(e => {
            console.error("Error: ", e);
          }); //axios
      });
    },
    deleteFromModeratorsList({ state, rootState, commit }, data) {
      return new Promise(function(resolve, reject) {
        axios
          .post(
            config.DOMAIN +
              config.API_VERSION +
              "organizations/" +
              rootState.auth.whoami.active_organization.id +
              "/organizationgroups/" +
              rootState.auth.whoami.active_organization_group.id +
              "/deletegroupmoderator",
            data
          )
          .then(response => {
            console.log("response -- ", response.data);
            toastr.error("Moderator removed successfully.", "", {
              timeOut: 1000
            });
            resolve(response);
          })
          .catch(e => {
            console.log("Post Request join group Error: ", e);
          }); //axios
      }); // promise
    },

    updateGroupSubscription({ state, rootState, commit }, data) {
      return new Promise(function(resolve, reject) {
        axios
          .put(
            config.DOMAIN +
              config.API_VERSION +
              "organizations/" +
              data.organization_id +
              "/organizationgroups/" +
              data.organization_group_id +
              "/subscribe",
            data
          )
          .then(
            response => {
              console.log("response -- ", response.data);
              resolve(response);
            },
            error => {
              toastr.error(error.response.data.message, "", {
                timeOut: 1000
              });
            }
          )
          .catch(e => {
            console.log("Error in group subscribed: ", e);
          }); //axios
      }); // promise
    },

    removeGroupMember({ state, rootState, commit }, data) {
      console.log("data -- ", data);
      return new Promise(function(resolve, reject) {
        axios
          .delete(
            config.DOMAIN +
              config.API_VERSION +
              "organizations/" +
              rootState.auth.whoami.active_organization.id +
              "/organizationgroups/" +
              data.organization_group_id +
              "/users/" +
              data.organization_user_id
          )
          .then(
            response => {
              console.log("response -- ", response.data);
              toastr.error("Group member removed successfully.", "", {
                timeOut: 1000
              });
              resolve(response);
            },
            error => {
              toastr.error(error.response.data.message, "", {
                timeOut: 1000
              });
            }
          )
          .catch(e => {
            console.log("Delete Request to remove group Member: ", e);
          }); //axios
      }); // promise
    },
    setParentIdToOrganization({ state, rootState, commit }, parent_id) {
      commit("setParentIdToOrganization", parent_id);
    },
    getAllGroupsOfOganization({ state, rootState, commit }) {
      console.log("getAllGroupsOfOganization -- ");
      return new Promise(function(resolve, reject) {
        let url =
          config.DOMAIN +
          config.API_VERSION +
          "organizations/" +
          rootState.auth.whoami.active_organization.id +
          "/organizationgroups/allsubgroups";
        axios
          .get(url)
          .then(response => {
            resolve(response);
          })
          .catch(e => {
            console.error("Error: ", e);
          }); //axios
      });
    },
    deleteGroup({ state, rootState, commit }, data) {
      console.log("data -- ", data);
      return new Promise(function(resolve, reject) {
        if (data && data.group_id) {
          var group_id = data.group_id;
        } else {
          var group_id = rootState.auth.whoami.active_organization_group.id;
        }
        axios
          .delete(
            config.DOMAIN +
              config.API_VERSION +
              "organizations/" +
              rootState.auth.whoami.active_organization.id +
              "/organizationgroups/" +
              group_id +
              "/removegroup"
          )
          .then(
            response => {
              console.log("response -- ", response.data);
              resolve(response);
              let updatedGroupList = state.groups.filter(
                ele => ele.id != response.data.id
              );
              commit("setGroups", updatedGroupList);
            },
            error => {
              resolve(error.response.data.message);
            }
          )
          .catch(e => {
            console.log("Delete Request to remove group Member: ", e);
          }); //axios
      }); // promise
    },

    getOrgUserDetails({ state }, data) {
      // this will return only single group data
      return new Promise(function(resolve, reject) {
        console.log("getGroup called");
        let url =
          config.DOMAIN +
          config.API_VERSION +
          "organizations/" +
          data.organization_id +
          "/user/" +
          data.organization_user_id;
        axios
          .get(url)
          .then(response => {
            resolve(response);
          })
          .catch(e => {
            console.error("Error: ", e);
          }); //axios
      });
    },
    updateGroupMemberRole({ state, rootState, commit }, data) {
      console.log("in updateGroupMemberRole -- ", data);
      return new Promise(function(resolve, reject) {
        console.log("getGroup called");
        let url =
          config.DOMAIN +
          config.API_VERSION +
          "organizations/" +
          rootState.auth.whoami.active_organization.id +
          "/organizationgroups/" +
          data.organization_group_id +
          "/updaterole/" +
          data.organization_user_id;
        axios
          .put(url, data)
          .then(
            response => {
              console.log("response --", response);
              resolve(response);
            },
            error => {
              toastr.error(error.response.data.message, "", {
                timeOut: 1000
              });
            }
          )
          .catch(e => {
            console.log("e--- ", e);
            console.error("Error: ", e);
          }); //axios
      });
    },
    getOrganizationEntitiesCount({ state, rootState, commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            config.DOMAIN +
              config.API_VERSION +
              "organizations/" +
              rootState.auth.whoami.active_organization.id +
              "/getentitiescount"
          )
          .then(response => {
            console.log(response.data);
            commit("setOrganizationEntitiesCount", response.data);
            resolve(response);
          })
          .catch(e => {
            console.log("Error: ", e);
          }); //axios
      });
    },

    cancelJoinRequest({ state, rootState, commit }, data) {
      return new Promise((resolve, reject) => {
        var external_group_cancel_join_request = false;
        if (!!data.external_group_cancel_join_request)
          external_group_cancel_join_request = true;
        axios
          .delete(
            config.DOMAIN +
              config.API_VERSION +
              "organizations/" +
              rootState.auth.whoami.active_organization.id +
              "/organizationgroups/" +
              data.group_id +
              "/request/" +
              data.request_id +
              "?is_cancel_join_group_of_subgroups=" +
              data.is_cancel_join_group_of_subgroups +
              "&external_group_cancel_join_request=" +
              external_group_cancel_join_request
          )
          .then(response => {
            resolve(response);
          })
          .catch(e => {
            console.log("Error: ", e);
          }); //axios
      });
    },
    attachSocialChannelsToAutoPost({ state, commit, rootState }, data) {
      commit("attachSocialChannelsToAutoPost", data);
    },
    detachSocialChannelsToAutoPost({ state, commit, rootState }, data) {
      commit("detachSocialChannelsToAutoPost", data);
    },
    attachGroupsToAutoPost({ state, commit, rootState }, data) {
      console.log("Inside action attachGroupsToAutoPost");
      commit("attachGroupsToAutoPost", data);
    },
    detachGroupsToAutoPost({ state, commit, rootState }, data) {
      console.log("Inside action detachGroupsToAutoPost");
      commit("detachGroupsToAutoPost", data);
    },
    getAutoPostChannels({ state, commit, rootState }) {
      console.log("Inside getAutoPostChannels called-----------");
      return new Promise((resolve, reject) => {
        axios
          .get(
            config.DOMAIN +
              config.API_VERSION +
              "organizations/" +
              rootState.auth.whoami.active_organization.id +
              "/organizationgroups/" +
              rootState.auth.whoami.active_organization_group.id +
              "/getautopostchannels"
          )
          .then(response => {
            console.log(
              "response===============",
              response.data,
              state.organization.channels
            );
            if (
              !!state.organization.channels &&
              state.organization.channels.length <= 0
            ) {
              console.log("Inside if------------------");
              //commit("setAutoPostChannels", response.data);
            }
            resolve(response);
          })
          .catch(e => {
            console.log("Error: ", e);
          }); //axios
      });
    },
    getAutoPostGroups({ state, commit, rootState }) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            config.DOMAIN +
              config.API_VERSION +
              "organizations/" +
              rootState.auth.whoami.active_organization.id +
              "/getautopostgroups"
          )
          .then(response => {
            console.log(response.data);
            // commit("setAutoPostChannels", response.data);
            resolve(response);
          })
          .catch(e => {
            console.log("Error: ", e);
          }); //axios
      });
    },
    modifyOrganizationSettings({ state, commit, rootState }, data) {
      new Promise(function(resolve, reject) {
        axios
          .post(
            config.DOMAIN +
              config.API_VERSION +
              "organizations/" +
              rootState.auth.whoami.active_organization.id +
              "/modifysettings",
            data
          )
          .then(response => {
            resolve(response);
            console.log("response -- ", response.data);
            toastr.error("Settings saved successfully.", "", {
              timeOut: 5000
            });
          })
          .catch(e => {
            console.log("modifyOrganizationSettings Error: ", e);
          }); //axios
      }); // promise
    },
    addExternalGroups({ state, commit, rootState }, data) {
      new Promise(function(resolve, reject) {
        axios
          .post(
            config.DOMAIN +
              config.API_VERSION +
              "organizations/" +
              rootState.auth.whoami.active_organization.id +
              "/addexternalgroup",
            data
          )
          .then(response => {
            resolve(response);
            console.log("response -- ", response.data);
            commit("setExternalGroups", response.data.external_groups);
            toastr.error("External groups added successfully.", "", {
              timeOut: 5000
            });
          })
          .catch(e => {
            console.log("modifyOrganizationSettings Error: ", e);
          }); //axios
      }); // promise
    },
    getExternalGroups({ state, commit, rootState }, data) {
      return new Promise(function(resolve, reject) {
        axios
          .get(
            config.DOMAIN +
              config.API_VERSION +
              "organizations/" +
              rootState.auth.whoami.active_organization.id +
              "/getexternalgroups"
          )
          .then(response => {
            resolve(response);
            console.log("response -- ", response.data);
            commit("setExternalGroups", response.data);
          })
          .catch(e => {
            console.log("modifyOrganizationSettings Error: ", e);
          }); //axios
      }); // promise
    },
    deleteExternalGroup({ state, commit, rootState }, external_group_id) {
      new Promise(function(resolve, reject) {
        axios
          .delete(
            config.DOMAIN +
              config.API_VERSION +
              "organizations/" +
              rootState.auth.whoami.active_organization.id +
              "/removeexternalgroup/" +
              external_group_id
          )
          .then(response => {
            console.log("response -- ", response.data);
            commit("removeExternalGroup", response.data);
            toastr.error("External group deleted successfully.", "", {
              timeOut: 5000
            });
          })
          .catch(e => {
            toastr.error("Error", "", {
              timeOut: 5000
            });
          }); //axios
      }); // promise
    },
    setSelectedGroupToAddMembers({ state, rootState, commit }, group) {
      commit("setSelectedGroupToAddMembers", group);
    },
    removeSelectedGroupToAddMembers({ state, rootState, commit }, group) {
      commit("removeSelectedGroupToAddMembers", group);
    },
    clearselectedGroupToAddMembers({ state, rootState, commit }) {
      commit("clearselectedGroupToAddMembers");
    },
    clearGroupData({ state, rootState, commit }) {
      commit("clearGroupData");
    },

    createWebUser({ state, commit, rootState }, data) {
      return new Promise(function(resolve, reject) {
        axios
          .post(
            config.DOMAIN + config.API_VERSION + "contribute/" + data.shortcode,
            data.userObj
          )
          .then(response => {
            resolve(response);
          })
          .catch(e => {
            reject(e);
            console.log("Error: ", e);
          }); //axios
      }); // promise
    },
    getPublicViewersGroup({ state, commit }, group_id) {
      // this will return only single group data
      return new Promise(function(resolve, reject) {
        console.log("getPublicViewersGroup called");
        let url =
          config.DOMAIN + config.API_VERSION + "publicviewgroup/" + group_id;
        axios
          .get(url)
          .then(response => {
            resolve(response);
          })
          .catch(e => {
            console.error("Error: ", e);
          }); //axios
      });
    },

    getLanguages({ state }) {
      return new Promise(function(resolve, reject) {
        let url =
          config.DOMAIN + config.API_VERSION + "organizations/user/languages";
        axios
          .get(url)
          .then(response => {
            console.log("languages - ", response);
            resolve(response);
          })
          .catch(e => {
            console.error("Error: ", e);
          }); //axios
      });
    },
    getTimeZones({ state }) {
      return new Promise(function(resolve, reject) {
        let url =
          config.DOMAIN + config.API_VERSION + "organizations/user/timezones";
        axios
          .get(url)
          .then(response => {
            console.log("timezones - ", response);
            resolve(response);
          })
          .catch(e => {
            console.error("Error: ", e);
          }); //axios
      });
    },
    getEnglishLevels({ state }) {
      return new Promise(function(resolve, reject) {
        let url =
          config.DOMAIN +
          config.API_VERSION +
          "organizations/user/english_levels";
        axios
          .get(url)
          .then(response => {
            console.log("english_levels - ", response);
            resolve(response);
          })
          .catch(e => {
            console.error("Error: ", e);
          }); //axios
      });
    },
    getDegrees({ state }) {
      return new Promise(function(resolve, reject) {
        let url =
          config.DOMAIN + config.API_VERSION + "organizations/user/degrees";
        axios
          .get(url)
          .then(response => {
            console.log("degrees - ", response);
            resolve(response);
          })
          .catch(e => {
            console.error("Error: ", e);
          }); //axios
      });
    },
    getSkillsCategories({ state }) {
      return new Promise(function(resolve, reject) {
        let url =
          config.DOMAIN + config.API_VERSION + "organizations/user/categories";
        axios
          .get(url)
          .then(response => {
            console.log("categories - ", response);
            resolve(response);
          })
          .catch(e => {
            console.error("Error: ", e);
          }); //axios
      });
    },
    getSchools({ state }) {
      return new Promise(function(resolve, reject) {
        let url =
          config.DOMAIN + config.API_VERSION + "organizations/user/schools";
        axios
          .get(url)
          .then(response => {
            console.log("schools - ", response);
            resolve(response);
          })
          .catch(e => {
            console.error("Error: ", e);
          }); //axios
      });
    },
    getOrganizationByShortCode({ state }, shortcode) {
      return new Promise(function(resolve, reject) {
        let url =
          config.DOMAIN +
          config.API_VERSION +
          "organizations/shortcode/" +
          shortcode +
          "?expand=subscription";
        axios
          .get(url)
          .then(response => {
            console.log("1596 Organization - ", response);
            resolve(response);
          })
          .catch(e => {
            console.error("Error: ", e);
          }); //axios
      });
    },
    getGroupByShortCode({ state }, data) {
      return new Promise(function(resolve, reject) {
        let url =
          config.DOMAIN +
          config.API_VERSION +
          "group/shortcode/" +
          data.shortcode +
          "?expand=organizationAddresses,organizationEmails,organizationPhones,organizationUrls";
        if (data.clientId) {
          url += "&clientId=" + data.clientId;
        }
        axios
          .get(url)
          .then(response => {
            console.log("Group - ", response);
            resolve(response);
          })
          .catch(e => {
            console.error("Error: ", e);
          }); //axios
      });
    },
    getUserByShortCode({ state }, data) {
      return new Promise(function(resolve, reject) {
        let url =
          config.DOMAIN +
          config.API_VERSION +
          "organizations/user/shortcode/" +
          data.shortcode;

        axios
          .get(url)
          .then(response => {
            console.log("User - ", response);
            resolve(response);
          })
          .catch(e => {
            console.error("Error: ", e);
            router.push("/");
          }); //axios
      });
    },
    updateBackgroundImage({ state, commit, rootState }, form_data) {
      //form_data.id = state.whoami.userid;
      console.log("rootState - ", rootState);
      return new Promise(function(resolve, reject) {
        let totalAttachments = 1;
        let progress = {
          total: totalAttachments,
          percent: 0,
          current: 0
        };
        let fileProgress = [];

        const configData = {
          onUploadProgress: function(progressEvent) {
            // save the individual file's progress percentage in object
            fileProgress[progress.current] =
              (progressEvent.loaded * 100) / progressEvent.total;
            // sum up all file progress percentages to calculate the overall progress
            let totalPercent = 0;
            // divide the total percentage by the number of files
            if (progress.total > 1) {
              totalPercent = fileProgress.filter(ele => ele == 100).length;
              progress.percent = parseInt(
                Math.round((totalPercent / progress.total) * 100)
              );
            } else {
              totalPercent = fileProgress
                ? Object.values(fileProgress).reduce((sum, num) => sum + num, 0)
                : 0;
              progress.percent = parseInt(Math.round(totalPercent));
            }
            commit("setUploadUserBackgroundImageProgress", progress);
            if (progressEvent.loaded === progressEvent.total) {
              progress.current++;
            }
          }
        };
        axios
          .post(
            config.DOMAIN +
              config.API_VERSION +
              "organizations/" +
              rootState.auth.whoami.active_organization.id +
              "/background_image",
            form_data,
            configData
          )
          .then(
            response => {
              if (true) {
                console.log(response);
                commit("setUploadUserBackgroundImage", true);
                commit("setUploadUserBackgroundImageProgress", 0);
                /*router.push("/organizations/profile");*/
                // if got respected response
                // var message_page = {'title': 'Password Change', 'message': 'Your Password has been changed. You may now use your new password for future logins.', 'next_route': '/'};
                // commit('setMessagePageState', message_page);
                // router.push({name: 'Message'});
              } else {
                toastr.error("Unable to change password. Try again", "Error ", {
                  timeOut: config.TOASTR_TIMEOUT
                });
              } // else
            },
            error => {
              toastr.error(error.response.data.message, "", {
                timeOut: config.TOASTR_TIMEOUT
              });
            }
          )
          .catch(e => {
            console.log("ERRORS: " + e);
          });
      });
    }, // updateBackgroundImage
    setUploadGroupImageFlag({ state, commit }, data) {
      commit("setUploadedGroupImage", data);
    },
    setUploadGroupLogoFlag({ state, commit }, data) {
      commit("setUploadedGroupLogo", data);
    },
    getUserById({ state }, data) {
      return new Promise(function(resolve, reject) {
        let url =
          config.DOMAIN +
          config.API_VERSION +
          "organizations/user/id/" +
          data.id;

        axios
          .get(url)
          .then(response => {
            console.log("Org User - ", response);
            resolve(response);
          })
          .catch(e => {
            console.error("Error: ", e);
            // router.push("/");
          }); //axios
      });
    }
  }
};
