import axios from "axios";
import config from "../config.js";
import store from "../store";

export default {
  getContent: function(data) {
    return new Promise((resolve, reject) => {
      let url = config.DOMAIN + config.API_VERSION + "stream";

      // filters
      for (var key in data.filters) url += "&" + key + "=" + data.filters[key];

      axios
        .get(url)
        .then(response => {
          resolve(response);
        })
        .catch(e => {
          console.error("Error: ", e);
          reject(e.response.data.message);
        });
    });
  },
  getImageVideoContentTypes: function(data) {
    return new Promise(function(resolve, reject) {
      let url =
        config.DOMAIN +
        config.API_VERSION +
        "organizations/" +
        data.organization_id;

      if (!!data.organization_group_id)
        url += "/organizationgroups/" + data.organization_group_id;

      if (!!data.contentTypeId && data.contentTypeId == 2) url += "/images";
      if (!!data.contentTypeId && data.contentTypeId == 3) url += "/videos";
      if (!!data.contentTypeId && data.contentTypeId == 7) url += "/pdf";
      if (!!data.contentTypeId && data.contentTypeId == 8) url += "/audio";
      if (!!data.organization_user_id)
        url += "?organization_user_id=" + data.organization_user_id;
      if (!!data.type && data.type == "count") data.count_required = true;
      if (
        store.state.auth.whoami.organization_user.organization_role_id == "8"
      ) {
        url += "&streams_of=" + store.state.auth.whoami.organization_user.id;
      }
      axios
        .post(url, data)
        .then(response => {
          resolve(response);
        })
        .catch(e => {
          console.error("Error: ", e);
          reject(e);
        }); //axios
    });
  }
};
