import axios from "axios";
import toastr from "toastr";
import config from "../../config.js";
import utils from "../../utils.js";
import b64toBlob2 from "b64-to-blob";
import postServices from "../../services/posts";

import * as moment from "moment";
export default {
  namespaced: true,
  state: {
    posts: [],
    draftPosts: [],
    counterUpdate: 0,
    postProgresses: [],
    progress: {},
    // only to check if any one post is in edit mode
    postEditModeOn: false,
    postForExternalUSer: false
  },

  getters: {},

  mutations: {
    setPosts(state, data) {
      console.log("Calling mutation: setPostsList");
      var l = data.length;
      for (var i = 0; i < l; i++) {
        var zone = moment().format("Z");
        data[i].date = moment(
          data[i].date + " 00:00:00 " + zone,
          "YYYY-MM-DD HH:mm:ss Z"
        ).format("MMM DD, YYYY HH:mm:ss");
        data[i].custom_time = moment(data[i].custom_time, "HH:mm").format(
          "hh:mm A"
        );
      }
      Vue.set(state, "posts", data);
    },
    createPostProgress(state, data) {
      console.log("Calling mutation: createProgress", state);
      state.postProgresses.splice(0, 0, data);
    },
    updateCounter(state, data) {
      state.counterUpdate = data;
    },
    updatePostProgress(state, data) {
      console.log("Calling mutation: updatePostProgress", state);
      const index = state.postProgresses.findIndex(
        postProgress => postProgress.id === data.id
      );
      state.postProgresses.splice(index, 1, data);
    },
    deletePostProgress(state, data) {
      console.log("Calling mutation: deletePostProgress", state);
      const index = state.postProgresses.findIndex(
        postProgress => postProgress.id === data.id
      );
      state.postProgresses.splice(index, 1);
    },
    updatePostPercentage(state, data) {
      if (
        data.current == state.progress.current &&
        data.percentage > state.progress.percent
      ) {
        state.progress = data;
      } else if (data.current != state.progress.current) {
        state.progress = data;
      }
    },

    createPost(state, data) {
      console.log("Inside create post mutation--------", data);
      var zone = new Date()
        .toLocaleTimeString("en-us", { timeZoneName: "short" })
        .split(" ")[2];
      data.date = new Date(data.date + " " + zone);
      console.log("80 date issue:: ", data);
      data.custom_time = moment(data.custom_time, "HH:mm").format("hh:mm A");

      console.log("Calling mutation: createPost", state, data);
      state.posts.splice(0, 0, data);
    },

    updatePost(state, data) {
      console.log("Inside update post mutation--------", data);
      var zone = new Date()
        .toLocaleTimeString("en-us", { timeZoneName: "short" })
        .split(" ")[2];
      data.date = new Date(data.date + " " + zone);
      console.log("92 date issue:: ", data);
      console.log(state.counterUpdate);
      if (
        data.organization_posttype_id == 2 ||
        data.organization_posttype_id == 3
      ) {
        if (state.counterUpdate == 1) {
          if (data.custom_time.indexOf("M") == -1) {
            data.custom_time = moment(data.custom_time, "HH:mm").format(
              "hh:mm A"
            );
          }
        }
      } else {
        if (data.custom_time.indexOf("M") == -1) {
          data.custom_time = moment(data.custom_time, "HH:mm").format(
            "hh:mm A"
          );
        }
      }
      console.log("Calling mutation: updatePost", state, data);

      if (data.cloneId > 0) {
        // for draftPosts
        const index = state.draftPosts.findIndex(post => post.id === data.id);
        state.draftPosts.splice(index, 1, data);
      } else if (data.id != null) {
        // for regular post
        const index = state.posts.findIndex(post => post.id === data.id);
        console.log("Index ==> ", index);
        state.posts.splice(index, 1, data);
      }
    },

    deletePost(state, postId) {
      console.log("Calling mutation: deletePost", state, postId);
      const index = state.posts.findIndex(post => post.id === postId);
      state.posts.splice(index, 1);
    },

    pushPostInDraftPosts(state, post) {
      state.draftPosts.push(post);
    },

    updateDraftPosts(state, data) {
      if (data == null) state.draftPosts = [];
      else state.draftPosts = data;
    },
    setPostForExternalUSer(state, data) {
      state.postForExternalUSer = data;
    }
  },

  actions: {
    updateDraftPosts({ state, commit, rootState }, data) {
      commit("updateDraftPosts", data);
    },
    setPostDraftModeOn({ state, commit, rootState }, post) {
      console.log("Inside setPostDraftModeOn=================");
      state.postEditModeOn = true;
      commit("updatePost", { ...post, isDraft: true });
      console.log("postEditModeValue-in- on----------", state.postEditModeOn);
    },
    setPostDraftModeOff({ state, commit, rootState }, post) {
      console.log("Inside setPostDraftModeOff=================");
      state.postEditModeOn = false;
      commit("updatePost", { ...post, isDraft: false });
      console.log(
        "postEditModeValue- in off------------",
        state.postEditModeOn
      );
    },
    addPostProgress({ state, commit, rootState }, postProgress) {
      commit("createPostProgress", postProgress);
    },
    updatePostProgress({ state, commit, rootState }, postProgress) {
      commit("updatePostProgress", postProgress);
    },
    updatePostPercentage({ state, commit }, progress) {
      commit("updatePostPercentage", progress);
    },
    deletePostProgress({ state, commit, rootState }, postProgress) {
      commit("deletePostProgress", postProgress);
    },
    getPostImages({ state, commit, rootState }, post) {
      console.log("Post152 ", post);
      let postId = post.id == null ? post.cloneId : post.id;
      console.log("Fetching Images of Post: ", postId);
      axios
        .get(
          config.DOMAIN +
            config.API_VERSION +
            "groups/" +
            post.organization_group_id +
            "/posts/" +
            postId +
            "/images"
        )
        .then(response => {
          console.log("Post Images:", response.data.posts);
          Vue.set(
            post,
            "images",
            response.data.posts.map(image => {
              image.default = 0;
              (image.highlight = 0),
                (image.name = image.id),
                (image.path =
                  config.DOMAIN +
                  config.API_VERSION +
                  "organizations/" +
                  rootState.auth.whoami.active_organization.id +
                  "/posts/" +
                  postId +
                  "/images/" +
                  image.id);
              return image;
            })
          );

          commit("updatePost", post);
        })
        .catch(e => {
          console.log("Error: ", e);
        }); //axios
    }, // getPostImages

    deleteImage({ state, commit, rootState }, { post, image }) {
      console.log("post,image", post, image);
      axios
        .delete(
          config.DOMAIN +
            config.API_VERSION +
            "groups/" +
            post.organization_group_id +
            "/posts/" +
            post.id +
            "/images/" +
            image.id
        )
        .then(response => {
          console.log(response.data);
        })
        .catch(e => {
          console.log("Error: ", e);
        }); //axios
    },
    deleteVideo({ state, commit, rootState }, post) {
      var video = post.videos[0];
      axios
        .delete(
          config.DOMAIN +
            config.API_VERSION +
            "groups/" +
            post.organization_group_id +
            "/posts/" +
            post.id +
            "/videos/" +
            video.id
        )
        .then(response => {
          console.log(response.data);
          Vue.set(post, "videos", []);
          commit("updatePost", post);
        })
        .catch(e => {
          console.log("Error: ", e);
        }); //axios
    },
    getPostVideos({ state, commit, rootState }, post) {
      let postId = post.id == null ? post.cloneId : post.id;
      axios
        .get(
          config.DOMAIN +
            config.API_VERSION +
            "groups/" +
            post.organization_group_id +
            "/posts/" +
            postId +
            "/videos"
        )
        .then(response => {
          console.log(response.data.posts);
          Vue.set(post, "videos", response.data.posts);
          commit("updatePost", post);
        })
        .catch(e => {
          console.log("Error: ", e);
        }); //axios
    },
    getAllPosts({ state, commit, rootState }, type = "all") {
      axios
        .get(
          config.DOMAIN +
            config.API_VERSION +
            "groups/" +
            rootState.auth.whoami.active_organization_group?.id +
            "/posts?per-page=10000"
        )
        .then(response => {
          console.log(response.data);
          response.data.posts.forEach(function(post) {
            post.isDraft = false;
            if (post.is_draft) {
              console.log("Initial assignment--if------", post.is_draft);
              post.saveAsDraft = true;
            } else {
              console.log("Initial assignment--else------", post.is_draft);
              post.saveAsDraft = false;
            }
          });
          if (type == "all") {
            commit("setPosts", response.data.posts);
          } else if (type == "future") {
            let futurePosts = [];
            for (var i = 0; i < response.data.posts.length; i++) {
              if (response.data.posts[i].posted == null) {
                console.log("Post is not posted");
                futurePosts.push(response.data.posts[i]);
              }
            }
            commit("setPosts", futurePosts);
          } else if (type == "past") {
            let pastPosts = [];
            for (var i = 0; i < response.data.posts.length; i++) {
              if (response.data.posts[i].posted != null) {
                console.log("Post is not posted");
                pastPosts.push(response.data.posts[i]);
              }
            }
            commit("setPosts", pastPosts);
          }
        })
        .catch(e => {
          console.log("Error: ", e);
        }); //axios
    },
    toggleIsDraft({ state, commit, rootState }, post) {
      console.log("Inside toggle post--------", post);
      post.zone = moment.tz.guess();
      axios
        .put(
          config.DOMAIN +
            config.API_VERSION +
            "groups/" +
            post.organization_group_id +
            "/posts/" +
            post.id +
            "?expand=organizationTags,organizationPostChannels,images,organizationGroup,videos,createdby,childPostIds,childPosts,organizationStreamPosts",
          post
        )
        .then(response => {
          console.log("Success::  ", response.data);
        })
        .catch(e => {
          console.log("Error: ", e);
        });
    },
    createPost({ state, commit, dispatch, rootState }, post) {
      return new Promise((resolve, reject) => {
        commit("setLoading", true, { root: true });
        // commit("socialPosts/setLoading", true, { root: true });
        let is_draft_mode = post.is_draft;
        if (
          post.organization_posttype_id == 2 ||
          post.organization_posttype_id == 3
        ) {
          post.is_draft = true;
        }

        if (!post.link.includes("http://") && !post.link.includes("https://"))
          post.link = "http://" + post.link;

        console.log("Saving Post:: ", post);

        let apiUrl =
          config.DOMAIN +
          config.API_VERSION +
          "groups/" +
          post.organization_group_id +
          "/posts" +
          "?expand=organizationTags,organizationPostChannels,images,organizationGroup,videos,createdby,childPostIds,childPosts,organizationStreamPosts";
        if (state.postForExternalUSer) {
          apiUrl += "&external_user=true";
        }
        axios
          .post(apiUrl, post)
          .then(response => {
            resolve(response);
            commit("socialPosts/appendPostId", response.data.id, {
              root: true
            });
            console.log("Post created:  ", response.data);
            let newPost = response.data;
            if ("type" in post && post.type == "message") {
              newPost.type = "message";
              newPost.status_id = 1;
              newPost.status_id_selected = 1;
            }
            if (
              post.organization_posttype_id == 1 ||
              post.organization_posttype_id == 4
            ) {
              if (newPost.is_draft) {
                commit("createPost", {
                  ...newPost,
                  saveAsDraft: !!newPost.is_draft
                });
              } else {
                commit("createPost", { ...newPost, saveAsDraft: false });
              }
            }
            console.log(
              "post.organization_posttype_id: ",
              post.organization_posttype_id
            );
            if (post.organization_posttype_id == 2) {
              let promises = [];
              console.log("post.fileList : ", post.fileList);

              if (post.fileList) {
                Array.from(post.fileList).forEach(file => {
                  if (!file.path) file.path = file.dataUrl;
                  if (!file.id) {
                    promises.push(
                      new Promise((resolve, reject) => {
                        let data = {
                          postId: response.data.id,
                          childPostIds: response.data.childPostIds,
                          groupId: response.data.organization_group_id,
                          path: file.path || file.dataUrl,
                          name: file.name,
                          resolve,
                          reject
                        };
                        console.log("Data: -> ", file, data);
                        dispatch("uploadImageToPost", data);
                      })
                    );
                  } else if (post.cloneId) {
                    console.log("Upload image to post from cloned post");
                  }
                }); // for each
              }
              console.log("promises:: ", promises);
              Promise.all(promises).then(function(values) {
                console.log(values);
                newPost.data_blocks =
                  values[values.length - 1].data.post.data_blocks;
                if (is_draft_mode) {
                  commit("createPost", { ...newPost, saveAsDraft: true });
                } else {
                  commit("createPost", {
                    ...newPost,
                    saveAsDraft: false,
                    is_draft: false
                  });
                  dispatch("toggleIsDraft", { ...newPost, is_draft: false });
                }
                setTimeout(function() {
                  commit("setLoading", false, { root: true });
                  commit("socialPosts/setPostSuccess", true, { root: true });
                  // commit("socialPosts/setLoading", false, { root: true });
                  if (
                    post.fileList &&
                    post.fileList.length > 0 &&
                    post.status_id_selected != post.status_id
                  ) {
                    newPost.status_id = post.status_id_selected;
                    dispatch("updateOnlyPost", newPost);
                  }
                }, config.TOASTR_TIMEOUT);
              });
            } else if (post.organization_posttype_id == 3) {
              console.log("463---> vdo type");
              let promises = [];
              if (post.fileList) {
                console.log("466---> post", post);
                if (!!post.video) {
                  Array.from([post.video]).forEach(file => {
                    if (!!file.id == false) {
                      promises.push(
                        new Promise((resolve, reject) => {
                          let data = {
                            postId: response.data.id,
                            groupId: response.data.organization_group_id,
                            file: file,
                            name: file.name,
                            resolve,
                            reject
                          };
                          dispatch("uploadVideoToPost", data);
                        })
                      );
                    }
                  });
                }
              }
              console.log("Promises:: ", promises);
              Promise.all(promises).then(function(values) {
                console.log("483: vdo promise resolved");
                console.log(values);
                newPost.data_blocks = values[0].data.data_blocks;
                // commit("createPost", newPost);
                if (is_draft_mode) {
                  commit("createPost", { ...newPost, saveAsDraft: true });
                } else {
                  commit("createPost", {
                    ...newPost,
                    saveAsDraft: false,
                    is_draft: false
                  });
                  console.log(newPost);
                  dispatch("toggleIsDraft", { ...newPost, is_draft: false });
                }

                setTimeout(function() {
                  commit("setLoading", false, { root: true });
                  // commit("socialPosts/setLoading", false, { root: true });
                  commit("socialPosts/setPostSuccess", true, { root: true });
                  if (
                    post.fileList &&
                    post.fileList.length > 0 &&
                    post.status_id_selected != post.status_id
                  ) {
                    newPost.status_id = post.status_id_selected;
                    dispatch("updateOnlyPost", newPost);
                  }
                }, config.TOASTR_TIMEOUT);
              });
            } else {
              commit("setLoading", false, { root: true });
              // commit("socialPosts/setLoading", false, { root: true });
              commit("socialPosts/setPostSuccess", true, { root: true });
            }
          })
          .catch(e => {
            console.log("Error: ", e);
            reject(e);
          }); //axios
      });
    }, // createPost

    updatePost({ state, commit, dispatch, rootState }, post) {
      return new Promise((resolve, reject) => {
        commit("setLoading", true, { root: true });
        // commit("socialPosts/setLoading", true, { root: true });
        console.log("Inside update post action==========================");
        let is_draft_mode = post.is_draft;
        if (
          post.organization_posttype_id == 2 ||
          post.organization_posttype_id == 3
        ) {
          post.is_draft = true;
        }

        if (!post.link.includes("http://") && !post.link.includes("https://"))
          post.link = "http://" + post.link;

        console.log("Updating Post:: ", post);
        axios
          .put(
            config.DOMAIN +
              config.API_VERSION +
              "groups/" +
              post.organization_group_id +
              "/posts/" +
              post.id +
              "?expand=organizationTags,organizationPostChannels,images,organizationGroup,videos,createdby,childPostIds,childPosts,organizationStreamPosts",
            post
          )
          .then(response => {
            resolve(response);
            commit("socialPosts/appendPostId", response.data.id, {
              root: true
            });
            console.log(
              "API Success::----------------------------  ",
              response.data
            );
            let updatedPost = response.data;
            if (
              post.organization_posttype_id == 1 ||
              post.organization_posttype_id == 4
            ) {
              if (is_draft_mode) {
                commit("updatePost", { ...updatedPost, saveAsDraft: true });
              } else {
                commit("updatePost", { ...updatedPost, saveAsDraft: false });
              }
              // commit("updatePost", updatedPost);
              if (post.is_draft) {
                commit("updatePost", {
                  ...updatedPost,
                  isDraft: false,
                  saveAsDraft: true
                });
              } else {
                commit("updatePost", {
                  ...updatedPost,
                  isDraft: false,
                  saveAsDraft: false
                });
              }
            }

            console.log(
              "606 post.organization_posttype_id 606: ",
              { ...post.organization_posttype_id },
              post.organization_posttype_id
            );
            if (post.organization_posttype_id == 2) {
              console.log("In 607: post type id is 2");
              let promises = [];
              if (post.fileList) {
                Array.from(post.fileList).forEach(file => {
                  if (!file.id) {
                    promises.push(
                      new Promise((resolve, reject) => {
                        let data = {
                          postId: response.data.id,
                          childPostIds: response.data.childPostIds,
                          groupId: response.data.organization_group_id,
                          path: file.dataUrl || file.path,
                          name: file.name,
                          resolve,
                          reject
                        };
                        dispatch("uploadImageToPost", data);
                      })
                    );
                  }
                });
              }
              console.log("625 post.fileList: ", post.fileList);
              console.log("627 Promises: ", promises);
              Promise.all(promises).then(function(values) {
                console.log("In 627: post type id is 2, in promise");

                if (is_draft_mode) {
                  updatedPost = { ...updatedPost, saveAsDraft: true };
                  commit("updatePost", updatedPost);
                } else {
                  updatedPost = {
                    ...updatedPost,
                    saveAsDraft: false,
                    is_draft: false
                  };
                  commit("updatePost", updatedPost);
                  dispatch("toggleIsDraft", {
                    ...updatedPost,
                    is_draft: false,
                    date: post.date
                  });
                }

                commit("updateCounter", 1);
                commit("updatePost", { ...updatedPost, isDraft: false });
                commit("updateCounter", 0);
                console.log("In 653: post type id is 2");

                setTimeout(function() {
                  commit("setLoading", false, { root: true });
                  // commit("socialPosts/setLoading", false, { root: true });
                  if (
                    post.fileList &&
                    post.fileList.length > 0 &&
                    post.status_id_selected != post.status_id
                  ) {
                    updatedPost.status_id = post.status_id_selected;
                    dispatch("updateOnlyPost", updatedPost);
                  }
                }, config.TOASTR_TIMEOUT);
              });
            } else if (post.organization_posttype_id == 3) {
              let promises = [];
              if (post.fileList) {
                Array.from(post.fileList).forEach(file => {
                  promises.push(
                    new Promise((resolve, reject) => {
                      let data = {
                        postId: response.data.id,
                        groupId: response.data.organization_group_id,
                        file: file,
                        name: file.name,
                        resolve,
                        reject
                      };
                      dispatch("uploadVideoToPost", data);
                    })
                  );
                });
              }

              Promise.all(promises).then(function(values) {
                // commit("updatePost", updatedPost);
                if (is_draft_mode) {
                  updatedPost = { ...updatedPost, saveAsDraft: true };
                  commit("updatePost", updatedPost);
                } else {
                  updatedPost = {
                    ...updatedPost,
                    saveAsDraft: false,
                    is_draft: false
                  };
                  commit("updatePost", updatedPost);
                  dispatch("toggleIsDraft", {
                    ...updatedPost,
                    is_draft: false,
                    date: post.date
                  });
                }

                commit("updateCounter", 1);
                commit("updatePost", { ...updatedPost, isDraft: false });
                commit("updateCounter", 0);
                setTimeout(function() {
                  commit("setLoading", false, { root: true });
                  // commit("socialPosts/setLoading", false, { root: true });
                  if (
                    post.fileList &&
                    post.fileList.length > 0 &&
                    post.status_id_selected != post.status_id
                  ) {
                    newPost.status_id = post.status_id_selected;
                    dispatch("updateOnlyPost", newPost);
                  }
                }, config.TOASTR_TIMEOUT);
              });
            } else {
              console.log("738: In else, setLoading...");
              commit("setLoading", false, { root: true });
              // commit("socialPosts/setLoading", false, { root: true });
            }
          })
          .catch(e => {
            console.log("Error: ", e);
            reject(e);
          }); //axios
      });
    }, //updatePost

    updateOnlyPost({ state, commit, dispatch, rootState }, post) {
      console.log("updateSatusOfPost() ", post);
      post.zone = moment.tz.guess();
      post.date = moment(post.date).format("YYYY-MM-DD");

      postServices.updatePost(post);

      console.log("788childPosts:: ", post.childPosts);
      // update child post status only
      post.childPosts.forEach(cp => {
        cp.status_id = post.status_id;
        cp.zone = post.zone;
        cp.date = post.date;
        console.log("Updating child post... ", cp);
        postServices.updatePost(cp);
      });
    }, // updateOnlyPost

    deletePost({ state, commit, dispatch, rootState }, postId) {
      console.log("deletePost() Called");
      axios
        .delete(
          config.DOMAIN +
            config.API_VERSION +
            "groups/" +
            post.organization_group_id +
            "/posts/" +
            postId
        )
        .then(response => {
          console.log("Deleted - " + response.data);
          commit("deletePost", postId);

          toastr.clear();
          toastr.error("Post deleted successfully!", "", {
            timeOut: config.TOASTR_TIMEOUT
          });
        })
        .catch(e => {
          console.log("Error: ", e);
        }); //axios
    }, // deletePost

    uploadImageToPost({ state, commit, dispatch, rootState }, data) {
      let imageData = new FormData();
      console.log("715 Data:: ", data);
      var ImageURL = data.path;
      if (!data.path) ImageURL = data.url;

      try {
        // Split the base64 string in data and contentType
        var block = ImageURL.split(";");
        // Get the content type of the image
        var contentType = block[0].split(":")[1]; // In this case "image/gif"
        // get the real base64 content of the file
        var realData = block[1].split(",")[1]; // In this case "R0lGODlhPQBEAPeoAJosM...."
        // Convert it to a blob to upload
        let blob;
        try {
          blob = b64toBlob2(realData, contentType);
          // blob = utils.b64toBlob(realData);
        } catch (e) {
          console.error(e);
        }

        // Create a FormData and append the file with "image" as parameter name
        imageData.append("image", blob, data.name);
        // /api/v1/organizations/{orgaization_id}/posts/{id}/images - POST
        axios
          .post(
            config.DOMAIN +
              config.API_VERSION +
              "groups/" +
              data.groupId +
              "/posts/" +
              data.postId +
              "/images",
            imageData
          )
          .then(response => {
            console.log(data.name + " file uploaded!");
            data.resolve(response);
          })
          .catch(e => {
            console.log("Unable to upload image - : ", e);
            data.resolve(response);
          }); //axios
      } catch (e) {
        console.error("Unable to upload This IMG: ", e);
        console.error(e);
      }
    }, // deletePost

    uploadVideoToPost({ state, commit, dispatch, rootState }, data) {
      let videoData = new FormData();
      let file = data.file;
      // append to formData
      videoData.append("video", file, data.name);

      axios
        .post(
          config.DOMAIN +
            config.API_VERSION +
            "groups/" +
            data.groupId +
            "/posts/" +
            data.postId +
            "/videos",
          videoData
        )
        .then(response => {
          console.log(data.name + " file uploaded!");
          data.resolve(response);
          // response.resolve();
        })
        .catch(e => {
          console.error("Unable to upload video - : " + e);
          data.resolve();
        });
    },

    getAllPostLinks({ state, commit, dispatch, rootState }, postId) {
      console.log("getAllPostLinks: ", postId);
    },
    setPostForExternalUSer({ state, commit, rootState }, data) {
      commit("setPostForExternalUSer", data);
    }
  }
};
