import PaginationPages from "../components/Common/PaginationPages";
import PaginationLimit from "../components/Common/PaginationLimit";
import axios from "axios";
import config from "../config";

export const pagination = {
  computed: {},
  data() {
    return {
      paginationLimit: 50
    };
  },
  components: {
    PaginationPages,
    PaginationLimit
  },
  methods: {
    getDataByPagination(api) {
      return new Promise(function(resolve, reject) {
        if (!api.url.includes("?")) api.url += "?";
        if (api.page > 0) api.url += "&page=" + api.page;
        if (!!api.limit && api.limit > 0) api.url += "&limit=" + api.limit;

        let self = this;
        axios({
          method: !!api.method ? api.method : "get",
          url: api.url,
          data: !!api.data ? api.data : null,
          baseURL: config.DOMAIN + config.API_VERSION
        })
          .then(res => {
            let data = {};
            data = res.data;
            console.log("collections", res.data[api.collection]);
            if (!!api.collection) data.models = res.data[api.collection];
            resolve(data);
          })
          .catch(e => {
            reject(e);
          });
      });
    }
  },

  mounted() {
    // console.info("Pagination mixin imported and applied.");
  }
};
