import axios from "axios";
import config from "../../config.js";
import * as moment from "moment";
import router from "../../router";
import toastr from "toastr";

export default {
  namespaced: true,
  state: {
    suggestedMessages: [],
    suggestedMessages: null,
    settingOptions: [],
    applySettingsFor: "",
    mainSettingsUrl: "",
    statusId: 3,
    authors: [],
    selectedAuthors: [],
    profileDetails: null
  },

  getters: {},
  mutations: {
    setSettingProfileDetails(state, data) {
      state.profileDetails = data;
    },
    setSettingOptions(state, data) {
      state.settingOptions = data;
    },
    setSuggestedMessages(state, data) {
      console.log("inside setSuggestedMessages commit");
      state.suggestedMessages = data;
      console.log(state.suggestedMessages);
    },
    assignSuggestedMessages(state, data) {
      console.log("inside setSuggestedMessages commit");
      state.suggestedMessages = data;
      console.log(state.suggestedMessages);
    },
    setApplySettingsFor(state, data) {
      state.applySettingsFor = data;
    },
    setMainSettingsUrl(state, data) {
      state.mainSettingsUrl = data;
    },
    setStatusId(state, data) {
      state.statusId = data;
    },
    setAuthors(state, data) {
      Vue.set(state, "authors", data);
    },
    setSelectedAuthors(state, data) {
      state.selectedAuthors = data;
    }
  },

  actions: {
    setSuggestedMessages({ state, commit, rootState, dispatch }, data) {
      if (rootState.settings.applySettingsFor == "curatedArticle") {
        dispatch("curatedArticle/setSuggestedMessages", data, { root: true });
      } else if (rootState.settings.applySettingsFor == "socialPosts") {
        dispatch("socialPosts/setSuggestedMessages", data, {
          root: true
        });
      }
    },
    assignSuggestedMessages({ state, commit, rootState, dispatch }, data) {
      console.log("Inside assignSuggestedMessages action");
      commit("assignSuggestedMessages", data);
      if (rootState.settings.applySettingsFor == "curatedArticle")
        dispatch("curatedArticle/assignSuggestedMessages", data, {
          root: true
        });
      else if (rootState.settings.applySettingsFor == "socialPosts")
        dispatch("socialPosts/assignSuggestedMessages", data, {
          root: true
        });
    },
    getSuggestedMesaages({ state, commit, rootState, dispatch }, data) {
      console.log("Inside getSuggestedMesaages=======");
      if (rootState.settings.applySettingsFor == "curatedArticle")
        dispatch("curatedArticle/getSuggestedMesaages", data, { root: true });
      else if (rootState.settings.applySettingsFor == "socialPosts")
        dispatch("socialPosts/getSuggestedMesaages", data, {
          root: true
        });
    },
    updateSuggestedMessage({ state, commit, rootState, dispatch }, data) {
      console.log("Update updateSuggestedMessage");
      dispatch("socialPosts/updateSuggestedMessage", data, {
        root: true
      });
    },
    setSettingOptions({ state, commit, rootState }, data) {
      commit("setSettingOptions", data);
    },
    checkApplySettingsForIsSetOrNot({ state, commit, rootState }, data) {
      if (
        rootState.settings.applySettingsFor == "" ||
        rootState.settings.applySettingsFor == null
      )
        console.error("applySettingsFor is not set!");
    },
    setApplySettingsFor({ state, commit, rootState }, data) {
      commit("setApplySettingsFor", data);
    },
    setMainSettingsUrl({ state, commit, rootState }, data) {
      commit("setMainSettingsUrl", data);
    },
    setScheduledDateAndTime({ state, commit, rootState, dispatch }, data) {
      dispatch("checkApplySettingsForIsSetOrNot");
      console.log("111 Date:: ->", data);
      if (rootState.settings.applySettingsFor == "curatedArticle") {
        /// code pending
      } else if (rootState.settings.applySettingsFor == "socialPosts")
        commit(
          "socialPosts/setSocialPost",
          {
            ...rootState.socialPosts.socialPost,
            custom_time: data,
            date: data
          },
          {
            root: true
          }
        );
    },

    // This fun will resolve date for immediately_menu
    getScheduledDateAndTime({ state, commit, rootState, dispatch }, data) {
      return new Promise(function(resolve, reject) {
        dispatch("checkApplySettingsForIsSetOrNot");

        // add condition here
        if (rootState.settings.applySettingsFor == "curatedArticle") {
          // resolve(rootState.curatedArticle.custom_time);
          // pending
        } else if (rootState.settings.applySettingsFor == "socialPosts") resolve(rootState.socialPosts.socialPost.custom_time);
      });
    },
    onBackButtonClickOfSettingPage(
      { state, commit, rootState, dispatch },
      data
    ) {
      if (rootState.settings.applySettingsFor == "curatedArticle") {
        router.push("/curatedarticle/create_step_3");
      } else if (rootState.settings.applySettingsFor == "socialPosts") {
        router.push({ name: "Step1AddMessage" });
      } else if (rootState.settings.applySettingsFor == "streams") {
        router.push({ name: "Streams" });
      } else if (rootState.redirect_url) {
        router.push(rootState.redirect_url);
      }
    },
    setStatusId({ state, commit, rootState, dispatch }) {
      if (rootState.settings.applySettingsFor == "curatedArticle") {
        let statusId = rootState.curatedArticle.curatedArticle.status_id;
        commit("setStatusId", statusId);
      } else if (rootState.settings.applySettingsFor == "socialPosts") {
        let statusId = rootState.socialPosts.socialPost.status_id;
        commit("setStatusId", statusId);
      }
    },
    getAllAuthors({ state, commit, rootState }) {
      console.log("getAllInvites() Called");
      axios
        .get(
          config.DOMAIN +
            config.API_VERSION +
            "organizations/" +
            rootState.auth.whoami.active_organization.id +
            "/categories/" +
            rootState.auth.whoami.active_organization_category_id +
            "/invite"
        )
        .then(response => {
          console.log("Invites GET API:: ", response.data);
          commit("setAuthors", response.data.invites);
        })
        .catch(e => {
          console.log("Error: ", e);
        }); //axios
    },
    attachSelectedAuthors({ state, commit, rootState, dispatch }, data) {
      if (
        rootState.settings.applySettingsFor == "" ||
        rootState.settings.applySettingsFor == null
      )
        console.error("applySettingsFor is not set!");
      commit("setSelectedAuthors", data);
      if (rootState.settings.applySettingsFor == "curatedArticle") {
        dispatch("curatedArticle/attachSelectedTag", data, { root: true });
      } else if (rootState.settings.applySettingsFor == "socialPosts") {
        dispatch("socialPosts/attachSelectedAuthors", data, {
          root: true
        });
      }
    },
    setSettingProfileDetails({ state, commit, rootState }, data) {
      console.log("in setSettingProfileDetails ---", data);
      commit("setSettingProfileDetails", data);
    },
    addSelectedContentTypesToGroup(
      { state, commit, rootState, dispatch },
      selectedContentTypes
    ) {
      console.log(
        "in settings addSelectedContentTypesToGroup -- ",
        rootState,
        state
      );
      dispatch(
        "organizations/addSelectedContentTypesToGroup",
        selectedContentTypes,
        { root: true }
      );
    }
  }
};
