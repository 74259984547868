import axios from "axios";
import config from "../../config.js";
import * as moment from "moment";
import router from "../../router";
import toastr from "toastr";
import utils from "../../utils.js";
import streamsServices from "../../services/streams";

export default {
  namespaced: true,
  state: {
    streams: [],
    streamsFor: null,
    playbookOrganizationId: null,
    streamsSummaryView: [],
    flatFiltersArray: [],
    filters: {
      post_status: [],
      channels: [],
      category: [],
      tags: [],
      content_type: [],
      not_post_ids: [],
      not_stream_ids: [],
      end_time: [],
      start_time: []
    },
    returnUrl: null,
    finishedStream: false,
    currentFilterString: null,
    assignResponseToStreams: true,
    streamSearchString: null,
    // this will decide where filter should be applied, helps reusability of streams feature in moderate feature
    filterFor: "streams", // 1.streams, 2. moderate
    //for checking whether create post is for social_campaign(true) or hub(false)
    social_campaign: false,
    featuredContent: null
  },

  getters: {},
  mutations: {
    setStreams(state, data) {
      state.streams = data;
    },
    setStreamsSummaryView(state, data) {
      state.streamsSummaryView = data;
      console.log(state.streamsSummaryView);
    },
    updateStreams(state, data) {
      const index = state.streams.findIndex(s => s.id === data.id);
      console.log("Index..> ", index, data);
      state.streams.splice(index, 1, data);
    },
    appendToStream(state, data) {
      console.log("appendToStream-----", data.length);
      if (data.length > 0) {
        for (var stream in data) {
          const found = state.streams.some(el => el.id === data[stream].id);
          if (!found) state.streams.push(data[stream]);
        }
        console.log(data.length);
        state.finishedStream = false;
      } else {
        console.log("In if");
        state.finishedStream = true;
      }
    },
    clearNotStreamIds(state) {
      console.log("Inside clearNotStreamIds-----------");
      state.filters.not_stream_ids = [];
      state.finishedStream = false;
    },
    setNotStreamIds(state, data) {
      console.log("Inside setNotStreamIds-----------", data);
      state.filters.not_stream_ids = data;
      // for(var value in data) {
      //   let filterDataElement = {
      //     data: date[value],
      //     filterType: "not_stream_ids"
      //   };
      // }
    },
    setEndTimeFilter(state, data) {
      console.log("Inside setEndTimeFilter-----------", data);
      state.filters.end_time = data;
      state.filters.not_stream_ids = [];
      state.finishedStream = false;
    },
    addFilter(state, filterData) {
      console.log(filterData);
      state.filters[filterData.filterType].push(filterData.data);
      filterData.data.filterName = filterData.filterType;
      state.flatFiltersArray.push(filterData.data);
      console.log(state.filters);
      console.log(state.flatFiltersArray);
    },
    updateFilter(state, filterData) {
      console.log("efiwehiofhiowehighiweg");
      state.filters[filterData.filterType] = filterData.data;
      console.log("Remove Filter---------------------", filterData.data);
      // state.flatFiltersArray = state.flatFiltersArray.filter(element=>{

      // });
    },
    updateCurrentFilterString(state, data) {
      state.currentFilterString = data;
    },
    deleteFromFlatFiltersArray(state, index) {
      state.flatFiltersArray.splice(index, 1);
    },
    setSelectedFilterType(state, data) {
      state.selectedFilterType = data;
    },
    removeOrganizationTagFilterById(state, tagId) {
      let filters = state.filters;
      for (var filterType in filters) {
        if (
          filterType == "category" ||
          filterType == "tags" ||
          filterType == "content_type"
        ) {
          const index = filters[filterType].findIndex(
            element => element.id === tagId
          );
          return filters[filterType].splice(index, 1);
        }
      }
      state.filters = filters;
    },
    clearFlatFiltersArray(state) {
      state.flatFiltersArray = [];
    },
    removeStream(state, data) {
      console.log("Calling mutation: removeStream", state, data);
      const index = state.streams.findIndex(stream => stream.id == data);
      console.log(index);
      state.streams.splice(index, 1);
    },
    deleteOrganizationChannel(state, data) {
      console.log("Calling mutation: deleteOrganizationChannel", data);
      let deletedStream = data;
      for (var stream in state.streams) {
        if (state.streams[stream].id == deletedStream.streamId) {
          let self = this;
          let streamObjects = state.streams[stream]["objects"];
          const index = streamObjects.findIndex(streamObject => {
            let objectAttr = streamObject["object"];
            if (
              objectAttr["organizationPostChannel"]["id"] ===
              deletedStream.organizationPostChannelId
            ) {
              return streamObject;
            }
          });
          console.log(streamObjects.length);
          if (streamObjects.length == 1) {
            state.streams[stream].type = "organization_post";
            streamObjects[0]["object"].organizationPostChannel = null;
          } else {
            streamObjects.splice(index, 1);
          }
          state.streams[stream]["objects"] = streamObjects;
        }
      }
    },

    setAssignResponseToStreams(state, data) {
      state.assignResponseToStreams = data;
    },
    setStreamsFor(state, data) {
      state.streamsFor = data;
    },
    setFeaturedContent(state, data) {
      state.featuredContent = data;
    },
    setPlaybookOrganizationId(state, data) {
      state.playbookOrganizationId = data;
    },
    setReturnUrl(state, data) {
      console.log("returnurl", data);
      state.returnUrl = data;
    },
    setStreamSearchString(state, data) {
      state.streamSearchString = data;
    },
    setSocialCampaign(state, data) {
      state.social_campaign = data;
    }
  },

  actions: {
    setStreams({ state, commit }, data) {
      commit("setStreams", data);
    },
    getStreams({ state, commit, dispatch, rootState }, filters = null) {
      console.log("filters===========", filters);
      var apiUrl = config.DOMAIN + config.API_VERSION;
      if (state.streamsFor == "Group" || state.streamsFor == "content_drip") {
        apiUrl +=
          "stream?&group_ids=" + rootState.auth.activeOrganizationGroup.id;
      } else if (state.streamsFor == "Organization") {
        apiUrl +=
          "stream?organization_id=" +
          rootState.auth.whoami.active_organization.id;
      } else if (state.streamsFor == "User") {
        apiUrl +=
          "stream?organization_user_id=" +
          rootState.auth.whoami.organization_user.id;
      } else if (state.streamsFor == "Moderation") {
        apiUrl +=
          "stream?organization_id=" +
          rootState.auth.whoami.active_organization.id +
          "&status_ids=2&post_moderation=true";
      } else if (state.streamsFor == "PageDomain") {
        // apiUrl +=
        // "stream?organization_id=" +
        // rootState.auth.whoami.active_organization.id;
        let res = rootState.auth.whoami.page_domains.find(
          pd => pd.domain_name == window.location.hostname
        );
        if (!!res) {
          apiUrl += "stream?organization_id=" + res.organization_id;
        }
      } else if (state.streamsFor == "Playbook") {
        apiUrl += "stream?organization_id=" + state.playbookOrganizationId;
      } else {
        apiUrl +=
          "stream?organization_id=" +
          rootState.auth.whoami.active_organization.id;
      }

      if (
        !!rootState.auth.whoami.organization_user &&
        rootState.auth.whoami.organization_user.organization_role_id == "8"
      ) {
        apiUrl += "&streams_of=" + rootState.auth.whoami.organization_user.id;
      }

      if (
        state.streamsFor == "Organization" ||
        state.streamsFor == "Playbook"
      ) {
        apiUrl += "&mystreams=0";
      } else {
        apiUrl += "&mystreams=1";
      }

      let self = this;
      let append = false;
      return new Promise((resolve, reject) => {
        let filterString = null;
        if (state.streamsFor != "Moderation") {
          filterString = utils.getFilterString(state, filters);
        }

        if (!!filters && typeof filters === "object" && filters.length > 0) {
          // console.log(filters);
          filters.forEach(el => {
            if (el.type == "param")
              filterString += "&" + el.title + "=" + el.value;
            if (el.title == "append") append = true;
          });
        }

        try {
          filterString = filterString.replace("null", "");
        } catch (e) {}

        console.log("162: ", filterString);
        axios
          .get(apiUrl + "&" + filterString)
          .then(response => {
            console.log("271 --", state.assignResponseToStreams);
            if (state.assignResponseToStreams) {
              console.log("In if 169 Second Stream Call");
              console.log("274 cond");
              if (state.filters.not_stream_ids.length > 0) {
                commit("appendToStream", response.data.streams);
                console.log("274 cond 1");
              } else if (!!response.data.streams) {
                commit("setStreams", response.data.streams);
                console.log("274 cond 2");
              } else if (append) {
                commit("appendToStream", response.data.models);
                console.log("274 cond 3");
              } else {
                commit("setStreams", response.data.models);
                console.log("274 cond 4");
              }
            } else {
              console.log("In else 177 Second Stream Call");
            }
            resolve(response);
            commit("setAssignResponseToStreams", true);
            console.log("183 streams.js", response.data.streams, state.streams);
          })
          .catch(e => {
            console.log("Error: ", e);
            reject(e);
          }); //axios
      });
    },

    getStreamByPostId({ state, commit, dispatch, rootState }, postId) {
      return new Promise((resolve, reject) => {
        axios
          .get(config.DOMAIN + config.API_VERSION + "stream?post_ids=" + postId)
          .then(response => {
            resolve(response.data.streams);
          })
          .catch(e => {
            console.log("Error: ", e);
          }); //axios
      });
    },
    getStreamsbyTime({ state, commit, dispatch, rootState }, time) {
      return new Promise((resolve, reject) => {
        let filterString = utils.getFilterString(state, filters);
        axios
          .get(
            config.DOMAIN +
              config.API_VERSION +
              "stream?organization_id=" +
              rootState.auth.whoami.active_organization.id +
              "&" +
              filterString
          )
          .then(response => {
            commit("setStreams", response.data.streams);
            resolve(response);
          })
          .catch(e => {
            console.log("Error: ", e);
          }); //axios
      });
    },
    getStreamsByMember({ state, commit, dispatch, rootState }, memberId) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            config.DOMAIN +
              config.API_VERSION +
              "stream?group_ids=" +
              rootState.auth.whoami.active_organization_group.id +
              "&streams_of=" +
              memberId +
              "&mystreams=1"
          )
          .then(response => {
            if (!!response.data.streams)
              commit("setStreams", response.data.streams);
            else commit("setStreams", response.data.models);

            resolve(response);
          })
          .catch(e => {
            console.log("Error: ", e);
          }); //axios
      });
    },
    getStreamsByFilter({ state, commit, dispatch, rootState }, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            config.DOMAIN +
              config.API_VERSION +
              "stream?group_ids=" +
              rootState.auth.whoami.active_organization_group.id +
              "&streams_of=" +
              memberId +
              "&mystreams=1"
          )
          .then(response => {
            commit("setStreams", response.data.streams);
            resolve(response);
          })
          .catch(e => {
            console.log("Error: ", e);
          }); //axios
      });
    },
    getSummaryViewOfStreams(
      { state, commit, dispatch, rootState },
      time = null
    ) {
      return new Promise((resolve, reject) => {
        if (time == null) {
          time = {
            start_time: moment()
              .startOf("month")
              .utc()
              .format("YYYY-MM-DD HH:mm"),
            current_time: moment().format("YYYY-MM-DD HH:mm"),
            end_time: moment()
              .endOf("month")
              .utc()
              .format("YYYY-MM-DD HH:mm")
          };
        }
        var groupid = rootState.auth.whoami.active_organization_group
          ? rootState.auth.whoami.active_organization_group.id
          : null;
        var url =
          config.DOMAIN +
          config.API_VERSION +
          "stream?&summary_view=true" +
          "&group_ids=" +
          groupid +
          "&per_page=500";
        if (time.start_time) {
          url += "&start_time=" + time.start_time;
        } else if (time.end_time) {
          url += "&end_time=" + time.end_time;
        } else {
          url += "&status_ids=3";
        }

        axios
          .get(url)
          .then(response => {
            console.log("getSummaryViewOfStreams committttt", response);
            commit("setStreamsSummaryView", response.data.streams);
            resolve(response);
          })
          .catch(e => {
            console.log("Error: ", e);
          }); //axios
      });
    },
    getScheduledPost({ state, commit, dispatch, rootState }, type) {
      return new Promise((resolve, reject) => {
        var time = {
          start_time: moment()
            .startOf("day")
            .utc()
            .format("YYYY-MM-DD HH:mm"),
          current_time: moment().format("YYYY-MM-DD HH:mm"),
          end_time: moment()
            .endOf("day")
            .utc()
            .format("YYYY-MM-DD HH:mm")
        };
        if (type == "group") {
          var url =
            config.DOMAIN +
            config.API_VERSION +
            "stream?group_ids=" +
            rootState.auth.whoami.active_organization_group.id +
            "&start_time=" +
            time.start_time +
            "&per_page=500&status_ids=3";
        } else if (type == "organization") {
          var url =
            config.DOMAIN +
            config.API_VERSION +
            "stream?organization_id=" +
            rootState.auth.whoami.active_organization.id +
            "&start_time=" +
            time.start_time +
            "&per_page=500&status_ids=3";
        } else if (type == "user") {
          var url =
            config.DOMAIN +
            config.API_VERSION +
            "stream?organization_user_id=" +
            rootState.auth.whoami.organization_user.id +
            "&start_time=" +
            time.start_time +
            "&per_page=500&status_ids=3";
        }
        streamsServices.getPost(url).then(response => {
          resolve(response);
        });
      });
    },
    streamRead({ state, commit, dispatch, rootState }, data) {
      console.log("Inside js stream.js store");
      return new Promise((resolve, reject) => {
        var url =
          config.DOMAIN +
          config.API_VERSION +
          "stream/" +
          data.stream_id +
          "/streamread";
        streamsServices.streamRead(url, data).then(response => {
          resolve(response);
        });
      });
    },
    getPendingApprovalPost({ state, commit, dispatch, rootState }, type) {
      return new Promise((resolve, reject) => {
        var time = {
          start_time: moment()
            .startOf("day")
            .utc()
            .format("YYYY-MM-DD HH:mm"),
          current_time: moment().format("YYYY-MM-DD HH:mm"),
          end_time: moment()
            .endOf("day")
            .utc()
            .format("YYYY-MM-DD HH:mm")
        };
        if (type == "group") {
          var url =
            config.DOMAIN +
            config.API_VERSION +
            "stream?group_ids=" +
            rootState.auth.whoami.active_organization_group.id +
            "&end_time=" +
            time.end_time +
            "&start_time=" +
            time.start_time +
            "&per_page=500&status_ids=2";
        } else if (type == "organization") {
          var url =
            config.DOMAIN +
            config.API_VERSION +
            "stream?organization_id=" +
            rootState.auth.whoami.active_organization.id +
            "&end_time=" +
            time.end_time +
            "&start_time=" +
            time.start_time +
            "&per_page=500&status_ids=2";
        } else if (type == "user") {
          var url =
            config.DOMAIN +
            config.API_VERSION +
            "stream?organization_user_id=" +
            rootState.auth.whoami.organization_user.id +
            "&end_time=" +
            time.end_time +
            "&start_time=" +
            time.start_time +
            "&per_page=500&status_ids=2";
        }

        streamsServices.getPost(url).then(response => {
          resolve(response);
        });
      });
    },
    getAnnouncements({ state, commit, dispatch, rootState }, type) {
      return new Promise((resolve, reject) => {
        var time = {
          start_time: moment()
            .startOf("day")
            .utc()
            .format("YYYY-MM-DD HH:mm"),
          current_time: moment().format("YYYY-MM-DD HH:mm")
        };
        if (type == "group") {
          var url =
            config.DOMAIN +
            config.API_VERSION +
            "stream?group_ids=" +
            rootState.auth.whoami.active_organization_group.id +
            "&start_time=" +
            time.start_time +
            "&per_page=500&organization_content_type_id=4&fetch_announcements=true";
        } else if (type == "organization") {
          var url =
            config.DOMAIN +
            config.API_VERSION +
            "stream?organization_id=" +
            rootState.auth.whoami.active_organization.id +
            "&start_time=" +
            time.start_time +
            "&per_page=500&organization_content_type_id=4&fetch_announcements=true";
        } else if (type == "user") {
          var url =
            config.DOMAIN +
            config.API_VERSION +
            "stream?organization_user_id=" +
            rootState.auth.whoami.organization_user.id +
            "&start_time=" +
            time.start_time +
            "&per_page=500&organization_content_type_id=4&fetch_announcements=true";
        }
        streamsServices.getPost(url).then(response => {
          resolve(response);
        });
      });
    },
    getSuggestedPost({ state, commit, dispatch, rootState }, type) {
      console.log("getSuggestedPost---------", type);
      return new Promise((resolve, reject) => {
        var time = {
          start_time: moment()
            .startOf("day")
            .utc()
            .format("YYYY-MM-DD HH:mm"),
          current_time: moment().format("YYYY-MM-DD HH:mm")
        };
        if (type == "group") {
          var url =
            config.DOMAIN +
            config.API_VERSION +
            "stream?group_ids=" +
            rootState.auth.whoami.active_organization_group.id +
            "&mystreams=1&organization_channel_type_id=11";
        } else if (type == "organization") {
          var url =
            config.DOMAIN +
            config.API_VERSION +
            "stream?organization_id=" +
            rootState.auth.whoami.active_organization.id +
            "&mystreams=1&organization_channel_type_id=11";
        } else if (type == "user") {
          var url =
            config.DOMAIN +
            config.API_VERSION +
            "stream?organization_user_id=" +
            rootState.auth.whoami.organization_user.id +
            "&mystreams=1&organization_channel_type_id=11";
        }
        streamsServices.getPost(url).then(response => {
          resolve(response);
        });
      });
    },
    getNewPosts({ state, commit, dispatch, rootState }, type) {
      return new Promise((resolve, reject) => {
        var time = {
          start_time: moment()
            .startOf("day")
            .utc()
            .format("YYYY-MM-DD HH:mm"),
          end_time: moment()
            .endOf("day")
            .utc()
            .format("YYYY-MM-DD HH:mm")
        };
        if (type == "group") {
          var url =
            config.DOMAIN +
            config.API_VERSION +
            "stream?group_ids=" +
            rootState.auth.whoami.active_organization_group.id +
            "&start_time=" +
            time.start_time +
            "&end_time=" +
            time.end_time +
            "&per_page=500&organization_content_type_id=1";
        } else if (type == "organization") {
          var url =
            config.DOMAIN +
            config.API_VERSION +
            "stream?organization_id=" +
            rootState.auth.whoami.active_organization.id +
            "&start_time=" +
            time.start_time +
            "&end_time=" +
            time.end_time +
            "&per_page=500&organization_content_type_id=1";
        } else if (type == "user") {
          var url =
            config.DOMAIN +
            config.API_VERSION +
            "stream?organization_user_id=" +
            rootState.auth.whoami.organization_user.id +
            "&start_time=" +
            time.start_time +
            "&end_time=" +
            time.end_time +
            "&per_page=500&organization_content_type_id=1";
        }
        streamsServices.getPost(url).then(response => {
          resolve(response);
        });
      });
    },
    prependStreamsToStreams(
      { state, commit, dispatch, rootState },
      streamsData
    ) {
      let data = {};
      data[0] = streamsData[0];
      console.log(
        "Prepend this streamsData: 1 ",
        data,
        "2",
        streamsData,
        state.streams
      );

      // check if stream already exist, remove if exist
      let streams = state.streams;
      const index = state.streams.findIndex(el => el.id === streamsData[0].id);
      if (index >= 0) streams.splice(index, 1);
      commit("setStreams", [...streamsData, ...streams]);
    },
    clearNotStreamIds({ state, commit, dispatch, rootState }) {
      commit("clearNotStreamIds");
    },
    setEndTimeFilter({ state, commit, dispatch, rootState }, data) {
      commit("setEndTimeFilter", data);
    },
    addOrganizationTagsFilter({ state, commit, dispatch, rootState }, data) {
      try {
        console.log("Inside addOrganizationTagsFilter----------", data);
        if (!Array.isArray(data)) {
          let filterType = null;
          if (
            data.organization_tag_type_id == 1 ||
            data.organization_tag_type_id == 4
          ) {
            //category
            filterType = "category";
          } else if (data.organization_tag_type_id == 2) {
            //tags
            filterType = "tags";
          } else if (data.organization_tag_type_id == 3) {
            filterType = "content_type";
          }
          let filterData = {
            data: data,
            filterType: filterType
          };
          commit("addFilter", filterData);
          console.log(filterData);
        } else {
          console.error("Data expected type was object. Array inserted");
        }
      } catch (e) {}
    },
    removeOrganizationTagFilter({ state, commit, dispatch, rootState }, data) {
      let filterType = null;
      if (data.organization_tag_type_id == 1) {
        //category
        filterType = "category";
      } else if (data.organization_tag_type_id == 2) {
        //tags
        filterType = "tags";
      } else if (data.organization_tag_type_id == 3) {
        filterType = "content_type";
      }
      let updatedFilters = state.filters[filterType].filter(element => {
        if (element.organization_tag_type_id == 3) {
          return false;
        } else {
          return element.id != data.id;
        }
      });
      console.log(updatedFilters);
      let filterData = {
        data: updatedFilters,
        filterType: filterType
      };
      console.log(filterData);
      commit("updateFilter", filterData);
      commit("deleteFromFlatFiltersArray", data);
    },
    addPostStatusFilter({ state, commit, dispatch, rootState }, data) {
      let filterData = {
        data: data,
        filterType: "post_status"
      };
      commit("addFilter", filterData);
    },
    removePostStatusFilter({ state, commit, dispatch, rootState }, postType) {
      console.log("removePostStatusFilter: postType ", postType);
      let updatedFilters = state.filters["post_status"].filter(element => {
        return element.id != postType.id;
      });
      let filterData = {
        data: updatedFilters,
        filterType: "post_status"
      };
      commit("updateFilter", filterData);
      commit("deleteFromFlatFiltersArray", postType.index);
    },
    addChannelTypeFilter({ state, commit, dispatch, rootState }, data) {
      let filterData = {
        data: data,
        filterType: "channels"
      };
      commit("addFilter", filterData);
    },
    addFilter({ state, commit, dispatch, rootState }, data) {
      commit("addFilter", data);
    },
    addInFlatFilterArray({ state, commit, dispatch, rootState }, data) {
      let filterData = {
        data: data,
        filterType: "channels"
      };
      commit("addFilter", filterData);
    },
    removeChannelTypeFilter({ state, commit, dispatch, rootState }, channel) {
      let updatedFilters = state.filters["channels"].filter(element => {
        return element.id != channel.id;
      });
      console.log("updatedFilters", updatedFilters);
      let filterData = {
        data: updatedFilters,
        filterType: "channels"
      };
      commit("updateFilter", filterData);
      commit("deleteFromFlatFiltersArray", channel.index);
    },
    setStreamSearchString({ commit }, data) {
      commit("setStreamSearchString", data);
    },
    clearFlatFiltersArray({ commit }) {
      commit("clearFlatFiltersArray");
    },
    removeFromStream({ state, commit, dispatch, rootState }, data) {
      return new Promise(function(resolve, reject) {
        axios
          .post(
            config.DOMAIN +
              config.API_VERSION +
              "stream/deletestream?organization_id=" +
              rootState.auth.whoami.active_organization.id,
            data
          )
          .then(response => {
            console.log("Stream Delete response: ", data, response.data);

            if (
              !!data.organizationPostId &&
              !!data.type &&
              data.type == "organization_post_group"
            ) {
              let streamFound = state.streams.find(
                el => el.id == response.data
              );
              console.log(
                "streamFound: ",
                streamFound,
                streamFound.objects.length
              );
              // will delete OrgGrpPost
              if (!!streamFound && streamFound.objects.length == 1)
                commit("removeStream", response.data);
              else if (!!streamFound) {
                let streamFoundIndex = state.streams.findIndex(
                  el => el.id == data.stream_id
                );
                // let postIndex = streamFound.objects[0].object.findIndex(el => el.id == data.organizationPostId);
                let postObjectIndex = streamFound.objects.findIndex(
                  el => el.object.id == data.organizationPostId
                );
                let objects = streamFound.objects.splice(postObjectIndex, 1);
                commit("updateStreams", streamFound);
                console.log(
                  "streamFound.objects: ",
                  streamFoundIndex,
                  objects,
                  streamFound.objects
                );
              }
            } else if (typeof response.data == "object") {
              // will delete channel from post only
              console.log("In object type data");
              commit("deleteOrganizationChannel", response.data);
            } else {
              // will delete complete stream
              console.log("In single data");
              commit("removeStream", response.data);
            }
            resolve(response);
          })
          .catch(e => {
            console.log("Error: ", e);
            reject(e);
          }); //axios
      });
    },
    removeNotPostIdsFromFlatFiltersArray({
      state,
      commit,
      dispatch,
      rootState
    }) {
      const indexOf = state.flatFiltersArray.findIndex(
        element => element.filterName === "not_post_ids"
      );
      console.log(indexOf);
      if (indexOf >= 0) {
        commit("deleteFromFlatFiltersArray", indexOf);
      }
    },
    removeOrganizationTagFilterById({ state, commit, rootState }, data) {
      console.log(data, "In action");
      commit("removeOrganizationTagFilterById", data);
    },
    updateStreams({ state, commit, rootState }, data) {
      console.log("streams ", state.streams, "To update: ", data);
      commit("updateStreams", data);
    },
    removeStream({ state, commit, rootState }, data) {
      commit("removeStream", data);
    },
    setAssignResponseToStreams({ state, commit, rootState }, data) {
      return new Promise((resolve, reject) => {
        commit("setAssignResponseToStreams", data);
        resolve(true);
      });
    },
    setIsModeratingStatus({ state, commit, rootState }, data) {
      let newFilter = {
        filterName: "post_status",
        id: 2,
        imgSrc: "",
        isChecked: true,
        title: "Posts Awaiting Approval",
        isHidden: true
      };
      let filterData = {
        data: newFilter,
        filterType: newFilter.filterName
      };
      commit("addFilter", filterData);
    },
    setStreamsFor({ state, commit, rootState }, data) {
      commit("setStreamsFor", data);
    },
    setFeaturedContent({ state, commit, rootState }, data) {
      commit("setFeaturedContent", data);
    },

    setPlaybookOrganizationId({ state, commit, rootState }, data) {
      commit("setPlaybookOrganizationId", data);
    },

    getPublicGroupStreams({}, data) {
      console.log("group_id===========", data.group_id);
      var apiUrl = config.DOMAIN + config.API_VERSION;

      apiUrl +=
        "stream?group_ids=" +
        data.group_id +
        "&status_ids=3,5&mystreams=1&publicstreamview=true";
      if (data.notStreamIds.length > 0) {
        // for(var id in data.notStreamIds) {
        apiUrl += "&not_stream_ids=" + data.notStreamIds;

        // }
      }
      let self = this;
      return new Promise((resolve, reject) => {
        axios
          .post(apiUrl)
          .then(response => {
            resolve(response);
          })
          .catch(e => {
            console.log("Error: ", e);
          }); //axios
      });
    },

    setReturnUrl({ commit }, data) {
      commit("setReturnUrl", data);
    },

    getBlogPost({ state, commit, dispatch, rootState }, data) {
      var blogPostFor = data.blogPostFor;
      var apiUrl = config.DOMAIN + config.API_VERSION;
      if (blogPostFor == "Group") {
        apiUrl += "stream?&group_ids=" + data.id;
      } else if (blogPostFor == "Organization") {
        apiUrl += "stream?organization_id=" + data.id;
        if (data.groupIds && data.groupIds.length > 0) {
          apiUrl += "&group_ids=" + data.groupIds.toString();
        }
      } else if (blogPostFor == "User") {
        apiUrl += "stream?streams_of=" + data.id;
      }
      if (blogPostFor == "Author") {
        apiUrl += "stream?author_id=" + data.id;
      }
      apiUrl += "&organization_content_type_id=1,6,7,8,9,10,11,12,13,14,15,16";
      if (data.notStreamIds && data.notStreamIds.length > 0) {
        apiUrl += "&not_stream_ids=" + data.notStreamIds;
      }
      if (rootState.auth.whoami.userid == null) {
        apiUrl += "&publicstreamview=true";
      }
      if (data.hub_ids) {
        apiUrl += "&hub_ids=" + data.hub_ids;
      }

      let self = this;
      return new Promise((resolve, reject) => {
        axios
          .get(apiUrl)
          .then(response => {
            console.log(response);
            resolve(response);
          })
          .catch(e => {
            console.log("Error: ", e);
          }); //axios
      });
    },

    setSocialCampaign({ commit }, data) {
      commit("setSocialCampaign", data);
    }
  }
};
