import Vue from "vue";
import Vuex from "vuex";

import router from "../router";

import auth from "./modules/auth";
import post from "./modules/post";
import categories from "./modules/categories";
import socialProfiles from "./modules/socialProfiles";
import facebookGroups from "./modules/facebookGroups";
import subscriptions from "./modules/subscriptions";

import links from "./modules/links";
import ads from "./modules/ads";

import domains from "./modules/domains";

import teams from "./modules/teams";
import advocacy from "./modules/advocacy";
import share from "./modules/share";
import curatedArticle from "./modules/curatedArticle";
import socialPosts from "./modules/socialPosts";
import channels from "./modules/channels";

import config from "../config.js";
import tags from "./modules/tags.js";
import streams from "./modules/streams.js";
import moderate from "./modules/moderate.js";

import settings from "./modules/settings.js";
import organizations from "./modules/organizations.js";

import grouptags from "./modules/grouptags.js";
import contentlibrary from "./modules/contentlibrary";
import page from "./modules/page";

import contact from "./modules/contact.js";
import campaigns from "./modules/campaigns.js";
import senders from "./modules/senders.js";

import organizationSocialAdAccount from "./modules/organizationSocialAdAccount.js";
import connections from "./modules/connections.js";
import leadForms from "./modules/leadForms.js";

import billingNotifications from "./modules/billingNotifications.js";

import prospectingModule from "./modules/prospecting";
import email from "./modules/email";

import credits from "./modules/credits";

import salesperson from "./modules/salesperson";
import companyApplication from "./modules/companyApplication";

import freelancerProspecting from "./modules/freelancerProspecting";
import postingProspecting from "./modules/postingProspecting";

import salespersonTimesheet from "./modules/salespersonTimesheet";
import chats from "./modules/chats";

Vue.use(Vuex);
export default new Vuex.Store({
  modules: {
    auth,
    post,
    categories,
    socialProfiles,
    facebookGroups,
    ads,
    links,
    subscriptions,
    teams,
    domains,
    advocacy,
    share,
    curatedArticle,
    socialPosts,
    channels,
    tags,
    streams,
    settings,
    organizations,
    grouptags,
    moderate,
    contentlibrary,
    page,
    contact,
    campaigns,
    organizationSocialAdAccount,
    connections,
    leadForms,
    senders,
    billingNotifications,
    prospectingModule,
    email,
    credits,
    salesperson,
    companyApplication,
    freelancerProspecting,
    postingProspecting,
    salespersonTimesheet,
    chats
  },

  state: {
    loading: false,
    icon: "/images/icon.png",
    logo: "images/sociallift-02.png",
    loadChatBubble: true,
    facebook: "images/facebook.svg",
    facebook_personal: "images/facebook_personal.jpg",
    facebook_group: "images/facebook_group.png",
    facebook_page: "images/facebook_page.png",
    free_social_media: "images/test4.png",
    features: "images/Get To Know Our Features.png",
    team: "images/Get Your Team Envolved.png",
    mobile_friendly: "images/MobileFriendly.png",
    growth_friendly: "images/Growth Friendly Pricing.png",
    google_plus: "images/google-plus.svg",
    linkedin: "images/linkedin.svg",
    linkedin_company: "images/linkedin_company.png",
    linkedin_personal: "images/linkedin_personal.png",

    instagram: "images/instagram.svg",
    pinterest: "images/pinterest.svg",
    twitter: "images/twitter.svg",
    pinterest: "images/pinterest.svg",

    close: "images/close.svg",
    // bg: "images/bg.jpg",
    isExtension: false,

    message_page: { title: null, message: null, next_route: null },
    message: "Hello",
    facebookLoginUrl:
      config.DOMAIN + config.API_VERSION + "facebooks/auth/redirect",
    facebookPageLoginUrl:
      config.DOMAIN + config.API_VERSION + "facebooks/connect/page/redirect",
    facebookProfileLoginUrl:
      config.DOMAIN + config.API_VERSION + "facebooks/connect/profile/redirect",
    facebookGroupLoginUrl:
      config.DOMAIN + config.API_VERSION + "facebooks/connect/group/redirect",
    linkedinLoginUrl:
      config.DOMAIN + config.API_VERSION + "linkedins/auth/redirect",
    linkedinProfileLoginUrl:
      config.DOMAIN + config.API_VERSION + "linkedins/profile/redirect",
    twitterLoginUrl:
      config.DOMAIN + config.API_VERSION + "twitters/connect/redirect",
    linkedinCompanyLoginUrl:
      config.DOMAIN + config.API_VERSION + "linkedins/company/redirect",
    pinterestLoginUrl:
      config.DOMAIN + config.API_VERSION + "pinterests/connect/redirect",
    googleLoginUrl:
      config.DOMAIN + config.API_VERSION + "googleclients/auth/redirect",

    redirect_url: "",
    isDropdownOpen: false,
    isDropdownOpenForGroups: false,
    isDropdownOpenForProfile: false,

    campaignPreviewUrl: null,
    userRole: { role_id: 1 }
  },
  mutations: {
    setIsExtension(state, data) {
      console.log("Inside mutation setIsExtension", data);
      Vue.set(state, "isExtension", data);
    },
    setLoadChatBubble(state, data) {
      console.log("Calling mutation: loadChatBubble: " + data);
      state.settingsFor = data;
    },
    setLoading(state, data) {
      Vue.set(state, "loading", data);
      console.log("loading:: -> ", data);
    },
    setRedirectUrl(state, url) {
      Vue.set(state, "redirect_url", url);
    },
    changeIsDropdownOpen(state) {
      state.isDropdownOpen = !state.isDropdownOpen;
    },
    changeIsDropdownOpenForGroups(state) {
      state.isDropdownOpenForGroups = !state.isDropdownOpenForGroups;
    },
    changeIsProfileDropdownOpen(state) {
      state.isDropdownOpenForProfile = !state.isDropdownOpenForProfile;
    },
    setCampaignPreviewUrl(state, data) {
      state.campaignPreviewUrl = data;
    },
    setUserRole(state, data) {
      console.log("Calling mutation: setUserRole: " + data);
      state.userRole = data;
    }
  },
  actions: {
    setIsExtension({ state, commit, rootState }, data) {
      console.log("Inside action setIsExtension", data);
      commit("setIsExtension", data);
    },
    setLoading({ state, commit, rootState }, data) {
      commit("setLoading", data);
    },
    setRedirectUrl({ state, commit, rootState }, url) {
      commit("setRedirectUrl", url);
    },
    changeIsDropdownOpen({ state, commit, rootState }) {
      commit("changeIsDropdownOpen");
    },
    changeIsDropdownOpenForGroups({ state, commit, rootState }) {
      commit("changeIsDropdownOpenForGroups");
    },
    changeIsProfileDropdownOpen({ state, commit, rootState }) {
      commit("changeIsProfileDropdownOpen");
    },
    setCampaignPreviewUrl({ state, commit }, data) {
      localStorage.setItem("campaignPreviewUrl", data);
      commit("setCampaignPreviewUrl", data);
    },
    setUserRole({ state, commit }, data) {
      console.log("Calling mutation: setUserRole: ", data);
      commit("setUserRole", data);
      localStorage.setItem("userRole", JSON.stringify(data));
    }
  },
  router
});
