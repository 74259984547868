import axios from "axios";
import toastr from "toastr";
import config from "../../config.js";
import * as moment from "moment";

export default {
  namespaced: true,
  state: {
    currentActiveTimerId: null,
    selectedContract: null,
    activeTimerData: {},
    timerDescription: ""
  },

  getters: {
    getTimerDescription(state) {
      return state.timerDescription;
    },
    getSelectedContract(state) {
      return state.selectedContract;
    }
  },

  mutations: {
    setCurrentActiveTimerId(state, data) {
      Vue.set(state, "currentActiveTimerId", data);
    },
    resetCurrentActiveTimerId(state, data) {
      Vue.set(state, "currentActiveTimerId", null);
    },
    setSelectedContract(state, data) {
      Vue.set(state, "selectedContract", data);
    },
    resetSelectedContract(state, data) {
      Vue.set(state, "selectedContract", null);
    },
    setActiveTimerData(state, data) {
      Vue.set(state, "activeTimerData", data);
    },
    setTimerDescription(state, data) {
      Vue.set(state, "timerDescription", data);
    },
    resetTimerDescription(state, data) {
      Vue.set(state, "timerDescription", "");
    }
  },

  actions: {
    setCurrentActiveTimerId({ state, commit, rootState }, data) {
      commit("setCurrentActiveTimerId", data);
    },
    resetCurrentActiveTimerId({ state, commit, rootState }, data) {
      commit("resetCurrentActiveTimerId", data);
    },
    setSelectedContract({ state, commit, rootState }, data) {
      commit("setSelectedContract", data);
    },
    resetSelectedContract({ state, commit, rootState }, data) {
      commit("resetSelectedContract", data);
    },
    setActiveTimerData({ state, commit, rootState }, data) {
      commit("setActiveTimerData", data);
    },
    setTimerDescription({ state, commit, rootState }, data) {
      commit("setTimerDescription", data);
    },
    resetTimerDescription({ state, commit, rootState }, data) {
      commit("resetTimerDescription", data);
    }
  }
};
