import axios from "axios";
import config from "../config.js";
import store from "../store";

export default {
  getTags: function(data) {
    return new Promise((resolve, reject) => {
      let url =
        config.DOMAIN +
        config.API_VERSION +
        "organizations/" +
        store.state.auth.whoami.active_organization.id +
        "/tags?";

      if (!!data.organization_tag_type)
        url += "organization_tag_type_id=" + data.organization_tag_type + "&";
      if (!!data.group_ids) url += "group_ids=" + data.group_ids;
      if (!!data.page_id) url += "page_id=" + data.page_id;
      if (!!data.search) url += "search=" + data.search;

      axios
        .get(url)
        .then(response => {
          resolve(response);
        })
        .catch(e => {
          reject(e);
        }); //axios
    });
  },
  updateCategory: function(data) {
    return new Promise(function(resolve, reject) {
      let url =
        config.DOMAIN +
        config.API_VERSION +
        "groups/" +
        store.state.auth.whoami.active_organization_group.id +
        "/posts/" +
        data.post_id +
        "/updatepostcategories";

      axios
        .post(url, data)
        .then(response => {
          resolve(response);
        })
        .catch(e => {
          console.error("Error: ", e);
          reject(e);
        }); //axios
    });
  }
};
