import axios from "axios";
import config from "./config.js";
import store from "./store";
let moment = require("moment-timezone");
let momentDurationFormatSetup = require("moment-duration-format");
// var momentPreciseRangePlugin = require("moment-precise-range-plugin");
momentDurationFormatSetup(moment);
export default {
  validateUrl: function(value) {
    return /^(?:(?:(?:https?|ftp):)?\/\/)?(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
      value
    );
  },
  validateDomain: function(value) {
    return /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,6}(:[0-9]{1,6})?(\/.*)?$/g.test(
      value
    );
  },
  generateUuid() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
      var r = (Math.random() * 16) | 0,
        v = c == "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  },
  getQueryVariables(s) {
    //returns query variables
    var query = {};
    if (s != undefined) {
      s.replace(/\b([^&=]*)=([^&=]*)\b/g, function(m, a, d) {
        if (typeof query[a] != "undefined") {
          query[a] += "," + d;
        } else {
          query[a] = d;
        }
      });
    }
    return query;
  },
  getUrlListFromString: function(value) {
    try {
      return value.match(
        /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
      );
    } catch (e) {
      return null;
    }
  },
  getMetaContent: function(html, name) {
    return html
      .filter((index, tag) => tag && tag.property && tag.property == name)
      .attr("content");
  },
  getShortUrlsFromArrayOfLongUrls: function(
    whoami,
    postContent,
    activeOrganizationGroup,
    linkFor
  ) {
    return new Promise((resolve, reject) => {
      let matchedUrls = this.getUrlListFromString(postContent);
      let uniqueMatchedUrls = [
        ...matchedUrls.reduce((p, c) => p.set(c, true), new Map()).keys()
      ];
      console.log("matchedUrls: ", uniqueMatchedUrls);
      if (uniqueMatchedUrls != null) {
        // api call to return short links
        axios
          .post(
            config.DOMAIN +
              config.API_VERSION +
              "group_id/" +
              activeOrganizationGroup.id +
              "/link/shortenurls?linkFor=" +
              linkFor,
            uniqueMatchedUrls
          )
          .then(response => {
            resolve(response.data);
          })
          .catch(e => {
            console.log("Error: ", e);
            // return postContent;
            resolve(postContent);
          });
      }
    });
  },

  getCurrentTime() {
    var today = new Date();
    var time = today.getHours() + ":" + today.getMinutes();
    return time;
  },

  toDataURL(url, callback) {
    var xhr = new XMLHttpRequest();
    xhr.onload = function() {
      var reader = new FileReader();
      reader.onloadend = function() {
        callback(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.send();
  },
  //Returns the Organization Group User role
  //This is an alternative for using if-else-if or switch case
  //https://ultimatecourses.com/blog/deprecating-the-switch-statement-for-object-literals
  //Pass the organization_user_type_id
  getUserRoleString(type_id) {
    let userRole = {
      1: "Contact",
      2: "Invited",
      3: "Content Creator",
      4: "Administrator",
      5: "Owner",
      6: "Subscriber",
      default: "Not Specified"
    };
    return userRole[type_id] || userRole["default"];
  },

  //Returns the Organization Group User role
  //Pass the organization_user object
  //Implements fallback for name fields.(No Name -> email, No email->No Name Specified )
  getOrganizationUsername(organizationUserObject) {
    return (
      (organizationUserObject.first_name &&
        organizationUserObject.first_name +
          " " +
          organizationUserObject.last_name) ||
      organizationUserObject.email ||
      "No Name Specified"
    );
  },

  //Returns the Organization User image
  //Pass the organization_user object
  //Implements fallback for image.(No image_id -> default image)
  getOrganizationUserimage(organizationUserObject) {
    //returns default placeholder image
    var rand = Date.now();
    if (!!!organizationUserObject || organizationUserObject.image_id == null) {
      return `/images/profile_placeholder.png`;
    }
    if (organizationUserObject.organization_id && organizationUserObject.id) {
      return (
        config.DOMAIN +
        config.API_VERSION +
        "organizations/" +
        organizationUserObject.organization_id +
        "/organizationuser/" +
        organizationUserObject.id +
        "/getuserimage?rand=" +
        rand
      );
    }
  },
  formatBytes(a, b) {
    if (0 == a) return "0 Bytes";
    var c = 1024,
      d = b || 2,
      e = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
      f = Math.floor(Math.log(a) / Math.log(c));
    return parseFloat((a / Math.pow(c, f)).toFixed(d)) + " " + e[f];
  },
  getOrientation(file, callback) {
    var reader = new FileReader();
    reader.onload = function(e) {
      var view = new DataView(e.target.result);
      if (view.getUint16(0, false) != 0xffd8) {
        return callback(-2);
      }
      var length = view.byteLength,
        offset = 2;
      while (offset < length) {
        if (view.getUint16(offset + 2, false) <= 8) return callback(-1);
        var marker = view.getUint16(offset, false);
        offset += 2;
        if (marker == 0xffe1) {
          if (view.getUint32((offset += 2), false) != 0x45786966) {
            return callback(-1);
          }

          var little = view.getUint16((offset += 6), false) == 0x4949;
          offset += view.getUint32(offset + 4, little);
          var tags = view.getUint16(offset, little);
          offset += 2;
          for (var i = 0; i < tags; i++) {
            if (view.getUint16(offset + i * 12, little) == 0x0112) {
              return callback(view.getUint16(offset + i * 12 + 8, little));
            }
          }
        } else if ((marker & 0xff00) != 0xff00) {
          break;
        } else {
          offset += view.getUint16(offset, false);
        }
      }
      return callback(-1);
    };
    reader.readAsArrayBuffer(file);
  },

  b64toBlob(dataURI) {
    var byteString = atob(dataURI.split(",")[1]);
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);

    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: "image/jpeg" });
  },

  getFilterString(state, filters) {
    let filterString = null;
    let filterStringArray = {
      tags: null,
      category: null,
      content_type: null,
      post_status: null,
      channels: null,
      not_post_ids: null,
      not_stream_ids: state.filters.not_stream_ids,
      end_time: state.filters.end_time,
      search: state.streamSearchString
    };
    for (var value in filters) {
      if (filters.type == "param") continue;

      let filterElement = filters[value];
      if (
        filterElement.filterName == "end_time" ||
        filterElement.filterName == "not_stream_ids" ||
        filterElement.filterName == "search"
      ) {
        continue;
      } else if (filterStringArray[filterElement.filterName] == null) {
        if (
          filterElement.filterName == "post_status" &&
          filterElement.id == 6
        ) {
          filterStringArray[filterElement.filterName] = null;
        } else {
          filterStringArray[filterElement.filterName] = filterElement.id;
        }
      } else {
        if (
          filterElement.filterName == "post_status" &&
          filterElement.id == 6
        ) {
          filterStringArray[filterElement.filterName] = null;
        } else {
          filterStringArray[filterElement.filterName] =
            filterStringArray[filterElement.filterName] +
            "," +
            filterElement.id;
        }
      }
    }

    for (value in filterStringArray) {
      if (filterStringArray[value] && value == "tags") {
        console.log("in if");
        if (!filterString) {
          filterString = "tag_ids=" + filterStringArray[value];
        } else {
          filterString = filterString + "&tag_ids=" + filterStringArray[value];
        }
      } else if (filterStringArray[value] && value == "category") {
        console.log("in if");
        if (!filterString) {
          filterString = "category_ids=" + filterStringArray[value];
        } else {
          filterString =
            filterString + "&category_ids=" + filterStringArray[value];
        }
      } else if (filterStringArray[value] && value == "content_type") {
        console.log("in if");
        if (!filterString) {
          filterString =
            "organization_content_type_id=" + filterStringArray[value];
        } else {
          filterString =
            filterString +
            "&organization_content_type_id=" +
            filterStringArray[value];
        }
      } else if (filterStringArray[value] && value == "post_status") {
        console.log("in if");
        if (!filterString) {
          filterString = "status_ids=" + filterStringArray[value];
        } else {
          filterString =
            filterString + "&status_ids=" + filterStringArray[value];
        }
      } else if (filterStringArray[value] && value == "channels") {
        console.log("in if");
        if (!filterString) {
          filterString = "channel_ids=" + filterStringArray[value];
        } else {
          filterString =
            filterString + "&channel_ids=" + filterStringArray[value];
        }
      } else if (filterStringArray[value] && value == "not_post_ids") {
        if (!filterString) {
          filterString = "not_post_ids=" + filterStringArray[value];
        } else {
          filterString =
            filterString + "&not_post_ids=" + filterStringArray[value];
        }
      } else if (
        filterStringArray[value] &&
        filterStringArray[value].length > 0 &&
        value == "not_stream_ids"
      ) {
        if (!filterString) {
          filterString = "not_stream_ids=" + filterStringArray[value];
        } else {
          filterString =
            filterString + "&not_stream_ids=" + filterStringArray[value];
        }
      } else if (
        filterStringArray[value] &&
        filterStringArray[value].length > 0 &&
        value == "end_time"
      ) {
        if (!filterString) {
          filterString = "end_time=" + filterStringArray[value];
        } else {
          filterString = filterString + "&end_time=" + filterStringArray[value];
        }
      } else if (
        filterStringArray[value] &&
        filterStringArray[value].length > 0 &&
        value == "search"
      ) {
        if (!filterString) {
          filterString = "search=" + filterStringArray[value];
        } else {
          filterString = filterString + "&search=" + filterStringArray[value];
        }
      }
    }
    return filterString;
  },
  getInvitedEmailOfOrganization(organizationUserObject) {
    if (
      this.getOrganizationUsername(organizationUserObject) ==
        "No Name Specified" &&
      organizationUserObject.invited_email
    ) {
      return organizationUserObject.invited_email;
    }
    return this.getOrganizationUsername(organizationUserObject);
  },
  isMobileDevice() {
    let isMobile = /Mobile|Phone|Android/i.test(navigator.userAgent);
    return isMobile;
  },
  emailIsValid(email) {
    if (email != "") {
      var pattern = /^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
      return pattern.test(email);
    } else {
      return false;
    }
  },

  showDropDown(id) {
    console.log("Test", id);
    document.getElementById(id).classList.toggle("show");
  },
  getOrganizationUserRoleString(type_id) {
    let userRole = {
      1: "Contact",
      2: "Invited",
      3: "Team",
      4: "Administrator",
      5: "Owner",
      6: "Subscriber",
      7: "Partner",
      default: "Not Specified"
    };
    return userRole[type_id] || userRole["default"];
  },
  getGroupUserRoleString(type_id) {
    let userRole = {
      1: "Owner",
      2: "Administrator",
      3: "Contributor",
      4: "Writer",
      5: "Approver",
      6: "Guest",
      7: "Subscriber",
      default: "Not Specified"
    };
    return userRole[type_id] || userRole["default"];
  },

  copyToClipboard(str) {
    const el = document.createElement("textarea");
    el.value = str;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
  },
  showToast(title, error_msg) {
    let append = false;
    this.$bvToast.toast(error_msg, {
      title: title,
      autoHideDelay: 5000,
      appendToast: append
    });
  },

  getTextContentFromDataBlocks(dataBlocks) {
    let textContent = "";
    dataBlocks.forEach(ele => {
      if (ele.type == "text" && ele.text) {
        textContent = textContent + ele.text;
      }
      if (ele.post_type == 6 || ele.post_type == 17) {
        if (ele.link && ele.link.message) textContent = ele.link.message;
      }
    });
    return textContent;
  },

  secondsToms(seconds) {
    let duration = moment.duration(seconds, "seconds");
    let formatted = duration.format("mm:ss");
    return formatted;
  },

  secondsToHms(d) {
    if (d) {
      return moment.duration(d, "seconds").format();
    } else {
      return 0;
    }
  },
  getLocalDateTime(utcTime) {
    if (utcTime) {
      var localDateTime = moment
        .utc(utcTime)
        .local()
        .format("YYYY-MM-DD HH:mm:ss");
      return localDateTime;
    } else {
      return "";
    }
  },
  getCurrentDateTimeInUTC() {
    var utcMoment = moment.utc();
    var localDateTime = utcMoment.format("YYYY-MM-DD HH:mm:ss");
    return localDateTime;
  },
  getCurrentDateInUTC() {
    var utcMoment = moment.utc();
    var localDate = utcMoment.format("YYYY-MM-DD");
    return localDate;
  },
  getCurrentTimeInUTC() {
    var utcMoment = moment.utc();
    var localTime = utcMoment.format("HH:mm:ss");
    return localTime;
  },
  convertlocaleDateTimeToUTC(localeDateTime, format = "YYYY-MM-DD HH:mm") {
    return moment(localeDateTime, format)
      .utc()
      .format(format);
  },
  getDateInYyyyMmDdFormat(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  },
  // to get the week number for a given date (week starting on Monday)
  getWeekNumber(date) {
    const NUMBER_OF_MILLISECONDS_IN_A_DAY = 1000 * 60 * 60 * 24;
    let d = new Date(
      Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
    );
    let dayNum = d.getUTCDay() || 7;
    d.setUTCDate(d.getUTCDate() + 4 - dayNum);
    let yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    return Math.ceil(
      ((d - yearStart) / NUMBER_OF_MILLISECONDS_IN_A_DAY + 1) / 7
    );
  },
  getLastDateNumberOfWeek(d) {
    const date = new Date(d);
    return new Date(date.setDate(date.getDate() + 6)).getDate();
  },
  getMonthNumber(date) {
    return new Date(date).getMonth() + 1;
  },
  getMonthName(date) {
    let monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ];
    return monthNames[date.getMonth()];
  },
  getFirstDateOfWeek(d) {
    const date = new Date(d);
    const day = date.getDay();
    const diff = date.getDate() - day + (day === 0 ? -6 : 1);
    return new Date(date.setDate(diff));
  },
  getLastDateOfWeek(d) {
    var firstDay = this.getFirstDateOfWeek(d);
    var lastDay = new Date(firstDay);
    lastDay.setDate(lastDay.getDate() + 6);
    return lastDay;
  },
  /*
    Returns hub link for internal and shared link
    Also handles default and custom domain

    Params :
    hub_slug, domainPrefix, link_type : external/internal
  */
  generateHubLink(hub_slug, domainPrefix, link_type, contact_id = null) {
    let url = null;
    let typePrefix = null;
    if (link_type == "external") {
      typePrefix = "uhub/";
    } else if (link_type == "internal") {
      typePrefix = "hub/";
    }

    //final url
    url = `${domainPrefix}${typePrefix}${hub_slug}`;

    if (link_type == "external" && contact_id) {
      url = `${domainPrefix}${typePrefix}${contact_id}/${hub_slug}`;
    }

    return url;
  },

  /*
    Returns hub link for internal and shared link
    Also handles default and custom domain

    Params :
    hub_slug, domainPrefix, link_type : external/internal
  */
  generateContentLink(
    hub_slug = null,
    post_slug,
    domainPrefix,
    link_type,
    contact_id = null
  ) {
    let url = null;
    let typePrefix = null;
    if (!post_slug) {
      console.error("No post slug");
      return null;
    }
    if (link_type == "external") {
      typePrefix = "uhub/";
    } else if (link_type == "internal") {
      typePrefix = "hub/";
    }

    //final url
    url = `${domainPrefix}${typePrefix}${hub_slug}/${post_slug}`;

    if (link_type == "external" && contact_id) {
      url = `${domainPrefix}${typePrefix}${contact_id}/${hub_slug}/${post_slug}`;
    }
    return url;
  },
  getFileUrl(data) {
    //Generating url
    let keyname_str = "";
    if (!!data.s3_object_key) keyname_str = s3_object_key.replace(/\//g, "_");

    let url = config.DOMAIN + config.API_VERSION + "images/s3/url?";

    if (keyname_str != "") url += "&keyname=" + keyname_str;

    if (!!data.id) url += "&id=" + data.id;

    return url;
  },
  getAttachmentUrl(data) {
    //Generating url
    let keyname_str = "";
    if (!!data.s3_object_key) keyname_str = s3_object_key.replace(/\//g, "_");

    let url =
      config.DOMAIN +
      config.API_VERSION +
      "organization/post/s3/attachment/url?";

    if (keyname_str != "") url += "&keyname=" + keyname_str;

    if (!!data.id) url += "&id=" + data.id;

    return url;
  },
  getSalespersonResume(data) {
    //Generating url
    let keyname_str = "";
    if (!!data.s3_object_key) keyname_str = data.s3_object_key;

    let url =
      config.DOMAIN + config.API_VERSION + "sales_person_details/get-resume?";

    if (keyname_str != "") url += "&keyname=" + keyname_str;

    if (!!data.id) url += "&id=" + data.id;

    return url;
  },
  getSalespersonVideo(data) {
    //Generating url
    let keyname_str = "";
    if (!!data.s3_object_key) keyname_str = data.s3_object_key;

    let url =
      config.DOMAIN +
      config.API_VERSION +
      "sales_person_details/get-video-url?";

    if (keyname_str != "") url += "&keyname=" + keyname_str;

    if (!!data.id) url += "&id=" + data.id;

    return url;
  },
  /**
   * This function will check http or https is appended in URL or not. If not then it will append it and return it.
   * @param {String} url
   * @returns String
   */
  checkHttpAppendIfNot(url) {
    let returnURL = url;
    if (!(url.substr(0, 7) == "http://") && !(url.substr(0, 8) == "https://")) {
      returnURL = "http://" + url;
    }
    return returnURL;
  },
  /**
   * This function will remove special characters from input file name and return renamed file name.
   * @param {String} inputFileName
   * @returns String
   */
  replaceSpecialCharacters(inputFileName) {
    let fileNameArray = inputFileName.split(".");
    let extension = fileNameArray.pop();
    let fileName = fileNameArray.join("");
    return fileName.replace(/[^a-zA-Z0-9.()]/g, "") + "." + extension;
  },
  isValidPassword(password) {
    let errorMsg = "";
    let validPassword = true;
    // Check the length requirement
    if (password.length < 8 || password.length > 64) {
      errorMsg = "Must be 8-64 characters long.<br/>";
      validPassword = false;
    }
    // Check for at least one lowercase letter
    if (!/[a-z]/.test(password)) {
      errorMsg += "Require at least one lowercase letter.<br/>";
      validPassword = false;
    }
    // Check for at least one uppercase letter
    if (!/[A-Z]/.test(password)) {
      errorMsg += "Require at least one uppercase letters.<br/>";
      validPassword = false;
    }
    // Check for at least one number
    if (!/\d/.test(password)) {
      errorMsg += "Require at least one number.<br/>";
      validPassword = false;
    }
    // Check for at least one symbol
    if (!/[~!@#$%^&*()]/.test(password)) {
      errorMsg += "Require at least one special characters.<br/>";
      validPassword = false;
    }
    return { errorMsg: errorMsg, validPassword: validPassword };
  },
  generateFormattedText(text) {
    // Replace ~~ with <s>
    text = text.replace(/~~(.*?)~~/g, "<s>$1</s>");

    // Replace ** with <b>
    text = text.replace(/\*\*(.*?)\*\*/g, "<b>$1</b>");

    // Replace * with <i> (only if not already enclosed in **)
    text = text.replace(/\*([^*]+)\*/g, "<i>$1</i>");

    return text;
  }
};
