import axios from "axios";
import config from "../config.js";
import store from "../store";
import { pagination } from "../mixins/pagination";
export default {
  inviteUsersToOrgOrGroup: function(data) {
    return new Promise((resolve, reject) => {
      console.log("Data in services: ", data);
      let url =
        config.DOMAIN +
        config.API_VERSION +
        "organizations/" +
        data.organization_id;
      // if (!!data.organization_group_id)
      // url += "/organizationgroups/" + data.organization_group_id;
      url += "/invite";

      axios
        .post(url, data)
        .then(response => {
          resolve(response);
        })
        .catch(e => {
          reject(e);
        }); //axios
    });
  },

  getAllInvitesOfOrg: function(data) {
    return new Promise((resolve, reject) => {
      let url =
        config.DOMAIN +
        config.API_VERSION +
        "organizations/" +
        data.organization_id +
        "/invite";

      axios
        .get(url)
        .then(response => {
          resolve(response);
        })
        .catch(e => {
          reject(e);
        }); //axios
    });
  },

  getAllInvitesOfOrg2: function(data) {
    return new Promise((resolve, reject) => {
      console.log("Pagination: ", pagination);
      // let url =
      //   config.DOMAIN +
      //   config.API_VERSION +
      //   "organizations/" +
      //   data.organization_id +
      //   "/invite";
      //
      // axios
      //   .get(url)
      //   .then(response => {
      //     resolve(response);
      //   })
      //   .catch(e => {
      //     reject(e);
      //   }); //axios
    });
  }
};
