<template>
  <div class="float-right" v-if="!!data && !!data._meta">
    <div class="">
      <button
        v-if="data._meta.pageCount != 0"
        @click="$emit('page-changed', data._meta.currentPage - 1)"
        href="#"
        class="btn btn-outline-primary"
        :disabled="data._meta.currentPage == 1 ? true : false"
      >
        &lt;
      </button>
      <button
        @click="$emit('page-changed', pageNo)"
        v-for="pageNo in data._meta.pageCount"
        v-if="showOrHide(pageNo)"
        href="#"
        class="btn ml-1"
        :class="
          data._meta.currentPage == pageNo
            ? 'btn-primary'
            : 'btn-outline-primary'
        "
      >
        {{ pageNo }}
      </button>
      <button
        v-if="data._meta.pageCount != 0"
        @click="$emit('page-changed', data._meta.currentPage + 1)"
        href="#"
        class="btn btn-outline-primary"
        :disabled="
          data._meta.currentPage >= data._meta.pageCount ? true : false
        "
      >
        &gt;
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "PaginationPages",
  props: ["data"],
  components: {},
  data() {
    return {};
  },
  watch: {},
  computed: {},
  mounted() {},
  methods: {
    showOrHide(pageNo) {
      const VISIBLE_FIRST_PAGES = 2; // how many first records should be visible?
      const VISIBLE_RECORDS_NEAR_ACTIVE_PAGE = 1; // how many nearest to active page
      const VISIBLE_LAST_PAGES = 1; // how many nearest to last page

      return (
        pageNo <= VISIBLE_FIRST_PAGES ||
        Math.abs(this.data._meta.currentPage - pageNo) <
          VISIBLE_RECORDS_NEAR_ACTIVE_PAGE ||
        Math.abs(this.data._meta.pageCount - pageNo) < VISIBLE_LAST_PAGES
      );
    }
  }
};
</script>

<style lang="scss" scoped></style>
