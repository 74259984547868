import axios from "axios";
import toastr from "toastr";
import config from "../../config.js";
import objCampaign from "../../objects/campaign.js";

export default {
  namespaced: true,
  state: {
    campaigns: { models: [], _meta: [] },
    isFormForPlaybook: false,
    campaignFormElementsToHide: [
      "start_sending_asap",
      "is_html",
      "bannerImage",
      "send_emails_timezone",
      "pause_between_emails",
      "emailSignature",
      "emailTemplate"
    ],
    createCampaignUsingPlaybook: false,
    // default campaign object is initialized in `/objects/campaign.js` file
    // for simplicity and reuse
    campaign: { ...objCampaign },

    sendersFromFile: [],
    contactsFromFile: [],
    csvColName: [],

    selectedEmailsForModal: null
  },

  getters: {},

  mutations: {
    setCampaigns(state, data) {
      Vue.set(state, "campaigns", data);
    },
    setCampaign(state, data) {
      Vue.set(state, "campaign", data);
      console.log("36 change", { ...data });
      console.log("36 change2", data);
    },

    updateCampaignsArray(state, data) {
      const index = state.campaigns.findIndex(i => i.id == data.id);
      state.campaigns.splice(index, 1, data);
    },
    addNewCampaign(state, data) {
      state.campaigns.models.push(data);
    },
    deleteCampaign(state, data) {
      const index = state.campaigns.findIndex(i => i.id == data.id);
      state.campaigns.splice(index, 1);
    },
    setCampaignSenders(state, data) {
      state.campaign.campaignSenders = data;
    },
    setCampaignContacts(state, data) {
      state.campaign.campaignContacts = data;
    },
    setEmailSignature(state, data) {
      state.campaign.emailSignature = data;
    },

    // Campaign CONTACTS
    addCampaignContact(state, data) {
      data.contact_id = data.id;
      data.id = null;
      if (state.campaign.campaignContacts.models) {
        state.campaign.campaignContacts.models.push(data);
      } else {
        state.campaign.campaignContacts = { models: [] };
        state.campaign.campaignContacts.models.push(data);
      }
    },
    addCampaignSenders(state, data) {
      if (state.campaign.campaignSenders.models) {
        state.campaign.campaignSenders.models.push(data);
      } else {
        state.campaign.campaignSenders = { models: [] };
        state.campaign.campaignSenders.models.push(data);
      }
    },
    removeCampaignContact(state, data) {
      const index = state.campaign.campaignContacts.models.findIndex(
        i => i.id == data.id
      );
      state.campaign.campaignContacts.models.splice(index, 1);
    },
    removeCampaignSender(state, data) {
      const index = state.campaign.campaignSenders.models.findIndex(
        i => i.id == data.id
      );
      state.campaign.campaignSenders.models.splice(index, 1);

      //If senders contact if added
      state.campaign.campaignContacts.models = state.campaign.campaignContacts.models.filter(
        e => {
          if (e.organization_sender_id != data.organization_sender_id) return e;
        }
      );
    },
    setEmailTemplate(state, data) {
      state.campaign.emailTemplate = data;
    },
    setEmailTemplateList(state, data) {
      state.campaign.emailTemplateList = data;
    },
    setEmailsPerDay(state, data) {
      state.campaign.emails_per_day = data;
    },
    setPauseBetweenEmails(state, data) {
      state.campaign.pause_between_emails = data;
    },
    setBannerImageToSingleCampaignEmails(state, data) {
      state.campaign.campaignEmails[0].bannerImage = data;
    },

    resetCampaign(state, data) {
      state.campaign = { ...objCampaign };
      console.log("Reset campaign");
    },

    updateSendersFromFile(state, data) {
      state.sendersFromFile = [...data];
    },
    updateContactsFromFile(state, data) {
      state.contactsFromFile = [...data];
    },
    updateCsvColName(state, data) {
      state.csvColName = [...data];
    },
    setFormForPlaybooks(state, data) {
      state.isFormForPlaybook = data;
    },
    setCreateCampaignUsingPlaybook(state, data) {
      state.createCampaignUsingPlaybook = data;
    }
  },

  actions: {
    setCampaigns({ state, commit, rootState }, data) {
      commit("setCampaigns", data);
    },
    setCampaign({ state, commit, rootState }, data) {
      if (!!data.campaignSenders == false)
        data.campaignSenders = { models: [] };
      if (!!data.campaignContacts == false)
        data.campaignContacts = { models: [] };
      console.log("campaign 95", data);
      commit("setCampaign", data);
    },
    resetCampaign({ state, commit, rootState }, data) {
      commit("resetCampaign");
    },

    updateCampaignsArray({ state, commit, rootState }, data) {
      commit("updateCampaignsArray", data);
    },
    addNewCampaign({ state, commit, rootState }, data) {
      commit("addNewCampaign", data);
    },
    deleteCampaign({ state, commit, rootState }, data) {
      commit("deleteCampaign", data);
    },
    setCampaignSenders({ state, commit, rootState, dispatch }, data) {
      commit("setCampaignSenders", data);
      // update selectedSender

      //below code commented to get the sender right
      // dispatch("senders/setSelectedSender", data[0], {
      //   root: true,
      // });
    },
    setCampaignContacts({ state, commit, rootState }, data) {
      commit("setCampaignContacts", data);
    },

    moveEmail({ state, commit, rootState }, data) {
      console.log("168", data);
      let campaign = state.campaign;
      let current = { ...campaign.campaignEmails[data.index] };
      let offset = data.direction == "left" ? -1 : 1;
      if (data.index > 0 && data.index < campaign.campaignEmails.length) {
        // console.log("175", campaign.campaignEmails[data.index]);
        // campaign.campaignEmails[data.index] = { ...campaign.campaignEmails[data.index+offset] };
        // campaign.campaignEmails[data.index+offset] = current;
        // console.log("176", campaign.campaignEmails[data.index] );

        campaign.campaignEmails.splice(
          data.index,
          2,
          array[data.index + offset],
          array[data.index]
        );
      }
      console.log("36 1");
      commit("setCampaign", { ...campaign });
      console.log("36 3");
      campaign.campaignEmails.filter(el => {
        console.log("id", el.id);
      });
    },

    addCampaignContact({ state, commit, rootState }, data) {
      //let sender = null;
      // rootState.senders.senders.filter(el => {
      //   // el.organizationSender.filter(e => {
      //   //   if (e.organization_page_id == state.campaign.organization_page_id)
      //   //     sender = e;
      //   // });
      //   if (
      //     el.organization_user_id == data.organization_user_id &&
      //     el.organization_page_id == state.campaign.organization_page_id
      //   ) {
      //     sender = el;
      //   }
      // });

      //console.log("120 sender:: ", sender);
      // if (
      //   rootState.senders.selectedSender &&
      //   rootState.senders.selectedSender.id
      // ) {
      //   data.organization_sender_id = rootState.senders.selectedSender.id;
      // }
      console.log("113 dataa------------", data);
      commit("addCampaignContact", data);
    },

    addCampaignSenders({ state, commit, rootState }, data) {
      data.name = data.first_name + " " + data.last_name;
      if (!!!data.organizationSender) {
        console.log("Inside if not ordsender inside data");
        let tempData = {};
        tempData.organizationSender = data;
        tempData.organization_sender_id = data.id;
        tempData.id = null;
        data = tempData;
      } else {
        let tempData = {};
        tempData.organizationSender = data.organizationSender;
        tempData.organization_sender_id = data.organizationSender.id;
        tempData.id = null;
        data = tempData;
      }
      commit("addCampaignSenders", data);
    },
    removeCampaignContact({ state, commit, rootState }, data) {
      commit("removeCampaignContact", data);
    },
    removeCampaignSender({ state, commit, rootState }, data) {
      commit("removeCampaignSender", data);
    },
    setEmailSignature({ state, commit, rootState }, data) {
      commit("setEmailSignature", data);
    },
    setEmailTemplateList({ state, commit, rootState }, data) {
      state.campaign.emailTemplateList = data;
    },
    setEmailTemplate({ state, commit, rootState }, data) {
      state.campaign.emailTemplate = data;
    },
    setEmailsPerDay({ state, commit, rootState }, data) {
      state.campaign.emails_per_day = data;
    },
    setPauseBetweenEmails({ state, commit, rootState }, data) {
      commit("setPauseBetweenEmails", data);
    },
    setBannerImageToSingleCampaignEmails({ state, commit, rootState }, data) {
      commit("setBannerImageToSingleCampaignEmails", data);
    },

    updateSendersFromFile({ state, commit, rootState }, data) {
      commit("updateSendersFromFile", data);
    },
    updateContactsFromFile({ state, commit, rootState }, data) {
      commit("updateContactsFromFile", data);
    },
    updateCsvColName({ state, commit, rootState }, data) {
      commit("updateCsvColName", data);
    },
    setFormForPlaybooks({ state, commit, rootState }, data) {
      commit("setFormForPlaybooks", data);
    },
    setCreateCampaignUsingPlaybook({ state, commit, rootState }, data) {
      commit("setCreateCampaignUsingPlaybook", data);
    }
  }
};
