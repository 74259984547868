import axios from "axios";
import toastr from "toastr";
import config from "../../config.js";
import router from "../../router";

export default {
  namespaced: true,
  state: {
    sharePost: {
      originalUrl: null,
      headline: null,
      message: null,
      image: null,
      shortentedLink: null,
      postDestinations: null,
      adId: null,
      imageURL: null
    },
    ideas: [],
    score: [],
    analytics: [],
    notifications: [],
    isValiOriginalUrl: false,
    imagesFromURL: null,
    selectedFullScreenAd: null,
    imageFile: null,
    selectedImageIndex: 0,
    filteredImages: []
  },

  getters: {},

  mutations: {
    setSharePost(state, data) {
      Vue.set(state, "sharePost", data);
    },
    getIdeas(state, data) {},
    getScore(state, data) {},
    getAnalytics(state, data) {},
    checkIsOriginalUrlValid(state, value) {
      state.isValiOriginalUrl = value;
    },
    setimagesFromURL(state, value) {
      state.imagesFromURL = value;
    },
    setSelectedFullScreenAd(state, value) {
      console.log("Inside mutation setSelectedFullScreenAd-----------");
      state.selectedFullScreenAd = value;
      console.log(state.selectedFullScreenAd);
    },
    setImageObject(state, value) {
      state.imageFile = value;
    },
    setImageId(state, value) {
      state.imageId = value;
    },
    setSelectedImageIndex(state, value) {
      state.selectedImageIndex = value;
    },
    setFilteredImages(state, value) {
      state.filteredImages.push(value);
    }
  },

  actions: {
    setSharePost({ state, commit, dispatch, rootState }, data) {
      commit("setSharePost", data);
    },
    getIdeas({ state, commit, rootState }) {},
    getAnalytics({ state, commit, rootState }) {},
    getMetaTags({ state, commit, rootState }, url) {
      console.log("Inside getMetaTags :: ", url);
      let vm = this;
      axios
        .get(
          config.DOMAIN +
            config.API_VERSION +
            "group_id/" +
            rootState.auth.whoami.active_organization_group.id +
            "/link/getmetatags?url=" +
            url
        )
        .then(response => {
          console.log(response.data);
          let imageData = null;
          //Checking whether image array is empty or not
          if (response.data["images"].length > 0) {
            imageData = response.data["images"][0]["url"];
          }
          let metaTags = {
            originalUrl: url,
            headline: response.data["title"],
            message: response.data["description"],
            image: imageData,
            imageURL: imageData
          };
          console.log("metaTags=========", metaTags);
          commit("setSharePost", metaTags);
        })
        .catch(e => {
          console.log("Error: ", e);
        }); //axios
    },
    checkIsOriginalUrlValid({ state, commit, rootState }, value) {
      console.log("checkIsOriginalUrlValid----------------", value);
      commit("checkIsOriginalUrlValid", value);
    },
    getImagesFromURL({ state, commit, rootState }, url) {
      console.log("in getImages");
      axios
        .get(
          config.DOMAIN +
            config.API_VERSION +
            "group_id/" +
            rootState.auth.whoami.active_organization_group.id +
            "/link/getimagesfromurl?url=" +
            url
        )
        .then(response => {
          console.log(response.data);
          console.log("Image URLs=========", response.data);
          commit("setimagesFromURL", response.data);
        })
        .catch(e => {
          console.log("Error: ", e);
        }); //axios
    },
    setSelectedFullScreenAd({ state, commit, rootState }, data) {
      console.log("Inside action setSelectedFullScreenAd-----------");
      commit("setSelectedFullScreenAd", data);
    },
    setImageObject({ state, commit, rootState }, data) {
      commit("setImageObject", data);
    },
    setImagesFromURL({ state, commit, rootState }, data) {
      commit("setimagesFromURL", data);
    },
    setSelectedImageIndex({ state, commit, rootState }, data) {
      commit("setSelectedImageIndex", data);
    },
    setFilteredImages({ state, commit, rootState }, data) {
      commit("setFilteredImages", data);
    }
  }
};
