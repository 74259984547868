import store from "../store";
import Swal from "sweetalert2";
import router from "../router";

function checkStatus() {
  Swal.fire({
    title: "Upgrade Plan",
    text: "Upgrade your plan to use these features",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Upgrade"
  }).then(result => {
    if (result.isConfirmed) {
      router.push({
        name: "BillingWrapper",
        params: { selectedTab: "Plan" }
      });
    }
  });
}

export default [
  {
    path: "/:organization_slug/admin/campaign/create",
    name: "Campaign",
    meta: { for: "campaign", type: "newCampaign" },
    component: () => import("../components/Workspace/Campaign/Campaign.vue"),
    beforeEnter: (to, from, next) => {
      if (
        store.state.auth.whoami.subscription_allowed_features.menu_options
          .campaign_add == true
      ) {
        next();
      } else {
        checkStatus();
      }
    }
  },
  {
    path: "/:organization_slug/admin/campaign/edit/:campaign_id",
    name: "CampaignEdit",
    meta: { for: "campaign" },
    component: () => import("../components/Workspace/Campaign/Campaign.vue")
  },

  {
    path: "/:organization_slug/admin/campaigns",
    name: "CampaignList",
    component: () =>
      import("../components/Workspace/Campaign/CampaignList.vue"),
    beforeEnter: (to, from, next) => {
      if (
        store.state.auth.whoami.subscription_allowed_features.menu_options
          .campaign_manage == true
      ) {
        next();
      } else {
        checkStatus();
      }
    }
  },
  {
    path: "/:organization_slug/admin/emailsettings",
    name: "EmailSettings",
    component: () =>
      import(
        "../components/Workspace/Campaign/EmailSettings/EmailSettings.vue"
      ),
    beforeEnter: (to, from, next) => {
      if (
        store.state.auth.whoami.subscription_allowed_features.menu_options
          .settings_email_settings == true
      ) {
        next();
      } else {
        checkStatus();
      }
    }
  },
  {
    path:
      "/:organization_slug/admin/unsubscribe/:contact_id/:token/:email_id/:campaign_id",
    name: "UnsubscribeConfirmation",
    component: () =>
      import("../components/Workspace/Campaign/UnsubscribeConfirmation.vue")
  },
  {
    path:
      "/:organization_slug/admin/campaign/approve/:organization_id/:campaign_id/:campaign_sender_id/:token",
    name: "ApproveCampaign",
    component: () =>
      import("../components/Workspace/Campaign/ApproveCampaign.vue")
  },

  {
    path:
      "/:organization_slug/campaign/:campaign_contact_id/:token/:email_id/hub/:hub_slug",
    name: "CampaignHubPage",
    meta: { for: "campaign", storeViewActivity: true },
    component: () => import("../components/ContentDrip/Hubs/Hub.vue")
    // import("../components/Workspace/Campaign/Public/HubPage.vue")
  },
  {
    path: "/admin/campaign/:campaign_contact_id/:token/:email_id/hub/:hub_slug",
    name: "CampaignHubPage1",
    meta: { for: "campaign", storeViewActivity: true },
    component: () => import("../components/ContentDrip/Hubs/Hub.vue")
    // import("../components/Workspace/Campaign/Public/HubPage.vue")
  },
  // public urls: this url links will be sent in a mail and tracking is required for this

  {
    path:
      "/:organization_slug/campaign/:campaign_contact_id/:token/:email_id/:hub_slug/:post_slug",
    name: "CampaignStreamPage",
    meta: { for: "campaign", storeViewActivity: true },
    component: () => import("../components/ContentDrip/Hubs/HubPost.vue")
    // import("../components/Workspace/Campaign/Public/StreamPage.vue")
  },

  // Route for custom domain content link
  {
    path:
      "/admin/campaign/:campaign_contact_id/:token/:email_id/:hub_slug/:post_slug",
    name: "CampaignStreamPage",
    meta: { for: "campaign", storeViewActivity: true },
    component: () => import("../components/ContentDrip/Hubs/HubPost.vue")
    // import("../components/Workspace/Campaign/Public/StreamPage.vue")
  },

  //Route for custom domain campaign content link with no hub
  {
    path: "/admin/campaign/:campaign_contact_id/:token/:email_id/:post_slug",
    name: "CampaignStreamPage1",
    meta: { for: "stream_without_hub", storeViewActivity: true },
    component: () => import("../components/ContentDrip/Hubs/HubPost.vue")
    // import("../components/Workspace/Campaign/Public/StreamPage.vue")
  },
  //Route for campaign content link with no hub (default domain)
  {
    path:
      "/:organization_slug/campaign/:campaign_contact_id/:token/:email_id/:post_slug",
    name: "CampaignStreamPage2",
    meta: { for: "stream_without_hub", storeViewActivity: true },
    component: () => import("../components/ContentDrip/Hubs/HubPost.vue")
    // import("../components/Workspace/Campaign/Public/StreamPage.vue")
  },

  {
    path: "/:organization_slug/admin/campaigncalendar",
    name: "CampaignCalendar",
    component: () =>
      import("../components/Workspace/Campaign/CampaignCalendar/Main.vue"),
    beforeEnter: (to, from, next) => {
      if (
        store.state.auth.whoami.subscription_allowed_features
          .email_outreach_and_automation.campaign_calendar == true
      ) {
        next();
      } else {
        checkStatus();
      }
    }
  }

  //
  // {
  //   // path: "/organizations/:id/pages/shortcode/:shortcode",
  //   path: "/:organization_slug/hub/:hub_slug",
  //   name: "Hub12",
  //   component: () => import("../components/ContentDrip/Hubs/Hub.vue")
  // },
  //
  // {
  //   // path: "/organizations/:id/pages/shortcode/:shortcode",
  //   path: "/:organization_slug/hub/:hub_slug/:post_slug",
  //   name: "HubPost",
  //   meta: { for: "hub" },
  //   component: () => import("../components/ContentDrip/Hubs/HubPost.vue")
  // },
];
