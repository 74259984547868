import companyApplicationService from "../../services/companyApplication";
import salespersonService from "../../services/salesPersonApplication";
import userActivitiesService from "../../services/userActivitiesServices";
import store from "../index";
import { helpers } from "../../components/Workspace/Prospecting/helpers";
import moment from "moment";

// freelancerProspecting
// postingProspecting
/**
 * !NOTE: Please add the initial state for the store.
 * @returns Initial Object for the store
 */

function initialStateForStore() {
  return {
    prospectingData: {
      models: [],
      _meta: {
        totalCount: 1,
        pageCount: 1,
        currentPage: 1,
        perPage: 50
      }
    },
    isWaitingForProspectingData: false,
    isWaitingForFilterData: false,
    isLoadSearchAppliedFilter: false,
    showSaveFilterButton: false,
    postingFiltersData: [
      { companyName: [] },
      { postedBy: [] },
      { salesRole: [] },
      {
        postingStatus: [
          { name: "All", id: null },
          { name: "Drafts", id: 0 },
          { name: "Open", id: 1 },
          { name: "Filled", id: 2 },
          { name: "Closed", id: 3 }
        ]
      },
      {
        projectDuration: [
          { name: "Less than 1 week" },
          { name: "1 to 4 weeks" },
          { name: "1 to 3 months" },
          { name: "3 to 6 months" },
          { name: "Longer than 6 months" },
          { name: "I'll decide later" }
        ]
      },
      {
        jobCommitment: [
          { name: "Full-time (40 hours/week)" },
          { name: "Part-time (20 hours/week)" },
          { name: "Hourly (Up to 10 hours/week)" },
          { name: "I'll decide later" }
        ]
      },
      {
        hourlyRate: [
          { name: "Less than $10/hr" },
          { name: "$11 - $20/hr" },
          { name: "$21 - $30/hr" },
          { name: "$31 - $50/hr" },
          { name: "$51 - $70/hr" },
          { name: "$71 - $90/hr" },
          { name: "$91+/hr" },
          { name: "Not sure on budget yet" }
        ]
      },
      {
        startDate: [
          { name: "Immediately" },
          { name: "In 1 to 2 weeks" },
          { name: "More than 2 weeks from now" },
          { name: "I'll decide later" }
        ]
      },
      {
        experience: [
          { name: "Less than 2 years" },
          { name: "2 to 3 years" },
          { name: "3 to 8 years" },
          { name: "More than 8 years" }
        ]
      },
      {
        postingVisibility: [
          { name: "All" },
          { name: "Visible" },
          { name: "Hidden" }
        ]
      },
      { salesSkills: [] },
      { technologySkills: [] },
      { industrySkills: [] }
    ],
    latestTimeStampForFilterSearch: {
      company_name: null,
      posted_by: null,
      sales_role: null,
      posted_status: null,
      project_length: null,
      job_commitment: null,
      hourly_rate: null,
      start_date: null,
      experience: null,
      sales_skills: null,
      technology_skills: null,
      industry_skills: null,
      posting_visibility: null
    },
    latestTimeStampForFilterData: {
      send_data_for_filter: null
    },
    appliedFilters: {
      filters: {
        postingVisibility: [{ name: "Visible" }],
        organization_id: null,
        sales_person_details_id: null
      },
      display: "contacts",
      fullSearch: "",
      pages: { page: 1, pageSize: 20 }
    },
    savedSearchList: [],
    recentActivitiesList: [],
    allActivitiesList: [],
    salespersonRoles: [],
    selectedProspects: [],
    selectedContactIds: [],
    selectedProspectIds: [],
    availableContactIds: []
  };
}

export const state = initialStateForStore();

export const actions = {
  updateCurrentPage(context, currentPage) {
    context.commit("setCurrentPage", currentPage);
    context.dispatch("sendFilterData");
  },
  updateLimit(context, limit) {
    context.commit("setPageLimit", limit);
    context.dispatch("sendFilterData");
  },
  async fetchData({ state, commit, rootState }) {
    const data = await companyApplicationService.prospectingSearch(
      state.filtersData
    );
    state.commit("setData", data);
    return data;
  },
  async setSalesRoles({ state, commit, rootState }) {
    let salespersonTypes = await salespersonService.getSalespersonTypes();
    let data = {
      data: salespersonTypes?.data,
      field: "sales_role"
    };
    commit("setFilterData", data);
    if (!!salespersonTypes.data) commit("setSalesRoles", salespersonTypes.data);
  },
  async setIndustryPreferences({ state, commit, rootState }) {
    let params = {
      preferences_type_id: 3
    };
    let salespersonTypes = await salespersonService.fetchPreferences(params);
    console.log(
      "🔥 -> file: postingProspecting.js:149 -> setIndustryPreferences -> salespersonTypes",
      salespersonTypes
    );
    let data = {
      data: salespersonTypes?.data,
      field: "sales_role"
    };
    commit("setFilterData", data);
    if (!!salespersonTypes.data) commit("setSalesRoles", salespersonTypes.data);
  },
  setCountryList({ state, commit, rootState }, countryList) {
    let data = {
      data: countryList,
      field: "location"
    };
    commit("setFilterData", data);
    let citizenshipData = {
      data: countryList,
      field: "citizenship"
    };
    commit("setFilterData", citizenshipData);
  },

  async updateSearchQuery(context, searchData) {
    // UPDATE_SEARCH_QUERY
    // NEED TO UPDATE THSI
    let { state, commit, rootState } = context;
    let { searchString, field } = searchData;
    if (searchString) {
      try {
        context.dispatch("updateWaiting", {
          value: true,
          waitingType: "filterData"
        });

        context.dispatch("updateTimestampForSearchFilter", field);

        let response = null;
        let data = {
          search: searchString,
          timestamp: state.latestTimeStampForFilterSearch[field],
          organization_id: rootState.auth.whoami.active_organization.id
        };
        if (field == "posted_by") {
          response = await companyApplicationService.getPostedByNames(data);
        } else if (field == "company_name") {
          response = await companyApplicationService.getCompanyDetails(data);
        } else if (field == "sales_skills") {
          data["preferences_type_id"] = 1;
          response = await companyApplicationService.getPreferencesForSearch(
            data
          );
        } else if (field == "technology_skills") {
          data["preferences_type_id"] = 2;
          response = await companyApplicationService.getPreferencesForSearch(
            data
          );
        } else if (field == "industry_skills") {
          data["preferences_type_id"] = 3;
          response = await companyApplicationService.getPreferencesForSearch(
            data
          );
        }
        if (
          state.latestTimeStampForFilterSearch[field] ==
          response.data["timeStamp"]
        ) {
          if (searchString) {
            let data = response.data;
            if (field == "posted_by") {
              data.map(el => {
                if (!el.hasOwnProperty("name")) {
                  el.name = el.first_name + " " + el.last_name;
                }
              });
            } else if (field == "company_name") {
              // data = data.models;
              data = data.models.map(el => {
                return {
                  id: el.id,
                  name: el.company_name
                };
              });
            }
            if (data.timeStamp) {
              delete data["timeStamp"];
            }

            commit("setSearchFilterData", {
              data,
              field
            });
          } else
            commit("setSearchFilterData", {
              data: { data: [] },
              field
            });
        }
      } catch (error) {
        // console.log("🚀 ~ file: prospecting.js ~ line 264 ~ error", error);
      } finally {
        context.dispatch("updateWaiting", {
          value: false,
          waitingType: "filterData"
        });
      }
    } else {
      // if searchString is empty then this is called
      // context.dispatch(FETCH_CUSTOM_FILTER_DATA, field);
      // context.dispatch("setSearchFilterData", { data: { data: [] }, field });
    }
  },
  updateWaiting(context, waitingData) {
    // UPDATE_WAITING
    let { value, waitingType } = waitingData;
    context.commit("setWaiting", { value, waitingType });
  },
  updateTimestampForSearchFilter(context, field) {
    // [UPDATE_TIMESTAMP_FOR_FILTER_SEARCH]
    context.commit("setTimestampForSearchFilter", field);
  },
  async updateAppliedFilter(context, newFilterData) {
    console.log("Inside updateAppliedFilter");

    let { state, commit, rootState } = context;
    console.log("rootState========>", rootState);
    // UPDATE_APPLIED_FILTER
    let { data, filterName } = newFilterData;
    commit("setAppliedFilter", newFilterData);
    console.log("rootState.auth===>", rootState.auth);
    if (rootState.auth.whoami.role_id == 1) {
      state.appliedFilters.filters.organization_id =
        rootState.auth.whoami.active_organization.id;
    } else {
      state.appliedFilters.filters.organization_id =
        rootState.auth.whoami.domain.organization_id;
    }
    if (rootState.auth.whoami.role_id == 2) {
      state.appliedFilters.filters.sales_person_details_id =
        rootState.auth.whoami.sale_person_details.id;
    }
    if (
      state.appliedFilters.fullSearch.length ||
      Object.keys(state.appliedFilters.filters).length
    ) {
      context.dispatch("sendFilterData");
    } else {
      context.dispatch("clearProspectingData");
      context.dispatch("clearSelectedProspects");
    }
    // dispatch("CHECK_APPLIED_FILTER_DATA");
    // update the store with new filter applied.
    // send the updated filters data to API
  },
  clearSelectedProspects(context) {
    context.commit("setSelectedProspects", []);
  },
  async sendFilterData(context) {
    // SEND_FILTERS_DATA
    try {
      context.dispatch("updateWaiting", {
        value: true,
        waitingType: "prospectingData"
      });
      context.dispatch("updateTimestampForFilterData", "send_data_for_filter");
      await companyApplicationService
        .prospectingSearch(
          context.state.appliedFilters,
          context.state.latestTimeStampForFilterData["send_data_for_filter"]
        )
        .then(data => {
          context.commit("setIsloadsearchappliedfilterFlag", false);
          if (Object.keys(context.state.appliedFilters.filters).length == 0) {
            // context.commit("clearProspectingData");
            if (
              state.latestTimeStampForFilterData["send_data_for_filter"] ==
              data.data["timeStamp"]
            ) {
              context.commit("setData", data);
            }
          } else {
            if (
              state.latestTimeStampForFilterData["send_data_for_filter"] ==
              data.data["timeStamp"]
            ) {
              context.commit("setData", data);
            }
          }
        });
    } catch (error) {
      console.log("🚀 ~ file: prospecting.js ~ line 188 ~ error", error);
    } finally {
      context.dispatch("updateWaiting", {
        value: false,
        waitingType: "prospectingData"
      });
    }
  },
  updateTimestampForFilterData(context, field) {
    context.commit("setTimestampForFilterData", field);
  },
  clearProspectingData(context) {
    // context.commit("setProspectingStateEmpty");
    context.dispatch("updateEntireAppliedFilter", []);
  },
  updateSelectedProspect(context, prospect) {
    context.commit("setSelectedProspects", prospect);
    context.commit("setSelectedContactIds");
    context.commit("setSelectedProspectIds");
  },
  updateSelectedPosting(context, prospect) {
    context.commit("setSelectedPostingVisibility", prospect);
    context.commit("setSelectedContactIds");
    context.commit("setSelectedProspectIds");
  },
  updateEntireAppliedFilter(context, newFilterData) {
    console.log(
      "hey update filter",
      context.rootState.auth.whoami.active_organization.id
    );
    context.commit("setEntireAppliedFilter", newFilterData);
    context.commit("setIsloadsearchappliedfilterFlag", true);

    context.state.appliedFilters.filters.organization_id =
      context.rootState.auth.whoami.active_organization.id;
    if (!!context.rootState.auth.whoami.sale_person_details) {
      context.state.appliedFilters.filters.sales_person_details_id =
        context.rootState.auth.whoami.sale_person_details.id;
    }

    context.dispatch("sendFilterData");

    // context.dispatch(CHECK_APPLIED_FILTER_DATA);
  }
};

export const mutations = {
  setProspectingStateEmpty(state) {
    state.prospectingData.models = [];
    state.prospectingData._meta = {
      totalCount: 1,
      pageCount: 1,
      currentPage: 1,
      perPage: 50
    };

    // let initial = initialStateForStore();
    // let keys = Object.keys(initial);
    // state = keys.map(key => {
    //   state[key] = initial[key];
    //   return state;
    // });
  },
  setSelectedProspects(state, selectedProspects) {
    state.selectedProspects = selectedProspects;
    if (!selectedProspects.length) {
      state.selectedContactIds = [];
      state.selectedProspectIds = [];
    }
  },
  setSelectedContactIds(state) {
    try {
      if (state.selectedProspects.length) {
        state.selectedContactIds = [];
        state.selectedContactIds = state.selectedProspects.map(
          prospect => prospect.contact_id
        );
        state.selectedContactIds.sort();
      }
    } catch (error) {
      console.log("🚀 ~ file: prospecting.js ~ line 578 ~ error", error);
    }
  },
  setSelectedProspectIds(state) {
    try {
      if (state.selectedProspects.length) {
        state.selectedProspectIds = state.selectedProspects.map(
          prospect => prospect.id
        );
        state.selectedProspectIds.sort();
      }
    } catch (error) {
      // console.log("🚀 ~ file: prospecting.js ~ line 578 ~ error", error);
    }
  },
  setProspectsWithContactId(state, dataWithContactId) {
    dataWithContactId.map(data => {
      state.prospectingData.models.map(selected => {
        if (selected.salesperson_id == data.salesperson_id) {
          selected.contact_id = data.contact_id;
          selected.id = data.salesperson_id;
        }
      });
    });
  },
  // setProspectsAllListWithContactId(state, dataWithContactId) {
  //   dataWithContactId.map(data => {
  //     state.prospectingData.models.map(selected => {
  //       if (selected.salesperson_id == data.salesperson_id) {
  //         if (!!data.all_lists) {
  //           selected.all_lists = data.all_lists;
  //         } else if (data.hasOwnProperty("contact_data")) {
  //           selected.all_lists = data.contact_data.all_lists;
  //         } else {
  //           selected.all_lists = data.contact.all_lists;
  //         }
  //       }
  //     });
  //   });
  // },
  setWaiting(state, waitingData) {
    // [SET_WAITING]
    let { value, waitingType } = waitingData;

    if (waitingType == "prospectingData") {
      state.isWaitingForProspectingData = value;
    } else if (waitingType == "filterData") {
      state.isWaitingForFilterData = value;
    }
  },
  setTimestampForSearchFilter(state, field) {
    // [SET_TIMESTAMP_FOR_FILTER_SEARCH]
    state.latestTimeStampForFilterSearch[field] = moment().valueOf();
  },
  setFilterData(state, newSearchFilterData) {
    // debugger;
    let { data, field } = newSearchFilterData;

    let keyName = "";
    if (field.indexOf("_") != -1) {
      let replaceString = field.substring(field.indexOf("_")).substring(0, 2);
      let replaceWith = field
        .substring(field.indexOf("_") + 1)
        .substring(0, 1)
        .toUpperCase();
      keyName = helpers.methods.replaceWithGivenString(
        field,
        replaceString,
        replaceWith
      );
    } else {
      keyName = field;
    }

    state.postingFiltersData.map(el => {
      if (Object.keys(el)[0] == keyName) {
        el[keyName] = data;
      }
    });
  },
  setAppliedFilter(state, newFilterData) {
    // SET_APPLIED_FILTER
    let { data, filterName } = newFilterData;
    // console.log(
    //   "🔥 -> file: postingProspecting.js:472 -> setAppliedFilter -> data",
    //   data,
    //   state.appliedFilters.filters[filterName]
    // );
    if (filterName == "postingVisibility") {
      state.appliedFilters.filters[filterName][0] = data;
    } else if (filterName != "fullSearch") {
      state.appliedFilters.filters[filterName] = [...data];
    } else if (filterName == "fullSearch") {
      state.appliedFilters.fullSearch = data;
    }
    const keysToKeep = Object.keys(state.appliedFilters.filters).filter(key => {
      return state.appliedFilters.filters[key].length > 0;
    });
    const newObj = {};

    keysToKeep.forEach(key => {
      newObj[key] = state.appliedFilters.filters[key];
    });
    state.appliedFilters.filters = newObj;
    state.appliedFilters.pages = { page: 1, pageSize: 20 };
  },
  setSearchFilterData(state, newSearchFilterData) {
    let { data, field } = newSearchFilterData;
    let replaceString = field.substring(field.indexOf("_")).substring(0, 2);
    let replaceWith = field
      .substring(field.indexOf("_") + 1)
      .substring(0, 1)
      .toUpperCase();
    let keyName = helpers.methods.replaceWithGivenString(
      field,
      replaceString,
      replaceWith
    );
    state.postingFiltersData.map(el => {
      if (Object.keys(el)[0] == keyName) {
        el[keyName] = data;
      }
    });
  },
  setTimestampForFilterData(state, field) {
    // SET_TIMESTAMP_FOR_FILTER_DATA
    state.latestTimeStampForFilterData[field] = moment().valueOf();
  },
  setData(state, prospectsData) {
    state.prospectingData.models = prospectsData.data.models;
    state.prospectingData._meta = prospectsData.data._meta;

    state.availableContactIds = [];
    state.prospectingData.models.map(contact => {
      state.availableContactIds.push(contact.contact_id);
    });
    state.availableContactIds.sort();
  },
  setIsloadsearchappliedfilterFlag(state, data) {
    // SET_ISLOADSEARCHAPPLIEDFILTER_FLAG
    state.isLoadSearchAppliedFilter = data;
  },
  setSalesRoles(state, data) {
    state.salesRole = data;
  },
  setCurrentPage(state, data) {
    state.appliedFilters.pages.page = parseInt(data);
  },
  setPageLimit(state, data) {
    state.appliedFilters.pages.pageSize = parseInt(data);
  },
  setEntireAppliedFilter(state, newFilterData) {
    // SET_ENTIRE_APPLIED_FILTER
    try {
      state.appliedFilters.filters = JSON.parse(newFilterData);
    } catch (error) {
      state.appliedFilters.filters = { ...newFilterData };
    }
    console.log(
      "state.appliedFilters.filters-->",
      state.appliedFilters.filters
    );
    const keysToKeep = Object.keys(state.appliedFilters.filters).filter(key => {
      return state.appliedFilters.filters[key].length > 0;
    });
    console.log("keysToKeep----->", keysToKeep);
    const newObj = {};

    keysToKeep.forEach(key => {
      console.log("key----->549", key);
      newObj[key] = state.appliedFilters.filters[key];
    });
    console.log("newObj---->551", newObj);
    state.appliedFilters.filters = newObj;
    // state.showSaveFilterButton = true;
  },
  setSelectedPostingVisibility(state, posting) {
    state.prospectingData.models.map(selected => {
      if (selected.id == posting.id) {
        selected.is_visible = posting.value;
      }
    });
  }
};

export const getters = {
  prospectingData(state) {
    return state.prospectingData;
  },
  isWaitingForProspectingData(state) {
    return state.isWaitingForProspectingData;
  },
  postingFiltersData(state) {
    return state.postingFiltersData;
  },
  showSaveFilterButton() {
    return state.showSaveFilterButton;
  },
  appliedFilters(state) {
    return state.appliedFilters;
  },
  savedSearchList(state) {
    return state.savedSearchList;
  },
  recentActivitiesList(state) {
    return state.recentActivitiesList;
  },
  allActivitiesList(state) {
    return state.allActivitiesList;
  },
  isWaitingForFilterData(state) {
    return state.isWaitingForFilterData;
  },
  SalesRoles(state) {
    return state.salesRole;
  },
  selectedProspects(state) {
    return state.selectedProspects;
  },
  selectedContactIds(state) {
    return state.selectedContactIds;
  },
  selectedProspectIds(state) {
    return state.selectedProspectIds;
  },
  availableContactIds(state) {
    return state.availableContactIds;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
