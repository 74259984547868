import store from "../store";
import router from "../router";
import Swal from "sweetalert2";
export default [
  {
    // path: "/organizations/:id/pages/shortcode/:shortcode",
    path: "/:organization_slug/hub/:hub_slug",
    name: "Hub12",
    // meta: {storeViewActivity: true},
    component: () => import("../components/ContentDrip/Hubs/Hub.vue")
  },
  {
    // path: "/organizations/:id/pages/shortcode/:shortcode",
    path: "/admin/hub/:hub_slug",
    name: "Hub13",
    // meta: {storeViewActivity: true},
    component: () => import("../components/ContentDrip/Hubs/Hub.vue")
  },

  {
    // path: "/organizations/:id/pages/shortcode/:shortcode",
    path: "/:organization_slug/hub/:hub_slug/category",
    name: "HubCategoryPageResult",
    meta: { for: "hub" },
    props: true,
    component: () => import("../components/ContentDrip/Hubs/HubCategory.vue")
  },

  {
    // path: "/organizations/:id/pages/shortcode/:shortcode",
    path: "/admin/hub/:hub_slug/category",
    name: "HubCategoryPageResult2",
    meta: { for: "hub" },
    props: true,
    component: () => import("../components/ContentDrip/Hubs/HubCategory.vue")
  },

  {
    // path: "/organizations/:id/pages/shortcode/:shortcode",
    path: "/:organization_slug/hub/:hub_slug/:post_slug",
    name: "HubPost",
    meta: { for: "hub" },
    component: () => import("../components/ContentDrip/Hubs/HubPost.vue")
  },

  {
    // path: "/organizations/:id/pages/shortcode/:shortcode",
    path: "/admin/hub/:hub_slug/:post_slug",
    name: "HubPost2",
    meta: { for: "hub" },
    component: () => import("../components/ContentDrip/Hubs/HubPost.vue")
  },

  {
    // path: "/organizations/:id/pages/shortcode/:shortcode",
    path: "/:organization_slug/hub/:hub_slug/category/:category_slug",
    name: "HubCategoryPage",
    meta: { for: "hub" },
    component: () => import("../components/ContentDrip/Hubs/HubCategory.vue")
  },

  {
    // path: "/organizations/:id/pages/shortcode/:shortcode",
    path: "/admin/hub/:hub_slug/category/:category_slug",
    name: "HubCategoryPage2",
    meta: { for: "hub" },
    component: () => import("../components/ContentDrip/Hubs/HubCategory.vue")
  },

  {
    // path: "/organizations/:id/pages/shortcode/:shortcode",
    path: "/blog/:post_slug",
    name: "HubPost3",
    meta: { for: "hub" },
    component: () => import("../components/ContentDrip/Hubs/HubPost.vue")
  },

  {
    // path: "/organizations/:id/pages/shortcode/:shortcode",
    path: "/:organization_slug/hub/:hub_slug/:post_slug",
    name: "HubPost1",
    meta: { for: "hub" },
    component: () => import("../components/ContentDrip/Hubs/HubPost.vue")
  },

  {
    path: "/:organization_slug/uhub/:user_id/:hub_slug",
    name: "Hub19",
    meta: { for: "hub", storeViewActivity: true },
    component: () => import("../components/ContentDrip/Hubs/Hub.vue")
  },

  //Added for share link of stream without hub
  {
    path: "/:organization_slug/uhub/:user_id/stream/:post_slug",
    name: "contentWithNoHub",
    meta: { for: "hub", storeViewActivity: true },
    component: () => import("../components/ContentDrip/Hubs/HubPost.vue")
  },

  {
    path: "/:organization_slug/uhub/:user_id/:hub_slug/:post_slug",
    name: "HubUserPost",
    meta: { for: "hub", storeViewActivity: true },
    component: () => import("../components/ContentDrip/Hubs/HubPost.vue")
  },

  //for custom domains
  {
    path: "/:admin/uhub/:user_id/:hub_slug",
    name: "Hub20",
    meta: { for: "hub", storeViewActivity: true },
    component: () => import("../components/ContentDrip/Hubs/Hub.vue")
  },

  //Added for share link of stream without hub for custom domain
  {
    path: "/:admin/uhub/:user_id/stream/:post_slug",
    name: "contentWithNoHub",
    meta: { for: "hub", storeViewActivity: true },
    component: () => import("../components/ContentDrip/Hubs/HubPost.vue")
  },

  {
    path: "/:admin/uhub/:user_id/:hub_slug/:post_slug",
    name: "HubUserPost1",
    meta: { for: "hub", storeViewActivity: true },
    component: () => import("../components/ContentDrip/Hubs/HubPost.vue")
  },

  {
    // path: "/organizations/:id/pages/shortcode/:shortcode",
    path: "/:organization_slug/post/:post_slug",
    name: "StreamPageWithoutHub",
    meta: { for: "stream_without_hub" },
    component: () => import("../components/ContentDrip/Hubs/HubPost.vue")
  },

  {
    // path: "/organizations/:id/pages/shortcode/:shortcode",
    path: "/admin/post/:post_slug",
    name: "StreamPageWithoutHub1",
    meta: { for: "stream_without_hub" },
    component: () => import("../components/ContentDrip/Hubs/HubPost.vue")
  },

  {
    // path: "/organizations/:id/pages/shortcode/:shortcode",
    path: "/:organization_slug/share/:hub_slug",
    name: "Share",
    meta: { for: "hub" },
    component: () => import("../components/ContentDrip/Share/Share.vue")
  },

  {
    // path: "/organizations/:id/pages/shortcode/:shortcode",
    path: "/:organization_slug/admin/sharelinks",
    name: "ShareLinks",
    meta: { for: "hub" },
    component: () => import("../components/ContentDrip/Share/Share.vue"),
    beforeEnter: (to, from, next) => {
      if (
        store.state.auth.whoami.subscription_allowed_features.content
          .link_tracking == true
      ) {
        next();
      } else {
        Swal.fire({
          title: "Upgrade Plan",
          text: "Upgrade your plan to use these features",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Upgrade"
        }).then(result => {
          if (result.isConfirmed) {
            router.push({
              name: "BillingWrapper",
              params: { selectedTab: "Plan" }
            });
          }
        });
      }
    }
  },

  {
    // path: "/organizations/:id/pages/shortcode/:shortcode",
    path: "/:organization_slug/admin/templates",
    name: "TemplatesHome",
    meta: { for: "hub" },
    component: () =>
      import("../components/ContentDrip/Hubs/Templates/TemplatesHome.vue")
  },

  {
    // path: "/organizations/:id/pages/shortcode/:shortcode",
    path: "/:organization_slug/admin/hub/:hub_slug/author/profile",
    name: "HubPostAuthorProfile",
    meta: { for: "hub" },
    component: () =>
      import("../components/ContentDrip/Hubs/HubPostAuthorProfile.vue")
  }
];
