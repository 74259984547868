import { uuid } from "vue-uuid";

function getUuid() {
  return uuid.v1();
}

export default {
  title: "",

  subject: "",
  body: "",
  is_unsubscribe_link: false,
  is_html: true,
  contents: [],
  date: null,
  contentLinks: "",
  send_as_reply: 0,
  uuid: getUuid(),

  engagement_type_id: 1,
  subtype: "",
  pause_days: 0,
  rules: {
    // email: {},
    // facebook: {},
    // linkedin: {},
    // instagram: {},
    // twitter: {},
    // calling: {},
    // other: {}
  }
};
