// import config from "../../config.js";
// import * as moment from "moment";

export default {
  namespaced: true,
  state: {
    hub: {},
    hubs: []
  },

  getters: {},
  mutations: {
    setHub(state, data) {
      Vue.set(state, "hub", data);
    },
    setHubs(state, data) {
      state.hubs = data;
    }
  },

  actions: {
    setHub({ state, commit, rootState }, data) {
      commit("setHub", data);
    },
    setHubs({ state, commit, rootState }, data) {
      commit("setHubs", data);
    }
  }
};
