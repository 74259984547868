import axios from "axios";
import config from "../../config.js";
import * as moment from "moment";
import router from "../../router";
import toastr from "toastr";

export default {
  namespaced: true,
  state: {
    curatedArticles: [],
    curatedArticle: {
      url: "",
      headline: "",
      description: "",
      date: "",
      time: "",
      author: "",
      image: null,
      shortentedLink: null,
      postDestinations: null,
      adId: null,
      imageURL: null,
      posts: "",
      tweets: "",
      matchedUrls: [],
      selectedCategories: [],
      selectedTags: [],
      selectedContentTypes: [],
      suggestedMessages: [],
      linkDomainData: {},
      status_id: 3
    },
    imagesToShow: [],
    ideas: [],
    score: [],
    authors: [],
    selectedAuthors: [],
    analytics: [],
    notifications: [],
    isValiOriginalUrl: false,
    imagesFromURL: null,
    selectedFullScreenAd: null,
    imageFile: null,
    isImageSelected: false,
    enlargedImageURL: null,
    selectedImageIndex: null,
    previousSelectedIndex: null,
    previewUrl: null,
    selectedAdId: null,
    searchResult: [],
    selectedContentChannels: [],
    followedStreamChannels: [],
    followedStreamChannelsCheckList: [],
    categories: [],
    selectedCategories: [],
    suggestedMessages: [],
    content_channels: [
      {
        title: "Best Images",
        description: "Social | Facebook Page",
        id: 1,
        imageUrl: "../images/upload_placeholder.png"
      },
      {
        title: "allmighty100",
        description: "Social | Twitter",
        id: 2,
        imageUrl: "../images/upload_placeholder.png"
      },
      {
        title: "Cars",
        description: "Social | Pinterest Page",
        id: 3,
        imageUrl: "../images/upload_placeholder.png"
      },
      {
        title: "Entrepreneurs",
        description: "Social | Linkedin Page",
        id: 4,
        imageUrl: "../images/upload_placeholder.png"
      },
      {
        title: "Trees",
        description: "Social | Linkedin Page",
        id: 5,
        imageUrl: "../images/upload_placeholder.png"
      },
      {
        title: "Tumblr",
        description: "Social | Linkedin Page",
        id: 6,
        imageUrl: "../images/upload_placeholder.png"
      },
      {
        title: "Social",
        description: "Social | Linkedin Page",
        id: 7,
        imageUrl: "../images/upload_placeholder.png"
      }
    ],
    streamChannels: [
      {
        title: "Best Images",
        description: "Social | Facebook Page",
        id: 1,
        imageUrl: "/images/upload_placeholder.png",
        expiration: "March 3,2019"
      },
      {
        title: "allmighty100",
        description: "Social | Twitter",
        id: 2,
        imageUrl: "/images/upload_placeholder.png",
        expiration: "March 3,2019"
      },
      {
        title: "Cars",
        description: "Social | Pinterest Page",
        id: 3,
        imageUrl: "/images/upload_placeholder.png",
        expiration: "March 3,2019"
      },
      {
        title: "Entrepreneurs",
        description: "Social | Linkedin Page",
        id: 4,
        imageUrl: "/images/upload_placeholder.png",
        expiration: "March 3,2019"
      },
      {
        title: "Trees",
        description: "Social | Linkedin Page",
        id: 5,
        imageUrl: "/images/upload_placeholder.png",
        expiration: "March 3,2019"
      },
      {
        title: "Tumblr",
        description: "Social | Linkedin Page",
        id: 6,
        imageUrl: "/images/upload_placeholder.png",
        expiration: "March 3,2019"
      },
      {
        title: "Social",
        description: "Social | Linkedin Page",
        id: 7,
        imageUrl: "/images/upload_placeholder.png",
        expiration: "March 3,2019"
      }
    ],
    newShortenUrls: [],
    matchedUrls: [],
    selectedCheckListId: null,
    selectedList: [],
    selectedFilters: [],
    selectedType: [],
    selectedTags: [],
    organizationTagImage: null,
    contentType: {
      id: "",
      name: "",
      description: "",
      image_id: ""
    },
    likeCount: "",
    filter: {
      type: "",
      message: "Posted",
      title: ""
    }
    // contentTypes: [],
    // selectedContentTypes: []
  },

  getters: {},
  mutations: {
    setCuratedArticle(state, data) {
      Vue.set(state, "curatedArticle", data);
    },
    getIdeas(state, data) {},
    getScore(state, data) {},
    getAnalytics(state, data) {},
    checkIsOriginalUrlValid(state, value) {
      state.isValiOriginalUrl = value;
    },
    setimagesFromURL(state, value) {
      console.log(value);
      state.imagesFromURL = value;
      console.log(state.imagesFromURL);
    },
    setSelectedFullScreenAd(state, value) {
      state.selectedFullScreenAd = value;
    },
    setCuratedImage(state, value) {
      state.curatedArticle.image = value;
    },
    setImageObject(state, value) {
      state.imageFile = value;
    },
    setAuthors(state, data) {
      Vue.set(state, "authors", data);
    },
    insertIntoSelectedAuthors(state, data) {
      state.selectedAuthors = [...data];
    },
    setIsImageSelected(state, data) {
      state.isImageSelected = data;
    },
    setEnlargedImageURL(state, data) {
      state.enlargedImageURL = data;
    },
    setSelectedImageIndex(state, data) {
      state.selectedImageIndex = data;
    },
    setPreviousSelectedIndex(state, data) {
      state.previousSelectedIndex = data;
    },
    setPreviewUrl(state, data) {
      state.previewUrl = data;
    },
    setSelectedAdId(state, data) {
      state.selectedAdId = data;
    },
    setSearchResult(state, data) {
      state.searchResult = data;
    },
    setSelectedContentChannels(state, data) {
      state.selectedContentChannels = data;
    },
    replaceUrls(state, { newText, type }) {
      if (type == "post") state.curatedArticle.posts = newText;
      else state.curatedArticle.tweets = newText;
    },
    assignSuggestedMessages(state, data) {
      state.suggestedMessages = data;
    },
    resetData(state, curatedObj) {
      state.curatedArticle = { ...curatedObj };
      state.imageFile = null;
      state.selectedImageIndex = null;
      state.selectedAdId = null;
      state.imageFile = null;
      state.searchResult = [];
      state.selectedContentChannels = [];
      state.imagesToShow = [];
    },
    setSelectedCategories(state, data) {
      state.curatedArticle.selectedCategories = data;
    },
    setSelectedTags(state, data) {
      state.curatedArticle.selectedTags = data;
    },
    setSelectedContentTypes(state, data) {
      state.curatedArticle.selectedContentTypes = data;
    },
    setOrganizationTagImage(state, data) {
      console.log("Inside mutation setOrganizationTagImage", data);
      state.organizationTagImage = data;
    },
    assignFilter(state, data) {
      state.filter = { ...data };
    },
    setFollowedStreamChannels(state, data) {
      state.followedStreamChannels = [...data];
    },
    setFollowedChannelsCheckList(state, data) {
      state.followedStreamChannelsCheckList = [...data];
    },
    setFollowedChannels(state, data) {
      state.followedStreamChannels = [...data];
    },
    setSelectedFilters(state, data) {
      state.selectedFilters = [...data];
    },
    setSelectedTypes(state, data) {
      state.selectedType = [...data];
    },
    setSuggestedMessages(state, data) {
      console.log("inside setSuggestedMessages===========");
      state.curatedArticle.suggestedMessages.push(data);
    },
    setLinkDomainData(state, data) {
      console.log("Inside setLinkDomainData", data);
      state.curatedArticle.linkDomainData = data;
    }
  },

  actions: {
    setCuratedArticle({ state, commit, dispatch, rootState }, data) {
      commit("setCuratedArticle", data);
    },
    getIdeas({ state, commit, rootState }) {},
    getAnalytics({ state, commit, rootState }) {},
    getMetaTags({ state, commit, rootState }, url) {
      console.log("Inside getMetaTags :: ", url);
      let vm = this;
      axios
        .get(
          config.DOMAIN +
            config.API_VERSION +
            "organizations/" +
            rootState.auth.whoami.active_organization.id +
            "/categories/" +
            rootState.auth.whoami.active_organization_category_id +
            "/link/getmetatags?url=" +
            url
        )
        .then(response => {
          console.log(response.data);
          let imageData = null;
          //Checking whether image array is empty or not
          if (response.data["images"].length > 0) {
            imageData = response.data["images"][0]["url"];
          }
          let metaTags = {
            url: url,
            headline: response.data["title"],
            description: response.data["description"],
            image: imageData,
            imageURL: imageData
          };
          console.log("metaTags=========", metaTags);
          commit("setCuratedArticle", metaTags);
        })
        .catch(e => {
          console.log("Error: ", e);
        }); //axios
    },
    getAllAuthors({ state, commit, rootState }) {
      console.log("getAllInvites() Called");
      axios
        .get(
          config.DOMAIN +
            config.API_VERSION +
            "organizations/" +
            rootState.auth.whoami.active_organization.id +
            "/categories/1/invite"
        )
        .then(response => {
          console.log("Invites GET API:: ", response.data);
          commit("setAuthors", response.data.invites);
        })
        .catch(e => {
          console.log("Error: ", e);
        }); //axios
    },
    checkIsOriginalUrlValid({ state, commit, rootState }, value) {
      console.log("checkIsOriginalUrlValid----------------", value);
      commit("checkIsOriginalUrlValid", value);
    },
    getImagesFromURL({ state, commit, rootState }, url) {
      console.log("in getImages");
      axios
        .get(
          config.DOMAIN +
            config.API_VERSION +
            "organizations/" +
            rootState.auth.whoami.active_organization.id +
            "/categories/" +
            rootState.auth.whoami.active_organization_category_id +
            "/link/getimagesfromurl?url=" +
            url
        )
        .then(response => {
          console.log(response.data);
          console.log("Image URLs=========", response.data);
          commit("setimagesFromURL", response.data);
        })
        .catch(e => {
          console.log("Error: ", e);
        }); //axios
    },
    setSelectedFullScreenAd({ state, commit, rootState }, data) {
      console.log("Inside action setSelectedFullScreenAd-----------");
      commit("setSelectedFullScreenAd", data);
    },
    setCuratedImage({ state, commit, rootState }, data) {
      commit("setCuratedImage", data);
    },
    uploadImageFile({ state, commit, rootState }, data) {},
    insertIntoSelectedAuthors({ state, commit, rootState }, id) {
      console.log(id);
      let selectedAuthors = state.authors.reduce(function(
        selectedAuthors,
        val
      ) {
        if (val.id == id) {
          selectedAuthors.push(val);
        }
        return selectedAuthors;
      },
      []);
      console.log(selectedAuthors);
      commit("insertIntoSelectedAuthors", selectedAuthors);
    },
    setSelectedImageIndex({ state, commit, rootState }, data) {
      commit("setSelectedImageIndex", data);
    },
    setPreviousSelectedIndex({ state, commit, rootState }, data) {
      commit("setPreviousSelectedIndex", data);
    },
    setImageObject({ state, commit, rootState }, data) {
      commit("setImageObject", data);
    },
    setPreviewUrl({ state, commit, rootState }, url) {
      console.log("previewUrl::", url);
      commit("setPreviewUrl", url);
    },
    setSelectedAdId({ state, commit, rootState }, id) {
      console.log("previewUrl::", id);
      commit("setSelectedAdId", id);
    },
    getResizedImage({ state, commit, rootState }, imageData) {
      console.log("in get resized image");
      let postData = {
        image_url: imageData
      };
      axios
        .post(
          config.DOMAIN + config.API_VERSION + "images/resizeimage",
          postData
        )
        .then(response => {
          let resizedImageUrl =
            config.DOMAIN + config.API_VERSION + response.data;
          commit("setCuratedImage", resizedImageUrl);
        })
        .catch(e => {
          console.log("Error: ", e);
        }); //axios
    },
    setSearchResult({ state, commit, rootState }, searchResult) {
      commit("setSearchResult", searchResult);
    },
    setSelectedContentChannels({ state, commit, rootState }, data) {
      commit("setSelectedContentChannels", data);
    },
    shortenUrls({ state, commit, rootState }, { matchedUrlsArray, type }) {
      axios
        .post(
          config.DOMAIN +
            config.API_VERSION +
            "organizations/" +
            rootState.auth.whoami.active_organization.id +
            "/campaigns/" +
            rootState.auth.whoami.active_organization_campaign_id +
            "/link/shortenurls",
          matchedUrlsArray
        )
        .then(response => {
          console.log(response.data);
          var urlText = "";

          if (type == "post") {
            urlText = state.curatedArticle.posts;
          } else {
            urlText = state.curatedArticle.tweets;
          }

          if (response.data.length != 0) {
            for (var i = 0; i < response.data.length; i++) {
              if (
                response.data[i]["url"].includes(
                  state.curatedArticle.matchedUrls[i]
                )
              ) {
                let x = urlText.replace(
                  state.curatedArticle.matchedUrls[i],
                  response.data[i]["new_url"]
                );
                urlText = x;
              }
            }
          }

          commit("replaceUrls", { newText: urlText, type: type });
        })
        .catch(e => {
          console.log("Error: ", e);
        });
    },

    assignSuggestedMessages({ state, commit, rootState }, data) {
      commit("assignSuggestedMessages", data);
    },
    resetData({ state, commit, rootState }, curatedObj) {
      commit("resetData", curatedObj);
    },
    assignFilter({ state, commit, rootState, dispatch }, filter) {
      console.log(filter);
      commit("assignFilter", filter);
    },

    setFollowedChannels({ state, commit, rootState, dispatch }, data) {
      commit("setFollowedChannels", data);
    },
    setFollowedChannelsCheckList({ state, commit, rootState }, data) {
      commit("setFollowedChannelsCheckList", data);
    },
    setSelectedFilters({ state, commit, rootState }, data) {
      console.log(data);
      commit("setSelectedFilters", data);
    },
    setSelectedTypes({ state, commit, rootState }, data) {
      commit("setSelectedTypes", data);
    },
    assignUpdatedChannels(
      { state, commit, rootState, dispatch },
      updatedChannels
    ) {
      commit("assignUpdatedChannels", updatedChannels);
    },
    // createTags({ state, commit, rootState, dispatch }, data) {
    //   let type = data.type;
    //   let val = data.data[0];
    //   if (val.name.indexOf(",") != -1) {
    //     var nameArray = val.name.split(",");
    //   } else {
    //     var nameArray = [];
    //     nameArray.push(val.name);
    //   }
    //   for (var i = 0; i < nameArray.length; i++) {
    //     val["name"] = nameArray[i];
    //     axios
    //       .post(
    //         config.DOMAIN +
    //           config.API_VERSION +
    //           "organizations/" +
    //           rootState.auth.whoami.active_organization.id +
    //           "/tags",
    //         val
    //       )
    //       .then(response => {
    //         console.log("tag saved!!! ", response.data);
    //         toastr.error(val.name + " Tag created successfully.", "", {
    //           timeOut: 5000
    //         });
    //       })
    //       .catch(e => {
    //         console.log("Create Category Error: ", e);
    //       });
    //     if (i == nameArray.length - 1) {
    //       router.push({ name: "PostTags" });
    //     }
    //   }
    // }
    setSelectedCategories({ state, commit, rootState }, data) {
      console.log("Inside Praj setSelectedCategories");
      commit("setSelectedCategories", data);
    },
    setSelectedTags({ state, commit, rootState }, data) {
      commit("setSelectedTags", data);
    },
    setSelectedContentTypes({ state, commit, rootState }, data) {
      commit("setSelectedContentTypes", data);
    }
  }
};
