import io from "socket.io-client";
import config from "../config.js";

function socketInit(router, to, whoami, domainOrgSlug) {
  // socket data
  console.log("1358 to => ,", to, to.params);
  let socketData = {
    url: config.DOMAIN + to.path,
    title: document.title,
    organization_slug: !!domainOrgSlug
      ? domainOrgSlug
      : whoami.domain_org_shortcode,
    //...to.params,
    meta: to.meta,
    aaa: "ok"
  };

  Object.assign(socketData, to.params);

  //doNotTrackActivityForLoginUserIfMemberOfOrganization
  let isUserLoggedIn = false;
  try {
    isUserLoggedIn = whoami.active_organization_user_id > 0;
  } catch (e) {}

  console.log("isUserLoggedIn", isUserLoggedIn);
  console.log("190 socketData", socketData);
  if (to.path == "/login") {
    //router.app.$socket.emit("disconnect");
    router.app.$socket.close();
    router.app.$socket = io(config.DOMAIN, {
      path: "/api/v1/flow/socket.io/"
    });
    router.app.$socket.on("connect", () => {
      if (!isUserLoggedIn) router.app.$socket.emit("url", socketData);
    });
  } else {
    console.log("190 socketData 2:", router.app.$socket);
    try {
      if (router.app.$socket.connected) {
        console.log("inside ifffff", socketData);
        if (!isUserLoggedIn) router.app.$socket.emit("url", socketData);
      } else {
        console.log("inside elseeeeeeeeeeeeee", socketData);
        router.app.$socket.on("connect", data => {
          if (!isUserLoggedIn) router.app.$socket.emit("url", socketData);
        });
      }
    } catch (error) {
      console.log(error);
    }
  }
}

export default socketInit;
