import axios from "axios";
import config from "../config.js";
import store from "../store";
import Swal from "sweetalert2";
import { uuid } from "vue-uuid";
function getUuid() {
  return uuid.v1();
}

export default {
  // Format of data object
  // data :{
  //     report_type:
  //     campaign_id:
  //   organization_id:
  // }
  getCampaignAnalytics: function(data) {
    return new Promise(function(resolve, reject) {
      console.log("19: ", data);
      if (data && data.report_type && data.campaign_id) {
        var url = `${config.DOMAIN}${config.API_VERSION}organizations/${data.organization_id}/analytics/${data.report_type}?campaign_id=${data.campaign_id}`;
      } else {
        console.error("Invalid data object passed to analytics service");
        reject("Invalid data object passed to analytics service");
      }
      axios
        .get(url)
        .then(response => {
          console.log("response -- ", response.data);
          resolve(response);
        })
        .catch(e => {
          console.log("Error: ", e);
          reject(e.response.data.message);
        });
    });
  },

  contactVisitedHubAndStream: function(data) {
    return new Promise((resolve, reject) => {
      // http://localhost/api/v1/organizations/3/emailcampaigncontacts/contact_visited_stream?stream_id=5&contact_id=1
      var url =
        config.DOMAIN +
        config.API_VERSION +
        "organizations/" +
        data.organization_id +
        "/emailcampaigncontacts/contact_visited_stream?";

      axios
        .post(url, data)
        .then(response => {
          console.log("response -- ", response.data);
          resolve(response);
        })
        .catch(e => {
          console.log("Error: ", e);
          reject(e.response.data.message);
        });
    });
  },

  activity: function(data) {
    return new Promise((resolve, reject) => {
      // http://localhost/api/v1/organizations/3/emailcampaigncontacts/contact_visited_stream?stream_id=5&contact_id=1
      var url =
        config.DOMAIN +
        config.API_VERSION +
        "organizations/" +
        data.organization_id +
        "/emailcampaigncontacts/activity?";

      axios
        .post(url, data)
        .then(response => {
          console.log("response -- ", response.data);
          resolve(response);
        })
        .catch(e => {
          console.log("Error: ", e);
          reject(e.response.data.message);
        });
    });
  }
};
