import salespersonService from "../../services/salesPersonApplication";
import userActivitiesService from "../../services/userActivitiesServices";
import store from "../index";
import { helpers } from "../../components/Workspace/Prospecting/helpers";
import moment from "moment";

// freelancerProspecting
/**
 * !NOTE: Please add the initial state for the store.
 * @returns Initial Object for the store
 */

function initialStateForStore() {
  return {
    prospectingData: {
      models: [],
      _meta: {
        totalCount: 1,
        pageCount: 1,
        currentPage: 1,
        perPage: 50
      }
    },
    isWaitingForProspectingData: false,
    isWaitingForFilterData: false,
    isLoadSearchAppliedFilter: false,
    showSaveFilterButton: false,
    freelancerFiltersData: [
      { contactName: [] },
      {
        experience: [
          { name: "Less than 2 years" },
          { name: "2 to 3 years" },
          { name: "3 to 8 years" },
          { name: "More than 8 years" }
        ]
      },
      { location: [] },
      {
        jobCommitment: [
          { name: "Full-time (40 hours/week)" },
          { name: "Part-time (20 hours/week)" },
          { name: "Hourly (Up to 10 hours/week)" }
        ]
      },
      {
        englishProficiency: [
          { name: "Fluent" },
          { name: "Native Speaker" },
          { name: "Advanced" },
          { name: "Intermediate" },
          { name: "Basic" }
        ]
      },
      {
        hourlyRate: [
          { name: "Less than $10/hr" },
          { name: "$11 - $20/hr" },
          { name: "$21 - $30/hr" },
          { name: "$31 - $50/hr" },
          { name: "$51 - $70/hr" },
          { name: "$71 - $90/hr" },
          { name: "$91+/hr" },
          { name: "Not sure on budget yet" }
        ]
      },
      {
        postingVisibility: [
          { name: "All" },
          { name: "Visible" },
          { name: "Hidden" }
        ]
      },
      { citizenship: [] },
      { salesRole: [] }
    ],
    latestTimeStampForFilterSearch: {
      contact_name: null,
      experience: null,
      location: null,
      job_commitment: null,
      english_proficiency: null,
      hourly_rate: null,
      citizenship: null,
      sales_role: null
    },
    latestTimeStampForFilterData: {
      send_data_for_filter: null
    },
    appliedFilters: {
      filters: {
        postingVisibility: [{ name: "Visible" }]
      },
      display: "contacts",
      fullSearch: "",
      pages: { page: 1, pageSize: 20 }
    },
    savedSearchList: [],
    recentActivitiesList: [],
    allActivitiesList: [],
    salespersonRoles: [],
    selectedProspects: [],
    selectedContactIds: [],
    selectedProspectIds: [],
    availableContactIds: [],
    allSelectedFilters: {}
  };
}

export const state = initialStateForStore();

export const actions = {
  updateCurrentPage(context, currentPage) {
    context.commit("setCurrentPage", currentPage);
    context.dispatch("sendFilterData");
  },
  updateLimit(context, limit) {
    context.commit("setPageLimit", limit);
    context.dispatch("sendFilterData");
  },
  async fetchData({ state, commit, rootState }) {
    const data = await salespersonService.prospectingSearch(state.filtersData);
    state.commit("setData", data);
    return data;
  },
  async setSalesRoles({ state, commit, rootState }) {
    let salespersonTypes = await salespersonService.getSalespersonTypes();
    let data = {
      data: salespersonTypes?.data,
      field: "sales_role"
    };
    commit("setFilterData", data);
    if (!!salespersonTypes.data) commit("setSalesRoles", salespersonTypes.data);
  },
  setCountryList({ state, commit, rootState }, countryList) {
    let data = {
      data: countryList,
      field: "location"
    };
    commit("setFilterData", data);
    let citizenshipData = {
      data: countryList,
      field: "citizenship"
    };
    commit("setFilterData", citizenshipData);
  },
  setProspectsWithContactId({ state, commit, rootState }, prospect) {
    commit("setProspectsWithContactId", prospect);
  },
  async setMarkAsBouncedProspects({ state, commit, rootState }) {
    const data = await salespersonService.setMarkAsBouncedProspects(
      selectedProspects
    );
    return data;
  },

  async updateSearchQuery(context, searchData) {
    // UPDATE_SEARCH_QUERY
    // NEED TO UPDATE THSI
    let { state, commit, rootState } = context;
    let { searchString, field } = searchData;
    if (searchString) {
      try {
        context.dispatch("updateWaiting", {
          value: true,
          waitingType: "filterData"
        });
        let salespersonTypes = await salespersonService.getSalespersonTypes();
        context.dispatch("updateTimestampForSearchFilter", field);
        let salespersonDetails = await salespersonService.getSalespersonDetails(
          {
            search: searchString,
            timestamp: state.latestTimeStampForFilterSearch[field],
            organization_id: rootState.auth.whoami.active_organization.id
          }
        );
        // console.log(
        //   "🔥 -> file: freelancerProspecting.js:178 -> updateSearchQuery -> data",
        //   salespersonDetails
        // );
        if (
          state.latestTimeStampForFilterSearch[field] ==
          salespersonDetails.data["timeStamp"]
        ) {
          if (searchString) {
            let data = salespersonDetails.data.models;
            data.map(el => {
              if (!el.hasOwnProperty("name")) {
                el.name = el.full_legal_name;
              }
            });
            commit("setSearchFilterData", {
              data,
              field
            });
          } else
            commit("setSearchFilterData", {
              data: { data: [] },
              field
            });
        }
      } catch (error) {
        // console.log("🚀 ~ file: prospecting.js ~ line 264 ~ error", error);
      } finally {
        context.dispatch("updateWaiting", {
          value: false,
          waitingType: "filterData"
        });
      }
    } else {
      // if searchString is empty then this is called
      // context.dispatch(FETCH_CUSTOM_FILTER_DATA, field);
      // context.dispatch("setSearchFilterData", { data: { data: [] }, field });
    }
  },
  updateWaiting(context, waitingData) {
    // UPDATE_WAITING
    let { value, waitingType } = waitingData;
    context.commit("setWaiting", { value, waitingType });
  },
  updateTimestampForSearchFilter(context, field) {
    // [UPDATE_TIMESTAMP_FOR_FILTER_SEARCH]
    context.commit("setTimestampForSearchFilter", field);
  },
  async updateAppliedFilter(context, newFilterData) {
    let { state, commit, rootState } = context;

    // UPDATE_APPLIED_FILTER
    let { data, filterName } = newFilterData;
    commit("setAppliedFilter", newFilterData);
    state.appliedFilters.filters.organization_id =
      rootState.auth.whoami.active_organization.id;
    if (
      state.appliedFilters.fullSearch.length ||
      Object.keys(state.appliedFilters.filters).length
    ) {
      context.dispatch("sendFilterData");
    } else {
      context.dispatch("clearProspectingData");
      context.dispatch("clearSelectedProspects");
    }
    // dispatch("CHECK_APPLIED_FILTER_DATA");
    // update the store with new filter applied.
    // send the updated filters data to API
  },
  clearSelectedProspects(context) {
    context.commit("setSelectedProspects", []);
  },
  async sendFilterData(context) {
    // SEND_FILTERS_DATA
    try {
      context.dispatch("updateWaiting", {
        value: true,
        waitingType: "prospectingData"
      });
      context.dispatch("updateTimestampForFilterData", "send_data_for_filter");
      await salespersonService
        .prospectingSearch(
          context.state.appliedFilters,
          context.state.latestTimeStampForFilterData["send_data_for_filter"]
        )
        .then(data => {
          context.commit("setIsloadsearchappliedfilterFlag", false);
          if (Object.keys(context.state.appliedFilters.filters).length == 0) {
            // context.commit("clearProspectingData");
            if (
              state.latestTimeStampForFilterData["send_data_for_filter"] ==
              data.data["timeStamp"]
            ) {
              context.commit("setData", data);
            }
          } else {
            if (
              state.latestTimeStampForFilterData["send_data_for_filter"] ==
              data.data["timeStamp"]
            ) {
              context.commit("setData", data);
            }
          }
        });
    } catch (error) {
      console.log("🚀 ~ file: prospecting.js ~ line 188 ~ error", error);
    } finally {
      context.dispatch("updateWaiting", {
        value: false,
        waitingType: "prospectingData"
      });
    }
  },
  updateTimestampForFilterData(context, field) {
    context.commit("setTimestampForFilterData", field);
  },
  clearProspectingData(context) {
    // context.commit("setProspectingStateEmpty");
    context.dispatch("updateEntireAppliedFilter", []);
  },
  updateSelectedProspect(context, prospect) {
    context.commit("setSelectedProspects", prospect);
    context.commit("setSelectedContactIds");
    context.commit("setSelectedProspectIds");
  },
  updateEntireAppliedFilter(context, newFilterData) {
    context.commit("setEntireAppliedFilter", newFilterData);
    context.commit("setIsloadsearchappliedfilterFlag", true);
    if (!!context.rootState.auth.whoami?.domain) {
      context.state.appliedFilters.filters.organization_id =
        context.rootState.auth.whoami.domain.organization_id;
      context.dispatch("sendFilterData");
      console.log(
        "heyyy organization id",
        context.rootState.auth.whoami.domain.organization_id
      );
    }
    // context.dispatch(CHECK_APPLIED_FILTER_DATA);
  },
  updateSelectedProspects(context, selectedProspects) {
    context.commit("setSelectedProspects", selectedProspects);
    context.commit("setSelectedContactIds");
    context.commit("setSelectedProspectIds");
  },
  setProspectsWithVerificationDate(context, contact) {
    context.commit("setProspectsWithVerificationDate", contact);
  },
  setProspectsWithEmailSubscribeStatus(context, contact) {
    context.commit("setProspectsWithVerificationDate", contact);
  },
  setDownloadAllFilters(context, filterSelected) {
    context.commit("setProspectsWithVerificationDate", filterSelected);
  },
  updateSelectedFreelancer(context, prospect) {
    context.commit("setSelectedFreelancerVisibility", prospect);
  },
  updateAddedVideoFreelancer(context, prospect) {
    context.commit("addedVideosForFreelancer", prospect);
  },
  updateIsFeaturedFreelancer(context, prospect) {
    context.commit("toggleFeaturedFreelancer", prospect);
  }
};

export const mutations = {
  setProspectingStateEmpty(state) {
    state.prospectingData.models = [];
    state.prospectingData._meta = {
      totalCount: 1,
      pageCount: 1,
      currentPage: 1,
      perPage: 50
    };

    // let initial = initialStateForStore();
    // let keys = Object.keys(initial);
    // state = keys.map(key => {
    //   state[key] = initial[key];
    //   return state;
    // });
  },
  setSelectedProspects(state, selectedProspects) {
    state.selectedProspects = selectedProspects;
    if (!selectedProspects.length) {
      state.selectedContactIds = [];
      state.selectedProspectIds = [];
    }
  },
  setSelectedContactIds(state) {
    try {
      if (state.selectedProspects.length) {
        state.selectedContactIds = [];
        state.selectedContactIds = state.selectedProspects.map(
          prospect => prospect.contact_id
        );
        state.selectedContactIds.sort();
      }
    } catch (error) {
      console.log("🚀 ~ file: prospecting.js ~ line 578 ~ error", error);
    }
  },
  setSelectedProspectIds(state) {
    try {
      if (state.selectedProspects.length) {
        state.selectedProspectIds = state.selectedProspects.map(
          prospect => prospect.id
        );
        state.selectedProspectIds.sort();
      }
    } catch (error) {
      console.log("🚀 ~ file: prospecting.js ~ line 578 ~ error", error);
    }
  },
  setProspectsWithContactId(state, dataWithContactId) {
    console.log(
      "🚀 ~ file: prospecting.js ~ line 848 ~ dataWithContactId",
      dataWithContactId
    );
    dataWithContactId.map(data => {
      state.prospectingData.models.map(selected => {
        if (selected.salesperson_id == data.salesperson_id) {
          selected.contact_id = data.contact_id;
          selected.id = data.salesperson_id;
        }
      });
    });
  },
  setProspectsWithVerificationDate(state, contact) {
    state.prospectingData.models.map(selected => {
      if (selected.salesperson_id == contact.salesperson_id) {
        selected.verified_on_date = contact.verified_on_date;
        selected.id = contact.salesperson_id;
      }
    });
  },
  setProspectsWithEmailSubscribeStatus(state, contact) {
    state.prospectingData.models.map(selected => {
      if (selected.salesperson_id == contact.salesperson_id) {
        selected.email_subscribe_status_id = contact.email_subscribe_status_id;
        selected.id = contact.salesperson_id;
      }
    });
  },
  // setProspectsAllListWithContactId(state, dataWithContactId) {
  //   dataWithContactId.map(data => {
  //     state.prospectingData.models.map(selected => {
  //       if (selected.salesperson_id == data.salesperson_id) {
  //         if (!!data.all_lists) {
  //           selected.all_lists = data.all_lists;
  //         } else if (data.hasOwnProperty("contact_data")) {
  //           selected.all_lists = data.contact_data.all_lists;
  //         } else {
  //           selected.all_lists = data.contact.all_lists;
  //         }
  //       }
  //     });
  //   });
  // },
  setWaiting(state, waitingData) {
    // [SET_WAITING]
    let { value, waitingType } = waitingData;

    if (waitingType == "prospectingData") {
      state.isWaitingForProspectingData = value;
    } else if (waitingType == "filterData") {
      state.isWaitingForFilterData = value;
    }
  },
  setTimestampForSearchFilter(state, field) {
    // [SET_TIMESTAMP_FOR_FILTER_SEARCH]
    state.latestTimeStampForFilterSearch[field] = moment().valueOf();
  },
  setFilterData(state, newSearchFilterData) {
    // debugger;
    let { data, field } = newSearchFilterData;

    let keyName = "";
    if (field.indexOf("_") != -1) {
      let replaceString = field.substring(field.indexOf("_")).substring(0, 2);
      let replaceWith = field
        .substring(field.indexOf("_") + 1)
        .substring(0, 1)
        .toUpperCase();
      keyName = helpers.methods.replaceWithGivenString(
        field,
        replaceString,
        replaceWith
      );
    } else {
      keyName = field;
    }

    state.freelancerFiltersData.map(el => {
      if (Object.keys(el)[0] == keyName) {
        el[keyName] = data;
      }
    });
  },
  setAppliedFilter(state, newFilterData) {
    // SET_APPLIED_FILTER
    let { data, filterName } = newFilterData;
    if (filterName == "postingVisibility") {
      state.appliedFilters.filters[filterName][0] = data;
    } else if (filterName != "fullSearch") {
      state.appliedFilters.filters[filterName] = [...data];
    } else if (filterName == "fullSearch") {
      state.appliedFilters.fullSearch = data;
    }
    const keysToKeep = Object.keys(state.appliedFilters.filters).filter(key => {
      return state.appliedFilters.filters[key].length > 0;
    });
    const newObj = {};

    keysToKeep.forEach(key => {
      newObj[key] = state.appliedFilters.filters[key];
    });
    state.appliedFilters.filters = newObj;
    state.appliedFilters.pages = { page: 1, pageSize: 20 };
  },
  setSearchFilterData(state, newSearchFilterData) {
    let { data, field } = newSearchFilterData;
    let replaceString = field.substring(field.indexOf("_")).substring(0, 2);
    let replaceWith = field
      .substring(field.indexOf("_") + 1)
      .substring(0, 1)
      .toUpperCase();
    let keyName = helpers.methods.replaceWithGivenString(
      field,
      replaceString,
      replaceWith
    );
    state.freelancerFiltersData.map(el => {
      if (Object.keys(el)[0] == keyName) {
        el[keyName] = data;
      }
    });
  },
  setTimestampForFilterData(state, field) {
    // SET_TIMESTAMP_FOR_FILTER_DATA
    state.latestTimeStampForFilterData[field] = moment().valueOf();
  },
  setData(state, prospectsData) {
    state.prospectingData.models = prospectsData.data.models;
    state.prospectingData._meta = prospectsData.data._meta;

    state.availableContactIds = [];
    state.prospectingData.models.map(contact => {
      state.availableContactIds.push(contact.contact_id);
    });
    state.availableContactIds.sort();
  },
  setIsloadsearchappliedfilterFlag(state, data) {
    // SET_ISLOADSEARCHAPPLIEDFILTER_FLAG
    state.isLoadSearchAppliedFilter = data;
  },
  setSalesRoles(state, data) {
    state.salesRole = data;
  },
  setCurrentPage(state, data) {
    state.appliedFilters.pages.page = parseInt(data);
  },
  setPageLimit(state, data) {
    state.appliedFilters.pages.pageSize = parseInt(data);
  },
  setEntireAppliedFilter(state, newFilterData) {
    // SET_ENTIRE_APPLIED_FILTER
    try {
      state.appliedFilters.filters = JSON.parse(newFilterData);
    } catch (error) {
      state.appliedFilters.filters = { ...newFilterData };
    }

    const keysToKeep = Object.keys(state.appliedFilters.filters).filter(key => {
      return state.appliedFilters.filters[key].length > 0;
    });
    const newObj = {};

    keysToKeep.forEach(key => {
      newObj[key] = state.appliedFilters.filters[key];
    });
    state.appliedFilters.filters = newObj;
    // state.showSaveFilterButton = true;
  },
  setProspectsWithVerificationDate(state, data) {
    state.allSelectedFilters = data;
  },
  setSelectedFreelancerVisibility(state, freelancer) {
    state.prospectingData.models.map(selected => {
      if (selected.id == freelancer.id) {
        selected.is_visible = freelancer.value;
      }
    });
  },
  addedVideosForFreelancer(state, freelancer) {
    state.prospectingData.models.map(selected => {
      if (selected.id == freelancer.id) {
        selected.video_urls = freelancer.video_urls;
      }
    });
  },
  toggleFeaturedFreelancer(state, freelancer) {
    state.prospectingData.models.map(selected => {
      if (selected.id == freelancer.id) {
        selected.is_featured = freelancer.is_featured;
      }
    });
  }
};

export const getters = {
  prospectingData(state) {
    return state.prospectingData;
  },
  isWaitingForProspectingData(state) {
    return state.isWaitingForProspectingData;
  },
  freelancerFiltersData(state) {
    return state.freelancerFiltersData;
  },
  showSaveFilterButton() {
    return state.showSaveFilterButton;
  },
  appliedFilters(state) {
    return state.appliedFilters;
  },
  savedSearchList(state) {
    return state.savedSearchList;
  },
  recentActivitiesList(state) {
    return state.recentActivitiesList;
  },
  allActivitiesList(state) {
    return state.allActivitiesList;
  },
  isWaitingForFilterData(state) {
    return state.isWaitingForFilterData;
  },
  SalesRoles(state) {
    return state.salesRole;
  },
  selectedProspects(state) {
    return state.selectedProspects;
  },
  selectedContactIds(state) {
    return state.selectedContactIds;
  },
  selectedProspectIds(state) {
    return state.selectedProspectIds;
  },
  availableContactIds(state) {
    return state.availableContactIds;
  },
  allSelectedFilters(state) {
    return state.allSelectedFilters;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
