import axios from "axios";
import config from "../config.js";
import store from "../store";

export default {
  getAllGroupsAndSubGroupsOfOganization: function(data) {
    return new Promise(function(resolve, reject) {
      console.log("getAllGroupsOfOganization -- ");
      let prepend = "";
      if (!!data.subscribed) prepend += "?subscribed=1";
      if (!!data.joined) prepend += "?&joined=1";
      if (!!data.joined_feed) prepend += "?&joined_feed=1";
      let url =
        config.DOMAIN +
        config.API_VERSION +
        "organizations/" +
        data.organization_id +
        "/organizationgroups/allsubgroups" +
        prepend;
      axios
        .get(url)
        .then(response => {
          resolve(response);
        })
        .catch(e => {
          console.error("Error: ", e);
          reject(e);
        }); //axios
    });
  },

  getAllGroupsOfOganization: function(data) {
    // this will only fetch who has parent_id null
    return new Promise(function(resolve, reject) {
      var url =
        config.DOMAIN +
        config.API_VERSION +
        "organizations/" +
        data.organization_id +
        "/organizationgroups?" +
        "&expand=group_user,join_request";

      axios
        .get(url)
        .then(response => {
          resolve(response);
        })
        .catch(e => {
          reject(e);
        });
    }); // promise.
  },

  attachTagToGroup: function(data) {
    // format: { data.group_id, data.tag_id }
    return new Promise((resolve, reject) => {
      let url =
        config.DOMAIN +
        config.API_VERSION +
        "organizations/" +
        store.state.auth.whoami.active_organization.id +
        "/organizationgroups/" +
        data.group_id +
        "/attachtag";
      axios
        .post(url, data)
        .then(response => {
          resolve(response);
        })
        .catch(e => {
          reject(e);
        }); //axios
    });
  },

  detachTagFromGroup: function(data) {
    // format: { data.group_id, data.tag_id }
    return new Promise((resolve, reject) => {
      let url =
        config.DOMAIN +
        config.API_VERSION +
        "organizations/" +
        store.state.auth.whoami.active_organization.id +
        "/organizationgroups/" +
        data.group_id +
        "/detachtag";
      axios
        .post(url, data)
        .then(response => {
          resolve(response);
        })
        .catch(e => {
          reject(e);
        }); //axios
    });
  },

  getPublicGroups: function() {
    return new Promise((resolve, reject) => {
      let url = config.DOMAIN + config.API_VERSION + "publicgroups";
      axios
        .get(url)
        .then(response => {
          resolve(response);
        })
        .catch(e => {
          reject(e);
        }); //axios
    });
  },

  getUserJoinRequestOfGroup: function(data) {
    // format: { data.group_id, data.tag_id }
    return new Promise((resolve, reject) => {
      let url =
        config.DOMAIN +
        config.API_VERSION +
        "organizations/" +
        data.organization_id +
        "/organizationgroups/" +
        data.organization_group_id +
        "/request/users";
      axios
        .get(url)
        .then(response => {
          resolve(response);
        })
        .catch(e => {
          reject(e);
        }); //axios
    });
  },

  getGroupsOfUser: function(data) {
    return new Promise((resolve, reject) => {
      // let type = !!data.subscribed ? "subscribed=1&" : "";
      // type = !!data.joined ? "joined=1&" : "";
      let url =
        config.DOMAIN +
        config.API_VERSION +
        "organizations/" +
        data.organization_id +
        "/organizationgroups?" +
        data.type.toLowerCase() +
        "&expand=group_user,join_request";
      axios
        .get(url)
        .then(response => {
          resolve(response);
        })
        .catch(e => {
          reject(e);
        }); //axios
    });
  },
  getGroupsOfUserById: function(data) {
    return new Promise((resolve, reject) => {
      // let type = !!data.subscribed ? "subscribed=1&" : "";
      // type = !!data.joined ? "joined=1&" : "";
      let url =
        config.DOMAIN +
        config.API_VERSION +
        "organizations/" +
        data.organization_id +
        "/organizationgroups?" +
        data.type +
        "&" +
        data.user_id +
        "&expand=group_user,join_request";
      axios
        .get(url)
        .then(response => {
          resolve(response);
        })
        .catch(e => {
          reject(e);
        }); //axios
    });
  },
  getPublicJoinedGroups: function() {
    return new Promise((resolve, reject) => {
      let url =
        config.DOMAIN +
        config.API_VERSION +
        "publicjoinedgroups?expand=group_user,join_request,external_group_user";
      axios
        .get(url)
        .then(response => {
          resolve(response);
        })
        .catch(e => {
          reject(e);
        }); //axios
    });
  },

  getGroupMembers: function(data) {
    console.log("getGroupMembers called");
    return new Promise((resolve, reject) => {
      let url =
        config.DOMAIN +
        config.API_VERSION +
        "organizations/" +
        data.organization_id +
        "/organizationgroups/" +
        data.organization_group_id +
        "/users";
      // "publicjoinedgroups?expand=group_user,join_request,external_group_user";
      axios
        .get(url)
        .then(response => {
          console.log("getGroupMembers : promise", response);
          resolve(response);
        })
        .catch(e => {
          reject(e);
        }); //axios
    });
  },
  getMemberPermission: function(data) {
    console.log("getMemberPermission called");
    // http://127.0.0.1:8000/api/v1/organizations/1/organizationgroups/1/users/1/permissions
    return new Promise((resolve, reject) => {
      let url =
        config.DOMAIN +
        config.API_VERSION +
        "organizations/" +
        data.organization_id +
        "/organizationgroups/" +
        data.organization_group_id +
        "/users/" +
        data.organization_group_user_id +
        "/permissions";
      // "publicjoinedgroups?expand=group_user,join_request,external_group_user";
      axios
        .get(url)
        .then(response => {
          console.log("getMemberPermission : promise", response);
          resolve(response);
        })
        .catch(e => {
          reject(e);
        }); //axios
    });
  },
  saveMemberPermission: function(data) {
    console.log("getMemberPermission called");
    // http://127.0.0.1:8000/api/v1/organizations/1/organizationgroups/1/users/1/permissions
    return new Promise((resolve, reject) => {
      let url =
        config.DOMAIN +
        config.API_VERSION +
        "organizations/" +
        data.organization_id +
        "/organizationgroups/" +
        data.organization_group_id +
        "/users/" +
        data.organization_group_user_id +
        "/permissions";
      // "publicjoinedgroups?expand=group_user,join_request,external_group_user";
      axios
        .post(url, data)
        .then(response => {
          console.log("getMemberPermission : promise", response);
          resolve(response);
        })
        .catch(e => {
          reject(e);
        }); //axios
    });
  },

  createJoinLink: function(data) {
    return new Promise((resolve, reject) => {
      console.log("createJoinLink", data);
      let url =
        config.DOMAIN +
        config.API_VERSION +
        "organizations/" +
        store.state.auth.whoami.active_organization.id;

      if (data.link_for != "organization")
        url +=
          "/organizationgroups/" +
          store.state.auth.whoami.active_organization_group.id;

      url += "/create_join_link";

      axios
        .post(url, data)
        .then(response => {
          resolve(response);
        })
        .catch(e => {
          reject(e);
        }); //axios
    });
  }
};
