import axios from "axios";
import toastr from "toastr";
import config from "../../config.js";
import * as moment from "moment";

export default {
  namespaced: true,
  state: {
    domainsData: [],
    domainsDataVerified: [],
    domainsVerifiedForLinksWithAds: []
  },

  getters: {},
  mutations: {
    setDomains(state, data) {
      console.log("in mutations setDomains - ", data);
      Vue.set(state, "domainsData", data);
      state.domainsDataVerified = {
        ...state.domainsData.filter(uc => uc.is_verified == true)
      };
      state.domainsVerifiedForLinksWithAds = {
        ...state.domainsData.filter(
          uc => uc.is_verified == true && uc.is_links_only != 1
        )
      };
    },
    deleteDomain(state, data) {
      const index = state.domainsData.findIndex(uc => uc.id === data);
      state.domainsData.splice(index, 1);
    },
    updateDefaultDomain(state, data) {
      const index = state.domainsData.findIndex(uc => uc.id === data.id);
      state.domainsData.splice(index, 1, data);
    },
    createDomain(state, data) {
      state.domainsData.push(data);
    },
    verifyDomain(state, data) {
      const index = state.domainsData.findIndex(uc => uc.id == data.id);
      state.domainsData.splice(index, 1, data);
    }
  },

  actions: {
    getAllDomains({ state, commit, rootState }, data) {
      console.log("getAllDomains() Called", data);
      return new Promise(function(resolve, reject) {
        let url =
          config.DOMAIN +
          config.API_VERSION +
          "organizations/" +
          rootState.auth.whoami.active_organization.id +
          "/domain";
        if (!!data && !!data.limit) url = url + "?limit=" + data.limit;

        if (!!data && !!data.offset) url = url + "&offset=" + data.offset;

        if (!!data && data.hasOwnProperty("count_required"))
          url = url + "&count_required=" + data.count_required;

        axios
          .get(url)
          .then(response => {
            resolve(response);
            console.log(response.data);
            //if (!data.hasOwnProperty("count_required"))
            //commit("setDomains", response.data.domains);
          })
          .catch(e => {
            console.log("Error: ", e);
          }); //axios
      });
    },
    createDomain({ state, commit, rootState }, data) {
      return new Promise(function(resolve, reject) {
        axios
          .post(
            config.DOMAIN +
              config.API_VERSION +
              "organizations/" +
              rootState.auth.whoami.active_organization.id +
              "/domain",
            data
          )
          .then(response => {
            resolve(response.data);
            console.log("Domain saved!!! ", response.data);
            commit("createDomain", response.data);
            toastr.error("Domain created successfully.", "", { timeOut: 5000 });
          })
          .catch(e => {
            console.log("Domain Post Error: ", e, e.response.data.message);
            reject(e.response.data.message);
          }); //axios
      });
    },
    deleteDomain({ state, commit, rootState }, domainId) {
      console.log("deleteAd() Called", domainId);
      axios
        .delete(
          config.DOMAIN +
            config.API_VERSION +
            "organizations/" +
            rootState.auth.whoami.active_organization.id +
            "/domain/" +
            domainId
        )
        .then(response => {
          toastr.success("Domain deleted successfully.", "", {
            timeOut: 5000
          });
          commit("deleteDomain", domainId);
          commit("updateDefaultDomain", response.data);
        })
        .catch(e => {
          console.log("Error: ", e);
        }); //axios
    },
    verifyDomain({ state, commit, rootState }, domainId) {
      console.log("verify domain");

      axios
        .get(
          config.DOMAIN +
            config.API_VERSION +
            "organizations/" +
            rootState.auth.whoami.active_organization.id +
            "/domain/verify/" +
            domainId
        )
        .then(response => {
          console.log(response.data);
          if (response.data.is_verified == 1) {
            toastr.success("Domain verified successfully.", "", {
              timeOut: 5000
            });
            commit("verifyDomain", response.data);
          } else {
            toastr.success("Domain verification failed", "", {
              timeOut: 5000
            });
          }
        })
        .catch(e => {});
    },
    setDefaultDomain({ state, commit, dispatch, rootState }, domainId) {
      axios
        .get(
          config.DOMAIN +
            config.API_VERSION +
            "organizations/" +
            rootState.auth.whoami.active_organization.id +
            "/domain/setdefault/" +
            domainId
        )
        .then(response => {
          console.log(response.data);
          toastr.success("Domain set as default successfully.", "", {
            timeOut: 5000
          });
          let activeOrganization = rootState.auth.whoami.active_organization;
          activeOrganization.default_domain_id = response.data.id;
          commit("auth/setActiveOrganization", activeOrganization, {
            root: true
          });
          dispatch("auth/whoAmI", null, { root: true });
          dispatch(
            "auth/getSubscriptionStatus",
            response.data.organization_id,
            { root: true }
          );
        })
        .catch(e => {});
    },

    updateDomain({ state, commit, dispatch, rootState }, domain) {
      return new Promise(function(resolve, reject) {
        axios
          .put(
            config.DOMAIN +
              config.API_VERSION +
              "organizations/" +
              rootState.auth.whoami.active_organization.id +
              "/domain/" +
              domain.id,
            domain
          )
          .then(response => {
            resolve(response);
            toastr.success("Domain updated successfully.", "", {
              timeOut: config.TOASTR_TIMEOUT
            });
            // commit("updateDomain", domain);
          })
          .catch(e => {
            console.log("Error: ", e);
          }); //axios
      });
    },
    setDomains({ state, commit, dispatch, rootState }, data) {
      commit("setDomains", data);
    },
    setPrimaryDomain({ state, commit, dispatch, rootState }, domainId) {
      axios
        .get(
          config.DOMAIN +
            config.API_VERSION +
            "organizations/" +
            rootState.auth.whoami.active_organization.id +
            "/domain/setprimary/" +
            domainId
        )
        .then(response => {
          console.log(response.data);
          toastr.success("Domain set as primary successfully.", "", {
            timeOut: 5000
          });
          let activeOrganization = rootState.auth.whoami.active_organization;
          activeOrganization.default_login_domain_id = response.data.id;
          commit("auth/setActiveOrganization", activeOrganization, {
            root: true
          });
          dispatch("auth/whoAmI", null, { root: true });
          dispatch(
            "auth/getSubscriptionStatus",
            response.data.organization_id,
            { root: true }
          );
        })
        .catch(e => {});
    }
  }
};
