import axios from "axios";
import toastr from "toastr";
import config from "../../config.js";
import * as moment from "moment";

export default {
  namespaced: true,
  state: {
    socialAccounts: []
  },

  getters: {},

  mutations: {
    setSocialAdAccounts(state, data) {
      state.socialAccounts = data;
    },
    updateSocialAdAccounts(state, data) {
      const index = state.socialAccounts.findIndex(
        element => element.id === data.id
      );
      state.socialAccounts.splice(index, 1);
    }
  },

  actions: {
    setSocialAdAccounts({ state, commit }, data) {
      commit("setSocialAdAccounts", data);
    },
    updateSocialAdAccounts({ state, commit }, data) {
      commit("updateSocialAdAccounts", data);
    }
  }
};
