import * as moment from "moment";
import objCampaignEmail from "./campaignEmail.js";

export default {
  id: null,
  name: null,
  organization_page_id: 0,
  createdby_id: null,
  views_count: 0,
  view_time: 0,
  status: 0,
  hub_id: 0,
  is_multiple_email_campaign: true, //  was is_single_email
  start_sending_asap: true,
  send_email_after_day: null,
  send_email_from_date: moment.parseZone().format("YYYY-MM-DD"),
  send_email_from_time: null,

  send_email_every_day: 3,
  send_email_time: null,

  send_emails_time: null,
  send_emails_time_json: {
    send_emails_at: null,
    send_emails_start_time: "09:00:00",
    send_emails_end_time: "18:00:00",
    send_emails_ampm: null,
    send_emails_timezone: null
  },
  send_email_timezone: null,

  campaign_exit_criteria_id: 1,
  campaign_sender_type_id: 1,
  new_contact_receive_email_type_id: 1,

  organization_id: null,
  organization: null,
  organizationPage: null,
  campaignContacts: { models: [] },
  campaignSenders: { models: [] },
  campaignEmails: [],
  emailSignature: {},
  contents: [],
  emailTemplate: "",
  emailTemplateList: [],

  emails_per_day: 100,
  pause_between_emails: 11,

  selectedEmailIndex: null,

  is_active: false,

  working: 1,
  is_unsubscribe_link_for_multiple_email: false,
  send_email_days: null
};
